import { Column, Entity } from 'typeorm';
import { DirectMessageEvent as ApiDirectMessageEvent } from '@scaffold/mediccoms-api-client/models';
import { NewMessageEvent as RtmsDirectMessageEvent } from './rtm-events.model';
import { AbstractSyncedModel } from './abstract-synced-model';

@Entity('direct_message_events')
export class DirectMessageEvent extends AbstractSyncedModel {

    public conversation: string;

    get attachments(): string[] {
        if (this._attachments) {
            return JSON.parse(this._attachments);
        }
        return [];
    }

    set attachments(value: string[]) {
        this._attachments = JSON.stringify(value);
    }

    @Column({
        name: '_attachments',
        type: 'varchar',
        nullable: true
    })
    private _attachments: string;

    @Column({
        name: 'type',
        type: 'varchar',
        nullable: true
    })
    public type: 'message';

    @Column({
        name: 'message_body',
        type: 'varchar',
        nullable: true
    })
    public messageBody: string;

    @Column({
        name: 'delivered_at',
        type: 'varchar',
        nullable: true
    })
    public deliveredAt: string;

    @Column({
        name: 'sent_at',
        type: 'varchar',
        nullable: true
    })
    public sentAt: string;

    @Column({
        name: 'read_at',
        type: 'varchar',
        nullable: true
    })
    public readAt: string;

    @Column({
        name: 'sent_by',
        type: 'varchar',
        nullable: true
    })
    public sentBy: string;

    public static mapFromApi(data: Partial<ApiDirectMessageEvent>): Partial<DirectMessageEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            deliveredAt: data.delivered_at,
            messageBody: data.message_body,
            readAt: data.read_at,
            sentAt: data.sent_at,
            sentBy: data.sent_by,
            type: data.type,
            attachments: data.attachments,
        };
    }

    public static mapFromRtms(data: Partial<RtmsDirectMessageEvent>): Partial<DirectMessageEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.data.message_id,
            messageBody: data.data.message_body,
            sentAt: data.timestamp,
            sentBy: data.sender_id,
            type: 'message',
            attachments: data.data.attachments,
        };
    }

    public fill(data: Partial<DirectMessageEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.attachments !== undefined) {
            this.attachments = data.attachments;
        }
        if (data.deliveredAt !== undefined) {
            this.deliveredAt = data.deliveredAt;
        }
        if (data.messageBody !== undefined) {
            this.messageBody = data.messageBody;
        }
        if (data.readAt !== undefined) {
            this.readAt = data.readAt;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.sentBy !== undefined) {
            this.sentBy = data.sentBy;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
        return this;
    }

    public get status(): 'sent' | 'delivered' | 'read' {
        if (!this.deliveredAt) { return 'sent'; }
        if (!this.readAt) { return 'delivered'; }
        return 'read';
    }

    public toJSON() {
        return {
            id: this.id,
            attachments: this.attachments,
            deliveredAt: this.deliveredAt,
            messageBody: this.messageBody,
            readAt: this.readAt,
            sentAt: this.sentAt,
            sentBy: this.sentBy,
            type: this.type,
            conversation: this.conversation,
        };
    }

    public get sentAtDate(): Date {
        return this.sentAt ? new Date(this.sentAt) : null;
    }

    public is(otherModel: Partial<this>): boolean {
        return this.id === otherModel.id;
    }

    public readBy(id: string) {
        return !!this.readAt;
    }
}
