import { Component } from '@angular/core';
import { NewGroupMessageName } from './new-group-message-name';

@Component({
    selector: 'tablet-new-group-message-name-page',
    templateUrl: 'tablet-new-group-message-name.html',
    styleUrls: ['new-group-message-name.scss', 'tablet-new-group-message-name.scss'],
})
export class TabletNewGroupMessageNamePage extends NewGroupMessageName {

}
