import { GroupMessagingService } from './abstract.service';
import { merge, Observable, Subject } from 'rxjs';
import { GroupMessageConversation } from '../models/group-messaging/group-message-conversation';
import { GroupMessageEvent } from '../models/group-messaging/group-message-event';
import { User } from '../models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { OrganisationService } from '../organisation.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { PrivateSettingsService } from '../private-settings.service';
import { UserService } from '../user.service';
import { GroupMessageConversationStore } from '../stores/group-message-conversation.store';
import { GroupMessageEventStore } from '../stores/group-message-event.store';
import { debounceTime, filter, first } from 'rxjs/operators';
import { SearchGroupMessageEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { GroupEvent } from '../models/group-messaging/group-event';
import { HttpEventType } from '@angular/common/http';
import { GroupMessageParticipantAddedEvent } from '../models/group-messaging/group-message-participant-added-event';
import { ConversationParticipantUpdated, RtmsEvent } from '../models/rtm-events.model';
import { GroupMessageParticipantRemovedEvent } from '../models/group-messaging/group-message-participant-removed-event';
import { GroupMessageReceipt } from '../models/group-messaging/group-message-receipt';
import { GroupMessageParticipantLeftEvent } from '../models/group-messaging/group-message-participant-left-event';

export class BrowserGroupMessagingService extends GroupMessagingService {

    protected conversations = new GroupMessageConversationStore();
    protected events = new GroupMessageEventStore();
    protected isReady = false;
    protected readySubject: Subject<boolean> = new Subject<boolean>();
    protected currentUser: User = null;

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, database, organisationService, rtms, storage, user);

        this.user.getMe().subscribe(me => this.currentUser = me);

        rtms.groupMessages.onConversationCreated().subscribe(conversation => this.rtmsConversationCreatedCallback(conversation));
        rtms.groupMessages.onConversationDeleted().subscribe(conversation => this.rtmsConversationDeletedCallback(conversation));
        rtms.groupMessages.onMessage().subscribe(event => this.rtmsEventCallback(event));
        rtms.groupMessages.onGroupParticipantAdded().subscribe(event => this.rtmsParticipantAddedEventCallback(event));
        rtms.groupMessages.onGroupParticipantRemoved().subscribe(event => this.rtmsParticipantLeftEventCallback(event));
        rtms.groupMessages.onGroupParticipantLeft().subscribe(event => this.rtmsParticipantLeftEventCallback(event));
        rtms.groupMessages.onGroupParticipantUpdated().subscribe(event => this.rtmsParticipantUpdated(event));
        rtms.groupMessages.onMessageDelivered().subscribe(event => this.rtmsMessageDeliveredEventCallback(event));
        rtms.groupMessages.onMessageRead().subscribe(event => this.rtmsMessageReadEventCallback(event));
    }

    private rtmsConversationCreatedCallback(conversationEvent: RtmsEvent) {
        const conversation = this.conversations.findById(conversationEvent.target_id) || GroupMessageConversation.createOne({
            id: conversationEvent.target_id,
        });
        conversation.fillFromRtms(conversationEvent);
        this.conversations.store(conversation);
    }

    private rtmsConversationDeletedCallback(conversationEvent: RtmsEvent) {
        const conversation = this.conversations.findById(conversationEvent.target_id);
        const events = this.events.filterByConversation(conversationEvent.target_id);
        for (const eventRemove of events) {
            this.events.remove(eventRemove);
        }
        if (conversation) {
            this.conversations.remove(conversation);
        }
    }

    private rtmsEventCallback(rtmsEvent: RtmsEvent) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        const event = this.events.findById(rtmsEvent.data.message_id) || GroupMessageEvent.createOne({
            id: rtmsEvent.data.message_id,
            conversation: conversation.id,
            type: rtmsEvent.event,
        });
        event.fillFromRtms(rtmsEvent);
        event.conversation = conversation.id;
        if (event.id) {
            this.events.store(event);
        }
        conversation.addEvent(event);
        this.conversations.store(conversation);
    }

    private rtmsParticipantUpdated(rtmsEvent: ConversationParticipantUpdated) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        const memberIndex = conversation.members.findIndex(participant => participant.id === rtmsEvent.data.participant.id);
        if (memberIndex >= 0) {
            conversation.members[memberIndex].admin = rtmsEvent.data.is_admin;
        }
        this.conversations.store(conversation);
    }

    private rtmsParticipantAddedEventCallback(rtmsEvent: RtmsEvent) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        // tslint:disable-next-line:max-line-length
        const event = this.events.findById(rtmsEvent.data.message_id) as GroupMessageParticipantAddedEvent || GroupMessageParticipantAddedEvent.createOne({
            id: rtmsEvent.data.target_id,
            conversation: conversation.id,
            type: rtmsEvent.event,
        });
        event.fillFromRtms(rtmsEvent);
        event.conversation = conversation.id;
        if (event.id) {
            this.events.store(event);
        }
        conversation.addEvent(event);
        if (conversation.members) {
            const memberIndex = conversation.members.findIndex(member => member.id === event.participant.id);
            if (memberIndex) {
                conversation.members[memberIndex] = event.participant;
            } else {
                conversation.members.push(event.participant);
            }
        } else {
            conversation.members = [
                event.participant
            ];
        }
        this.conversations.store(conversation);
    }

    private rtmsParticipantLeftEventCallback(rtmsEvent: RtmsEvent) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        if (rtmsEvent.data.participant.id === this.currentUser.id) {
            this.conversations.remove(conversation);
            const events = this.events.filterByConversation(conversation.id);
            for (const eventRemove of events) {
                this.events.remove(eventRemove);
            }
            return;
        }

        let event;
        switch (rtmsEvent.event) {
            case 'participant_left':
                event = this.events.findById(rtmsEvent.data.message_id) || GroupMessageParticipantLeftEvent.createOne({
                    id: rtmsEvent.data.event_id,
                    conversation: conversation.id,
                    type: rtmsEvent.event,
                });
                break;
            case 'participant_removed':
                event = this.events.findById(rtmsEvent.data.message_id) || GroupMessageParticipantRemovedEvent.createOne({
                    id: rtmsEvent.data.event_id,
                    conversation: conversation.id,
                    type: rtmsEvent.event,
                });
                break;
        }

        if (event) {
            event.fillFromRtms(rtmsEvent);
            event.conversation = conversation.id;
            if (event.id) {
                this.events.store(event);
            }
            conversation.addEvent(event);
        }

        const memberToRemove = conversation.members.findIndex(participant => participant.id === rtmsEvent.data.participant.id);
        if (conversation.members[memberToRemove] !== undefined) {
            conversation.members.splice(memberToRemove, 1);
        }

        this.conversations.store(conversation);
    }

    private rtmsMessageDeliveredEventCallback(rtmsEvent: RtmsEvent) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        const event = this.events.findById(rtmsEvent.data.message_id) as GroupMessageEvent || GroupMessageEvent.createOne({
            id: rtmsEvent.data.message_id,
            conversation: conversation.id,
            type: 'message',
        });
        const deliveredEvent = GroupMessageReceipt.createOneFromRtms(rtmsEvent);
        if (event.deliveredAt) {
            if (!event.deliveredAt.find(currentEvent => currentEvent.user.id === deliveredEvent.user.id)) {
                event.deliveredAt.push(deliveredEvent);
            }
        } else {
            event.deliveredAt = [deliveredEvent];
        }
        if (event.id) {
            this.events.store(event);
        }
    }

    private rtmsMessageReadEventCallback(rtmsEvent: RtmsEvent) {
        const conversation = this.conversations.findById(rtmsEvent.target_id) || GroupMessageConversation.createOne({
            id: rtmsEvent.target_id,
        });
        const event = this.events.findById(rtmsEvent.data.message_id) as GroupMessageEvent || GroupMessageEvent.createOne({
            id: rtmsEvent.data.message_id,
            conversation: conversation.id,
            type: 'message',
        });
        event.readAt.push(GroupMessageReceipt.createOneFromRtms(rtmsEvent));
        const readEvent = GroupMessageReceipt.createOneFromRtms(rtmsEvent);
        if (event.readAt) {
            if (!event.readAt.find(currentEvent => currentEvent.user.id === readEvent.user.id)) {
                event.readAt.push(readEvent);
            }
        } else {
            event.readAt = [readEvent];
        }
        if (event.id) {
            this.events.store(event);
        }
    }

    public async createGroup(name: string, participants: string[], image: string = null): Promise<GroupMessageConversation> {
        const response = await this.api.groupMessages.createConversation({
            name,
            members: participants,
            image,
        }).toPromise();

        if (response.group) {
            const conversation = this.conversations.findById(response.group.id) || GroupMessageConversation.createOne({
                id: response.group.id
            });
            conversation.fillFromApi(response.group);
            return Promise.resolve(this.conversations.store(conversation));
        }

        return Promise.resolve(undefined);
    }

    public async fetchEvents(groupId: string, request: SearchGroupMessageEventsRequest): Promise<GroupEvent[]> {
        if (!groupId) {
            return;
        }
        if (!request) {
            request = {
                before: new Date().toISOString(),
            };
        }
        const response = await this.api.groupMessages.searchEvents(groupId, request).toPromise();
        if (!response) {
            return;
        }
        const {events = []} = response;
        const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
        const storedEvents: GroupEvent[] = [];

        for (const apiEvent of events) {
            if (apiEvent) {
                const event = this.events.findById(apiEvent.id) || GroupMessageConversation.createEvent({
                    id: apiEvent.id,
                    type: apiEvent.type,
                    conversation: conversation.id,
                });
                event.fillFromApi(apiEvent);
                event.conversation = conversation.id;
                storedEvents.push(event);
                if (event.id) {
                    this.events.store(event);
                }
            }
        }
        conversation.addEvents(storedEvents);
        this.conversations.store(conversation);
        return storedEvents;
    }

    public async fetchEvent(groupId: string, eventId: string): Promise<GroupEvent> {
        if (!groupId || !eventId) {
            return;
        }
        const {event = null} = await this.api.groupMessages.getEvent(groupId, eventId).toPromise();

        const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
        const storeEvent = this.events.findById(event.id) || GroupMessageConversation.createEvent({
            id: event.id,
            type: event.type,
            conversation: conversation.id,
        });
        storeEvent.fillFromApi(event);
        storeEvent.conversation = conversation.id;
        this.events.store(storeEvent);
        conversation.addEvent(storeEvent);
        this.conversations.store(conversation);
    }

    public async fetchGroup(groupId: string): Promise<GroupMessageConversation> {
        const response = await this.api.groupMessages.getConversation(groupId).toPromise();
        const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: response.group.id});
        conversation.fillFromApi(response.group);

        // tslint:disable-next-line:forin
        for (const participantIndex in conversation.members) {
            const participant = conversation.members[participantIndex];
            participant.user = this.user.users.findById(participant.id);
            conversation.members[participantIndex] = participant;
        }

        return this.conversations.store(conversation);
    }

    public async fetchGroups(): Promise<GroupMessageConversation[]> {
        let currentPage = 1;
        let perPage = 0;
        let totalItems = 0;

        do {
            const response = await this.api.groupMessages.allConversations({
                page: currentPage,
            }).toPromise();
            if (!response) {
                return;
            }
            for (const apiGroup of response.groups) {
                currentPage = response.pagination.current_page;
                perPage = response.pagination.per_page;
                totalItems = response.pagination.total_items;
                const conversation = this.conversations.findById(apiGroup.id) || GroupMessageConversation.createOne({
                    id: apiGroup.id,
                });
                conversation.fillFromApi(apiGroup);

                // tslint:disable-next-line:forin
                for (const participantIndex in conversation.members) {
                    const participant = conversation.members[participantIndex];
                    participant.user = this.user.users.findById(participant.id);
                    conversation.members[participantIndex] = participant;
                }

                this.conversations.store(conversation);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        return this.conversations.all();
    }

    public getEvents(groupId: string): Observable<GroupEvent[]> {
        return new Observable<GroupEvent[]>(observer => {
            const events = this.events;

            observer.next(events.filterByConversation(groupId));

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => {
                    if (event.conversation !== undefined) {
                        return event.conversation === groupId;
                    }
                    return false;
                }),
            ).subscribe(() => {
                observer.next(this.events.filterByConversation(groupId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getAllMessages(): Observable<GroupEvent[]> {
        return new Observable<GroupEvent[]>(observer => {
            const events = this.events;

            observer.next(events.messages());

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => event.type === 'message'),
            ).subscribe(() => {
                observer.next(this.events.messages());
            });

            return () => subscription.unsubscribe();
        });
    }

    public getEvent(groupId: string, eventId: string): Observable<GroupEvent> {
        return new Observable<GroupEvent>(observer => {
            const events = this.events;

            observer.next(events.findById(eventId));

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => {
                    if (event.conversation !== undefined) {
                        return event.conversation === groupId;
                    }
                    return event.id === eventId;
                }),
            ).subscribe(() => {
                observer.next(this.events.findById(eventId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getGroup(groupId: string): Observable<GroupMessageConversation> {
        return new Observable<GroupMessageConversation>(observer => {
            const conversations = this.conversations;

            observer.next(conversations.findById(groupId));

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                filter(conversation => conversation.id === groupId),
            ).subscribe(() => {
                observer.next(this.conversations.findById(groupId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getGroups(): Observable<GroupMessageConversation[]> {
        return new Observable<GroupMessageConversation[]>(observer => {
            const conversations = this.conversations;
            observer.next(conversations.all());

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(this.conversations.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    public async addParticipant(groupId: string, userId: string): Promise<boolean> {
        const {event = null} = await this.api.groupMessages.addParticipant(groupId, {
            user_id: userId,
        }).toPromise();

        if (event) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            // tslint:disable-next-line:max-line-length
            const storeEvent = this.events.findById(event.id) as GroupMessageParticipantAddedEvent || GroupMessageParticipantAddedEvent.createOne({
                id: event.id,
                type: 'participant_added',
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);

            conversation.members.push(storeEvent.participant);

            return Promise.resolve(!!this.conversations.store(conversation));
        }

        return Promise.resolve(false);
    }

    public async deleteGroup(groupId: string): Promise<boolean> {
        const {deleted = false} = await this.api.groupMessages.deleteConversation(groupId).toPromise();
        return Promise.resolve(deleted);
    }

    public async leaveGroup(groupId: string): Promise<boolean> {
        const {event = null} = await this.api.groupMessages.leaveConversation(groupId).toPromise();

        if (event) {
            const conversation = this.conversations.findById(groupId);
            const events = this.events.filterByConversation(groupId);
            for (const eventRemove of events) {
                this.events.remove(eventRemove);
            }
            if (conversation) {
                this.conversations.remove(conversation);
            }
            return Promise.resolve(true);
        }

        return Promise.resolve(false);
    }

    public async makeAdmin(groupId: string, userId: string): Promise<boolean> {
        const {success = false} = await this.api.groupMessages.makeAdmin(groupId, {
            user_id: userId,
        }).toPromise();

        if (success) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            if (conversation) {
                const memberIndex = conversation.members.findIndex(participant => participant.id === userId);
                conversation.members[memberIndex].admin = true;
                this.conversations.store(conversation);
            }
        }

        return Promise.resolve(success);
    }

    public async readMessage(groupId: string, messageId: string): Promise<GroupMessageConversation> {
        const {event = null} = await this.api.groupMessages.readEvent(groupId, messageId).toPromise();
        if (event) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            const storeEvent = this.events.findById(event.id) || GroupMessageEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            this.events.store(storeEvent);
            const eventIndex = conversation.events.findIndex(conversationEvent => conversationEvent.id === storeEvent.id);
            conversation.events[eventIndex] = storeEvent;
            return Promise.resolve(this.conversations.store(conversation));
        }
        return Promise.resolve(undefined);
    }

    public async removeAdmin(groupId: string, userId: string): Promise<boolean> {
        const {success = false} = await this.api.groupMessages.removeAdmin(groupId, {
            user_id: userId,
        }).toPromise();

        if (success) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            if (conversation) {
                const memberIndex = conversation.members.findIndex(participant => participant.id === userId);
                conversation.members[memberIndex].admin = false;
                this.conversations.store(conversation);
            }
        }

        return Promise.resolve(success);
    }

    public async removeParticipant(groupId: string, userId: string): Promise<boolean> {
        const {event = null} = await this.api.groupMessages.removeParticipant(groupId, {
            user_id: userId,
        }).toPromise();

        if (event) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            const storeEvent = this.events.findById(event.id) || GroupMessageParticipantRemovedEvent.createOne({
                id: event.id,
                type: 'participant_removed',
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);

            const memberToRemove = conversation.members.findIndex(participant => participant.id === userId);
            if (conversation.members[memberToRemove] !== undefined) {
                conversation.members.splice(memberToRemove, 1);
            }

            return Promise.resolve(!!this.conversations.store(conversation));
        }

        return Promise.resolve(false);
    }

    public async sendMessage(groupId: string, message: string, attachments: File[]): Promise<boolean> {
        const attachmentIds: string[] = [];

        if (attachments) {
            for (const attachment of attachments) {
                const response = await new Promise<string>((resolve) => {
                    this.api.attachments.uploadImage(attachment).subscribe(result => {
                        if (result.type === HttpEventType.Response) {
                            resolve(result.body.image_id);
                        }
                    });
                });
                attachmentIds.push(response);
            }
        }

        const {event = null} = await this.api.groupMessages.sendMessage(groupId, {
            message,
            attachments: attachmentIds
        }).toPromise();

        if (event) {
            const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: groupId});
            const storeEvent = this.events.findById(event.id) || GroupMessageEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);

            storeEvent.conversation = conversation.id;
            return Promise.resolve(!!this.events.store(storeEvent));
        }

        return Promise.resolve(false);
    }

    public clear(): Promise<void> {
        this.conversations.clear();
        this.events.clear();
        return Promise.resolve(undefined);
    }

    public loadFromMemory(): Promise<void> {
        this.isReady = true;
        this.readySubject.next(true);
        return Promise.resolve(undefined);
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.isReady) {
                return resolve(true);
            }

            this.readySubject.asObservable().pipe(
                first()
            ).subscribe((ready) => resolve(ready));
        });
    }

    public async renameGroup(groupId: string, groupName: string): Promise<GroupMessageConversation> {
        const response = await this.api.groupMessages.editConversation(groupId, {
            name: groupName
        }).toPromise();

        const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: response.group.id});
        conversation.fillFromApi(response.group);

        // tslint:disable-next-line:forin
        for (const participantIndex in conversation.members) {
            const participant = conversation.members[participantIndex];
            participant.user = this.user.users.findById(participant.id);
            conversation.members[participantIndex] = participant;
        }

        return this.conversations.store(conversation);
    }

    public async changeGroupIcon(groupId: string, groupIcon: string): Promise<GroupMessageConversation> {
        const response = await this.api.groupMessages.editConversation(groupId, {
            image: groupIcon
        }).toPromise();

        const conversation = this.conversations.findById(groupId) || GroupMessageConversation.createOne({id: response.group.id});
        conversation.fillFromApi(response.group);

        // tslint:disable-next-line:forin
        for (const participantIndex in conversation.members) {
            const participant = conversation.members[participantIndex];
            participant.user = this.user.users.findById(participant.id);
            conversation.members[participantIndex] = participant;
        }

        return this.conversations.store(conversation);
    }

}
