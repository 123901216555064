import { Component } from '@angular/core';
import { InstructionsPage } from './instructions';

@Component({
    selector: 'app-large-case-instructions-page',
    templateUrl: 'instructions.html',
    styleUrls: ['instructions.scss', 'large-instructions.scss'],
})
export class LargeInstructionsPage extends InstructionsPage {

}
