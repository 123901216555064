import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { DirectMessagingService } from './abstract.service';
import { BrowserDirectMessagingService } from './browser.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { MobileDirectMessagingService } from './mobile.service';
import { PrivateSettingsService } from '../private-settings.service';

export const DirectMessagingServiceFactory = (
    api: ApiClientService,
    database: DatabaseService,
    rtms: RealTimeMessagingService,
    platform: Platform,
    storage: PrivateSettingsService,
    user: UserService,
): DirectMessagingService => {
    const service = platform.is('cordova') ? MobileDirectMessagingService : BrowserDirectMessagingService;
    return new service(api, database, rtms, storage, user);
};
