import { BeforeInsert, BeforeUpdate, Column, Entity } from 'typeorm';
import { AbstractSyncedModel } from './abstract-synced-model';
import { Organisation as ApiOrganisation } from '@scaffold/mediccoms-api-client/models/organisation';
import { Address } from './address';
import { PrimaryContact } from './primary-contact';

@Entity('organisations')
export class Organisation extends AbstractSyncedModel {

    @Column({
        name: 'image',
        type: 'varchar',
        nullable: true
    })
    public image: string = null;

    @Column({
        name: 'name',
        type: 'varchar',
        nullable: true
    })
    public name: string = null;

    @Column({
        name: 'primary_contact',
        type: 'varchar',
        nullable: true,
        transformer: {
            to: value => JSON.stringify(value),
            from: value => PrimaryContact.createOne(JSON.parse(value)),
        }
    })
    public primaryContact: PrimaryContact = null;

    @Column({
        name: 'address',
        type: 'varchar',
        nullable: true,
        transformer: {
            to: value => JSON.stringify(value),
            from: value => Address.createOne(JSON.parse(value)),
        }
    })
    public address: Address = null;

    public static mapFromApi(data: Partial<ApiOrganisation>): Partial<Organisation> {
        if (!data) { return null; }

        return {
            id: data.id,
            image: data.image,
            name: data.name,
            address: Address.createOne(data.address as Partial<Address>),
            primaryContact: PrimaryContact.createOne(data.primary_contact as Partial<PrimaryContact>),
        };
    }

    public fill(data: Partial<Organisation>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.address !== undefined) {
            this.address = Address.createOne(data.address);
        }
        if (data.primaryContact !== undefined) {
            this.primaryContact = PrimaryContact.createOne(data.primaryContact);
        }
    }

}
