import { Component, ViewChild, ElementRef } from '@angular/core';
import { AWSError, CognitoIdentityServiceProvider } from 'aws-sdk';
import { Router } from '@angular/router';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';

@Component({
    selector: 'app-forgot-password-change-page',
    template: '',
    styleUrls: ['forgot-password-change.scss'],
})

export abstract class ForgotPasswordChangePage {

    public confirmationCode = '';
    public passwordNew = '';
    public passwordNewConfirm = '';

    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private router: Router,
    ) {
    }

    public addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    public clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    public goBackToPreviousPage() {
        this.router.navigate(['/forgot-password']);
    }

    public confirmForgotPassword() {

        this.clearErrors();

        if (this.confirmationCode === '' || this.passwordNew === '' || this.passwordNewConfirm === '') {
            this.addError('All Fields Required.');
            return;
        }

        if (this.passwordNew !== this.passwordNewConfirm) {
            this.addError('New Passwords Do Not Match.');
            return;
        }

        this.clearErrors();
        this.isLoading = true;
        this.cognitoAuth.confirmForgotPassword(this.confirmationCode, this.passwordNew)
            .then(response => {
                if (response) {
                    this.router.navigate(['/forgot-password-changed']);
                } else {
                    this.addError('An Unexpected Error Occurred');
                    this.isLoading = false;
                }
            }).catch(error => {
            this.addError(error);
            this.isLoading = false;
        });
    }

    public ionViewWillEnter() {
        this.confirmationCode = '';
        this.passwordNew = '';
        this.passwordNewConfirm = '';

        if (!this.cognitoAuth.hasForgotPasswordResponse()) {
            this.router.navigate(['/login']);
        }
    }
}
