import { NgModule } from '@angular/core';
import { ApiClientModule, ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { SettingsServiceFactory } from './factory';
import { SettingsService } from './abstract.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';

@NgModule({
    // imports: [
    //     ApiClientModule,
    // ],
    providers: [
        DatabaseService,
        // RealTimeMessagingService,
        {
            provide: SettingsService,
            useFactory: SettingsServiceFactory,
            deps: [
                // ApiClientService,
                DatabaseService,
                // RealTimeMessagingService,
                Platform,
            ],
        }
    ],
})
export class SettingsServiceModule {}
