import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { CaseConversation } from '../../services/models/case-conversation';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { OrganisationService } from '../../services/organisation.service';
import { Branch } from '../../services/models/branch';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { RemoteDataService } from '../../services/remote-data.service';

@Injectable()
export class CasesService {

    private activeTab: string = null;
    private cases: CaseConversation[] = [];
    private currentUser: User = null;
    private currentUserBranches: string[] = [];
    private fetchBranchesRequest: Promise<Branch[]> = null;
    private searchTerm = '';
    private users: User[] = [];
    private branches: Branch[] = [];

    public allCases: CaseConversation[] = [];
    public myCases: CaseConversation[] = [];
    public assignedCases: CaseConversation[] = [];
    public openCases: CaseConversation[] = [];
    public incomingCases: CaseConversation[] = [];
    public resolvedCases: CaseConversation[] = [];
    public unassignedCases: CaseConversation[] = [];

    constructor(
        private alertCtrl: AlertController,
        private api: ApiClientService,
        private caseMessaging: CaseMessagingService,
        private cognitoAuthService: CognitoAuthenticationService,
        private organisationService: OrganisationService,
        private remoteData: RemoteDataService,
        private userService: UserService,
    ) {
        this.cognitoAuthService.getLogin().subscribe(() => {
            this.userService.fetchUsers();
        });
        this.userService.getMe().subscribe(me => {
            this.currentUser = me;
            if (me && me.organisation) {
                if (!this.fetchBranchesRequest) {
                    this.fetchBranchesRequest = this.organisationService.fetchBranches(me.organisation.id).then(branches => {
                        this.fetchBranchesRequest = null;
                        return this.branches = branches;
                    });
                }
            }
        });
        this.userService.getUsers().subscribe(users => {
            this.setUsers(users);
        });
        this.caseMessaging.getMyCases().subscribe(cases => this.setCases(cases.filter(item => item.createdAt)));
        this.caseMessaging.getAllCases().subscribe(cases => this.setIncomingCases(cases.filter(item => item.createdAt)));
        this.userService.getMyBranches().subscribe((branches) => this.currentUserBranches = branches);

        // this.caseMessaging.fetchAllCases().then(cases => {
        //     cases.forEach(conversation => {
        //         this.caseMessaging.fetchEvents(conversation.id);
        //     });
        // });
        //
        // this.caseMessaging.searchAllCases(null, null).then(cases => {
        //     cases.forEach(conversation => {
        //         this.caseMessaging.fetchEvents(conversation.id);
        //     });
        // });
    }

    public fetchCases() {
        return this.remoteData.fetchCases();
    }

    public searchCases() {
        return this.remoteData.searchCases();
    }

    public filterCase(conversation: CaseConversation) {
        const searchTerm = this.searchTerm;

        if (!searchTerm) {
            return true;
        }
        if (conversation.caseNumber?.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
        }
        if (conversation.patientId?.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
        }

        return false;
    }

    public getAssignedCases(): CaseConversation[] {
        return this.assignedCases.filter(conversation => this.filterCase(conversation));
    }

    public getUnassignedCases(): CaseConversation[] {
        return this.unassignedCases.filter(conversation => this.filterCase(conversation));
    }

    public getCases(): CaseConversation[] {
        return this.cases.filter(conversation => this.filterCase(conversation));
    }

    public getIncomingCases(): CaseConversation[] {
        return this.incomingCases.filter(conversation => this.filterCase(conversation));
    }

    public setIncomingCases(cases: CaseConversation[]) {
        this.incomingCases = cases.filter(item => {
            let canAssign = true;
            if (item?.createdBy?.id === this.currentUser?.id) {
                canAssign = false;
            }
            this.branches.forEach(branch => {
                if (branch.id === item.senderBranch?.id) {
                    canAssign = false;
                }
            });
            if (!this.currentUserBranches.includes(item.recipientBranch.id)) {
                canAssign = false;
            }
            return canAssign;
        }).filter(conversation => this.filterCase(conversation));
        this.assignedCases = this.incomingCases.filter(item => item.isAssigned);
        this.unassignedCases = this.incomingCases.filter(item => !item.isAssigned).filter(item => item.isOpen);

        this.users.forEach(user => this.associateUser(user));
    }

    public setCases(cases: CaseConversation[]) {
        this.cases = cases;
        this.allCases = cases.filter(conversation => this.filterCase(conversation));
        if (this.currentUser) {
            this.myCases = this.allCases.filter(item => item.isMine(this.currentUser.id));
        } else {
            this.myCases = [];
        }
        this.openCases = this.allCases.filter(item => item.isOpen);
        this.resolvedCases = this.allCases.filter(item => item.isResolved);
        // this.incomingCases = this.allCases.filter(item => (item.createdBy.id !== this.currentUser.id));
        // this.assignedCases = this.incomingCases.filter(item => item.isAssigned);
        // this.unassignedCases = this.incomingCases.filter(item => !item.isAssigned).filter(item => item.isOpen);

        this.users.forEach(user => this.associateUser(user));
    }

    public setUsers(users: User[]) {
        this.users = users;
        users.forEach(user => this.associateUser(user));
    }

    public associateUser(user: User) {
        for (const conversation of this.cases) {
            if (conversation.assignee?.id === user.id) { conversation.assignee = user; }
            if (conversation.createdBy?.id === user.id) { conversation.createdBy = user; }
        }
    }

    public getSearchTerm(): string {
        return this.searchTerm || '';
    }

    public setSearchTerm(searchTerm: string) {
        if (searchTerm === this.searchTerm) { return; }
        this.searchTerm = searchTerm;
        this.setCases(this.cases);
    }

    public getIncomingSearchTerm(): string {
        return this.searchTerm || '';
    }

    public setIncomingSearchTerm(searchTerm: string) {
        if (searchTerm === this.searchTerm) { return; }
        this.searchTerm = searchTerm;
        this.setIncomingCases(this.cases);
    }

    public getActiveTab(): string {
        return this.activeTab || '';
    }

    public setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

}
