import { AfterContentInit, Component, DoCheck, ElementRef, ViewChild } from '@angular/core';
import { DashboardPage } from './dashboard';

@Component({
    selector: 'app-phone-dashboard-page',
    templateUrl: 'phone-dashboard.html',
    styleUrls: ['dashboard.scss', 'phone-dashboard.scss'],
})
export class PhoneDashboardPage extends DashboardPage implements AfterContentInit, DoCheck {

    @ViewChild('page') pageRef: ElementRef;
    @ViewChild('widgets') widgetsRef: ElementRef;

    public dockHeight = 0;
    public distanceTop = 0;

    public ngAfterContentInit() {
        console.log('VIEW INIT');
        this.calculateDistanceTop();
        this.calculateDockHeight();
    }

    public ngDoCheck(): void {
        this.calculateDistanceTop();
        this.calculateDockHeight();
    }

    public calculateDistanceTop() {
        if (!this.pageRef) {
            return;
        }

        const page: HTMLElement = this.pageRef.nativeElement;
        const bounds = page.getBoundingClientRect();
        this.distanceTop = bounds.top;
    }

    public calculateDockHeight() {
        if (!this.widgetsRef) {
            return;
        }

        const widgets: HTMLElement = this.widgetsRef.nativeElement;
        const bounds = widgets.getBoundingClientRect();
        this.dockHeight = window.innerHeight - bounds.bottom - 32;
    }
}
