import { Component } from '@angular/core';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-message-group-shared-images-page',
    template: '',
    styleUrls: ['message-group-shared-images.scss'],
})

export abstract class MessageGroupSharedImages {
    public conversation: GroupMessageConversation = null;
    private conversationId: string = null;

    constructor(
        public alertController: AlertController,
        private groupMessaging: GroupMessagingService,
        private navCtrl: NavController,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
    ){
        route.params.subscribe((params: { conversation: string }) => {
            if (params.conversation !== this.conversationId) {
                this.conversationId = params.conversation;
                this.setConversation(params.conversation);
            }
        });
    }

    public setConversation(conversationId: string) {
        this.groupMessaging.getGroup(conversationId).subscribe((conversation) => {
            this.conversation = conversation;
        });
        this.groupMessaging.fetchGroup(conversationId).then(() => {
            this.groupMessaging.fetchEvents(conversationId, {
                before: new Date().toISOString()
            });
        });
    }

    public async navigateBack() {
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        await this.router.navigate([`/my-messages/group/${this.conversationId}/info`]);
    }
}
