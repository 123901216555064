import { BrowserGroupMessagingService } from './browser.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { PrivateSettingsService } from '../private-settings.service';
import { UserService } from '../user.service';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';
import { GroupMessageConversation } from '../models/group-messaging/group-message-conversation';
import { GroupMessageParticipant } from '../models/group-messaging/group-message-participant';
import { GroupMessageParticipantAddedEvent } from '../models/group-messaging/group-message-participant-added-event';
import { GroupMessageParticipantRemovedEvent } from '../models/group-messaging/group-message-participant-removed-event';
import { GroupMessageParticipantLeftEvent } from '../models/group-messaging/group-message-participant-left-event';

export class MobileGroupMessagingService extends BrowserGroupMessagingService {

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, database, organisationService, rtms, storage, user);
    }

    public loadFromMemory(): Promise<any> {
        return new Promise(async (resolve) => {
            let conversations = null;

            const conversationsRaw = await this.storage.get('group_message.conversations');
            if (conversationsRaw) {
                conversations = JSON.parse(conversationsRaw);
                for (const conversationData of conversations) {
                    // tslint:disable-next-line:max-line-length
                    const conversation = this.conversations.findById(conversationData.id) || GroupMessageConversation.createOne(conversationData);

                    const participants: GroupMessageParticipant[] = [];
                    for (const user of conversationData.members) {
                        const participant = GroupMessageParticipant.createOne(user);
                        participant.user = this.user.users.findById(participant.id);
                        participants.push(participant);
                    }

                    conversation.members = participants;
                    this.conversations.store(conversation);
                }
            }

            const eventsRaw = await this.storage.get('group_message.events');
            if (eventsRaw) {
                const events = JSON.parse(eventsRaw);
                for (const eventData of events) {
                    const event = this.events.findById(eventData.id) || GroupMessageConversation.createEvent(eventData);
                    event.conversation = eventData.conversation;

                    // tslint:disable-next-line:max-line-length
                    if (event instanceof GroupMessageParticipantAddedEvent || event instanceof GroupMessageParticipantRemovedEvent || event instanceof GroupMessageParticipantLeftEvent) {
                        if (event.participant) {
                            event.participant.user = this.user.users.findById(event?.participant?.id);
                        }
                    }
                    if (eventData.sentBy) {
                        event.sentBy = this.user.users.findById(eventData.sentBy);
                    }

                    const conversation = this.conversations.findById(eventData.conversation);
                    conversation.addEvent(event);
                    this.events.store(event);
                    this.conversations.store(conversation);
                }
            }

            this.setupSubscriptions();
            resolve(undefined);
            this.isReady = true;
            this.readySubject.next(true);
        });
    }

    private persistConversationsMemory() {
        const conversations = this.conversations.all();
        const time = Date.now();
        this.storage.set('group_message.conversations', JSON.stringify(conversations))
            .then(() => console.log('[GROUP CONVERSATIONS STORED]', conversations.length, Date.now() - time));
    }

    private persistEventsMemory() {
        const events = this.events.all();
        const time = Date.now();
        this.storage.set('group_message.events', JSON.stringify(events))
            .then(() => console.log('[GROUP EVENTS STORED]', events.length, Date.now() - time));
    }

    private setupSubscriptions(): void {
        merge(
            this.conversations.onInsert(),
            this.conversations.onUpdate(),
            this.conversations.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistConversationsMemory();
        });

        merge(
            this.events.onInsert(),
            this.events.onUpdate(),
            this.events.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistEventsMemory();
        });
    }
}
