import { Component } from '@angular/core';
import { MyMessages } from './my-messages';

@Component({
    selector: 'app-phone-my-messages-page',
    templateUrl: 'phone-my-messages.html',
    styleUrls: ['my-messages.scss', 'phone-my-messages.scss'],
})
export class PhoneMyMessagesPage extends MyMessages {

}
