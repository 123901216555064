/* tslint:disable:directive-selector */
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[phoneOnly]',
})
export class PhoneOnlyDirective implements AfterViewInit {

    private element: HTMLElement;

    constructor(private elementRef: ElementRef) {

    }

    public ngAfterViewInit(): void {
        this.element = this.elementRef.nativeElement;
    }
}
