import { Component } from '@angular/core';
import { ChangedPasswordPage } from './changed-password';

@Component({
    selector: 'app-phone-changed-password-page',
    templateUrl: 'phone-changed-password.html',
    styleUrls: ['changed-password.scss', 'phone-changed-password.scss'],
})
export class PhoneChangedPasswordPage extends ChangedPasswordPage {

}
