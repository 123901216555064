import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseOpenedEvent as ApiCaseOpenedEvent } from '@scaffold/mediccoms-api-client/models/case-opened-event';
import { CaseOpenedEvent as RtmsCaseOpenedEvent } from '../rtm-events.model';

export class CaseOpenedEvent extends CaseEvent {
    public type: string;
    public openedBy: User;

    public static mapFromApi(data: Partial<ApiCaseOpenedEvent>): Partial<CaseOpenedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            openedBy: User.mapFromApi(data.opened_by) as Required<User>,
            sentAt: data.sent_at,
        };
    }

    public static mapFromRtms(data: Partial<RtmsCaseOpenedEvent>): Partial<CaseOpenedEvent> {
        if (!data) {
            return null;
        }

        const openedBy = data.data.opened_by ? User.mapFromApi({
            id: data.data.opened_by.id,
            first_name: data.data.opened_by.first_name,
            last_name: data.data.opened_by.last_name,
            organisation_id: data.data.opened_by.organisation_id,
            image: data.data.opened_by.image,
            title: data.data.opened_by.title,
        }) : null;

        return {
            id: data.data.event_id,
            type: data.event,
            openedBy: openedBy as Required<User>,
            sentAt: data.timestamp,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            sentAt: this.sentAt,
            openedBy: this.openedBy ? this.openedBy.id : null,
            conversation: this.conversation,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public fill(data: Partial<CaseOpenedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.openedBy !== undefined) {
            this.openedBy = User.createOne(data.openedBy);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
        if (data.isOffline !== undefined) {
            this.isOffline = data.isOffline;
        }
        if (data.offlineId !== undefined) {
            this.offlineId = data.offlineId;
        }
    }
}
