import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseAssignedEvent as ApiCaseAssignedEvent } from '@scaffold/mediccoms-api-client/models/case-assigned-event';
import { CaseAssignedEvent as RtmsCaseAssignedEvent } from '../rtm-events.model';

export class CaseAssignedEvent extends CaseEvent {
    public type: string;
    public assignedBy: User;
    public newAssignee: User;
    public previousAssignee: User;

    public static mapFromApi(data: Partial<ApiCaseAssignedEvent>): Partial<CaseAssignedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            assignedBy: User.mapFromApi(data.assigned_by) as Required<User>,
            newAssignee: User.mapFromApi(data.new_assignee) as Required<User>,
            previousAssignee: User.mapFromApi(data.previous_assignee) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<RtmsCaseAssignedEvent>): Partial<CaseAssignedEvent> {
        if (!data) {
            return null;
        }

        const assignedBy = data.data.assigned_by ? User.mapFromApi({
            id: data.data.assigned_by.id,
            first_name: data.data.assigned_by.first_name,
            last_name: data.data.assigned_by.last_name,
            organisation_id: data.data.assigned_by.organisation_id,
            image: data.data.assigned_by.image,
            title: data.data.assigned_by.title,
        }) : null;

        const newAssignee = data.data.assigned_to ? User.mapFromApi({
            id: data.data.assigned_to.id,
            first_name: data.data.assigned_to.first_name,
            last_name: data.data.assigned_to.last_name,
            organisation_id: data.data.assigned_to.organisation_id,
            image: data.data.assigned_to.image,
            title: data.data.assigned_to.title,
        }) : null;

        const previousAssignee = data.data.previous_assignee ? User.mapFromApi({
            id: data.data.previous_assignee.id,
            first_name: data.data.previous_assignee.first_name,
            last_name: data.data.previous_assignee.last_name,
            organisation_id: data.data.previous_assignee.organisation_id,
            image: data.data.previous_assignee.image,
            title: data.data.previous_assignee.title,
        }) : null;

        return {
            id: data.data.event_id,
            type: data.event,
            sentAt: data.timestamp,
            assignedBy: assignedBy as Required<User>,
            newAssignee: newAssignee as Required<User>,
            previousAssignee: previousAssignee as Required<User>,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            assignedBy: this.assignedBy ? this.assignedBy.id : null,
            newAssignee: this.newAssignee ? this.newAssignee.id : null,
            previousAssignee: this.newAssignee ? this.previousAssignee.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public fill(data: Partial<CaseAssignedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.assignedBy !== undefined) {
            this.assignedBy = User.createOne(data.assignedBy);
        }
        if (data.newAssignee !== undefined) {
            this.newAssignee = User.createOne(data.newAssignee);
        }
        if (data.previousAssignee !== undefined) {
            this.previousAssignee = User.createOne(data.previousAssignee);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }
}
