import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { PrivateSettingsService } from './abstract.service';
import { BrowserSettingsService } from './browser.service';
import { MobileSettingsService } from './mobile.service';

export const SettingsServiceFactory = (
    database: DatabaseService,
    platform: Platform,
): PrivateSettingsService => {
    const service = platform.is('cordova') ? MobileSettingsService : BrowserSettingsService;
    return new service(database);
};
