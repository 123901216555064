import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { UserService } from '../services/user.service';
import { OrganisationService } from '../services/organisation.service';
import { DirectMessagingService } from '../services/direct-messaging.service';
import { CaseMessagingService } from '../services/case-messaging-service';
import { GroupMessagingService } from '../services/group-messaging.service';

@Injectable()
export class ReadyGuard implements CanActivate {
    constructor(
        private router: Router,
        private caseMessageService: CaseMessagingService,
        private directMessageService: DirectMessagingService,
        private groupMessageService: GroupMessagingService,
        private organisationService: OrganisationService,
        private userService: UserService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise( (resolve) => {
            Promise.all([
                this.caseMessageService.ready(),
                this.directMessageService.ready(),
                this.groupMessageService.ready(),
                this.organisationService.ready(),
                this.userService.ready(),
            ]).then(() => resolve(true));
        });
    }
}
