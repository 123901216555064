import { Component } from '@angular/core';
import { MessageGroupParticipant } from './message-group-participant';

@Component({
    selector: 'app-phone-message-group-participant-page',
    templateUrl: 'phone-message-group-participant.html',
    styleUrls: ['message-group-participant.scss', 'phone-message-group-participant.scss'],
})
export class PhoneMessageGroupParticipantPage extends MessageGroupParticipant {



}
