import { Component } from '@angular/core';
import { LoadingController, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { CognitoAuthenticationService } from './services/cognito-authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from './services/database.service';
import { DirectMessagingService } from './services/direct-messaging.service';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { HttpClient } from '@angular/common/http';
import { RealTimeMessagingService } from './services/real-time-messaging.service';
import { SettingsService } from './services/settings.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { CaseMessagingService } from './services/case-messaging-service';
import { OrganisationService } from './services/organisation.service';
import { PrivateSettingsService } from './services/private-settings.service';
import { GroupMessagingService } from './services/group-messaging.service';
import { fromEvent, merge } from 'rxjs';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { RemoteDataService } from './services/remote-data.service';
import { BiometricService } from './services/biometric.service';
import { EnvironmentService } from './services/environment.service';
import { AnalyticsService } from './services/analytics.service';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
// import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    public showMenu = false;
    private isOnline: boolean = null;

    private currentBioAuth: Promise<boolean> = null;

    constructor(
        private analytics: AnalyticsService,
        private api: ApiClientService,
        private biometric: BiometricService,
        private cognitoAuth: CognitoAuthenticationService,
        private caseMessaging: CaseMessagingService,
        private database: DatabaseService,
        private directMessaging: DirectMessagingService,
        private environment: EnvironmentService,
        private fcm: FirebaseMessaging,
        private faio: FingerprintAIO,
        private groupMessaging: GroupMessagingService,
        private http: HttpClient,
        private loading: LoadingController,
        private menu: MenuController,
        private network: Network,
        private organisations: OrganisationService,
        private platform: Platform,
        private privateStorage: PrivateSettingsService,
        private rtms: RealTimeMessagingService,
        private router: Router,
        private remoteData: RemoteDataService,
        private settings: SettingsService,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private userService: UserService,
    ) {
        this.platform.ready().then(() => {
            this.network.onConnect().subscribe(() => this.isOnline = true);
            this.network.onDisconnect().subscribe(() => this.isOnline = false);
        });
        // this.environment.setEnvironmentByHost(window.location.host);
        this.initializeApp();
    }

    initializeApp() {
        merge(
            fromEvent(document, 'pause'),
            fromEvent(document, 'resign')
        ).subscribe(event => {
            if (event.type === 'pause') {
                this.rtms.disconnect();
                this.menu.close();
                this.platform.ready().then(async () => {
                    if (this.platform.is('cordova')) {
                        this.currentBioAuth = null;
                        this.biometric.lock();
                        this.splashScreen.show();
                        this.caseMessaging.cancelOfflineActions();
                    }
                });
            }
        });


        merge(
            fromEvent(document, 'active'),
            fromEvent(document, 'resume')
        ).subscribe(async event => {
            this.platform.ready().then(async () => {
                if (this.platform.is('cordova')) {
                    const bioAuthAvailable = await this.faio.isAvailable();
                    if (this.biometric.locked && bioAuthAvailable && this.cognitoAuth.isLoggedIn()) {
                        await this.bioAuth();
                    }
                    if (this.isOnline) {
                        await this.caseMessaging.completeOfflineActions();
                    }
                } else {
                    await this.caseMessaging.cancelOfflineActions();
                }
            }).finally(() => {
                this.rtms.connect();
                this.remoteData.fetch();
                this.splashScreen.hide();
            });
        });

        this.platform.ready().then(async () => {
            this.statusBar.backgroundColorByName('white');
            this.statusBar.styleDefault();

            if (this.platform.is('cordova')) {
                this.network.onChange().subscribe(event => {
                    if (event.type === 'online') {
                        this.rtms.connect();
                        this.remoteData.fetch();
                        this.caseMessaging.completeOfflineActions();
                    }
                    if (event.type === 'offline') {
                        this.caseMessaging.cancelOfflineActions();
                    }
                });
            }

            await this.database.initShared();

            let environmentName = await this.settings.get('environment');
            if (environmentName) {
                await this.environment.setEnvironmentByName(environmentName);
            } else {
                environmentName = await this.environment.setEnvironmentByHost(window.location.host);
            }

            await this.initialiseAuthHandlers();

            this.cognitoAuth.getLogin().subscribe(async () => {
                console.log('loginExpiry: ', await this.settings.get('login:expiresAt'));
            });

            const idToken = await this.settings.get('cognito:idToken');
            const loginExpiry = await this.settings.get('login:expiresAt');

            console.log('loginExpiry: ', loginExpiry);

            if (idToken) {
                this.api.setToken(idToken);
                this.rtms.setToken(idToken);
                this.userService.fetchMe();
            }
            if (this.platform.is('cordova')) {
                if (loginExpiry && (new Date(loginExpiry) <= new Date())) {
                    this.cognitoAuth.logout();
                    await this.router.navigate(['login']);
                }
            }

            if (this.platform.is('cordova')) {
                this.splashScreen.show();

                const bioAuthAvailable = await this.faio.isAvailable();
                if (bioAuthAvailable) {
                    await this.bioAuth();
                }
            }

            const userId = await this.settings.get('user');
            if (userId) {
                this.userService.setCurrentUserId(userId);
                if (this.platform.is('cordova')) {
                    await this.database.initPrivate(environmentName + '_' + userId);
                }
                const loading = await this.loading.create();
                await loading.present();
                this.loadFromMemory().then(_ => loading.dismiss());
                this.remoteData.fetch();
                this.showMenu = true;
            }

            await this.initialisePushNotifications();

            if (this.isOnline) {
                await this.caseMessaging.completeOfflineActions();
            }

        }).finally(() => this.splashScreen.hide());

        this.platform.ready().then(() => {
            // if (this.platform.is('cordova')) {
            //     // this.analytics.logEvent('factory_app_initialised', null)
            //     //     .then((res: any) => console.log(res))
            //     //     .catch((error: any) => console.error(error));
            //     console.log('logEvent: app_initialised');
            //     this.appAnalytics.setEnabled(true);
            //     this.appAnalytics.logEvent('app_initialised', {data: 'test data'})
            //         .then((res: any) => console.log('res: ', res))
            //         .catch((error: any) => console.error('error: ', error));
            // } else {
            //     console.log('logEvent: web_initialised');
            //     this.webAnalytics.setAnalyticsCollectionEnabled(true);
            //     this.webAnalytics.logEvent('web_initialised', {data: 'test data'})
            //         .then((res: any) => console.log('res: ', res))
            //         .catch((error: any) => console.error('error: ', error));
            // }
        });

    }

    private async bioAuth() {
        this.splashScreen.show();

        if (!this.cognitoAuth.isLoggedIn()) {
            return;
        }
        const auth = await this.currentBioAuth;
        const loginExpiry = this.cognitoAuth.getLoginExpiry();
        if (auth !== null) {
            return;
        }

        this.currentBioAuth = Promise.resolve(true);

        if (loginExpiry > new Date()) {
            let loggedIn = false;
            let responseError = null;
            await this.faio.show({}).then(async (result: any) => {
                loggedIn = true;
            }).catch(async (error: any) => {
                responseError = error;
                console.log('bioAuthError', responseError);
                this.biometric.lock();
            }).finally(async () => {
                if (loggedIn) {
                    this.biometric.unlock();
                    this.splashScreen.hide();
                } else if (this.platform.is('ios')) {
                    if (responseError && responseError.code !== -100) {
                        this.cognitoAuth.logout();
                    }
                } else if (this.platform.is('android')) {
                    if (responseError && responseError.code !== -100 && responseError.code !== -108) {
                        this.cognitoAuth.logout().then(_ => {
                            this.router.navigate(['login']);
                        });
                    }
                }
                // Clear currentBioAuth
                this.currentBioAuth = null;
            });
        } else {
            this.biometric.lock();
            this.cognitoAuth.logout();
        }
    }

    public async initialisePushNotifications() {
        if (!this.platform.is('cordova')) {
            return;
        }

        await this.fcm.requestPermission();
        const token = await this.fcm.getToken();

        if (this.cognitoAuth.getCurrentCognitoUser()) {
            await this.api.me.registerPushNotifications({
                push_notification_token: token
            }).toPromise();
        }

        this.cognitoAuth.getCognitoUser().subscribe(user => {
            this.api.me.registerPushNotifications({
                push_notification_token: token
            }).toPromise();
        });
    }

    private async initialiseAuthHandlers() {
        this.cognitoAuth.getLogout().subscribe(async () => {
            this.api.setToken(null);
            this.showMenu = false;
            this.biometric.unlock();
            this.rtms.disconnect();

            Promise.all([
                this.clearBrowserStores(),
                this.privateStorage.clearCache(),
                this.settings.remove('user'),
            ]).finally(async () => {
                if (this.router.url !== '/') {
                    await this.router.navigate(['login']);
                }
            });
        });

        this.cognitoAuth.getToken().subscribe((idToken) => {
            this.api.setToken(idToken);
            this.rtms.setToken(idToken);
            this.userService.fetchMe();
        });

        this.cognitoAuth.getCognitoUser().pipe(
            map(user => user ? user.Username : null),
            filter(id => id !== this.userService.getCurrentUserId())
        ).subscribe(async userId => {
            await this.database.closePrivate();
            if (userId) {
                this.rtms.connect();
                this.userService.setCurrentUserId(userId);
                await this.settings.set('user', userId);
                if (this.platform.is('cordova')) {
                    await this.database.initPrivate(userId);
                }
                const loading = await this.loading.create();
                await loading.present();
                this.loadFromMemory().then(_ => loading.dismiss());
                this.remoteData.fetch();
                this.showMenu = true;

                if (this.platform.is('cordova')) {
                    const bioAuthAvailable = await this.faio.isAvailable();
                    if (bioAuthAvailable) {
                        const biometricsEnabled = await this.settings.get('biometricsEnabled');
                        if (!biometricsEnabled || biometricsEnabled !== 'yes') {
                            this.faio.show({}).then(async () => {
                                await this.settings.set('biometricsEnabled', 'yes');
                            });
                        }
                    }
                }
            }
        });
    }


    private async clearBrowserStores() {
        await this.database.closePrivate();
        await this.caseMessaging.clear();
        await this.directMessaging.clear();
        await this.userService.clear();
        await this.organisations.clear();
        await this.groupMessaging.clear();
    }

    private async loadFromMemory() {
        await this.organisations.loadFromMemory().then(() => console.log('[ORGANISATIONS + BRANCHES LOADED]'));
        await this.userService.loadFromMemory().then(() => console.log('[USERS LOADED]'));
        await this.directMessaging.loadFromMemory().then(() => console.log('[DMS LOADED]'));
        await this.caseMessaging.loadFromMemory().then(() => console.log('[CASES LOADED]'));
        await this.groupMessaging.loadFromMemory().then(() => console.log('[GROUPS LOADED]'));
    }

}
