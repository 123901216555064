import { BaseEntity, FindConditions, getConnectionManager, PrimaryColumn } from 'typeorm';
import { AbstractModel as AbstractApiModel } from '@scaffold/mediccoms-api-client/models/abstract-model';
import { RtmsEvent } from './rtm-events.model';
import { FindOneOptions } from 'typeorm/find-options/FindOneOptions';
import { AbstractModel } from './abstract-model';

export abstract class AbstractSyncedModel extends AbstractModel {

    @PrimaryColumn({
        name: 'id',
        type: 'uuid',
        unique: false,
    })
    public id: string;

    public static createOneFromApi<T extends AbstractSyncedModel>(this: new (...args: any[]) => T, data?: Partial<AbstractApiModel>): T {
        const instance = new this();
        if (data) {
            instance.fillFromApi(data);
        }
        return instance;
    }

    public static createOneFromRtms<T extends AbstractSyncedModel>(this: new (...args: any[]) => T, data?: Partial<RtmsEvent>): T {
        const instance = new this();
        if (data) {
            instance.fillFromRtms(data);
        }
        return instance;
    }

    public static createManyFromApi<T extends AbstractSyncedModel>(
        this: new (...args: any[]) => T,
        data: Partial<AbstractApiModel>[] = []): T[] {

        const instances: T[] = [];
        for (const item of data) {
            const context = this.prototype.constructor;
            const instance: T = context.createOneFromApi(item);
            instances.push(instance);
        }

        return instances;
    }

    public static createManyFromRtms<T extends AbstractSyncedModel>(this: new (...args: any[]) => T, data: Partial<RtmsEvent>[] = []): T[] {
        const instances: T[] = [];
        for (const item of data) {
            const context = this.prototype.constructor;
            const instance: T = context.createOneFromRtms(item);
            instances.push(instance);
        }

        return instances;
    }

    public static mapFromApi(data: Partial<AbstractApiModel>): Partial<AbstractSyncedModel> {
        return null;
    }

    public static mapFromRtms(data: Partial<RtmsEvent>): Partial<AbstractSyncedModel> {
        return null;
    }

    public fillFromApi(data: Partial<AbstractApiModel>): this {
        const thisClass: typeof AbstractSyncedModel = this.constructor as typeof AbstractSyncedModel;
        this.fill(thisClass.mapFromApi(data));
        return this;
    }

    public fillFromRtms(data: Partial<RtmsEvent>): this {
        const thisClass: typeof AbstractSyncedModel = this.constructor as typeof AbstractSyncedModel;
        this.fill(thisClass.mapFromRtms(data));
        return this;
    }

}
