import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AttachmentService } from '../services/attachment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OrganisationService } from '../services/organisation.service';
import { Organisation } from '../services/models/organisation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { User } from '../services/models/user';

@Pipe({
    name: 'organisationName'
})
export class OrganisationNamePipe implements PipeTransform, OnDestroy {

    private isDestroyed: Subject<void> = new Subject<void>();
    private me: User = null;

    constructor(
        private organisationService: OrganisationService,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public async transform(organisation: Organisation, prefix?: string): Promise<string> {
        if (!organisation || organisation.id === undefined) {
            return Promise.resolve(null);
        }
        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((user) => this.me = user);


        return new Promise(resolve => {
            this.organisationService.getOrganisation(organisation.id).pipe(
                takeUntil(this.isDestroyed)
            ).subscribe((response) => {
                if (response.name && response.id !== this.me.organisation.id) {
                    resolve(prefix + response.name);
                } else {
                    resolve(null);
                }
            });
        });
    }

}
