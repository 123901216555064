import { Pipe, PipeTransform } from '@angular/core';
import { AttachmentService } from '../services/attachment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'attachment'
})
export class AttachmentPipe implements PipeTransform {

    constructor(
        private attachmentService: AttachmentService,
        private domSanitizer: DomSanitizer,
    ) {
    }

    public async transform(imageId: string, size?: string): Promise<SafeUrl> {
        return this.domSanitizer.bypassSecurityTrustUrl(await this.attachmentService.getImageUrl(imageId, size));
    }

}
