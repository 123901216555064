import { Component } from '@angular/core';
import { NewGroupMessageParticipants } from './new-group-message-participants';

@Component({
    selector: 'tablet-new-group-message-participants-page',
    templateUrl: 'tablet-new-group-message-participants.html',
    styleUrls: ['new-group-message-participants.scss', 'tablet-new-group-message-participants.scss'],
})
export class TabletNewGroupMessageParticipantsPage extends NewGroupMessageParticipants {

}
