import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-case-sent-page',
    template: '',
    styleUrls: ['sent.scss'],
})
export abstract class CaseSentPage implements OnDestroy {

    public caseId: string = null;
    public isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.route.params.pipe(takeUntil(this.isDestroyed)).subscribe(params => this.caseId = params.id);
    }

    public close() {
        this.navCtrl.setDirection('back');
        this.router.navigate(['cases', this.caseId]);
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

}
