import { Component, OnDestroy, OnInit } from '@angular/core';
import { DirectMessagingService } from '../../../services/direct-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { DirectMessageConversation } from '../../../services/models/direct-message-conversation';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-direct-messaging-list-page',
    templateUrl: 'list.html',
    styleUrls: ['list.scss'],
})
export class AppDirectMessagingListPage implements OnDestroy, OnInit {

    public conversations: DirectMessageConversation[] = null;
    public onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private directMessaging: DirectMessagingService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.directMessaging.getAllConversations().pipe(
            takeUntil(this.onDestroy)
        ).subscribe(conversations => {
            this.setConversations(conversations);
        });
    }

    public ionViewWillEnter() {
        this.directMessaging.fetchAllConversations();
    }

    public setConversations(conversations: DirectMessageConversation[]) {
        this.conversations = conversations;
        console.log(this.conversations);
    }

    public selectConversation(conversation: DirectMessageConversation) {
        this.router.navigate([conversation.id], {relativeTo: this.route});
    }

}
