import { merge } from 'rxjs';
import { User } from '../models/user';
import { debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { BrowserUserService } from './browser.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';

@Injectable()
export class MobileUserService extends BrowserUserService {

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public storage: PrivateSettingsService,
    ) {
        super(api, database, organisationService, storage);
    }

    public loadFromMemory(): Promise<void> {
        return new Promise(async (resolve) => {
            const usersRaw = await this.storage.get('users');
            if (usersRaw) {
                const users = JSON.parse(usersRaw);
                for (const userData of users) {
                    const user = User.createOne(userData);
                    user.organisation = this.organisationService.organisations.findById(userData.organisation);
                    this.users.store(user);
                }
            }

            const meRaw = await this.storage.get('users.me');
            if (meRaw) {
                this.currentUserId = JSON.parse(meRaw);
            }

            this.setupSubscriptions();
            resolve(undefined);
            this.isReady = true;
            this.readySubject.next(true);
        });
    }

    private persistUsersMemory() {
        const users = this.users.all();
        const time = Date.now();
        this.storage.set('users', JSON.stringify(users))
            .then(() => console.log('[USERS STORED]', users.length, Date.now() - time));
    }

    private persistMeMemory() {
        const time = Date.now();
        this.storage.set('users.me', JSON.stringify(this.currentUserId))
            .then(() => console.log('[ME STORED]', 1, Date.now() - time));
    }

    private setupSubscriptions(): void {
        merge(
            this.users.onInsert(),
            this.users.onUpdate(),
            this.users.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistUsersMemory();
        });

        this.currentUserIdChangedSubject.pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistMeMemory();
        });
    }
}
