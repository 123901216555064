import { Component } from '@angular/core';
import { DirectMessagingService } from '../../../services/direct-messaging.service';
import { DirectMessageConversation } from '../../../services/models/direct-message-conversation';
import { ActivatedRoute } from '@angular/router';
import { DirectMessageEvent } from '../../../services/models/direct-message-event';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface PendingMessage {
    id: number;
    body: string;
    timestamp: Date;
}

@Component({
    selector: 'app-direct-messaging-single-page',
    templateUrl: 'single.html',
    styleUrls: ['single.scss'],
})
export class AppDirectMessagingSinglePage {

    public conversation: DirectMessageConversation = null;
    public events: DirectMessageEvent[] = null;
    public routeChange: Subject<void> = new Subject<void>();
    public loading = false;
    public newMessage = '';

    public pendingMessages: PendingMessage[] = [];
    public messageId = 0;

    constructor(
        private directMessaging: DirectMessagingService,
        private route: ActivatedRoute,
    ) {
        const untilRouteChange = <T>() => takeUntil<T>(this.routeChange.asObservable());

        route.params.subscribe(params => {
            this.routeChange.next();
            this.loading = true;

            this.directMessaging.getConversation(params.id).pipe(untilRouteChange()).subscribe(conversation => {
                this.setConversation(conversation);
            });

            this.directMessaging.getAllEvents(params.id).pipe(untilRouteChange()).subscribe(events => {
                this.setEvents(events || []);
            });
        });
    }

    public onKeyPress(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.sendMessage();
        }
    }

    public sendMessage() {
        if (this.newMessage === '') {
            return;
        }
        const message = {
            id: this.messageId++,
            body: this.newMessage,
            timestamp: new Date(),
        };
        this.newMessage = '';

        this.pendingMessages.push(message);

        // this.directMessaging.sendMessage(this.conversation.id, message.body).then(response => {
        //     this.pendingMessages = this.pendingMessages.filter(entry => entry.id !== message.id);
        // });

        this.directMessaging.sendMessage(this.conversation.id, message.body);
    }

    public setConversation(conversation: DirectMessageConversation) {
        // if (!this.conversation) {
        //     this.directMessaging.fetchConversation(conversation.id);
        //     this.directMessaging.fetchAllEvents(conversation.id, {before: new Date().toISOString()});
        // }
        this.conversation = conversation;
    }

    public setEvents(events: DirectMessageEvent[]) {
        this.events = events.filter(((value, index, array) => {
            return index >= array.length - 7;
        }));
    }

    public isRecipient(userId: string): boolean {
        if (!this.conversation || !this.conversation.recipient) {
            return true;
        }
        return this.conversation.recipient.id === userId;
    }

}
