import { Column, Entity, PrimaryColumn } from 'typeorm';
import { AbstractModel } from './abstract-model';

@Entity('settings')
export class Settings extends AbstractModel {

    @PrimaryColumn({
        name: 'id',
        type: 'varchar',
        nullable: false
    })
    public id: string;

    @Column({
        name: 'value',
        type: 'varchar',
        nullable: false
    })
    public value: string;

    fill(data: Partial<Settings>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.value !== undefined) {
            this.value = data.value;
        }
    }
}
