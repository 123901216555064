import { NgModule } from '@angular/core';
import { CasesService } from './cases.service';
import { ComponentsModule } from '../../components/components.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CasesPage } from './cases.page';
import { OpenCasePage } from './open/open';
import { CaseMessengerPage } from './messenger/messenger';
import { LargeCaseMessengerPage } from './messenger/large.messenger';
import { PipesModule } from '../../pipes/pipes.module';
import { CaseHistoryPage } from './history/history';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SelectRecipientPage } from './select-recipient/select-recipient';
import { IncomingCasesPage } from './incoming/incoming';
import { SelectAssigneePage } from './select-assignee/select-assignee';
import { PhoneCaseSentPage } from './sent/phone-sent';
import { LargeCaseSentPage } from './sent/large-sent';
import { PhoneInstructionsPage } from './instructions/phone-instructions';
import { LargeInstructionsPage } from './instructions/large-instructions';
import { MessageInfoPage } from './message-info/message-info';

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        FormsModule,
        IonicModule,
        NgxMaskModule.forChild(),
        PipesModule,
    ],
    providers: [
        CasesService,
    ],
    declarations: [
        CasesPage,
        CaseMessengerPage,
        LargeCaseMessengerPage,
        OpenCasePage,
        CaseHistoryPage,
        SelectRecipientPage,
        IncomingCasesPage,
        SelectAssigneePage,
        PhoneCaseSentPage,
        LargeCaseSentPage,
        PhoneInstructionsPage,
        LargeInstructionsPage,
        MessageInfoPage,
    ],
})
export class CasesModule {

}
