import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { DirectMessageConversation } from '../models/direct-message-conversation';
import { SearchDirectMessageEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { DirectMessageEvent } from '../models/direct-message-event';
import { Observable } from 'rxjs';
import { PendingDirectMessage } from '../models/pending-direct-message';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';

@Injectable()
export abstract class DirectMessagingService {
    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        (window as any).api = api;
    }

    public abstract newConversation(userId: string): Promise<DirectMessageConversation>;

    public abstract fetchAllConversations(): Promise<DirectMessageConversation[]>;

    public abstract fetchConversation(conversationId: string): Promise<DirectMessageConversation>;

    public abstract fetchAllEvents(conversationId: string, request: SearchDirectMessageEventsRequest): Promise<DirectMessageEvent[]>;

    public abstract fetchEvent(conversationId: string, eventId: string): Promise<DirectMessageEvent>;

    public abstract getAllConversations(): Observable<DirectMessageConversation[]>;

    public abstract getAllEvents(conversationId: string): Observable<DirectMessageEvent[]>;

    public abstract getAllPendingMessages(conversationId: string): Observable<PendingDirectMessage[]>;

    public abstract getConversation(conversationId: string): Observable<DirectMessageConversation>;

    public abstract getEvent(conversationId: string, eventId: string): Observable<DirectMessageEvent>;

    public abstract sendMessage(conversationId: string, body: string | null, attachments?: File[] | null): Promise<boolean>;

    public abstract readEvent(event: DirectMessageEvent): Promise<any>;

    public abstract getAllMessages(): Observable<DirectMessageEvent[]>;

    public abstract clear(): Promise<void>;

    public abstract loadFromMemory(): Promise<void>;

    public abstract ready(): Promise<boolean>;
}
