import { User } from '../../services/models/user';
import { UserService } from '../../services/user.service';
import { Injectable } from '@angular/core';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { NetworkService } from '../../services/network.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../../services/database.service';
import { DirectMessagingService } from '../../services/direct-messaging.service';

@Injectable()
export class NewMessageService {

    private currentUser: User = null;
    private externalUsers: User[] = [];
    private internalUsers: User[] = [];
    private searchTerm: string = null;
    private users: User[] = [];

    constructor(
        private alertCtrl: AlertController,
        private api: ApiClientService,
        private database: DatabaseService,
        private directMessaging: DirectMessagingService,
        private network: NetworkService,
        private platform: Platform,
        private router: Router,
        private userService: UserService,
    ) {
        this.userService.getMe().subscribe(me => this.currentUser = me);
    }

    public getInternalUsers(): User[] {
        return this.internalUsers.filter(user => this.filterUser(user));
    }

    public filterUser(user: User) {
        if (!this.searchTerm) { return true; }
        return user.fullName.toLowerCase().includes(this.searchTerm.toLowerCase());
    }

    public getExternalUsers(): User[] {
        return this.externalUsers.filter(user => this.filterUser(user));
    }

    public getUsers(): User[] {
        return this.users.filter(user => this.filterUser(user));
    }

    public setUsers(users: User[]) {
        this.users = users.filter(user => user.id !== this.currentUser.id).sort((a, b) => {
            if (a.friendlyName < b.friendlyName) {
                return -1;
            }
            if (a.friendlyName > b.friendlyName) {
                return 1;
            }
            return 0;
        });

        this.internalUsers = this.users.filter(user => user.organisation?.id === this.currentUser.organisation?.id);
        this.externalUsers = this.users.filter(user => user.organisation?.id !== this.currentUser.organisation?.id)
            .filter(user => user.messaging);
    }

    public async selectUser(user: User) {
        if (!this.platform.is('cordova')) {
            const conversation = await this.directMessaging.newConversation(user.id);
            if (conversation) {
                await this.router.navigate(['my-messages', conversation.id]);
            }
        } else {
            let conversation = await this.database.directMessageConversations.findOne({
                where: {
                    recipient: {
                        id: user.id
                    }
                }
            });

            if (conversation) {
                await this.router.navigate(['my-messages', conversation.id]);
            }

            if (!conversation) {
                if (this.network.isOffline) {
                    const alert = await this.alertCtrl.create(({
                        header: 'Network Required',
                        message: 'You must be connected to the Internet to start a new conversation',
                        buttons: [{
                            text: 'OK',
                            role: 'cancel',
                        }],
                    }));

                    return alert.present();
                }

                const createConversation = async () => {
                    try {
                        const response = await this.api.directMessages.createConversation({
                            recipient_id: user.id,
                        }).toPromise();

                        conversation = await DirectMessageConversation.findOrCreateOne({
                            id: response.direct_message.id,
                        }, {
                            ...DirectMessageConversation.mapFromApi(response.direct_message),
                            recipient: user,
                        });

                        await conversation.save();

                        await this.router.navigate(['my-messages', conversation.id]);
                    } catch (error) {
                        const alert = await this.alertCtrl.create(({
                            header: 'An Error Occurred',
                            message: 'Something went wrong while starting this conversation. Please make sure you have an active internet connection.',
                            buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                            }, {
                                text: 'Try Again',
                                role: 'confirm',
                                handler: () => createConversation(),
                            }],
                        }));

                        return alert.present();
                    }
                };

                await createConversation();
            }
        }
    }

    public getSearchTerm(): string {
        return this.searchTerm || '';
    }

    public setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

}
