import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { File as CordovaFile } from '@ionic-native/file/ngx';

@Pipe({
    name: 'offlineAttachment'
})
export class OfflineAttachmentPipe implements PipeTransform {

    constructor(
        public cordovaFile: CordovaFile,
        private domSanitizer: DomSanitizer,
    ) {
    }

    public async transform(imagePath: string): Promise<SafeUrl> {
        return new Promise(async resolve => {
            const dir = await this.cordovaFile.resolveDirectoryUrl(this.cordovaFile.dataDirectory);
            const dirEntity = await this.cordovaFile.getDirectory(dir, 'offline', {});
            this.cordovaFile.getFile(dirEntity, imagePath, {}).then((fe) => {
                const safeUrl = (window as any).Ionic.WebView.convertFileSrc(fe.toURL());
                return resolve(this.domSanitizer.bypassSecurityTrustUrl(safeUrl));
            });
        });
    }

}
