import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DirectMessageEvent } from '../../services/models/direct-message-event';

@Component({
    selector: 'app-new-messages-widget',
    templateUrl: 'new-messages-widget.html',
    styleUrls: ['new-messages-widget.scss'],
})
export class NewMessagesWidgetComponent {

    private events: DirectMessageEvent[] = [];

    constructor(private router: Router){}

    public goToMyMessages(){
        this.router.navigate(['/my-messages']);
    }

    public get messages(): DirectMessageEvent[] {
        return this.events;
    }

    @Input()
    public set messages(events: DirectMessageEvent[]) {
        this.events = events;
    }
}
