import { UserService } from './abstract.service';
import { User } from '../models/user';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { UserStore } from '../stores/user.store';
import { User as ApiUser } from '@scaffold/mediccoms-api-client/models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { HttpEventType } from '@angular/common/http';

export class BrowserUserService extends UserService {

    protected currentUserId: string = null;
    protected currentUserIdChangedSubject: Subject<string> = new Subject<string>();
    public users: UserStore = new UserStore();
    protected isReady = false;
    protected readySubject: Subject<boolean> = new Subject<boolean>();
    protected myBranches: string[] = [];
    protected myBranchesSubject: Subject<string[]> = new Subject<string[]>();

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public storage: PrivateSettingsService,
    ) {
        super(api, database, organisationService, storage);
    }

    public getCurrentUserId(): string {
        return this.currentUserId;
    }

    public getUser(id: string): Observable<User> {
        return new Observable<User>(observer => {
            const users = this.users;
            observer.next(this.loadUser(id));

            const subscription = merge(
                users.onInsert(),
                users.onUpdate(),
                users.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(this.loadUser(id));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getUsers(): Observable<User[]> {
        return new Observable<User[]>(observer => {
            const users = this.users;
            observer.next(users.all());

            const subscription = merge(
                users.onInsert(),
                users.onUpdate(),
                users.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(users.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    public loadMe(): User {
        return this.loadUser(this.getCurrentUserId());
    }

    public loadUser(id: string): User {
        return this.users.findById(id);
    }

    public onCurrentUserIdChange(): Observable<string> {
        return this.currentUserIdChangedSubject.asObservable();
    }

    public setCurrentUserId(userId: string) {
        this.currentUserId = userId;
        this.currentUserIdChangedSubject.next(this.currentUserId);
    }

    public async fetchMe(): Promise<User> {
        const response = await this.api.me.getProfile().toPromise();

        if (!response) {
            return;
        }

        this.setCurrentUserId(response.user.id);
        this.fetchMyBranches(this.currentUserId);

        const user = this.loadUser(response.user.id) || User.createOne({id: response.user.id});
        user.fillFromApi(response.user);

        return this.users.store(user);
    }

    public async fetchUser(id: string): Promise<User> {
        const response = await this.api.users.get(id).toPromise();

        if (!response) {
            return;
        }

        const user = this.loadUser(response.user.id) || User.createOne({id: response.user.id});
        user.fillFromApi(response.user);

        return this.users.store(user);
    }

    public async fetchUsers(): Promise<User[]> {
        const results: ApiUser[] = [];
        let currentPage = 1;
        let perPage = 0;
        let totalItems = 0;
        do {
            const response = await this.api.users.search({page: currentPage}).toPromise();

            if (!response) {
                return;
            }

            currentPage = response.pagination.current_page;
            perPage = response.pagination.per_page;
            totalItems = response.pagination.total_items;
            for (const data of response.users) {
                results.push(data);
                const user = this.users.findById(data.id) || User.createOne({id: data.id});
                user.fillFromApi(data);
                this.users.store(user);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        for (const user of [].concat(this.users.all())) {
            const exists = results.find(result => result.id === user.id);
            if (!exists) {
                this.users.remove(user);
            }
        }

        return this.users.all();
    }

    public getMe(): Observable<User> {
        return new Observable<User>(observer => {
            const users = this.users;
            observer.next(this.loadMe());

            const subscription = merge(
                this.onCurrentUserIdChange(),
                users.onInsert(),
                users.onUpdate(),
                users.onRemove(),
            ).subscribe(() => {
                observer.next(this.loadMe());
            });

            return () => subscription.unsubscribe();
        });
    }

    public clear(): Promise<void> {
        this.setCurrentUserId(null);
        this.users.clear();
        return Promise.resolve(undefined);
    }

    public loadFromMemory(): Promise<void> {
        this.isReady = true;
        this.readySubject.next(true);
        return Promise.resolve(undefined);
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.isReady) {
                return resolve(true);
            }

            this.readySubject.asObservable().pipe(
                first()
            ).subscribe((ready) => resolve(ready));
        });
    }

    protected async fetchMyBranches(id: string): Promise<string[]> {
        const response = await this.api.users.getBranches(id).toPromise();
        if (!response) {
            return;
        }
        this.myBranches = response.branches;
        this.myBranchesSubject.next(this.myBranches);
        return this.myBranches;
    }

    public getMyBranches(): Observable<string[]> {
        return new Observable<string[]>((observer) => {
            observer.next(this.myBranches);

            const subscription = this.myBranchesSubject
                .asObservable()
                .subscribe(() => {
                    observer.next(this.myBranches);
                });

            return () => subscription.unsubscribe();
        });
    }

    public async updateProfile(attachment: File): Promise<User> {
        const imageId = await new Promise<string>((resolve, reject) => {
            this.api.attachments.uploadImage(attachment).subscribe(result => {
                if (result.type === HttpEventType.Response) {
                    resolve(result.body.image_id);
                }
            });
        });

        const response = await this.api.me.updateProfile({
            image: imageId
        }).toPromise();
        const user = User.createOne({id: response.user.id});
        user.fillFromApi(response.user);

        return this.users.store(user);
    }
}
