import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { SearchComponent } from '../search/search';
import { Network } from '@ionic-native/network/ngx';
import { RemoteDataService } from '../../services/remote-data.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.html',
    styleUrls: ['header.scss'],
})
export class HeaderComponent implements AfterViewInit {

    @Input() showSearch: boolean;
    @Input() showMenu: boolean;
    @Input() showBack: boolean;
    @Input() showRefresh: boolean;

    @Output('back') public onBack = new EventEmitter<void>();
    @Output() public searchTermChange = new EventEmitter<string>();

    @ViewChild('header', {read: ElementRef}) public header: ElementRef;
    @ViewChild('search', {read: SearchComponent}) public search: SearchComponent;

    private appRefreshSubject: Promise<any> = null;

    private _searchTerm = '';

    public headerHeight = 0;

    public isRefreshing = false;

    constructor(
        private menu: MenuController,
        private navCtrl: NavController,
        private network: Network,
        private remoteData: RemoteDataService,
    ) {
    }

    @Input()
    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        if (value === this._searchTerm) { return; }
        this._searchTerm = value;
        this.searchTermChange.emit(this._searchTerm);
    }

    public ngAfterViewInit() {
        this.headerHeight = this.header.nativeElement.offsetHeight;
    }

    public openMenu() {
        this.menu.open('first');
    }

    public openSearch() {
        this.search.open();
    }

    public goBack() {
        if (this.onBack.observers.length) { this.onBack.emit(); }
        else { this.navCtrl.pop(); }
    }

    public async refreshApp() {
        if (this.appRefreshSubject) {
            return this.appRefreshSubject;
        }

        this.isRefreshing = true;
        if (this.network.type !== this.network.Connection.NONE) {
            this.appRefreshSubject = this.remoteData.fetch().finally(() => {
                this.isRefreshing = false;
                this.appRefreshSubject = null;
            }).catch((e) => console.log(e));
        }

        return this.appRefreshSubject;
    }
}
