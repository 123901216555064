import { AbstractModel } from './abstract-model';
import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { DirectMessageConversation } from './direct-message-conversation';
import { PendingAttachment } from './pending-attachment';
import { DirectMessageEvent } from './direct-message-event';

@Entity('pending_direct_messages')
export class PendingDirectMessage extends AbstractModel {

    @PrimaryGeneratedColumn()
    public id: number;

    @Column({
        name: 'remote_id',
        type: 'uuid',
        nullable: true,
        unique: true,
    })
    public remoteId: string;

    @Column({
        name: 'body',
        type: 'varchar',
        nullable: true
    })
    public body: string;

    @Column({
        name: 'created_at',
        type: 'varchar',
        nullable: true,
    })
    public createdAt: string;

    @Column({
        name: 'sent_at',
        type: 'varchar',
        nullable: true,
    })
    public sentAt: string;

    @OneToMany(() => PendingAttachment, attachment => attachment.message, { nullable: true })
    public attachments: PendingAttachment[];

    @ManyToOne(() => DirectMessageConversation, conversation => conversation.pendingMessages, {nullable: true})
    @JoinColumn({name: 'conversation_id'})
    public conversation: DirectMessageConversation;

    public fill(data: Partial<PendingDirectMessage>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.remoteId !== undefined) {
            this.remoteId = data.remoteId;
        }
        if (data.attachments !== undefined) {
            this.attachments = PendingAttachment.createMany(data.attachments);
        }
        if (data.body !== undefined) {
            this.body = data.body;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = DirectMessageConversation.createOne(data.conversation);
        }
    }

    public asDirectMessageEvent(): DirectMessageEvent {
        return DirectMessageEvent.createOne({
            attachments: null,
            messageBody: this.body,
            conversation: this.conversation.id,
        });
    }

}
