import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';

import { IonBottomDrawerModule } from 'ion-bottom-drawer';
import { MenuComponent } from './components/menu/menu';
import { ApiClientModule } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from './services/database.service';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { RealTimeMessagingService } from './services/real-time-messaging.service';
import { DirectMessagingPagesModule } from './pages/direct-messages/pages.module';
import { CognitoAuthenticationService } from './services/cognito-authentication.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { Camera } from '@ionic-native/camera/ngx';
import { File as CordovaFile } from '@ionic-native/file/ngx';
import { AttachmentService } from './services/attachment.service';
import { NetworkService } from './services/network.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { CaseMessagingServiceModule } from './services/case-messaging-service';
import { UserServiceModule } from './services/user.service';
import { NgxMaskModule } from 'ngx-mask';
import { OrganisationServiceModule } from './services/organisation.service';
import { DirectMessagingServiceModule } from './services/direct-messaging.service';
import { SettingsServiceModule } from './services/settings.service';
import { MessageService } from './pages/message/message.service';
import { PrivateSettingsServiceModule } from './services/private-settings.service';
import { GroupMessagingServiceModule } from './services/group-messaging.service';
import { GroupParticipantsService } from './pages/new-group-message-participants/group-participants.service';
import * as Hammer from 'hammerjs';
import { LogoutInterceptor } from './interceptors/logout-interceptor.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import * as Sentry from '@sentry/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { RemoteDataService } from './services/remote-data.service';
import { BiometricService } from './services/biometric.service';
import { EnvironmentService } from './services/environment.service';
import { AnalyticsService } from './services/analytics.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AngularFireAnalytics, AngularFireAnalyticsModule, CONFIG } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

// Sentry.init({
//     dsn: 'https://7b9155e2f37341cfab558faa433c1fbe@o135271.ingest.sentry.io/5629329',
// });

export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: {
            direction: Hammer.DIRECTION_DOWN,
        },
        pan: {
            direction: Hammer.DIRECTION_DOWN,
        }
    } as any;
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        ApiClientModule,
        AppRoutingModule,
        BrowserModule,
        ComponentsModule,
        DirectMessagingPagesModule,
        HttpClientModule,
        IonBottomDrawerModule,
        IonicModule.forRoot(),
        NgxMaskModule.forRoot(),
        PagesModule,
        OrganisationServiceModule,
        CaseMessagingServiceModule,
        DirectMessagingServiceModule,
        GroupMessagingServiceModule,
        SettingsServiceModule,
        PrivateSettingsServiceModule,
        UserServiceModule,
        HammerModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
    ],
    providers: [
        AppVersion,
        AttachmentService,
        BiometricService,
        Camera,
        Clipboard,
        CognitoAuthenticationService,
        CordovaFile,
        DatabaseService,
        FingerprintAIO,
        MessageService,
        // DirectMessagingServiceOld,
        FirebaseMessaging,
        Keyboard,
        Network,
        NetworkService,
        RemoteDataService,
        StatusBar,
        SplashScreen,
        // SettingsService,
        RealTimeMessagingService,
        GroupParticipantsService,
        EnvironmentService,
        AnalyticsService,
        FirebaseAnalytics,
        AngularFireAnalytics,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LogoutInterceptor, multi: true},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
        {provide: CONFIG, useValue: {send_page_view: false, allow_ad_personalization_signals: false, anonymize_ip: true}}
    ],
    bootstrap: [
        AppComponent,
        MenuComponent,
    ],
})
export class AppModule {
}
