import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { OrganisationService } from '../../services/organisation.service';
import { Organisation } from '../../services/models/organisation';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-detail',
    templateUrl: 'user-detail.html',
    styleUrls: ['user-detail.scss'],
})
export class UserDetailComponent implements OnChanges, OnInit, OnDestroy {

    private conversationChanged: Subject<DirectMessageConversation> = new Subject<DirectMessageConversation>();
    private isDestroyed: Subject<void> = new Subject<void>();
    private organisationChanged: Subject<Organisation> = new Subject<Organisation>();
    private userChanged: Subject<User> = new Subject<User>();

    @Input() public conversation: DirectMessageConversation = null;
    @Input() public user: User = null;
    public organisation: Organisation = null;

    constructor(
        public api: ApiClientService,
        public organisationService: OrganisationService,
        public userService: UserService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('conversation' in changes) {
            this.conversationChanged.next(changes.conversation.currentValue);
        }
        if ('organisation' in changes) {
            this.organisationChanged.next(changes.organisation.currentValue);
        }
        if ('user' in changes) {
            this.userChanged.next(changes.user.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.conversationChanged.asObservable().pipe(takeUntil(this.isDestroyed)).subscribe(conversation => {
            this.userService.getUser(conversation.recipient.id).pipe(
                takeUntil(this.isDestroyed),
                takeUntil(this.conversationChanged),
            ).subscribe((user) => {
                if (user && user !== this.user) {
                    this.user = user;
                    this.organisationService.getOrganisation(user.organisation.id).pipe(
                        takeUntil(this.isDestroyed),
                        takeUntil(this.conversationChanged),
                        takeUntil(this.userChanged),
                    ).subscribe((organisation) => {
                        if (organisation && organisation !== this.organisation) {
                            this.organisation = organisation;
                        }
                    });
                    this.organisationService.fetchOrganisation(user.organisation.id);
                }
            });
            this.userService.fetchUser(conversation.recipient.id);
        });

        if (this.conversation) {
            this.conversationChanged.next(this.conversation);
        }

        this.userChanged.asObservable().pipe(
            takeUntil(this.isDestroyed),
            filter(() => this.conversation === null),
        ).subscribe(user => {
            this.organisationService.getOrganisation(user.organisation.id).pipe(
                takeUntil(this.isDestroyed),
                takeUntil(this.userChanged),
            ).subscribe((organisation) => {
                if (organisation && organisation !== this.organisation) {
                    this.organisation = organisation;
                }
            });
            this.organisationService.fetchOrganisation(user.organisation.id);
        });
    }

}
