import { Injectable } from '@angular/core';
import * as SocketIO from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RtmsEvent } from './models/rtm-events.model';
import { RtmsDirectMessageService } from './real-time-messaging/direct-message.service';
import { RtmsGroupMessageService } from './real-time-messaging/group-message.service';
import { RtmsCaseService } from './real-time-messaging/case.service';

@Injectable()
export class RealTimeMessagingService {
    private endpoint: string;
    private socket: SocketIOClient.Socket;
    private token: string;
    private onEventSubject: Subject<any> = new Subject<any>();

    constructor() {
    }

    public connect() {
        this.socket = SocketIO(this.endpoint, {
            query: {
                token: this.token,
            }
        });
        this.socket.on('event', event => this.trigger(event));
    }

    public reconnect() {
        this.disconnect();
        this.connect();
    }

    public disconnect() {
        if (this.socket && this.socket.connected) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
            this.socket = null;
        }
    }

    public getEndpoint(): string {
        return this.endpoint;
    }

    public setEndpoint(value: string) {
        this.endpoint = value;
    }

    public getToken(): string {
        return this.token;
    }

    public setToken(value: string) {
        if (value !== this.token) {
            this.token = value;
            this.reconnect();
        }
    }

    public onEvent(): Observable<RtmsEvent> {
        return this.onEventSubject.asObservable().pipe(
            map(event => RtmsEvent.createOne(event)),
        );
    }

    public trigger(event: any) {
        this.onEventSubject.next(event);
    }

    public get directMessages(): RtmsDirectMessageService {
        return new RtmsDirectMessageService(this);
    }

    public get groupMessages(): RtmsGroupMessageService {
        return new RtmsGroupMessageService(this);
    }

    public get cases(): RtmsCaseService {
        return new RtmsCaseService(this);
    }
}
