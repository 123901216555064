import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'backgroundImage'
})
export class BackgroundImagePipe implements PipeTransform {

    constructor(
        private domSanitizer: DomSanitizer,
    ) {
    }

    public transform(url: SafeUrl): SafeStyle;
    public transform(url: Promise<SafeUrl>): Promise<SafeStyle>;
    public transform(url: SafeUrl|Promise<SafeUrl>): SafeStyle|Promise<SafeStyle> {
        if (url instanceof Promise) {
            return url.then((safeUrl) => this.transform(safeUrl));
        }
        return this.domSanitizer.bypassSecurityTrustStyle('url(' + this.domSanitizer.sanitize(SecurityContext.URL, url) + ')');
    }

}
