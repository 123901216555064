import { AbstractSyncedModel } from '../abstract-synced-model';
import { RtmsEvent } from '../rtm-events.model';
import { User } from '../user';

export class GroupEvent extends AbstractSyncedModel {
    public id: string;
    public sentAt: string;
    public type: string;
    public conversation: string;
    public sentBy: User;
    public messageBody: string;

    public static mapFromApi(data: Partial<any>): Partial<GroupEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            sentAt: data.sent_at,
            type: data.type,
            conversation: data.conversation,
            sentBy: User.mapFromApi({
                id: data.sent_by
            }) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<RtmsEvent>): Partial<GroupEvent> {
        if (!data) {
            return null;
        }

        return {
            id: undefined,
            sentAt: undefined,
            type: undefined,
            conversation: undefined,
        };
    }

    public fill(data: Partial<GroupEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
        if (data.sentBy !== undefined) {
            this.sentBy = User.createOne(data.sentBy);
        }
    }

    public get sentAtDate(): Date {
        return this.sentAt ? new Date(this.sentAt) : null;
    }

    public is(otherModel: Partial<this>): boolean {
        return this.id === otherModel.id;
    }
}
