import { Component } from '@angular/core';
import { OnBoarding } from './on-boarding';

@Component({
    selector: 'app-tablet-on-boarding-page',
    templateUrl: 'tablet-on-boarding.html',
    styleUrls: ['on-boarding.scss', 'tablet-on-boarding.scss'],
})
export class TabletOnBoardingPage extends OnBoarding {

}
