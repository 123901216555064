import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseAcceptedEvent as ApiCaseAcceptedEvent } from '@scaffold/mediccoms-api-client/models/case-accepted-event';
import { CaseAcceptedEvent as RtmsCaseAcceptedEvent } from '../rtm-events.model';

export class CaseAcceptedEvent extends CaseEvent {
    public type: string;
    public acceptedAt: string;
    public acceptedBy: User;

    public static mapFromApi(data: Partial<ApiCaseAcceptedEvent>): Partial<CaseAcceptedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            acceptedAt: data.accepted_at,
            acceptedBy: User.mapFromApi(data.accepted_by) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<RtmsCaseAcceptedEvent>): Partial<CaseAcceptedEvent> {
        if (!data) {
            return null;
        }

        const acceptedBy = data.data.accepted_by ? User.mapFromApi({
            id: data.data.accepted_by.id,
            first_name: data.data.accepted_by.first_name,
            last_name: data.data.accepted_by.last_name,
            organisation_id: data.data.accepted_by.organisation_id,
            image: data.data.accepted_by.image,
            title: data.data.accepted_by.title,
        }) as Required<User> : undefined;

        return {
            id: data.data.event_id,
            type: data.event,
            acceptedAt: data.timestamp,
            acceptedBy,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            acceptedAt: this.acceptedAt,
            acceptedBy: this.acceptedBy ? this.acceptedBy.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public fill(data: Partial<CaseAcceptedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.acceptedAt !== undefined) {
            this.acceptedAt = data.acceptedAt;
        }
        if (data.acceptedBy !== undefined) {
            this.acceptedBy = User.createOne(data.acceptedBy);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }
}
