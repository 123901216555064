import { NgModule } from '@angular/core';
import { UserService } from './abstract.service';
import { UserServiceFactory } from './factory';
import { ApiClientModule, ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';

@NgModule({
    imports: [
        ApiClientModule,
    ],
    providers: [
        DatabaseService,
        {
            provide: UserService,
            useFactory: UserServiceFactory,
            deps: [
                ApiClientService,
                DatabaseService,
                OrganisationService,
                Platform,
                PrivateSettingsService,
            ],
        }
    ],
})
export class UserServiceModule {
}
