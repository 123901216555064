/* tslint:disable:variable-name */
import { BeforeInsert, BeforeUpdate, Column, Entity, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { User } from './user';
import { DirectMessageConversation as ApiDirectMessageConversation } from '@scaffold/mediccoms-api-client/models';
import { ConversationCreatedEvent as RtmsDirectMessageConversation } from './rtm-events.model';
import { DirectMessageEvent } from './direct-message-event';
import { PendingDirectMessage } from './pending-direct-message';
import { AbstractSyncedModel } from './abstract-synced-model';
import { max as maxDate } from "date-fns";

@Entity('direct_message_conversations')
export class DirectMessageConversation extends AbstractSyncedModel {

    public events: DirectMessageEvent[] = [];

    @Column({
        name: 'created_at',
        type: 'varchar',
        nullable: true
    })
    public createdAt: string;

    @ManyToOne(() => User, null, {nullable: true, eager: true})
    @JoinColumn({name: 'recipient_id'})
    public recipient: User;

    @OneToMany(() => PendingDirectMessage, message => message.conversation, {nullable: true})
    public pendingMessages: PendingDirectMessage[];

    public static mapFromApi(data: Partial<ApiDirectMessageConversation>): Partial<DirectMessageConversation> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            createdAt: data.created_at,
            recipient: User.mapFromApi(data.recipient) as Required<User>
        };
    }

    public static mapFromRtms(data: Partial<RtmsDirectMessageConversation>): Partial<DirectMessageConversation> {
        if (!data) {
            return null;
        }

        return {
            id: data.target_id,
            createdAt: data.timestamp,
        };
    }

    @BeforeInsert()
    @BeforeUpdate()
    public async beforeSave() {
        if (this.recipient) { await this.recipient.save(); }
    }

    public fill(data: Partial<DirectMessageConversation>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.recipient !== undefined) {
            this.recipient = User.createOne(data.recipient);
        }
        if (data.events !== undefined) {
            for (const eventData of data.events) {
                if (!eventData) {
                    console.log('[WEIRD EventData]', eventData);
                    continue;
                }
                let event = this.events.find(_event => _event?.id === eventData?.id);
                if (!event) {
                    // Create Event and Push
                    if (!(eventData instanceof DirectMessageEvent)) {
                        event = DirectMessageEvent.createOne(eventData);
                    } else {
                        event = eventData;
                    }
                    this.events.push(event);
                } else {
                    // Update Event
                    event.fill(eventData);
                }
            }
        }
        return this;
    }

    public get updatedAt(): Date {
        const dates = [
            this.latestMessage?.sentAt,
            this.createdAt,
        ].filter(item => item).map(item => new Date(item));

        return dates.length ? maxDate(dates) : null;
    }

    public get latestMessage(): DirectMessageEvent {
        let latestSentAt = null;
        let latestEvent = null;

        for (const currentEvent of this.events) {
            const sentAt = new Date(currentEvent.sentAt);
            if (sentAt > latestSentAt) {
                latestEvent = currentEvent;
                latestSentAt = sentAt;
            }
        }

        return latestEvent;
    }

    public addEvent(event: DirectMessageEvent) {
        this.events.push(event);
    }

    public addEvents(events: DirectMessageEvent[]) {
        for (const event of events) {
            this.addEvent(event);
        }
    }

    public toJSON() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            recipient: this.recipient ? this.recipient.id : null,
        };
    }

    public get displayName(): string {
        return this.recipient?.friendlyName;
    }
}
