import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseResolvedEvent as ApiCaseResolvedEvent } from '@scaffold/mediccoms-api-client/models/case-resolved-event';
import { CaseResolvedEvent as RtmsCaseResolvedEvent } from '../rtm-events.model';

export class CaseResolvedEvent extends CaseEvent {
    type: string;
    resolvedAt: string;
    resolvedBy: User;

    public static mapFromApi(data: Partial<ApiCaseResolvedEvent>): Partial<CaseResolvedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            resolvedAt: data.resolved_at,
            resolvedBy: User.mapFromApi(data.resolved_by) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<RtmsCaseResolvedEvent>): Partial<CaseResolvedEvent> {
        if (!data) {
            return null;
        }

        const resolvedBy = User.mapFromApi({
            id: data.data.resolved_by.id,
            first_name: data.data.resolved_by.first_name,
            last_name: data.data.resolved_by.last_name,
            organisation_id: data.data.resolved_by.organisation_id,
            image: data.data.resolved_by.image,
            title: data.data.resolved_by.title,
        });

        return {
            id: data.data.event_id,
            type: data.event,
            resolvedAt: data.timestamp,
            resolvedBy: resolvedBy as Required<User>,
        };
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            resolvedAt: this.resolvedAt,
            resolvedBy: this.resolvedBy ? this.resolvedBy.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public fill(data: Partial<CaseResolvedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.resolvedAt !== undefined) {
            this.resolvedAt = data.resolvedAt;
        }
        if (data.resolvedBy !== undefined) {
            this.resolvedBy = data.resolvedBy;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
        if (data.isOffline !== undefined) {
            this.isOffline = data.isOffline;
        }
        if (data.offlineId !== undefined) {
            this.offlineId = data.offlineId;
        }
    }
}
