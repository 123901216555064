import { Component } from '@angular/core';
import { ForgotPassword } from './forgot-password';

@Component({
    selector: 'app-tablet-forgot-password-page',
    templateUrl: 'tablet-forgot-password.html',
    styleUrls: ['forgot-password.scss', 'tablet-forgot-password.scss'],
})
export class TabletForgotPasswordPage extends ForgotPassword {

}
