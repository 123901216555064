import { Component } from '@angular/core';
import { MessageGroupParticipant } from './message-group-participant';

@Component({
    selector: 'app-tablet-message-group-participant-page',
    templateUrl: 'tablet-message-group-participant.html',
    styleUrls: ['message-group-participant.scss', 'tablet-message-group-participant.scss'],
})
export class TabletMessageGroupParticipantPage extends MessageGroupParticipant {

}
