import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppDirectMessagingListPage } from './list/list';
import { AppDirectMessagingSinglePage } from './single/single';

const routes: Routes = [
    {
        path: '',
        component: AppDirectMessagingListPage,
    },
    {
        path: ':id',
        component: AppDirectMessagingSinglePage,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DirectMessagingRoutingModule {
}
