import { BeforeInsert, BeforeUpdate, Column, Entity, JoinColumn, ManyToOne } from 'typeorm';
import { AbstractSyncedModel } from './abstract-synced-model';
import { Branch as ApiBranch } from '@scaffold/mediccoms-api-client/models/branch';
import { Address } from './address';
import { PrimaryContact } from './primary-contact';
import { Organisation } from './organisation';

@Entity('branches')
export class Branch extends AbstractSyncedModel {

    @Column({
        name: 'image',
        type: 'varchar',
        nullable: true
    })
    public image: string = null;

    @Column({
        name: 'name',
        type: 'varchar',
        nullable: true
    })
    public name: string = null;

    @Column({
        name: 'primary_contact',
        type: 'varchar',
        nullable: true,
        transformer: {
            to: value => JSON.stringify(value),
            from: value => PrimaryContact.createOne(JSON.parse(value)),
        }
    })
    public primaryContact: PrimaryContact = null;

    @Column({
        name: 'address',
        type: 'varchar',
        nullable: true,
        transformer: {
            to: value => JSON.stringify(value),
            from: value => Address.createOne(JSON.parse(value)),
        }
    })
    public address: Address = null;

    @ManyToOne(() => Organisation, null, {nullable: true, eager: false})
    @JoinColumn({name: 'organisation_id'})
    public organisation: Organisation;

    public get initials(): string {
        const letters = (() => {
            if (!this.name) { return []; }

            const capitalLetters = this.name.split('').filter(letter => {
                const charCode = letter.charCodeAt(0);
                return charCode >= 65 && charCode <= 90;
            });

            if (capitalLetters.length) { return capitalLetters; }

            const words = this.name.split(' ');

            if (words.length) { return words; }
        })();

        return letters.length ? letters.slice(0, 2).join('') : 'N/A';
    }

    public get friendlyName(): string {
        return this.name;
    }

    public static mapFromApi(data: Partial<ApiBranch>): Partial<Branch> {
        if (!data) { return null; }

        return {
            id: data.id,
            image: data.image,
            name: data.name,
            organisation: Organisation.mapFromApi({
                id: data.organisation_id
            }) as Required<Organisation>,
            address: Address.createOne(data.address as Partial<Address>),
            primaryContact: PrimaryContact.createOne(data.primary_contact as Partial<PrimaryContact>),
        };
    }

    @BeforeInsert()
    @BeforeUpdate()
    public async beforeSave() {
        if (this.organisation) { await this.organisation.save(); }
    }

    public fill(data: Partial<Branch>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.organisation !== undefined) {
            this.organisation = Organisation.createOne(data.organisation);
        }
        if (data.address !== undefined) {
            this.address = Address.createOne(data.address);
        }
        if (data.primaryContact !== undefined) {
            this.primaryContact = PrimaryContact.createOne(data.primaryContact);
        }
    }

    public toJSON() {
        return {
            id: this.id,
            image: this.image,
            name: this.name,
            organisation: this.organisation ? this.organisation.id : null,
            address: this.address,
            primaryContact: this.primaryContact,
        };
    }

}
