import { AbstractSyncedModel } from '../abstract-synced-model';
import { User } from '../user';
import { MessageDeliveredEvent, MessageReadEvent } from '../rtm-events.model';

export class GroupMessageReceipt extends AbstractSyncedModel {
    public timestamp: string;
    public user: User;

    public static mapFromApi(data: Partial<any>): Partial<GroupMessageReceipt> {
        if (!data) {
            return null;
        }

        return {
            timestamp: data.timestamp,
            user: User.mapFromApi({
                id: data.user_id
            }) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<MessageDeliveredEvent> | Partial<MessageReadEvent>): Partial<GroupMessageReceipt> {
        if (!data) {
            return null;
        }

        return {
            timestamp: data.timestamp,
            user: User.mapFromApi({
                id: data.sender_id
            }) as Required<User>,
        };
    }

    public fill(data: Partial<any>) {
        if (data.timestamp !== undefined) {
            this.timestamp = data.timestamp;
        }
        if (data.user !== undefined) {
            this.user = data.user;
        }
    }
}
