import { Component } from '@angular/core';
import { ForgotPassword } from './forgot-password';

@Component({
    selector: 'app-phone-forgot-password-page',
    templateUrl: 'phone-forgot-password.html',
    styleUrls: ['forgot-password.scss', 'phone-forgot-password.scss'],
})
export class PhoneForgotPasswordPage extends ForgotPassword {

}
