export class WebFile extends Blob implements File {
    readonly lastModified: number;
    readonly name: string;

    constructor(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag) {
        super(fileBits, options);
        this.name = fileName;
        this.lastModified = options ? options.lastModified || null : null;
    }
}
