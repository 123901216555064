import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '../../../services/organisation.service';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AttachmentService } from '../../../services/attachment.service';
import { ModalController } from '@ionic/angular';
import { Branch } from '../../../services/models/branch';
import { User } from '../../../services/models/user';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-cases-select-recipient-page',
    templateUrl: 'select-recipient.html',
    styleUrls: ['select-recipient.scss'],
})
export class SelectRecipientPage implements OnDestroy, OnInit {

    public branches: Branch[] = [];
    private onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();
    public currentUser: User = null;

    constructor(
        public attachments: AttachmentService,
        private organisationService: OrganisationService,
        private modalCtrl: ModalController,
        private userService: UserService,
    ) {
        this.userReady.asObservable().pipe(
            first(),
            takeUntil(this.onDestroy),
        ).subscribe(() => {
            this.organisationService.getAllBranches().pipe(
                map(branches => {
                    return branches.filter(branch => branch.organisation.id !== this.currentUser.organisation.id);
                }),
                takeUntil(this.onDestroy),
            ).subscribe(branches => {
                this.branches = branches;
            });
        });

        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void { }

    public select(branch: Branch) {
        this.dismiss(branch);
    }

    public dismiss(result?: Branch) {
        return this.modalCtrl.dismiss(result);
    }

}
