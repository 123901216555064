import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DeveloperEnvironmentPage } from '../developer-environment/developer-environment.page';

@Component({
    selector: 'app-developer-tools',
    templateUrl: './developer-tools.page.html',
    styleUrls: ['./developer-tools.page.scss'],
})
export class DeveloperToolsPage implements OnInit {

    constructor(
        private modalCtrl: ModalController,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
    }

    public closeTools(): void {
        this.router.navigate(['login']);
    }

    public chooseEnvironment(): void {
        this.modalCtrl.create({
            component: DeveloperEnvironmentPage,
        }).then(modal => modal.present());
    }

    public clearDatabases(): void {

    }

    public resetApp(): void {

    }

}
