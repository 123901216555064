import { Component } from '@angular/core';
import { ChangeTemporaryPassword } from './change-temporary-password';

@Component({
    selector: 'app-change-temporary-password-page',
    templateUrl: 'tablet-change-temporary-password.html',
    styleUrls: ['change-temporary-password.scss', 'tablet-change-temporary-password.scss'],
})
export class TabletChangeTemporaryPassword extends ChangeTemporaryPassword {

}
