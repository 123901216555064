import { Observable } from 'rxjs';
import {
    CaseAcceptedEvent,
    CaseAssignedEvent,
    CaseCreatedEvent,
    CaseOpenedEvent,
    CaseResolvedEvent,
    MessageDeliveredEvent,
    MessageReadEvent,
    NewMessageEvent,
    RtmsEvent,
    RtmsTargetType
} from '../models/rtm-events.model';
import { filter, map } from 'rxjs/operators';
import { RtmsBaseEventsService } from './base-events.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RtmsCaseService extends RtmsBaseEventsService {

    public targetType: RtmsTargetType = 'case';

    public onEvent(): Observable<RtmsEvent> {
        return super.onEvent().pipe(
            filter(event => event.target_type === this.targetType),
        );
    }

    public onMessage(conversationId?: string): Observable<NewMessageEvent> {
        return super.onMessage(this.targetType, conversationId);
    }

    public onCaseCreated(): Observable<CaseCreatedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (event.target_type !== this.targetType) {
                    return false;
                }
                return event.event === 'conversation_created';
            }),
            map(event => CaseCreatedEvent.createOne(event)),
        );
    }

    public onMessageDelivered(conversationId?: string): Observable<MessageDeliveredEvent> {
        return super.onMessageDelivered(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }

    public onMessageRead(conversationId?: string): Observable<MessageReadEvent> {
        return super.onMessageRead(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }

    public onCaseOpened(): Observable<CaseOpenedEvent> {
        return this.onEvent().pipe(
            filter(event => event.event === 'opened'),
            map(event => CaseOpenedEvent.createOne(event)),
        );
    }

    public onCaseAccepted(conversationId?: string): Observable<CaseAcceptedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'accepted';
            }),
            map(event => CaseAcceptedEvent.createOne(event)),
        );
    }

    public onCaseAssigned(conversationId?: string): Observable<CaseAssignedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'assigned';
            }),
            map(event => CaseAssignedEvent.createOne(event)),
        );
    }

    public onCaseResolved(conversationId?: string): Observable<CaseResolvedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'resolved';
            }),
            map(event => CaseResolvedEvent.createOne(event)),
        );
    }

}
