import { AbstractModel } from '../models/abstract-model';
import { Predicate } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class EphemeralStore<T extends AbstractModel> {
    protected items: T[] = [];

    protected insertSubject: Subject<T> = new Subject<T>();
    protected removeSubject: Subject<T> = new Subject<T>();
    protected updateSubject: Subject<T> = new Subject<T>();

    public onInsert(): Observable<T> {
        return this.insertSubject.asObservable();
    }

    public onRemove(): Observable<T> {
        return this.removeSubject.asObservable();
    }

    public onUpdate(): Observable<T> {
        return this.updateSubject.asObservable();
    }

    constructor(items?: T[]) {
        if (items) {
            for (const item of items) {
                this.insert(item);
            }
        }
    }

    public all(): T[] {
        return this.items.map(item => item.clone());
    }

    public find(predicate: Predicate<T>): T {
        return this.items.find(item => predicate(item));
    }

    public has(model: T): boolean {
        return this.items.findIndex(item => item.is(model)) > -1;
    }

    public insert(model: T): T {
        const clone = model.clone();
        this.items.push(clone);
        this.insertSubject.next(clone);
        return clone;
    }

    public remove(model: T): T {
        const clone = model.clone();
        this.items = this.items.filter(item => !item.is(model));
        this.removeSubject.next(clone);
        return clone;
    }

    public store(model: T): T {
        return this.has(model) ? this.update(model) : this.insert(model);
    }

    public update(model: T): T {
        const index = this.items.findIndex(item => item.is(model));
        const clone = model.clone();
        this.items[index] = clone;
        this.updateSubject.next(clone);
        return clone;
    }

    public clear(): void {
        for (const item of this.items) {
            this.remove(item);
        }
    }

    public sort(compareFn?: (a: T, b: T) => number): T[] {
        return this.items.sort(compareFn);
    }
}
