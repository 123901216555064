import { Component } from '@angular/core';
import { MessageComponent } from '../message';
import { User } from '../../../services/models/user';

@Component({
    selector: 'app-pending-message',
    templateUrl: '../message.html',
    styleUrls: ['../message.scss'],
})
export class AppPendingMessageComponent extends MessageComponent {

    public get user(): User {
        return this.currentUser;
    }

}
