import { EphemeralStore } from './ephemeral.store';
import { GroupEvent } from '../models/group-messaging/group-event';

export class GroupMessageEventStore extends EphemeralStore<GroupEvent> {

    public findById(id: string): GroupEvent {
        return this.find(event => event.id === id);
    }

    public filterByConversation(id: string): GroupEvent[] {
        return this.all()
            .filter(event => !!event.id)
            .filter(event => event.conversation === id);
    }

    public messages(): GroupEvent[] {
        return this.all()
            .filter(event => !!event.id)
            .filter(event => event.type === 'message');
    }

}
