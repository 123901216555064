import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BiometricService {

    private lockedValue: boolean = null;
    private cameraOpen: boolean = null;

    constructor(

    ) {

    }

    public get state(): 'locked' | 'unlocked' {
        return (this.cameraOpen || !this.lockedValue) ? 'unlocked' : 'locked';
    }

    public get locked(): boolean {
        return this.state === 'locked';
    }

    public lock(): void {
        this.lockedValue = true;
    }

    public unlock(): void {
        this.lockedValue = false;
    }

    public cameraTriggered(): void {
        this.cameraOpen = true;
    }

    public cameraCompleted(): void {
        this.cameraOpen = false;
    }
}
