import { Observable } from 'rxjs';
import {
    ConversationCreatedEvent,
    MessageDeliveredEvent,
    MessageReadEvent,
    NewMessageEvent,
    RtmsEvent,
    RtmsTargetType
} from '../models/rtm-events.model';
import { filter } from 'rxjs/operators';
import { RtmsBaseEventsService } from './base-events.service';

export class RtmsDirectMessageService extends RtmsBaseEventsService {

    public targetType: RtmsTargetType = 'direct_message';

    public onEvent(): Observable<RtmsEvent> {
        return super.onEvent().pipe(
            filter(event => event.target_type === this.targetType),
        );
    }

    public onConversationCreated(): Observable<ConversationCreatedEvent> {
        return super.onConversationCreated(this.targetType);
    }

    public onMessage(conversationId?: string): Observable<NewMessageEvent> {
        return super.onMessage(this.targetType, conversationId);
    }

    public onMessageDelivered(conversationId?: string): Observable<MessageDeliveredEvent> {
        return super.onMessageDelivered(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }

    public onMessageRead(conversationId?: string): Observable<MessageReadEvent> {
        return super.onMessageRead(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }
}
