import { Component, Output, EventEmitter, HostBinding, Input, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: 'search.html',
    styleUrls: ['search.scss'],
})
export class SearchComponent implements AfterViewInit, OnDestroy {

    @Output() private closeChange = new EventEmitter<void>();
    @Output() private openChange = new EventEmitter<void>();
    @Output() private searchTermChange = new EventEmitter<string>();

    @ViewChild('searchTermInput') private searchTermInputRef: ElementRef;

    @HostBinding('class.open') public isOpen: boolean;

    private _searchTerm = '';
    private searchTermInput: HTMLInputElement;
    private isDestroyed: Subject<void> = new Subject<void>();

    @Input()
    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        if (value === this._searchTerm) { return; }
        this._searchTerm = value;
        this.searchTermChange.emit(this._searchTerm);
    }

    public ngAfterViewInit(): void {
        this.searchTermInput = this.searchTermInputRef.nativeElement;

        fromEvent<KeyboardEvent>(this.searchTermInput, 'keydown').pipe(
            filter(event => event.code === 'Escape'),
            takeUntil(this.isDestroyed),
        ).subscribe(() => this.clear());
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public clear() {
        if (!this.searchTerm) { return this.close(); }
        this.searchTerm = '';
    }

    public close() {
        this.searchTerm = '';
        this.isOpen = false;
        this.closeChange.emit();
    }

    public open() {
        this.isOpen = true;
        this.openChange.emit();
        this.searchTermInput.focus();
    }

    public toggle() {
        if (this.isOpen) { this.close(); }
        else { this.open(); }
    }

}
