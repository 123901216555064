import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'patientId'
})
export class PatientIdPipe implements PipeTransform {

    public transform(value: string): string {
        while (value.length < 10) {
            value = '0' + value;
        }

        const length = value.length;

        const parts = [
            value.substring(length - 7, 0),
            value.substring(length - 4, length - 7),
            value.substring(length, length - 4),
        ];
        return parts.join(' ');
    }

}
