import { Component, OnDestroy } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CaseMessagingService } from '../../../services/case-messaging-service';
import { CaseConversation } from '../../../services/models/case-conversation';
import { Subject } from 'rxjs';
import { CaseEvent } from '../../../services/models/case-conversation-events/case-event';
import { User } from '../../../services/models/user';
import { UserService } from '../../../services/user.service';
import { Branch } from '../../../services/models/branch';
import { SelectAssigneePage } from '../select-assignee/select-assignee';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'app-case-messenger-page',
    templateUrl: 'messenger.html',
    styleUrls: ['messenger.scss'],
})
export class CaseMessengerPage implements OnDestroy {

    private onDestroy: Subject<void> = new Subject<void>();
    private onRouteChange: Subject<void> = new Subject<void>();

    public case: CaseConversation = null;
    public caseId: string = null;
    public currentUser: User = null;
    public dropDownOpen = true;
    public pendingEvents: CaseEvent[] = [];
    public completedEvents: CaseEvent[] = [];
    public message: string = null;

    public get events(): CaseEvent[] {
        return [].concat(this.pendingEvents, this.completedEvents);
    }

    constructor(
        protected alertController: AlertController,
        protected caseMessaging: CaseMessagingService,
        protected loading: LoadingController,
        protected modalCtrl: ModalController,
        protected navCtrl: NavController,
        protected organisations: OrganisationService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected platform: Platform,
        protected userService: UserService,
    ) {
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => this.currentUser = me);

        route.params.pipe(takeUntil(this.onDestroy)).subscribe((params: { id: string }) => {
            if (this.caseId === params.id) { return; }
            this.caseId = params.id;

            this.caseMessaging.getCase(params.id).pipe(
                takeUntil(this.onRouteChange),
                takeUntil(this.onDestroy),
            ).subscribe(conversation => {
                this.case = conversation;

                if (this.case !== undefined) {
                    this.organisations.fetchBranchUsers(this.case?.recipientBranch?.organisation?.id, this.case?.recipientBranch?.id);
                }

                this.caseMessaging.getPendingEvents(conversation?.offlineId).pipe(
                    takeUntil(this.onRouteChange),
                    takeUntil(this.onDestroy),
                ).subscribe(pendingEvents => {
                    this.pendingEvents = pendingEvents;
                });
            });

            this.caseMessaging.getEvents(params.id).pipe(
                takeUntil(this.onRouteChange),
                takeUntil(this.onDestroy),
            ).subscribe(completedEvents => {
                this.completedEvents = completedEvents;
            });

            this.caseMessaging.fetchCase(params.id);
            this.caseMessaging.fetchEvents(params.id, {
                before: new Date().toISOString(),
            });
        });
    }

    public get otherBranch(): Branch {
        const currentUserId = this.currentUser?.organisation?.id;
        const recipientBranchId = this.case?.recipientBranch?.organisation?.id;
        return currentUserId === recipientBranchId ? this.case.senderBranch : this.case.recipientBranch;
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ionViewWillLeave() {
        this.onRouteChange.next();
    }

    public toggleDropDown() {
        this.dropDownOpen = !this.dropDownOpen;
    }

    async markAsResolved() {
        const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            message: 'Has this case been resolved?',
            buttons: [
              {
                  text: '',
                  role: 'cancel',
                  cssClass: 'close',
              },
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: 'Yes',
                cssClass: 'primary',
                handler: async () => {
                  await this.caseMessaging.resolveCase(this.case.id);
                }
              }
            ]
          });

        await alert.present();
    }


    public async assignUser() {
        const loading = await this.loading.create();
        await loading.present();
        if (this.case.isAwaitingAcceptance) {
            await this.caseMessaging.acceptCase(this.case.id);
        }
        this.userService.fetchUsers();
        const modal = await this.modalCtrl.create({
            component: SelectAssigneePage,
            componentProps: {
                case: this.case,
            },
        });
        await modal.present().then(() => loading.dismiss());
        const user = await modal.onWillDismiss().then(result => result.data || null);
        if (user) {
            await this.caseMessaging.assignCase(this.case.id, user.id);
        }
    }

    public goToCaseHistory() {
        this.router.navigate(['cases', 'history', this.case.patientId]);
    }

    public async openCase() {
        let alert;
        if (this.message === null) {
            alert = await this.alertController.create({
                cssClass: 'custom-alert',
                message: 'Are all case details provided correct?',
                buttons: [
                    {
                        text: '',
                        role: 'cancel',
                        cssClass: 'close',
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    }, {
                        text: 'Yes',
                        cssClass: 'primary',
                        handler: async () => {
                            await this.caseMessaging.openCase(this.case.id);
                            this.router.navigate(['cases', this.case.id, 'sent']);
                        }
                    }
                ]
            });
        } else {
            alert = await this.alertController.create({
                cssClass: 'custom-alert',
                message: 'Please send your message before opening this case.',
                buttons: [
                    {
                        text: '',
                        role: 'cancel',
                        cssClass: 'close',
                    },
                    {
                        text: 'Ok',
                        role: 'cancel',
                        cssClass: 'primary',
                    }
                ]
            });
        }

        if (alert) {
            await alert.present();
        }
    }

    public async cancelCase() {
        const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            message: 'Are you sure you wish to cancel this case?',
            buttons: [
                {
                    text: '',
                    role: 'cancel',
                    cssClass: 'close',
                },
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    cssClass: 'primary',
                    handler: async () => {
                        await this.caseMessaging.cancelCase(this.case.id);
                        this.router.navigate(['cases']);
                    }
                }
            ]
        });

        await alert.present();
    }

    public navigateBack() {
        const commands = this.router.url.split('/');
        commands.pop();
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        this.router.navigate(commands);
    }

    public get isMobile(): boolean {
        return this.platform.is('mobile');
    }

    public messageChanged(message) {
        this.message = message;
    }
}
