import { Component} from '@angular/core';
import { DrawerState } from 'ion-bottom-drawer';

@Component({
    selector: '[app-button]',
    templateUrl: 'button.html',
    styleUrls: ['button.scss'],
})
export class ButtonComponent {

}
