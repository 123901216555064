import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Injectable()
export class GuestGuard implements CanActivate {
    constructor(
        private router: Router,
        private settings: SettingsService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            this.settings.ready().then(() => {
                this.settings.get('cognito:idToken').then((value: string) => {
                    if (!value || value === '') {
                        resolve(true);
                    } else {
                        resolve(this.router.navigate(['']));
                    }
                });
            });
        });
    }
}
