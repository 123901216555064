import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { CaseMessagingService } from './abstract.service';
import { MobileCaseMessagingService } from './mobile.service';
import { BrowserCaseMessagingService } from './browser.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { Router } from '@angular/router';
import { File as CordovaFile } from '@ionic-native/file/ngx';

export const CaseMessagingServiceFactory = (
    api: ApiClientService,
    cordovaFile: CordovaFile,
    database: DatabaseService,
    organisationService: OrganisationService,
    router: Router,
    rtms: RealTimeMessagingService,
    platform: Platform,
    storage: PrivateSettingsService,
    user: UserService,
): CaseMessagingService => {
    const service = platform.is('cordova') ? MobileCaseMessagingService : BrowserCaseMessagingService;
    return new service(api, cordovaFile, database, organisationService, router, rtms, storage, user);
};
