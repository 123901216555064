import { PrivateSettingsService } from './abstract.service';

export class BrowserSettingsService extends PrivateSettingsService {

    public get(id: string): Promise<string> {
        return Promise.resolve(JSON.parse(localStorage.getItem(id)));
    }

    public ready(): Promise<void> {
        return Promise.resolve();
    }

    public remove(id: string): Promise<void> {
        return Promise.resolve(localStorage.removeItem(id));
    }

    public set(id: string, value: any): Promise<void> {
        return Promise.resolve(localStorage.setItem(id, JSON.stringify(value)));
    }

    public clearCache(): Promise<void> {
        return Promise.resolve(undefined);
    }
}
