/* tslint:disable:variable-name */
import { BeforeInsert, BeforeUpdate, Column, Entity, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { AbstractSyncedModel } from './abstract-synced-model';
import { CaseConversation as ApiCaseConversation } from '@scaffold/mediccoms-api-client/models';
import { CaseEvent } from './case-conversation-events/case-event';
import { CaseMessageEvent } from './case-conversation-events/case-message-event';
import { Branch } from './branch';
import { CaseAcceptedEvent } from './case-conversation-events/case-accepted-event';
import { CaseAssignedEvent } from './case-conversation-events/case-assigned-event';
import { CaseOpenedEvent } from './case-conversation-events/case-opened-event';
import { CaseResolvedEvent } from './case-conversation-events/case-resolved-event';
import { CaseCreatedEvent as RtmsCaseConversation } from './rtm-events.model';
import { User } from './user';
import { PendingCaseMessage } from './pending-case-message';
import { max as maxDate, startOfToday, differenceInDays, parseISO } from 'date-fns';

@Entity('case_conversation')
export class CaseConversation extends AbstractSyncedModel {

    public events: CaseEvent[] = [];
    public isOffline: boolean = null;
    public offlineId: string = null;

    @Column({
        name: 'image_id',
        type: 'uuid',
        nullable: true,
    })
    public imageId: string;

    @Column({
        name: 'created_at',
        type: 'varchar',
        nullable: true
    })
    public createdAt: string;

    @Column({
        name: 'opened_at',
        type: 'varchar',
        nullable: true
    })
    public openedAt: string;

    @Column({
        name: 'accepted_at',
        type: 'varchar',
        nullable: true
    })
    public acceptedAt: string;

    @Column({
        name: 'resolved_at',
        type: 'varchar',
        nullable: true
    })
    public resolvedAt: string;

    @Column({
        name: 'patient_id',
        type: 'varchar',
    })
    public patientId: string;

    @Column({
        name: 'case_number',
        type: 'varchar',
    })
    public caseNumber: string;

    @ManyToOne(() => User, null, {nullable: true, eager: true})
    @JoinColumn({name: 'created_by'})
    public createdBy: User;

    @ManyToOne(() => Branch, null, {nullable: true, eager: true})
    @JoinColumn({name: 'sender_branch_id'})
    public senderBranch: Branch;

    @ManyToOne(() => Branch, null, {nullable: true, eager: true})
    @JoinColumn({name: 'recipient_branch_id'})
    public recipientBranch: Branch;

    @ManyToOne(() => User, null, {nullable: true, eager: true})
    @JoinColumn({name: 'assignee_id'})
    public assignee: User;

    @OneToMany(() => PendingCaseMessage, message => message.conversation, {nullable: true})
    public pendingMessages: PendingCaseMessage[];

    public static mapFromApi(data: Partial<ApiCaseConversation>): Partial<CaseConversation> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            imageId: data.image,
            createdAt: data.created_at,
            openedAt: data.opened_at,
            acceptedAt: data.accepted_at,
            resolvedAt: data.resolved_at,
            patientId: data.patient_id,
            caseNumber: data.case_number,
            createdBy: User.mapFromApi(data.creator) as Required<User>,
            senderBranch: Branch.mapFromApi(data.sender_branch) as Required<Branch>,
            recipientBranch: Branch.mapFromApi(data.recipient_branch) as Required<Branch>,
            assignee: data.assignee_id ? User.mapFromApi(data.assignee)  as Required<User> : undefined,
        };
    }

    public static mapFromRtms(data: Partial<RtmsCaseConversation>): Partial<CaseConversation> {
        if (!data) {
            return null;
        }

        if (!data.data) {
            return null;
        }

        if (!data.data.case) {
            return null;
        }

        const caseData = data.data.case;
        return {
            id: caseData.id,
            imageId: caseData.image,
            createdAt: caseData.created_at,
            openedAt: caseData.opened_at,
            acceptedAt: caseData.accepted_at,
            resolvedAt: caseData.resolved_at,
            patientId: caseData.patient_id,
            caseNumber: caseData.case_number,
            createdBy: User.mapFromApi({id: caseData.created_by}) as Required<User>,
            senderBranch: Branch.mapFromApi(caseData.sender_branch as any) as Required<Branch>,
            recipientBranch: Branch.mapFromApi(caseData.recipient_branch as any) as Required<Branch>,
            assignee: caseData.assignee_id ? User.mapFromApi({id: caseData.assignee_id}) as Required<User> : undefined,
        };
    }

    public static createEvent(data: Partial<CaseEvent>): CaseEvent {
        let event = null;
        switch (data.type) {
            case 'message':
            case 'new_message':
            case 'message_delivered':
            case 'message_read':
                event = CaseMessageEvent.createOne(data) as CaseMessageEvent;
                break;
            case 'accepted':
                event = CaseAcceptedEvent.createOne(data) as CaseAcceptedEvent;
                break;
            case 'assigned':
                event = CaseAssignedEvent.createOne(data) as CaseAssignedEvent;
                break;
            case 'opened':
                event = CaseOpenedEvent.createOne(data) as CaseOpenedEvent;
                break;
            case 'resolved':
                event = CaseResolvedEvent.createOne(data) as CaseResolvedEvent;
                break;
            default:
                break;
        }
        return event;
    }

    @BeforeInsert()
    @BeforeUpdate()
    public async beforeSave() {
        if (this.createdBy) { await this.createdBy.save(); }
        if (this.senderBranch) { await this.senderBranch.save(); }
        if (this.recipientBranch) { await this.recipientBranch.save(); }
        if (this.assignee) { await this.assignee.save(); }
        if (this.events) {
            for (const event of this.events) {
                await event.save();
            }
        }
    }

    public addEvent(event: CaseEvent) {
        const _event: CaseEvent = new CaseEvent();
        _event.conversation = this.id;
        _event.type = event.type;
        this.events.push(_event);
    }

    public addEvents(events: CaseEvent[]) {
        for (const event of events) {
            this.addEvent(event);
        }
    }

    public fill(data: Partial<CaseConversation>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.imageId !== undefined) {
            this.imageId = data.imageId;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.createdBy !== undefined) {
            this.createdBy = User.createOne( data.createdBy );
        }
        if (data.openedAt !== undefined) {
            this.openedAt = data.openedAt;
        }
        if (data.acceptedAt !== undefined) {
            this.acceptedAt = data.acceptedAt;
        }
        if (data.resolvedAt !== undefined) {
            this.resolvedAt = data.resolvedAt;
        }
        if (data.patientId !== undefined) {
            this.patientId = data.patientId;
        }
        if (data.caseNumber !== undefined) {
            this.caseNumber = data.caseNumber;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.senderBranch !== undefined) {
            this.senderBranch = Branch.createOne(data.senderBranch);
        }
        if (data.recipientBranch !== undefined) {
            this.recipientBranch = Branch.createOne(data.recipientBranch);
        }
        if (data.assignee !== undefined) {
            this.assignee = User.createOne(data.assignee);
        }
        if (data.events !== undefined) {
            for (const eventData of data.events) {
                if (!eventData) { continue; }
                let event = this.events.find(_event => _event?.id === eventData?.id);
                if (!event) {
                    // Create Event and Push
                    if (!(eventData instanceof CaseEvent)) {
                        console.log('CreateCaseEvent', eventData);
                        event = CaseEvent.createOne(eventData);
                    } else {
                        event = eventData;
                    }
                    this.events.push(event);
                } else {
                    // Update Event
                    event.fill(eventData);
                }
            }
        }
        if (data.isOffline !== undefined) {
            this.isOffline = data.isOffline;
        }
        if (data.offlineId !== undefined) {
            this.offlineId = data.offlineId;
        }
    }

    public get hasMessages(): boolean {
        return !!this.events.filter(event => event.type === 'message').length;
    }

    public get hasBeenOpened(): boolean {
        return !!this.openedAt;
    }

    public get hasBeenAssigned(): boolean {
        return !!this.assignee && !!this.assignee?.id;
    }

    public get hasBeenAccepted(): boolean {
        return !!this.acceptedAt;
    }

    public get hasBeenResolved(): boolean {
        return !!this.resolvedAt;
    }

    public get initials(): string {
        if (!this.recipientBranch || !this.recipientBranch.name) { return 'N/A'; }
        return this.recipientBranch.initials;
    }

    public get isOpen(): boolean {
        return this.hasBeenOpened && !this.hasBeenResolved;
    }

    public get isAccepted(): boolean {
        return this.isOpen && this.hasBeenAccepted && !this.isAssigned;
    }

    public get isAssigned(): boolean {
        return this.isOpen && this.hasBeenAssigned;
    }

    public get isResolved(): boolean {
        return this.hasBeenResolved;
    }

    public get isAwaitingAcceptance(): boolean {
        return this.isOpen && !this.hasBeenAccepted;
    }

    public get isAwaitingAssignment(): boolean {
        return this.isAccepted && !this.hasBeenAssigned;
    }

    public get updatedAt(): Date {
        const dates = [
            this.latestMessage?.sentAt,
            this.createdAt,
            this.openedAt,
            this.acceptedAt,
            this.resolvedAt,
        ].filter(item => item).map(item => new Date(item));

        return dates.length ? maxDate(dates) : null;
    }

    public get latestMessage(): CaseMessageEvent {
        const messages = this.events ? this.events.filter(event => event.type === 'message') : [];

        if (!messages.length) { return null; }

        let latestSentAt = null;
        let latestEvent = null;

        for (const event of messages) {
            const sentAt = new Date(event.sentAt);
            if (sentAt > latestSentAt) {
                latestEvent = event;
                latestSentAt = sentAt;
            }
        }

        return latestEvent;
    }

    public toJSON() {
        return {
            id: this.id,
            imageId: this.imageId,
            createdAt: this.createdAt,
            createdBy: this.createdBy ? this.createdBy.id : null,
            openedAt: this.openedAt,
            acceptedAt: this.acceptedAt,
            resolvedAt: this.resolvedAt,
            patientId: this.patientId,
            caseNumber: this.caseNumber,
            senderBranch: this.senderBranch ? this.senderBranch.id : null,
            recipientBranch: this.recipientBranch ? this.recipientBranch.id : null,
            assignee: this.assignee ? this.assignee.id : null,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public isMine(id: string) {
        return this.createdBy?.id === id || this.assignee?.id === id;
    }

    public is(otherModel: Partial<this>): boolean {
        return this.id === otherModel.id;
    }

    public daysResolved() {
        if (!this.isResolved) {
            return false;
        }
        const today = startOfToday();
        return differenceInDays(today, parseISO(this.resolvedAt));
    }

    public resolvedWithinDays(numberOfDays: number = 30) {
        if (!this.isResolved) {
            return false;
        }
        return this.daysResolved() > numberOfDays;
    }
}
