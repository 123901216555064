import { OrganisationService } from './abstract.service';
import { OrganisationStore } from '../stores/organisation.store';
import { merge, Observable, Subject } from 'rxjs';
import { Organisation } from '../models/organisation';
import { debounceTime, filter, first } from 'rxjs/operators';
import { BranchStore } from '../stores/branch.store';
import { Branch } from '../models/branch';
import { User } from '../models/user';
import { User as ApiUser } from '@scaffold/mediccoms-api-client/models/user';
import { BranchUserStore } from '../stores/branchUser.store';
import { BranchUser } from '../models/branch-user';

export class BrowserOrganisationService extends OrganisationService {

    public branches: BranchStore = new BranchStore();
    public organisations: OrganisationStore = new OrganisationStore();
    public users: BranchUserStore = new BranchUserStore();
    protected isReady = false;
    protected readySubject: Subject<boolean> = new Subject<boolean>();

    public async fetchOrganisation(organisationId: string): Promise<Organisation> {
        const response = await this.api.organisations.get(organisationId).toPromise();

        const organisation = this.organisations.findById(response.organisation.id) ||
            Organisation.createOne({ id: response.organisation.id });

        organisation.fillFromApi(response.organisation);

        return this.organisations.store(organisation);
    }

    public async fetchOrganisations(): Promise<Organisation[]> {
        const response = await this.api.organisations.all({page: 1}).toPromise();
        if (!response) {
            return;
        }
        return response.organisations.map(item => {
            const organisation = this.organisations.findById(item.id) || Organisation.createOne({ id: item.id });
            organisation.fillFromApi(item);
            this.organisations.store(organisation);
            return organisation;
        });
    }

    public async fetchBranches(organisationId: string): Promise<Branch[]> {
        const response = await this.api.branches.all(organisationId, {page: 1}).toPromise();
        const organisation = this.organisations.findById(organisationId) || Organisation.createOne({ id: organisationId });

        if (!response) {
            return;
        }

        return response.branches.map(item => {
            const branch = this.branches.findById(item.id) || Branch.createOne({ id: item.id });
            branch.fillFromApi(item);
            branch.fill({ organisation });
            return this.branches.store(branch);
        });
    }

    public getOrganisation(organisationId: string): Observable<Organisation> {
        return new Observable<Organisation>(observer => {
            const organisations = this.organisations;
            observer.next(organisations.findById(organisationId));

            const subscription = merge(
                organisations.onInsert(),
                organisations.onUpdate(),
                organisations.onRemove(),
            ).pipe(
                filter(organisation => organisation && organisation.id === organisationId),
                debounceTime(10)
            ).subscribe(() => {
                observer.next(organisations.findById(organisationId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getOrganisations(): Observable<Organisation[]> {
        return new Observable<Organisation[]>(observer => {
            const organisations = this.organisations;
            observer.next(organisations.all());

            const subscription = merge(
                organisations.onInsert(),
                organisations.onUpdate(),
                organisations.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(organisations.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    public getAllBranches(): Observable<Branch[]> {
        return this.getBranches();
    }

    public getBranches(organisationId?: string): Observable<Branch[]> {
        return new Observable<Branch[]>(observer => {
            const branches = this.branches;
            observer.next(organisationId ? branches.findByOrganisationId(organisationId) : branches.all());

            const subscription = merge(
                branches.onInsert(),
                branches.onUpdate(),
                branches.onRemove(),
            ).pipe(
                filter(branch => organisationId ? branch.organisation?.id === organisationId : true),
                debounceTime(10)
            ).subscribe(() => {
                observer.next(organisationId ? branches.findByOrganisationId(organisationId) : branches.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    public getBranch(branchId: string): Observable<Branch> {
        return new Observable<Branch>(observer => {
            const branches = this.branches;
            observer.next(branches.findById(branchId));

            const subscription = merge(
                branches.onInsert(),
                branches.onUpdate(),
                branches.onRemove(),
            ).pipe(
                filter(branch => branchId === branch.id),
                debounceTime(10)
            ).subscribe(() => {
                observer.next(branches.findById(branchId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public clear(): Promise<void> {
        console.log('organisations.branches clear');
        this.branches.clear();
        console.log('organisations.branches cleared');
        console.log('organisations.organisations clear');
        this.organisations.clear();
        console.log('organisations.organisations cleared');
        console.log('organisations.users clear');
        this.users.clear();
        console.log('organisations.users cleared');
        return Promise.resolve(undefined);
    }

    public loadFromMemory(): Promise<void> {
        this.isReady = true;
        this.readySubject.next(true);
        return Promise.resolve(undefined);
    }

    public async fetchBranchUsers(organisationId: string, branchId: string): Promise<User[]> {
        const results: ApiUser[] = [];
        let currentPage = 1;
        let perPage = 0;
        let totalItems = 0;
        const users: User[] = [];
        do {
            const response = await this.api.branches.allUsers(organisationId, branchId, {page: currentPage}).toPromise();

            if (!response) {
                return;
            }

            currentPage = response.pagination.current_page;
            perPage = response.pagination.per_page;
            totalItems = response.pagination.total_items;
            for (const data of response.users) {
                results.push(data);
                const user = User.createOne({id: data.id});
                user.fillFromApi(data);
                users.push(user);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        const branchUsers = this.users.findById(branchId) || BranchUser.createOne({
            branch_id: branchId,
        });
        branchUsers.users = users;
        this.users.store(branchUsers);
        return this.users.getUsers(branchId);
    }

    public getBranchUsers(organisationId: string, branchId: string): Observable<User[]> {
        return new Observable<User[]>(observer => {
            const users = this.users;
            observer.next(users.getUsers(branchId));

            const subscription = merge(
                users.onInsert(),
                users.onUpdate(),
                users.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(users.getUsers(branchId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.isReady) {
                return resolve(true);
            }

            this.readySubject.asObservable().pipe(
                first()
            ).subscribe((ready) => resolve(ready));
        });
    }
}
