import { Component } from '@angular/core';
import { MessageInfo } from './message-info';

@Component({
    selector: 'app-phone-message-info-page',
    templateUrl: 'phone-message-info.html',
    styleUrls: ['message-info.scss', 'phone-message-info.scss'],
})
export class PhoneMessageInfoPage extends MessageInfo {



}
