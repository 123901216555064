import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, AlertController, NavController, Platform } from '@ionic/angular';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { User } from '../../services/models/user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { WebFile } from '../../helpers/web-file';
import { HttpEventType } from '@angular/common/http';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { BiometricService } from '../../services/biometric.service';

@Component({
    selector: 'app-message-group-detail-page',
    template: '',
    styleUrls: ['message-group-detail.scss'],
})

export abstract class MessageGroupDetail implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild('imageInput', { static: false }) public imageUploadRef: ElementRef<HTMLInputElement>;
    public imageInput: HTMLInputElement;

    public actionSheet: HTMLIonActionSheetElement = null;
    public conversation: GroupMessageConversation = null;
    private conversationId: string = null;
    private currentUser: User = null;
    public currentUserAdmin = false;
    public onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();
    public conversationReady: Subject<void> = new Subject<void>();
    public viewInitSubject: Subject<void> = new Subject<void>();
    public imageId: string = null;
    public viewInit = false;

    constructor(
        private actionSheetController: ActionSheetController,
        public alertController: AlertController,
        private biometric: BiometricService,
        private api: ApiClientService,
        private camera: Camera,
        private file: CordovaFile,
        private groupMessaging: GroupMessagingService,
        private navCtrl: NavController,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ){
        route.params.subscribe((params: { conversation: string }) => {
            if (params.conversation !== this.conversationId) {
                this.conversationId = params.conversation;
                this.setConversation(params.conversation);
            }
        });

        this.viewInitSubject.asObservable().pipe(
                takeUntil(this.onDestroy)
            ).subscribe(() => {
            if (this.currentUser && this.conversation && !this.imageInput) {
                if (this.imageUploadRef) {
                    this.imageInput = this.imageUploadRef.nativeElement;
                }
            }
        });

        this.userReady.asObservable().pipe(
                takeUntil(this.onDestroy)
            ).subscribe(() => {
            if (this.viewInit && this.conversation && !this.imageInput) {
                if (this.imageUploadRef) {
                    this.imageInput = this.imageUploadRef.nativeElement;
                }
            }
        });

        this.conversationReady.asObservable().pipe(
                takeUntil(this.onDestroy)
            ).subscribe(() => {
            if (this.viewInit && this.currentUser && !this.imageInput) {
                if (this.imageUploadRef) {
                    this.imageInput = this.imageUploadRef.nativeElement;
                }
            }
        });
    }

    public ngAfterViewInit() {
        this.viewInit = true;
        this.viewInitSubject.next();
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
            if (me && this.conversation) {
                const currentUserParticipant = this.conversation.members.find(participant => participant.id === this.currentUser.id);
                this.currentUserAdmin = currentUserParticipant?.admin;
            }
        });
    }

    public async goToGroupMemberDetail(user: User) {
        await this.router.navigate([`/my-messages/group/${this.conversationId}/participant/${user.id}`]);
    }

    public setConversation(conversationId: string) {
        this.groupMessaging.getGroup(conversationId).pipe(takeUntil(this.onDestroy)).subscribe((conversation) => {
            this.conversation = conversation;
            this.conversationReady.next();

            if (conversation instanceof GroupMessageConversation) {
                if (this.currentUser) {
                    const currentUserParticipant = conversation.members.find(participant => participant.id === this.currentUser.id);
                    this.currentUserAdmin = currentUserParticipant?.admin;
                }
            }
        });
        this.groupMessaging.fetchGroup(conversationId).then(() => {
            this.groupMessaging.fetchEvents(conversationId, {
                before: new Date().toISOString()
            });
        });
    }

    public async uploadIcon() {
        if (this.currentUserAdmin) {
            if (this.platform.is('android')) {
                await this.presentAddImageAction();
            } else {
                this.selectPhoto();
            }
        }
    }

    private selectPhoto() {
        if (this.platform.is('android')) {
            this.biometric.cameraTriggered();
        }
        this.imageInput.click();
    }

    private presentAddImageAction() {
        this.actionSheetController.create({
            buttons: [{
                text: 'Photo Library',
                icon: 'image-outline',
                handler: () => this.selectPhoto()
            }, {
                text: 'Take Photo',
                icon: 'camera-outline',
                handler: () => this.androidTakePhoto()
            }]
        }).then(async (actionSheet) => {
            this.actionSheet = actionSheet;
            await actionSheet.present();
        });
    }

    private async androidTakePhoto() {
        this.biometric.cameraTriggered();

        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            targetWidth: 1280,
            targetHeight: 1280,
        };

        this.camera.getPicture(options).then(async (tempFile: string) => {
            const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
            const tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
            const directory = await this.file.resolveDirectoryUrl(tempBaseFilesystemPath);
            const file = await this.file.getFile(directory, tempFilename, {});

            const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolve) => {
                const fileReader = new FileReader();
                fileReader.onload = event => resolve(event.target.result as ArrayBuffer);
                fileReader.readAsArrayBuffer(input);
            });

            file.file(async (resFile: IFile) => {
                const buffer = await getArrayBufferForFile(resFile);

                const icon = new WebFile([buffer], resFile.name, { lastModified: Date.now() });
                const response = await new Promise<string>((resolve) => {
                    this.api.attachments.uploadImage(icon).pipe(takeUntil(this.onDestroy)).subscribe(result => {
                        if (result.type === HttpEventType.Response) {
                            resolve(result.body.image_id);
                        }
                    });
                });

                this.biometric.cameraCompleted();

                await this.groupMessaging.changeGroupIcon(this.conversationId, response);

                if (this.actionSheet) {
                    this.actionSheet.dismiss();
                }
            });
        });
    }

    public async renameGroup() {
        if (this.currentUserAdmin) {
            await this.router.navigate([`/my-messages/group/${this.conversationId}/rename`]);
        }
    }

    public async goToSharedImages(){
        await this.router.navigate([`/my-messages/group/${this.conversationId}/images`]);
    }

    public async addUsers(){
        await this.router.navigate([`/my-messages/group/${this.conversationId}/participant/add`]);
    }

    async leaveGroup() {
        const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            message: 'Are you sure you want to leave this group?',
            buttons: [
                {
                    text: '',
                    role: 'cancel',
                    cssClass: 'close',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Leave',
                    cssClass: 'primary',
                    handler: () => {
                        this.groupMessaging.leaveGroup(this.conversationId).then(async () => {
                            this.navCtrl.setDirection('back', true);
                            await this.router.navigate(['my-messages']);
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async deleteGroup() {
        const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            message: 'Are you sure you want to delete this group?',
            buttons: [
                {
                    text: '',
                    role: 'cancel',
                    cssClass: 'close',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                },
                {
                    text: 'Delete',
                    cssClass: 'primary',
                    handler: () => {
                        this.groupMessaging.deleteGroup(this.conversationId).then(async () => {
                            this.navCtrl.setDirection('back', true);
                            await this.router.navigate(['my-messages']);
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    public async navigateBack() {
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        await this.router.navigate([`/my-messages/group/${this.conversationId}`]);
    }

    public async onIconAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        if (this.imageInput) {
            if (this.imageInput.files && this.imageInput.files.length) {
                const response = await new Promise<string>((resolve) => {
                    this.api.attachments.uploadImage(this.imageInput.files[0]).pipe(takeUntil(this.onDestroy)).subscribe(result => {
                        if (result.type === HttpEventType.Response) {
                            resolve(result.body.image_id);
                        }
                    });
                });

                if (this.platform.is('android')) {
                    this.biometric.cameraCompleted();
                }

                await this.groupMessaging.changeGroupIcon(this.conversationId, response);
            }
        }
    }

}
