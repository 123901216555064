import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-notification-bar',
    templateUrl: 'notification-bar.html',
    styleUrls: ['notification-bar.scss'],
})
export class NotificationBarComponent {

    @Input('setNotificationType') setNotificationType: string;

    notificationTypes = [
        {
            "name": "offline",
            "icon": "/assets/icon/warning.svg",
            "text": "You are currently offline."
        }
    ];

}
