import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AnalyticsService {

    constructor(
        private activatedRoute: ActivatedRoute,
        private analyticsApp: FirebaseAnalytics,
        private analyticsWeb: AngularFireAnalytics,
        private platform: Platform,
        private router: Router,
    ) {
        this.platform.ready().then(() => {
            this.setEnabled(true);
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.rootRoute(this.activatedRoute)),
                switchMap(route => route.data),
                map(data => data?.pageName)
            ).subscribe((pageName) => {
                this.setCurrentScreen(pageName);
            });
        });
    }

    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    public async logEvent(name: string, params?: any): Promise<any> {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                return this.analyticsApp.logEvent(name, params);
            } else {
                return this.analyticsWeb.logEvent(name, params);
            }
        });
    }

    public async setUserProperty(name: string, value: string): Promise<any> {
        if (this.platform.is('cordova')) {
            return this.analyticsApp.setUserProperty(name, value);
        } else {
            return this.analyticsWeb.setUserProperties({[name]: value});
        }
    }

    public async setCurrentScreen(screenName: string): Promise<any> {
        if (this.platform.is('cordova')) {
            return this.analyticsApp.setCurrentScreen(screenName);
        } else {
            await this.analyticsWeb.setCurrentScreen(screenName);
            return this.analyticsWeb.logEvent('screen_view');
        }
    }

    private async setEnabled(enabled: boolean): Promise<any> {
        if (this.platform.is('cordova')) {
            return this.analyticsApp.setEnabled(enabled);
        } else {
            return this.analyticsWeb.setAnalyticsCollectionEnabled(enabled);
        }
    }
}
