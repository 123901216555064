/* tslint:disable:variable-name */
import { AbstractSyncedModel } from '../abstract-synced-model';
import { GroupMessageConversation as ApiGroupMessageConversation } from '@scaffold/mediccoms-api-client/models/group-message-conversation';
import { ConversationCreatedEvent as RtmsGroupMessageConversation } from './../rtm-events.model';
import { GroupMessageParticipant } from './group-message-participant';
import { GroupMessageEvent } from './group-message-event';
import { GroupEvent } from './group-event';
import { GroupMessageParticipantAddedEvent } from './group-message-participant-added-event';
import { GroupMessageParticipantLeftEvent } from './group-message-participant-left-event';
import { GroupMessageParticipantRemovedEvent } from './group-message-participant-removed-event';
import { max as maxDate } from 'date-fns';
import { User } from '../user';

export class GroupMessageConversation extends AbstractSyncedModel {

    public id: string;
    public image: string;
    public members: GroupMessageParticipant[];
    public name: string;
    public events: GroupEvent[] = [];
    public createdAt: string;
    public recipient: User = null;

    public static createEvent(data: Partial<GroupEvent>): GroupEvent {
        let event = null;
        switch (data.type) {
            case 'message':
                event = GroupMessageEvent.createOne(data);
                break;
            case 'participant_added':
                event = GroupMessageParticipantAddedEvent.createOne(data);
                break;
            case 'participant_left':
                event = GroupMessageParticipantLeftEvent.createOne(data);
                break;
            case 'participant_removed':
                event = GroupMessageParticipantRemovedEvent.createOne(data);
                break;
        }
        return event;
    }

    public static mapFromApi(data: Partial<ApiGroupMessageConversation>): Partial<GroupMessageConversation> {
        if (!data) {
            return null;
        }

        const members: GroupMessageParticipant[] = [];
        for (const member of data.members) {
            members.push(GroupMessageParticipant.mapFromApi(member) as Required<GroupMessageParticipant>);
        }

        return {
            id: data.id,
            image: data.image,
            members,
            name: data.name,
            createdAt: data.created_at,
        };
    }

    public static mapFromRtms(data: Partial<RtmsGroupMessageConversation>): Partial<GroupMessageConversation> {
        if (!data) {
            return null;
        }

        const members: GroupMessageParticipant[] = [];
        for (const member of data.data.participants) {
            members.push(GroupMessageParticipant.mapFromRtms(member) as Required<GroupMessageParticipant>);
        }

        return {
            id: data.target_id,
            image: data.data.image,
            members,
            name: data.data.name,
            createdAt: data.timestamp,
        };
    }

    public addEvent(event: GroupEvent) {
        this.events.push(event);
    }

    public addEvents(events: GroupEvent[]) {
        for (const event of events) {
            this.addEvent(event);
        }
    }

    public get latestMessage(): GroupMessageEvent {
        let latestSentAt = null;
        let latestEvent = null;

        for (const currentEvent of this.events) {
            if (!(currentEvent instanceof GroupMessageEvent)) {
                continue;
            }
            const sentAt = new Date(currentEvent.sentAt);
            if (sentAt > latestSentAt) {
                latestEvent = currentEvent;
                latestSentAt = sentAt;
            }
        }

        return latestEvent;
    }

    public get updatedAt(): Date {
        const dates = [
            this.latestMessage?.sentAt,
            this.createdAt
        ].filter(item => item).map(item => new Date(item));

        return dates.length ? maxDate(dates) : null;
    }

    public get initials(): string | null {
        const letters = (() => {
            if (!this.name) { return []; }

            const capitalLetters = this.name.split('').filter(letter => {
                const charCode = letter.charCodeAt(0);
                return charCode >= 65 && charCode <= 90;
            });

            if (capitalLetters.length) { return capitalLetters; }

            const words = this.name.split(' ');

            if (words.length) { return words.map(item => item[0]); }
        })();

        return letters.length ? letters.slice(0, 2).join('') : 'N/A';
    }

    public fill(data: Partial<GroupMessageConversation>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.members !== undefined) {
            this.members = GroupMessageParticipant.createMany(data.members);
        }
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.events !== undefined) {
            for (const eventData of data.events) {
                if (!eventData) { continue; }
                let event = this.events.find(_event => _event?.id === eventData?.id);
                if (!event) {
                    // Create Event and Push
                    if (!(eventData instanceof GroupEvent)) {
                        event = GroupMessageConversation.createEvent(eventData);
                    } else {
                        event = eventData;
                    }
                    this.events.push(event);
                } else {
                    // Update Event
                    event.fill(eventData);
                }
            }
        }
    }

    public get displayName(): string {
        return this.name;
    }
}
