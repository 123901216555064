import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';

@Injectable()
export abstract class SettingsService {

    constructor(
        public database: DatabaseService,
    ) {}

    public abstract get(id: string): Promise<string>;
    public abstract set(id: string, value: string): Promise<void>;
    public abstract remove(id: string): Promise<void>;
    public abstract ready(): Promise<void>;
}
