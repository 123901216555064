import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlatformFactory } from '../helpers';

import { PhoneOnBordingPage } from './on-boarding/phone-on-boarding';
import { TabletOnBoardingPage } from './on-boarding/tablet-on-boarding';

import { PhoneDashboardPage } from './dashboard/phone-dashboard';
import { TabletDashboardPage } from './dashboard/tablet-dashboard';

import { PhoneMyMessagesPage } from './my-messages/phone-my-messages';

import { PhoneMessengerProfilePage } from './messenger-profile/phone-messenger-profile';
import { TabletMessengerProfilePage  } from './messenger-profile/tablet-messenger-profile';

import { PhoneNewGroupMessageParticipantsPage } from './new-group-message-participants/phone-new-group-message-participants';
import { TabletNewGroupMessageParticipantsPage  } from './new-group-message-participants/tablet-new-group-message-participants';

import { PhoneNewGroupMessageNamePage } from './new-group-message-name/phone-new-group-message-name';

import { PhoneMessagePage } from './message/phone-message';

import { PhoneMessageGroupPage } from './message-group/phone-message-group';
import { TabletMessageGroupPage  } from './message-group/tablet-message-group';

import { PhoneMessageGroupDetailPage } from './message-group-detail/phone-message-group-detail';
import { TabletMessageGroupDetailPage  } from './message-group-detail/tablet-message-group-detail';

import { PhoneMessageGroupRenamePage } from './message-group-rename/phone-message-group-rename';
import { TabletMessageGroupRenamePage   } from './message-group-rename/tablet-message-group-rename';

import { PhoneMessageGroupSharedImagesPage } from './message-group-shared-images/phone-message-group-shared-images';
import { TabletMessageGroupSharedImagesPage } from './message-group-shared-images/tablet-message-group-shared-images';

import { PhoneMessageUserDetailPage } from './message-user-detail/phone-message-user-detail';
import { TabletMessageUserDetailPage  } from './message-user-detail/tablet-message-user-detail';

import { PhoneLoginPage } from './login/phone-login';
import { TabletLoginPage  } from './login/tablet-login';

import { PhoneForgotPasswordPage} from './forgot-password/phone-forgot-password';
import { TabletForgotPasswordPage  } from './forgot-password/tablet-forgot-password';

import { PhoneForgotPasswordSentPage} from './forgot-password-sent/phone-forgot-password-sent';
import { TabletForgotPasswordSentPage  } from './forgot-password-sent/tablet-forgot-password-sent';

import { MyProfilePage } from './my-profile/my-profile';

import { PhoneMessageInfoPage } from './message-info/phone-message-info';

import { PhoneMessageGroupParticipantPage } from './message-group-participant/phone-message-group-participant';
import { TabletMessageGroupParticipantPage } from './message-group-participant/tablet-message-group-participant';

import { PhoneChangeTemporaryPassword } from './change-temporary-password/phone-change-temporary-password';
import { TabletChangeTemporaryPassword } from './change-temporary-password/tablet-change-temporary-password';

import { TabletLoginMfaPage } from './login-mfa/tablet-login-mfa';
import { PhoneLoginMfaPage } from './login-mfa/phone-login-mfa';

import { TabletForgotPasswordChangedPage } from './forgot-password-changed/tablet-forgot-password-changed';
import { PhoneForgotPasswordChangedPage } from './forgot-password-changed/phone-forgot-password-changed';

import { PhoneForgotPasswordChangePage } from './forgot-password-change/phone-forgot-password-change';
import { TabletForgotPasswordChangePage } from './forgot-password-change/tablet-forgot-password-change';

import { TabletChangePasswordPage } from './change-password/tablet-change-password';
import { PhoneChangePasswordPage } from './change-password/phone-change-password';

import { TabletChangedPasswordPage } from './changed-password/tablet-changed-password';
import { PhoneChangedPasswordPage } from './changed-password/phone-changed-password';

import { AuthGuard } from '../guards/auth.guard';
import { GuestGuard } from '../guards/guest.guard';
import { FirstLoadGuard } from '../guards/first-load.guard';
import { TabletSplitViewPage } from './tablet-split-view/tablet-split-view';
import { ReadyGuard } from '../guards/ready.guard';
import { BlankPage } from './blank/blank';
import { DeveloperToolsPage } from './developer-tools/developer-tools.page';

const routes: Routes = [
    {
        path: '',
        component: PlatformFactory(TabletOnBoardingPage, { phone: PhoneOnBordingPage }),
        canActivate: [FirstLoadGuard],
        data: {
            pageName: 'Welcome / Onboarding'
        }
    },
    {
        path: 'login',
        component: PlatformFactory(TabletLoginPage, { phone: PhoneLoginPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Login'
        }
    },
    {
        path: 'developer',
        component: PlatformFactory(DeveloperToolsPage, { phone: DeveloperToolsPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Developer Tools'
        }
    },
    {
        path: 'change-temporary-password',
        component: PlatformFactory(TabletChangeTemporaryPassword, { phone: PhoneChangeTemporaryPassword }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Change Temporary Password'
        }
    },
    {
        path: 'mfa',
        component: PlatformFactory(TabletLoginMfaPage, { phone: PhoneLoginMfaPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Login MFA'
        }
    },
    {
        path: 'forgot-password',
        component: PlatformFactory(TabletForgotPasswordPage, { phone: PhoneForgotPasswordPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Forgot Password - Request'
        }
    },
    {
        path: 'forgot-password-sent',
        component: PlatformFactory(TabletForgotPasswordSentPage, { phone: PhoneForgotPasswordSentPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Forgot Password - Sent'
        }
    },
    {
        path: 'reset-password',
        component: PlatformFactory(TabletForgotPasswordSentPage, { phone: PhoneForgotPasswordSentPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Forgot Password - Sent '
        }
    },
    {
        path: 'forgot-password-change',
        component: PlatformFactory(TabletForgotPasswordChangePage, { phone: PhoneForgotPasswordChangePage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Forgot Password - Change'
        }
    },
    {
        path: 'forgot-password-changed',
        component: PlatformFactory(TabletForgotPasswordChangedPage, { phone: PhoneForgotPasswordChangedPage }),
        canActivate: [GuestGuard],
        data: {
            pageName: 'Forgot Password - Change Success'
        }
    },
    {
        path: 'dashboard',
        component: PlatformFactory(TabletDashboardPage, { phone: PhoneDashboardPage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'Dashboard'
        }
    },
    {
        path: 'cases',
        loadChildren: () => import('./cases/cases.routing').then(m => m.CasesRoutingModule),
    },
    {
        path: 'my-messages',
        component: PlatformFactory(TabletSplitViewPage, { phone: BlankPage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Messages - List'
        },
        children: [{
            path: '',
            outlet: 'sidebar',
            component: PhoneMyMessagesPage,
        }, {
            path: '',
            component: PlatformFactory(BlankPage, { phone: PhoneMyMessagesPage }),
            data: {
                pageName: 'My Messages - All Messages'
            }
        }, {
            path: ':id',
            outlet: 'sidebar',
            component: PhoneMyMessagesPage,
        }, {
            path: ':id',
            component: PhoneMessagePage,
            data: {
                pageName: 'My Messages - Direct Message'
            }
        }, {
            path: 'group/:id',
            outlet: 'sidebar',
            component: PhoneMyMessagesPage,
        }, {
            path: 'group/:id',
            component: PhoneMessagePage,
            data: {
                pageName: 'My Messages - Group Conversation'
            }
        }]
    },
    {
        path: 'my-messages/group/:conversation/info',
        canActivate: [AuthGuard, ReadyGuard],
        component: PlatformFactory(TabletMessageGroupDetailPage, { phone: PhoneMessageGroupDetailPage }),
        data: {
            pageName: 'My Messages - Group Conversation Information'
        }
    },
    {
        path: 'my-messages/group/:conversation/images',
        canActivate: [AuthGuard, ReadyGuard],
        component: PlatformFactory(TabletMessageGroupSharedImagesPage, { phone: PhoneMessageGroupSharedImagesPage }),
        data: {
            pageName: 'My Messages - Group Conversation Images'
        }
    },
    {
        path: 'my-messages/group/:conversation/rename',
        component: PlatformFactory(TabletMessageGroupRenamePage, { phone: PhoneMessageGroupRenamePage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Messages - Rename Group Conversation'
        }
    },
    {
        path: 'my-messages/group/:conversation/participant/add',
        component: PlatformFactory(TabletNewGroupMessageParticipantsPage, { phone: PhoneNewGroupMessageParticipantsPage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Messages - Add Participant to Group Conversation'
        }
    },
    {
        path: 'my-messages/group/:conversation/participant/:user',
        canActivate: [AuthGuard, ReadyGuard],
        component: PlatformFactory(TabletMessageGroupParticipantPage, { phone: PhoneMessageGroupParticipantPage }),
        data: {
            pageName: 'My Messages - View Participant in Group Conversation'
        }
    },
    {
        path: 'my-messages/group/:conversation/:id',
        component: PlatformFactory(TabletSplitViewPage, { phone: PhoneMessageInfoPage }),
        canActivate: [AuthGuard, ReadyGuard],
        children: [{
            path: '',
            outlet: 'sidebar',
            component: PhoneMyMessagesPage,
        }, {
            path: '',
            component: PhoneMessageInfoPage,
            data: {
                pageName: 'My Messages - View Individual Group Conversation Message'
            }
        }]
    },
    {
        path: 'my-messages/:conversation/info',
        canActivate: [AuthGuard, ReadyGuard],
        component: PlatformFactory(TabletMessageUserDetailPage, {phone: PhoneMessageUserDetailPage}),
        data: {
            pageName: 'My Messages - Direct Message Information'
        }
    },
    {
        path: 'my-messages/:conversation/:id',
        component: PlatformFactory(TabletSplitViewPage, { phone: PhoneMessageInfoPage }),
        canActivate: [AuthGuard, ReadyGuard],
        children: [{
            path: '',
            outlet: 'sidebar',
            component: PhoneMyMessagesPage,
        }, {
            path: '',
            component: PhoneMessageInfoPage,
            data: {
                pageName: 'My Messages - View Individual Direct Message'
            }
        }]
    },
    {
        path: 'messenger-profile',
        component: PlatformFactory(TabletMessengerProfilePage, { phone: PhoneMessengerProfilePage }),
        canActivate: [AuthGuard, ReadyGuard],
    },
    {
        path: 'new-message',
        loadChildren: () => import('./new-message/new-message.routing').then(m => m.NewMessageRoutingModule),
    },
    {
        path: 'new-message/group',
        component: PlatformFactory(TabletSplitViewPage, { phone: PhoneNewGroupMessageParticipantsPage }),
        canActivate: [AuthGuard, ReadyGuard],
        children: [
            {
                path: '',
                outlet: 'sidebar',
                component: PhoneNewGroupMessageParticipantsPage,
            },
            {
                path: '',
                component: PlatformFactory(BlankPage, {phone: PhoneNewGroupMessageParticipantsPage}),
                data: {
                    pageName: 'New Group Conversation - Choose Participants'
                }
            }
        ],
    },
    {
        path: 'new-message/group/create',
        component: PlatformFactory(TabletSplitViewPage, { phone: PhoneNewGroupMessageNamePage }),
        canActivate: [AuthGuard, ReadyGuard],
        children: [
            {
                path: '',
                outlet: 'sidebar',
                component: PhoneNewGroupMessageNamePage,
            },
            {
                path: '',
                component: PlatformFactory(BlankPage, {phone: PhoneNewGroupMessageNamePage}),
                data: {
                    pageName: 'New Group Conversation - Conversation Details'
                }
            }
        ],
    },
    // {
    //     path: 'message-group',
    //     component: PlatformFactory(TabletMessageGroupPage, { phone: PhoneMessageGroupPage }),
    //     canActivate: [AuthGuard, ReadyGuard],
    // },
    // {
    //     path: 'message-group-detail',
    //     component: PlatformFactory(TabletMessageGroupDetailPage, { phone: PhoneMessageGroupDetailPage }),
    //     canActivate: [AuthGuard, ReadyGuard],
    // },
    // {
    //     path: 'message-group-shared-images',
    //     component: PlatformFactory(TabletMessageGroupSharedImagesPage, { phone: PhoneMessageGroupSharedImagesPage }),
    //     canActivate: [AuthGuard, ReadyGuard],
    // },
    {
        path: 'my-profile',
        component: MyProfilePage,
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Profile'
        }
    },
    {
        path: 'my-profile/change-password',
        component: PlatformFactory(TabletChangePasswordPage, { phone: PhoneChangePasswordPage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Profile - Change Password'
        }
    },
    {
        path: 'my-profile/password-changed',
        component: PlatformFactory(TabletChangedPasswordPage, { phone: PhoneChangedPasswordPage }),
        canActivate: [AuthGuard, ReadyGuard],
        data: {
            pageName: 'My Profile - Changed Password'
        }
    },
    // {
    //     path: 'message-group-participant',
    //     component: PlatformFactory(TabletMessageGroupParticipantPage, { phone: PhoneMessageGroupParticipantPage }),
    //     canActivate: [AuthGuard, ReadyGuard],
    // },
    {
        path: 'direct-messaging',
        loadChildren: () => import('./direct-messages/routing.module').then(m => m.DirectMessagingRoutingModule),
    }, {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, GuestGuard, FirstLoadGuard, ReadyGuard]
})
export class PageRoutingModule {
}
