import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { User } from '../../../services/models/user';
import { CaseMessageEvent } from '../../../services/models/case-conversation-events/case-message-event';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { UserService } from '../../../services/user.service';
import { AttachmentService } from '../../../services/attachment.service';
import { CaseConversation } from '../../../services/models/case-conversation';
import { Branch } from '../../../services/models/branch';
import { CaseMessagingService } from '../../../services/case-messaging-service';
import { Subject, Subscription, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
    selector: 'app-case-message',
    templateUrl: 'case-message.html',
    styleUrls: ['../message.scss', 'case-message.scss'],
})
export class AppCaseMessageComponent implements OnChanges, OnDestroy, OnInit {

    @Input() public conversation: CaseConversation = null;
    @Input() public event: CaseMessageEvent = null;

    private isDestroyed: Subject<void> = new Subject<void>();
    public copyMessageTimer: Subscription;

    public attachment: string = null;
    public offlineAttachment: string = null;
    public currentUser: User = null;
    public senderUser: User = null;

    public get isSentByMe(): boolean {
        return this.event?.sentBy?.id === this.currentUser?.id;
    }

    public get isSentByMyOrganisation(): boolean {
        return this.senderUser?.organisation?.id === this.currentUser?.organisation?.id;
    }

    public get isSentByRecipient(): boolean {
        return !this.isSentByMe && !this.isSentByMyOrganisation;
    }

    public get sender(): User | Branch {
        const currentUserId = this.currentUser?.organisation?.id;
        const recipientBranchId = this.conversation?.recipientBranch?.organisation?.id;
        const branch = currentUserId === recipientBranchId ? this.conversation.senderBranch : this.conversation.recipientBranch;
        return this.isSentByMyOrganisation ? this.senderUser || null : branch || null;
    }

    constructor(
        private caseMessaging: CaseMessagingService,
        private clipboard: Clipboard,
        private attachments: AttachmentService,
        private platform: Platform,
        private router: Router,
        private toastController: ToastController,
        private userService: UserService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('event' in changes) {
            const event = changes.event.currentValue;
            if (event.attachments && event.attachments.length) {
                if (event.isOffline) {
                    this.offlineAttachment = event.attachments[0];
                } else {
                    this.attachment = event.attachments[0];
                }
            }
            this.userService.fetchUser(event.sentBy?.id);
        }
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.userService.getUser(this.event?.sentBy?.id).pipe(takeUntil(this.isDestroyed)).subscribe(user => this.senderUser = user);
        this.userService.getMe().pipe(takeUntil(this.isDestroyed)).subscribe(me => this.currentUser = me);
    }

    public goToMessageInfo() {
        const event = this.event as CaseMessageEvent;

        if (!event || !event.id) {
            return;
        }

        if (this.isSentByMyOrganisation) {
            this.router.navigate(['cases', event.conversation, event.id]);
        }
    }

    public getMessageClasses() {
        const classes: string[] = [];
        const message = this.event;
        const sentByMe = message.sentBy?.id === this.currentUser?.id;

        if (message.isOffline) {
            return classes;
        }

        switch (message.status) {
            case 'sent':
            case 'delivered':
                classes.push(sentByMe ? 'sent-message' : 'none');
                break;
            case 'read':
                classes.push(sentByMe ? 'seen-message' : 'none');
                break;
            default:
                classes.push('opened-message');
                break;
        }

        return classes;
    }

    public clearCopyMessageTimer() {
        if (this.copyMessageTimer) {
            this.copyMessageTimer.unsubscribe();
            this.copyMessageTimer = null;
        }
    }

    public pressCopyMessageStart() {
        this.clearCopyMessageTimer();

        this.copyMessageTimer = timer(2000).pipe(
            first(),
        ).subscribe(async () => {
            if (this.event?.messageBody) {
                if (this.platform.is('cordova')) {
                    this.clipboard.copy(this.event.messageBody);
                } else {
                    document.addEventListener('copy', (e: ClipboardEvent) => {
                        e.clipboardData.setData('text/plain', (this.event.messageBody));
                        e.preventDefault();
                        document.removeEventListener('copy', null);
                    });
                    document.execCommand('copy');
                }

                const toast = await this.toastController.create({
                    message: 'Message copied!',
                    duration: 2000,
                    color: 'tertiary',
                });
                toast.present();
            }
        });
    }

    public pressCopyMessageEnd() {
        this.clearCopyMessageTimer();
    }

}
