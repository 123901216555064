import { NgModule } from '@angular/core';
import { OrderByPipe } from './order-by.pipe';
import { DateFnsPipe } from './date-fns.pipe';
import { DistanceToNowPipe } from './distance-to-now.pipe';
import { ReversePipe } from './reverse.pipe';
import { PatientIdPipe } from './patient-id.pipe';
import { AttachmentPipe } from './attachment.pipe';
import { BackgroundImagePipe } from './background-image.pipe';
import { DistanceBetweenPipe } from './distance-between.pipe';
import { TruncatePipe } from './truncate.pipe';
import { OrganisationNamePipe } from './organisation-name.pipe';
import { OfflineAttachmentPipe } from './offline-attachment.pipe';

const PIPES = [
    AttachmentPipe,
    BackgroundImagePipe,
    DateFnsPipe,
    DistanceToNowPipe,
    DistanceBetweenPipe,
    OfflineAttachmentPipe,
    OrderByPipe,
    OrganisationNamePipe,
    PatientIdPipe,
    ReversePipe,
    TruncatePipe,
];

@NgModule({
    exports: [
        ...PIPES,
    ],
    declarations: [
        ...PIPES,
    ]
})
export class PipesModule {
}
