import { Component, ViewChild, ElementRef } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { DatabaseService } from '../../services/database.service';
import { Settings } from '../../services/models/settings';
import { SettingsService } from '../../services/settings.service';

@Component({
    selector: 'app-on-boarding-page',
    template: '',
    styleUrls: ['on-boarding.scss'],
})

export abstract class OnBoarding {

    @ViewChild('onBoardingSlider', { static: true }) slider: IonSlides;

    public sliderEnd = false;
    public currentSlide;

    public slideOpts = {
        noSwiping: true,
        initialSlide: 0,
        speed: 400
    };

    constructor(
        private navCtrl: NavController,
        private settings: SettingsService,
    ) {}

    async nextSlide() {
        this.slider.slideNext();
        this.sliderEnd = await this.slider.isEnd();
    }

    public slideChanged() {
        this.slider.getActiveIndex().then(index => {
            this.currentSlide = index;
            if(this.currentSlide == 2) {
                this.sliderEnd = true;
            }else {
                this.sliderEnd = false;
            }
        });
    }

    public async skip() {
        this.firstLoad().then(() => {
            this.navCtrl.navigateForward('/login');
        });
    }

    public goToLogin() {
        this.firstLoad().then(() => {
            this.navCtrl.navigateForward('/login');
        });
    }

    private async firstLoad() {
        return await this.settings.set('canActivate:firstLoad', 'true');
    }

}
