import { Component } from '@angular/core';
import { Message } from './message';

@Component({
    selector: 'app-phone-message-page',
    templateUrl: 'phone-message.html',
    styleUrls: ['message.scss', 'phone-message.scss'],
})
export class PhoneMessagePage extends Message {



}
