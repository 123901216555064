import { Component } from '@angular/core';
import { CaseSentPage } from './sent';

@Component({
    selector: 'app-large-case-sent-page',
    templateUrl: 'sent.html',
    styleUrls: ['sent.scss', 'large-sent.scss'],
})
export class LargeCaseSentPage extends CaseSentPage {

}
