import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { OrganisationStore } from '../stores/organisation.store';
import { UserStore } from '../stores/user.store';

@Injectable()
export abstract class UserService {

    public abstract users: UserStore;

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public storage: PrivateSettingsService,
    ) {
    }

    public abstract getCurrentUserId(): string;

    public abstract setCurrentUserId(userId: string);

    public abstract getMe(): Observable<User>;

    public abstract getMyBranches(): Observable<string[]>;

    public abstract getUser(id: string): Observable<User>;

    public abstract getUsers(): Observable<User[]>;

    public abstract fetchMe(): Promise<User>;

    public abstract updateProfile(icon: File): Promise<User>;

    protected abstract fetchMyBranches(id: string): Promise<string[]>;

    public abstract fetchUser(id: string): Promise<User>;

    public abstract fetchUsers(): Promise<User[]>;

    public abstract onCurrentUserIdChange(): Observable<string>;

    public abstract clear(): Promise<void>;

    public abstract loadFromMemory(): Promise<void>;

    public abstract ready(): Promise<boolean>;
}
