import { Component } from '@angular/core';
import { ForgotPasswordChanged } from './forgot-password-changed';

@Component({
    selector: 'app-tablet-forgot-password-changed-page',
    templateUrl: 'tablet-forgot-password-changed.html',
    styleUrls: ['forgot-password-changed.scss', 'tablet-forgot-password-changed.scss'],
})
export class TabletForgotPasswordChangedPage extends ForgotPasswordChanged {

}
