import { Injectable } from '@angular/core';
import { BrowserOrganisationService } from './browser.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { PrivateSettingsService } from '../private-settings.service';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Branch } from '../models/branch';
import { Organisation } from '../models/organisation';

@Injectable()
export class MobileOrganisationService extends BrowserOrganisationService {

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public storage: PrivateSettingsService,
    ) {
        super(api, database, storage);
    }

    public loadFromMemory(): Promise<void> {
        return new Promise(async (resolve) => {
            const organisationsRaw = await this.storage.get('organisations');
            if (organisationsRaw) {
                const organisations = JSON.parse(organisationsRaw);
                for (const organisationData of organisations) {
                    const organisation = this.organisations.findById(organisationData.id) || Organisation.createOne(organisationData);
                    this.organisations.store(organisation);
                }
            }

            const branchesRaw = await this.storage.get('branches');
            if (branchesRaw) {
                const branches = JSON.parse(branchesRaw);
                for (const branchData of branches) {
                    const branch = this.branches.findById(branchData.id) || Branch.createOne(branchData);
                    branch.organisation = this.organisations.findById(branchData.organisation);
                    this.branches.store(branch);
                }
            }

            this.setupSubscriptions();
            resolve(undefined);
            this.isReady = true;
            this.readySubject.next(true);
        });
    }

    private persistBranchesMemory() {
        const branches = this.branches.all();
        const time = Date.now();
        this.storage.set('branches', JSON.stringify(branches))
            .then(() => console.log('[BRANCHES STORED]', branches.length, Date.now() - time));
    }

    private persistOrganisationsMemory() {
        const organisations = this.organisations.all();
        const time = Date.now();
        this.storage.set('organisations', JSON.stringify(organisations))
            .then(() => console.log('[ORGANISATIONS STORED]', organisations.length, Date.now() - time));
    }

    private setupSubscriptions(): void {
        merge(
            this.organisations.onInsert(),
            this.organisations.onUpdate(),
            this.organisations.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistOrganisationsMemory();
        });

        merge(
            this.branches.onInsert(),
            this.branches.onUpdate(),
            this.branches.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistBranchesMemory();
        });
    }

}
