import { EphemeralStore } from './ephemeral.store';
import { CaseEvent } from '../models/case-conversation-events/case-event';

export class CaseEventStore extends EphemeralStore<CaseEvent> {

    public findById(id: string): CaseEvent {
        return this.find(event => event.id === id);
    }

    public filterByCaseId(id: string): CaseEvent[] {
        return this.all()
            .filter(event => !!event.id)
            .filter(event => {
            if (event.conversation !== undefined) {
                return event.conversation === id;
            }
            return false;
        });
    }

    public offlineOnly(): CaseEvent[] {
        return this.items.filter(item => item.isOffline);
    }

    public offlineConversation(caseId: string): CaseEvent[] {
        return this.all()
            .filter(item => item.isOffline)
            .filter(item => item.conversation === caseId);
    }

}
