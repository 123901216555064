import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CaseMessagingService } from '../../../services/case-messaging-service';
import { CaseConversation } from '../../../services/models/case-conversation';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-case-history-page',
    templateUrl: 'history.html',
    styleUrls: ['history.scss'],
})
export class CaseHistoryPage implements OnDestroy, OnInit {

    private patientIdChanged: Subject<string> = new Subject<string>();
    private isDestroyed: Subject<void> = new Subject<void>();

    public cases: CaseConversation[] = [];
    public patientId: string = null;

    constructor(
        private caseMessaging: CaseMessagingService,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.patientIdChanged.pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(patientId => {
            if (!patientId) {
                return;
            }

            this.patientId = patientId;
            this.caseMessaging.getMyCases().pipe(
                takeUntil(this.patientIdChanged),
                takeUntil(this.isDestroyed),
            ).subscribe(cases => {
                this.cases = cases.filter(conversation => conversation.updatedAt && conversation.patientId === patientId);
            });
        });

        this.route.params.pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(({ patientId }) => {
            this.patientIdChanged.next(patientId);
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public selectCase(conversation: CaseConversation) {
        this.router.navigate(['cases', conversation.id]);
    }

}
