import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DrawerState } from 'ion-bottom-drawer';
import { CaseConversation } from '../../services/models/case-conversation';
import { IonBottomDrawerComponent } from 'ion-bottom-drawer/app/modules/ion-bottom-drawer/ion-bottom-drawer';

@Component({
    selector: 'app-drawer',
    templateUrl: 'drawer.html',
    styleUrls: ['drawer.scss'],
})

export class DrawerComponent implements OnChanges, AfterViewInit {

    @ViewChild('drawer') public drawer: IonBottomDrawerComponent;
    @ViewChild('scrollable') public scrollableRef: ElementRef<HTMLDivElement>;

    @Output('selectCase') public selectCaseEvent: EventEmitter<CaseConversation> = new EventEmitter<CaseConversation>();

    @Input() public cases: CaseConversation[] = [];
    @Input() public distanceTop = 9999;
    @Input() public dockedHeight = 0;
    @Input() public minimumHeight = 0;

    private scrollableNative: HTMLDivElement = null;

    public drawerState = DrawerState.Bottom;
    public shouldBounce = true;
    public isOpen = false;
    public isReady = false;

    public closeDrawer() {
        this.drawerState = DrawerState.Docked;
        this.isOpen = false;
    }

    public closeDrawerIfAtTop() {
        if (!this.scrollableNative) { return; }
        if (this.scrollableNative.scrollTop > 0) { return; }
        this.closeDrawer();
    }

    public changedState(event) {
        this.isOpen = this.drawerState === DrawerState.Top;
    }

    public ngAfterViewInit() {
        if (this.scrollableRef) {
            this.scrollableNative = this.scrollableRef.nativeElement;
        }
        this.drawerState = DrawerState.Docked;
        this.isReady = true;
        this.triggerDrawerUpdate();
    }

    public triggerDrawerUpdate() {
        if (!this.isReady) { return; }
        this.drawer.ngOnChanges({
            state: {
                previousValue: this.drawerState,
                currentValue: this.drawerState,
                firstChange: false,
                isFirstChange: () => false,
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ('dockedHeight' in changes) {
            const dockedHeight = changes.dockedHeight;
            if (dockedHeight.previousValue !== dockedHeight.currentValue) { this.triggerDrawerUpdate(); }
        }
    }

    public selectCase(conversation: CaseConversation) {
        this.selectCaseEvent.emit(conversation);
    }

}
