import { Component } from '@angular/core';
import { NewMessage } from './new-message';

@Component({
    selector: 'app-tablet-new-message-page',
    templateUrl: 'tablet-new-message.html',
    styleUrls: ['new-message.scss', 'tablet-new-message.scss'],
})
export class TabletNewMessagePage extends NewMessage {

}
