import { Component } from '@angular/core';
import { MessageGroup } from './message-group';

@Component({
    selector: 'app-tablet-message-group-page',
    templateUrl: 'tablet-message-group.html',
    styleUrls: ['message-group.scss', 'tablet-message-group.scss'],
})
export class TabletMessageGroupPage extends MessageGroup {

}
