import { Component } from '@angular/core';
import { MessageGroupRename } from './message-group-rename';

@Component({
    selector: 'app-tablet-message-group-rename-page',
    templateUrl: 'tablet-message-group-rename.html',
    styleUrls: ['message-group-rename.scss', 'tablet-message-group-rename.scss'],
})
export class TabletMessageGroupRenamePage extends MessageGroupRename{

}
