import { Component } from '@angular/core';
import { MessageGroupSharedImages } from './message-group-shared-images';

@Component({
    selector: 'app-phone-message-group-shared-images-page',
    templateUrl: 'phone-message-group-shared-images.html',
    styleUrls: ['message-group-shared-images.scss', 'phone-message-group-shared-images.scss'],
})
export class PhoneMessageGroupSharedImagesPage extends MessageGroupSharedImages {



}
