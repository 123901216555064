/* tslint:disable:unified-signatures ban-types */
import { Injectable } from '@angular/core';
import {
    Connection,
    createConnection,
    getMetadataArgsStorage,
    InsertEvent,
    LoadEvent,
    RemoveEvent,
    Repository,
    UpdateEvent
} from 'typeorm';
import { DirectMessageConversation } from './models/direct-message-conversation';
import { Platform } from '@ionic/angular';
import { User } from './models/user';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DirectMessageEvent } from './models/direct-message-event';
import { EntitySchema } from 'typeorm/entity-schema/EntitySchema';
import { Settings } from './models/settings';
import { SqljsConnectionOptions } from 'typeorm/driver/sqljs/SqljsConnectionOptions';
import { CordovaConnectionOptions } from 'typeorm/driver/cordova/CordovaConnectionOptions';
import { PendingDirectMessage } from './models/pending-direct-message';
import { PendingAttachment } from './models/pending-attachment';
import { CaseConversation } from './models/case-conversation';
import { CaseEvent } from './models/case-conversation-events/case-event';
import { Branch } from './models/branch';
import { Organisation } from './models/organisation';
import { PendingCaseMessage } from './models/pending-case-message';
import { PrivateSettings } from './models/settings.private';

const SHARED_ENTITIES = [
    Settings,
];

const PRIVATE_ENTITIES = [
    PrivateSettings,
    Branch,
    CaseConversation,
    CaseEvent,
    DirectMessageConversation,
    DirectMessageEvent,
    Organisation,
    PendingAttachment,
    PendingCaseMessage,
    PendingDirectMessage,
    User,
];

@Injectable()
export class DatabaseService {

    private afterLoadSubject: Subject<LoadEvent<any>> = new Subject<LoadEvent<any>>();
    private afterInsertSubject: Subject<InsertEvent<any>> = new Subject<InsertEvent<any>>();
    private afterRemoveSubject: Subject<RemoveEvent<any>> = new Subject<RemoveEvent<any>>();
    private afterUpdateSubject: Subject<UpdateEvent<any>> = new Subject<UpdateEvent<any>>();
    private onPrivateReady: Subject<void> = new Subject<void>();
    private onSharedReady: Subject<void> = new Subject<void>();

    private sharedConnection: Connection = null;
    private privateConnection: Connection = null;

    constructor(
        private platform: Platform,
    ) {

    }

    public afterLoad(): Observable<LoadEvent<any>>;
    public afterLoad<T>(entity: new() => T): Observable<LoadEvent<T>>;
    public afterLoad<T>(entities: (new() => T)[]): Observable<LoadEvent<T>>;
    public afterLoad<T>(entities?: (new() => T) | (new() => T)[]): Observable<LoadEvent<T>> {
        if (!entities) {
            return this.afterLoadSubject.asObservable();
        }
        if (!Array.isArray(entities)) {
            return this.afterLoad([entities]);
        }
        return this.afterLoadSubject.asObservable().pipe(
            filter(event => {
                for (const entity of entities) {
                    if (event.entity instanceof entity) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    public afterInsert(): Observable<InsertEvent<any>>;
    public afterInsert<T>(entity: new() => T): Observable<InsertEvent<T>>;
    public afterInsert<T>(entities: (new() => T)[]): Observable<InsertEvent<T>>;
    public afterInsert<T>(entities?: (new() => T) | (new() => T)[]): Observable<InsertEvent<T>> {
        if (!entities) {
            return this.afterInsertSubject.asObservable();
        }
        return !Array.isArray(entities) ? this.afterInsert([entities]) : this.afterInsertSubject.asObservable().pipe(
            filter(event => {
                for (const entity of entities) {
                    if (event.entity instanceof entity) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    public afterRemove(): Observable<RemoveEvent<any>>;
    public afterRemove<T>(entity: new() => T): Observable<RemoveEvent<T>>;
    public afterRemove<T>(entities: (new() => T)[]): Observable<RemoveEvent<T>>;
    public afterRemove<T>(entities?: (new() => T) | (new() => T)[]): Observable<RemoveEvent<T>> {
        if (!entities) {
            return this.afterRemoveSubject.asObservable();
        }
        return !Array.isArray(entities) ? this.afterRemove([entities]) : this.afterRemoveSubject.asObservable().pipe(
            filter(event => {
                for (const entity of entities) {
                    if (event.entity instanceof entity) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    public afterUpdate(): Observable<InsertEvent<any>>;
    public afterUpdate<T>(entity: new() => T): Observable<UpdateEvent<T>>;
    public afterUpdate<T>(entities: (new() => T)[]): Observable<UpdateEvent<T>>;
    public afterUpdate<T>(entities?: (new() => T) | (new() => T)[]): Observable<UpdateEvent<T>> {
        if (!entities) {
            return this.afterUpdateSubject.asObservable();
        }
        return !Array.isArray(entities) ? this.afterUpdate([entities]) : this.afterUpdateSubject.asObservable().pipe(
            filter(event => {
                for (const entity of entities) {
                    if (event.entity instanceof entity) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    public watch<T>(entity: new() => T): ({
        insert: Observable<InsertEvent<T>>,
        update: Observable<UpdateEvent<T>>,
        remove: Observable<RemoveEvent<T>>
    }) {
        return {
            insert: this.afterInsert(entity),
            update: this.afterUpdate(entity),
            remove: this.afterRemove(entity)
        };
    }

    public get caseEvents(): Repository<CaseEvent> {
        return this.privateConnection?.getRepository(CaseEvent);
    }

    public get pendingCaseMessages(): Repository<PendingCaseMessage> {
        return this.privateConnection?.getRepository(PendingCaseMessage);
    }

    public get directMessageConversations(): Repository<DirectMessageConversation> {
        return this.privateConnection?.getRepository(DirectMessageConversation);
    }

    public get directMessageEvents(): Repository<DirectMessageEvent> {
        return this.privateConnection?.getRepository(DirectMessageEvent);
    }

    public get pendingDirectMessages(): Repository<PendingDirectMessage> {
        return this.privateConnection?.getRepository(PendingDirectMessage);
    }

    public get users(): Repository<User> {
        return this.privateConnection?.getRepository(User);
    }

    public get settings(): Repository<Settings> {
        return this.sharedConnection?.getRepository(Settings);
    }

    public get privateSettings(): Repository<Settings> {
        return this.privateConnection?.getRepository(PrivateSettings);
    }

    public get cases(): Repository<CaseConversation> {
        return this.privateConnection?.getRepository(CaseConversation);
    }

    public get branches(): Repository<Branch> {
        return this.privateConnection?.getRepository(Branch);
    }

    public get organisations(): Repository<Organisation> {
        return this.privateConnection?.getRepository(Organisation);
    }

    public async initShared() {
        if (this.platform.is('cordova')) {
            this.sharedConnection = await this.createConnection('shared', SHARED_ENTITIES, 'shared');
            for (const entity of SHARED_ENTITIES) {
                entity.useConnection(this.sharedConnection);
            }
        }

        this.onSharedReady.next();
    }

    public async initPrivate(connectionName: string) {
        if (this.privateConnection) {
            const options = this.privateConnection.driver.options;
            if (options.type === 'sqljs' && (options as SqljsConnectionOptions).location === connectionName) {
                return;
            }
            if (options.type === 'cordova' && (options as CordovaConnectionOptions).name === `${connectionName}.db`) {
                return;
            }
            await this.closePrivate();
        }

        console.log('[PRIVATE] Creating connection', connectionName);
        this.privateConnection = await this.createConnection(connectionName, PRIVATE_ENTITIES, 'private');
        for (const entity of PRIVATE_ENTITIES) {
            await entity.useConnection(this.privateConnection);
        }

        this.onPrivateReady.next();
    }

    // tslint:disable-next-line:max-line-length
    public createConnection(name: string, entities: (Function | string | EntitySchema)[], type: string, location: string = 'default'): Promise<Connection> {
        if (this.platform.is('cordova')) {
            return this.createDeviceConnection(name, entities, type, location);
        } else {
            return this.createBrowserConnection(name, entities, type);
        }
    }

    public createBrowserConnection(name: string, entities: (Function | string | EntitySchema)[], type: string) {
        return createConnection({
            type: 'sqljs',
            autoSave: true,
            location: name,
            logging: ['error'],
            // logging: ['error', 'query', 'schema'],
            synchronize: true,
            subscribers: [this.getEntitySubscriber()],
            entities,
            name: type,
        });
    }

    public createDeviceConnection(name: string, entities: (Function | string | EntitySchema)[], type: string, location: string) {
        return createConnection({
            type: 'cordova',
            database: `${name}.db`,
            location,
            logging: ['error'],
            // logging: ['error', 'query', 'schema'],
            synchronize: true,
            subscribers: [this.getEntitySubscriber()],
            entities,
            name: type,
        });
    }

    public getEntitySubscriber(): () => void {
        const database = this;

        function Subscriber() {
        }

        Subscriber.prototype.afterLoad = (entity: any, event?: LoadEvent<any>) => {
            database.afterLoadSubject.next(event);
        };
        Subscriber.prototype.afterInsert = (event: InsertEvent<any>): Promise<any> | void => {
            database.afterInsertSubject.next(event);
        };
        Subscriber.prototype.afterRemove = (event: RemoveEvent<any>): Promise<any> | void => {
            database.afterRemoveSubject.next(event);
        };
        Subscriber.prototype.afterUpdate = (event: UpdateEvent<any>): Promise<any> | void => {
            database.afterUpdateSubject.next(event);
        };

        getMetadataArgsStorage().entitySubscribers.push({
            target: Subscriber
        });

        return Subscriber;
    }

    public ready(): Promise<void> {
        return Promise.all([
            this.sharedReady(),
            this.privateReady(),
        ]).then(null);
    }

    public sharedReady(): Promise<void> {
        if (this.sharedConnection) {
            return Promise.resolve();
        }
        return new Promise<void>(resolve => this.onSharedReady.subscribe(() => resolve()));
    }

    public privateReady(): Promise<void> {
        if (this.privateConnection) {
            return Promise.resolve();
        }
        return new Promise<void>(resolve => this.onPrivateReady.subscribe(() => resolve()));
    }

    public async closePrivate() {
        if (this.privateConnection && this.privateConnection.isConnected) {
            console.log('[PRIVATE] Closing connection');
            await this.privateConnection.close();
            this.privateConnection = null;
        }
    }
}
