import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabsComponent } from '../../../components/tabs/tabs';
import { Subject } from 'rxjs';
import { CaseConversation } from '../../../services/models/case-conversation';
import { NavController, Platform } from '@ionic/angular';
import { CaseMessagingService } from '../../../services/case-messaging-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../cases.service';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';

export class UniqueArray extends Array {

    public push<T>(...items): number {
        const uniqueItems: T[] = [];
        for (const item of items) {
            if (this.find(search => search === item)) {
                continue;
            }
            uniqueItems.push(item);
        }
        return super.push(...uniqueItems);
    }

}

@Component({
    selector: 'app-incoming-cases-page',
    templateUrl: 'incoming.html',
    styleUrls: ['incoming.scss'],
})
export class IncomingCasesPage implements AfterViewInit, OnDestroy {

    @ViewChild('tabs') public tabs: TabsComponent;

    public onDestroy: Subject<void> = new Subject<void>();

    public get searchTerm(): string {
        return this.service.getIncomingSearchTerm();
    }

    public set searchTerm(value: string) {
        this.service.setIncomingSearchTerm(value);
    }

    public get cases(): CaseConversation[] {
        return this.service.getIncomingCases();
    }

    constructor(
        private caseMessaging: CaseMessagingService,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private navCtrl: NavController,
        private service: CasesService,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.searchTerm = '';
        this.onDestroy.next();
    }

    public async ngAfterViewInit() {

        this.service.fetchCases();
        this.service.searchCases();

        this.tabs.tabChanged.pipe(takeUntil(this.onDestroy)).subscribe(tab => this.service.setActiveTab(tab.label));

        this.route.queryParams.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(params => {
            const initialTab: string = params.status || this.service.getActiveTab();
            if (initialTab) {
                const activeTab = this.tabs.tabs.find(tab => tab.label.toLowerCase() === initialTab.toLowerCase());
                if (activeTab) {
                    this.tabs.activateTab(activeTab);
                }
            }
        });
    }

    public ionViewDidEnter() {
        // this.caseMessaging.fetchAllCases().then(cases => {
        //     const individualCases = cases.map(conversation => this.caseMessaging.fetchCase(conversation.id));
        //     Promise.all(individualCases).then(conversations => {
        //         const userIds: string[] = new UniqueArray();
        //         conversations.forEach(conversation => {
        //             if (conversation.assignee) {
        //                 userIds.push(conversation.assignee.id);
        //             }
        //             if (conversation.createdBy) {
        //                 userIds.push(conversation.createdBy.id);
        //             }
        //         });
        //         userIds.forEach(id => this.userService.fetchUser(id));
        //     });
        // });
    }

    public selectCase(conversation: CaseConversation) {
        this.navCtrl.setDirection('forward', this.platform.is('mobile'));
        this.router.navigate(['cases', 'incoming', conversation.id]);
    }

    public get assignedCases(): CaseConversation[] {
        return this.service.assignedCases;
    }

    public get unassignedCases(): CaseConversation[] {
        return this.service.unassignedCases;
    }

}
