import { SettingsService } from './abstract.service';

export class BrowserSettingsService extends SettingsService {

    public get(id: string): Promise<string> {
        return Promise.resolve(JSON.parse(localStorage.getItem(id)));
    }

    public ready(): Promise<void> {
        return Promise.resolve();
    }

    public remove(id: string): Promise<void> {
        return Promise.resolve(localStorage.removeItem(id));
    }

    public set(id: string, value: any): Promise<void> {
        return Promise.resolve(localStorage.setItem(id, JSON.stringify(value)));
    }

    public clear(id: string): Promise<void> {
        localStorage.clear();
        return Promise.resolve(this.set('canActivate:firstLoad', true));
    }

}
