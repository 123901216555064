import { Component } from '@angular/core';
import { NewMessageService } from '../new-message.service';
import { User } from '../../../services/models/user';

@Component({
    selector: 'app-new-internal-message-page',
    templateUrl: 'internal.html',
    styleUrls: ['internal.scss'],
})
export class AppNewInternalMessagePage {

    public get users(): User[] {
        return this.service.getInternalUsers();
    }

    constructor(
        private service: NewMessageService,
    ) {
    }

    public onSelect(user: User) {
        this.service.selectUser(user);
    }

}
