import { Component } from '@angular/core';

@Component({
    selector: 'app-messenger-profile-page',
    template: '',
    styleUrls: ['messenger-profile.scss'],
})

export abstract class MessengerProfile {

}
