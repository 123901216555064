import { Component } from '@angular/core';
import { BuildPage } from '../../pages/build/build';

@Component({
    selector: 'app-sidebar',
    templateUrl: 'sidebar.html',
    styleUrls: ['sidebar.scss'],
})
export class SidebarComponent extends BuildPage  {

    // public ngOnInit() {
    //     console.log(this.searchResults);
    // }

}
