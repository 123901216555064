import { NgModule } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { SettingsServiceFactory } from './factory';
import { PrivateSettingsService } from './abstract.service';

@NgModule({
    providers: [
        DatabaseService,
        {
            provide: PrivateSettingsService,
            useFactory: SettingsServiceFactory,
            deps: [
                DatabaseService,
                Platform,
            ],
        }
    ],
})
export class PrivateSettingsServiceModule {}
