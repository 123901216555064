import { Component } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { ActivatedRoute } from '@angular/router';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';

@Component({
    selector: 'app-message-user-detail-page',
    template: '',
    styleUrls: ['message-user-detail.scss'],
})

export abstract class MessageUserDetail {

    public conversation: DirectMessageConversation = null;
    private conversationId: string = null;

    constructor(
        private settings: SettingsService,
        private directMessaging: DirectMessagingService,
        private route: ActivatedRoute,
    ) {
        route.params.subscribe((params: { conversation: string }) => {
            if (params.conversation !== this.conversationId) {
                this.conversationId = params.conversation;
                this.setConversation(params.conversation);
            }
        });
    }

    public setConversation(conversationId: string) {
        this.directMessaging.getConversation(conversationId).subscribe((conversation) => {
            this.conversation = conversation;
        });
        this.directMessaging.fetchConversation(conversationId).then(() => {
            this.directMessaging.fetchAllEvents(conversationId, {
                before: new Date().toISOString()
            });
        });
    }

}
