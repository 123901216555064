import { AbstractModel } from './abstract-model';
import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { PendingDirectMessage } from './pending-direct-message';
import { WebFile } from '../../helpers/web-file';

@Entity('pending_attachments')
export class PendingAttachment extends AbstractModel {

    @PrimaryGeneratedColumn()
    public id: number;

    @Column({
        name: 'remote_id',
        type: 'uuid',
        nullable: true,
        unique: true,
    })
    public remoteId: string;

    @Column({
        name: 'bits',
        type: 'varchar',
    })
    public bits: string;

    @Column({
        name: 'name',
        type: 'varchar',
    })
    public name: string;

    @Column({
        name: 'last_modified',
        type: 'integer',
    })
    public lastModified: number;

    @Column({
        name: 'type',
        type: 'varchar',
    })
    public type: string;

    @Column({
        name: 'created_at',
        type: 'varchar',
        nullable: true,
    })
    public createdAt: string;

    @Column({
        name: 'sent_at',
        type: 'varchar',
        nullable: true,
        default: null,
    })
    public sentAt: string;

    @ManyToOne(() => PendingDirectMessage, message => message.attachments, {nullable: true})
    @JoinColumn({name: 'message_id'})
    public message: PendingDirectMessage;

    public static async createFromFile(file: WebFile): Promise<PendingAttachment> {
        const readAsDataUrl = (): Promise<string> => new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);

            reader.readAsDataURL(file);
        });

        return PendingAttachment.createOne({
            bits: await readAsDataUrl(),
            name: file.name,
            lastModified: file.lastModified,
            type: file.type,
            sentAt: null,
        });
    }

    public fill(data: Partial<PendingAttachment>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.remoteId !== undefined) {
            this.remoteId = data.remoteId;
        }
        if (data.bits !== undefined) {
            this.bits = data.bits;
        }
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.lastModified !== undefined) {
            this.lastModified = data.lastModified;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
    }

    public toFile(): WebFile {
        const parts = this.bits.split(';base64,');

        const mimeType = parts[0].split(':')[1];
        const data = window.atob(parts[1]);
        const uInt8Array = new Uint8Array(data.length);

        for (let i = 0; i < data.length; ++i) {
            uInt8Array[i] = data.charCodeAt(i);
        }

        return new WebFile([ new Blob([uInt8Array], { type: mimeType })], this.name, {
            lastModified: this.lastModified,
            type: this.type,
        });
    }

}
