import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, formatDistanceStrict, parseISO } from 'date-fns';

@Pipe({
    name: 'distanceBetween'
})
export class DistanceBetweenPipe implements PipeTransform {

    public transform(start: Date | number | string, end: Date | number | string, strict: true, options?: {
        addSuffix?: boolean
        unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
        roundingMethod?: 'floor' | 'ceil' | 'round'
        locale?: Locale
    }): string;
    public transform(start: Date | number | string, end: Date | number | string, strict: false | null, options?: {
        includeSeconds?: boolean
        addSuffix?: boolean
        locale?: Locale
    }): string;
    public transform(start: Date | number | string, end: Date | number | string, strict: boolean, options?): string;
    public transform(start: Date | number | string, end: Date | number | string, strict: boolean, options?): string {
        if (typeof start === 'string') {
            return this.transform(parseISO(start), end, strict, options);
        }
        if (typeof end === 'string') {
            return this.transform(start, parseISO(end), strict, options);
        }
        const formatter = strict ? formatDistanceStrict : formatDistance;
        return formatter(start, end, options);
    }

}
