import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../services/models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';

@Component({
    selector: 'app-user-list-item',
    templateUrl: 'user-list-item.html',
    styleUrls: ['user-list-item.scss'],
})
export class UserListItemComponent {

    @Input() user: User;
    @Input() itemData: User;
    @Output() select: EventEmitter<User> = new EventEmitter<User>();

    constructor(
        public api: ApiClientService,
    ) {
    }

    public onClick() {
        this.select.emit(this.user);
    }

}
