import { Component } from '@angular/core';
import { Login } from './login';

@Component({
    selector: 'app-phone-login-page',
    templateUrl: 'phone-login.html',
    styleUrls: ['login.scss', 'phone-login.scss'],
})
export class PhoneLoginPage extends Login {

}
