import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Router } from '@angular/router';
import { DirectMessageEvent } from '../../services/models/direct-message-event';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Platform, ToastController } from '@ionic/angular';
import { AttachmentService } from '../../services/attachment.service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Subject, Subscription, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ConversationEvent } from '../messenger/messenger';
import { Conversation } from '../../pages/my-messages/my-messages';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';
import { GroupEvent } from '../../services/models/group-messaging/group-event';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';

@Component({
    selector: 'app-message',
    templateUrl: 'message.html',
    styleUrls: ['message.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {

    // tslint:disable-next-line:variable-name
    private _event: ConversationEvent = null;
    private isDestroyed: Subject<void> = new Subject<void>();
    public copyMessageTimer: Subscription;

    public attachment: string = null;
    @Input() public conversation: Conversation;
    public currentUser: User = null;
    public sendingUser: User = null;

    public get event(): ConversationEvent {
        return this._event;
    }

    @Input() public set event(value: ConversationEvent) {
        this._event = value;

        if (value instanceof GroupMessageEvent || value instanceof DirectMessageEvent) {
            if (value.attachments && value.attachments.length) {
                this.attachment = value.attachments[0];
                // const imageId = value.attachments[0];
                // this.attachmentService.getImageUrl(imageId).then((image) => this.attachment = image);
            }
        }

        if (this.event instanceof GroupMessageEvent) {
            this.userService.getUser(this.event?.sentBy?.id).pipe(takeUntil(this.isDestroyed)).subscribe(me => this.sendingUser = me);
        }
        if (this.event instanceof DirectMessageEvent) {
            this.userService.getUser(this.event?.sentBy).pipe(takeUntil(this.isDestroyed)).subscribe(me => this.sendingUser = me);
        }
    }

    public get isSentByMe(): boolean {
        return this.user?.id === this.currentUser?.id;
    }

    public get isSentByRecipient(): boolean {
        return !this.isSentByMe;
    }

    public get user(): User {
        const currentUserId = this.currentUser ? this.currentUser.id : null;
        let sentBy = null;
        if (this.event instanceof DirectMessageEvent) {
            sentBy = this.event ? this.event?.sentBy : null;
        }
        if (this.event instanceof GroupEvent) {
            sentBy = this.event ? this.event?.sentBy?.id : null;
        }
        return sentBy === currentUserId ? (this.currentUser || null) : (this.sendingUser || null);
    }

    constructor(
        public api: ApiClientService,
        private attachmentService: AttachmentService,
        private clipboard: Clipboard,
        private platform: Platform,
        private router: Router,
        private toastController: ToastController,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.isDestroyed)).subscribe(me => this.currentUser = me);
    }

    public goToMessageInfo(event: ConversationEvent) {
        if (!event || !event.id) { return; }
        if (this.conversation instanceof DirectMessageConversation && event instanceof DirectMessageEvent) {
            if (this.conversation.recipient.id !== event.sentBy) {
                this.router.navigate([`/my-messages/${event.conversation}/${event.id}`]);
            }
        }
        if (this.conversation instanceof GroupMessageConversation && event instanceof GroupMessageEvent) {
            if (this.isSentByMe) {
                this.router.navigate([`/my-messages/group/${event.conversation}/${event.id}`]);
            }
        }
    }

    public getMessageClasses() {
        if (!this.event) {
            return ['new-message'];
        }

        if (!this.isSentByMe) {
            return ['none'];
        }

        if (this.event instanceof DirectMessageEvent) {
            switch (this.event.status) {
                case 'sent':
                    return ['sent-message'];
                case 'delivered':
                    return ['sent-message'];
                case 'read':
                    return ['seen-message'];
                default:
                    return ['none'];
            }
        } else {
            return ['none'];
        }
    }

    public isGroupMessageEvent(event: ConversationEvent) {
        return event instanceof GroupEvent && event instanceof GroupMessageEvent;
    }

    public isDirectMessageEvent(event: ConversationEvent) {
        return event instanceof DirectMessageEvent;
    }

    public clearCopyMessageTimer() {
        if (this.copyMessageTimer) {
            this.copyMessageTimer.unsubscribe();
            this.copyMessageTimer = null;
        }
    }

    public pressCopyMessageStart() {
        this.clearCopyMessageTimer();

        this.copyMessageTimer = timer(2000).pipe(
            first(),
        ).subscribe(async () => {
            if (this.event?.messageBody) {
                if (this.platform.is('cordova')) {
                    this.clipboard.copy(this.event.messageBody);
                } else {
                    document.addEventListener('copy', (e: ClipboardEvent) => {
                        e.clipboardData.setData('text/plain', (this.event.messageBody));
                        e.preventDefault();
                        document.removeEventListener('copy', null);
                    });
                    document.execCommand('copy');
                }

                const toast = await this.toastController.create({
                    message: 'Message copied!',
                    duration: 2000,
                    color: 'tertiary',
                });
                toast.present();
            }
        });
    }

    public pressCopyMessageEnd() {
        this.clearCopyMessageTimer();
    }
}
