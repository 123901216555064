import { Component } from '@angular/core';
import { NewGroupMessageParticipants } from './new-group-message-participants';

@Component({
    selector: 'app-phone-new-group-message-participants-page',
    templateUrl: 'phone-new-group-message-participants.html',
    styleUrls: ['new-group-message-participants.scss', 'phone-new-group-message-participants.scss'],
})
export class PhoneNewGroupMessageParticipantsPage extends NewGroupMessageParticipants {



}
