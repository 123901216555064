import { EphemeralStore } from './ephemeral.store';
import { DirectMessageEvent } from '../models/direct-message-event';

export class DirectMessageEventStore extends EphemeralStore<DirectMessageEvent> {

    public findById(id: string): DirectMessageEvent {
        return this.find(event => event.id === id);
    }

    public filterByDirectMessageId(id: string): DirectMessageEvent[] {
        return this.all().filter(event => event.conversation === id);
    }

}
