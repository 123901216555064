import { Component } from '@angular/core';
import { User } from '../../services/models/user';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { CaseConversation } from '../../services/models/case-conversation';
import { Router } from '@angular/router';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { SettingsService } from '../../services/settings.service';
import { DirectMessageEvent } from '../../services/models/direct-message-event';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';
import { RemoteDataService } from '../../services/remote-data.service';

@Component({
    selector: 'app-dashboard-page',
    template: '',
    styleUrls: ['dashboard.scss'],
})

export abstract class DashboardPage {

    public cases: CaseConversation[] = [];
    public onViewWillLeave: Subject<void> = new Subject<void>();
    public user: User = null;
    private userReady: Subject<boolean> = new Subject<boolean>();
    public newDirectMessages: DirectMessageEvent[] = [];
    public newGroupMessages: GroupMessageEvent[] = [];

    constructor(
        private caseMessaging: CaseMessagingService,
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private remoteData: RemoteDataService,
        private router: Router,
        private settings: SettingsService,
        private userService: UserService,
    ) {
    }

    public get newMessages(): (DirectMessageEvent|GroupMessageEvent)[] {
        return [].concat(this.newDirectMessages, this.newGroupMessages);
    }

    public ionViewWillEnter() {
        this.userReady.asObservable().pipe(
            first(),
        ).subscribe( async () => {
            this.remoteData.fetchMessages();
            this.remoteData.fetchCases();
        });

        this.userService.getMe().pipe(
            takeUntil(this.onViewWillLeave),
        ).subscribe(user => {
            this.user = user;
            if (user) { this.userReady.next(true); }
        });

        this.caseMessaging.getMyCases().pipe(
            takeUntil(this.onViewWillLeave),
        ).subscribe(cases => {
            this.cases = cases.filter(item => item.updatedAt);
        });

        this.directMessaging.getAllMessages().pipe(
            map(events => events.filter(event => {
                if (event.readAt) { return; }
                if (!this.user) { return; }
                if (event.sentBy === this.user.id) { return; }
                return event;
            })),
            takeUntil(this.onViewWillLeave),
        ).subscribe((events) => {
            this.newDirectMessages = events;
        });

        this.groupMessaging.getAllMessages().pipe(
            takeUntil(this.onViewWillLeave),
        ).subscribe((events) => {
            this.newGroupMessages = (events as GroupMessageEvent[]).filter(event => {
                if (event.type !== 'message') {
                    return;
                }
                if (!this.user) {
                    return;
                }
                if (event.sentBy === undefined) {
                    return;
                }
                if (event.readBy(this.user.id)) {
                    return;
                }
                if (event.sentBy.id === this.user?.id) {
                    return;
                }
                return event;
            });
        });
    }

    public ionViewWillLeave() {
        this.onViewWillLeave.next();
    }

    public selectCase(conversation: CaseConversation) {
        this.router.navigate(['cases', conversation.id]);
    }

    public showMyCases() {
        this.router.navigate(['cases'], {
            queryParams: {
                status: 'open',
            }
        });
    }

}
