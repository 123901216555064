import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectMessageEvent } from '../../services/models/direct-message-event';
import { filter, takeUntil } from 'rxjs/operators';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { Subject } from 'rxjs';
import { ConversationEvent } from '../../components/messenger/messenger';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';

@Component({
    selector: 'app-message-info-page',
    template: '',
    styleUrls: ['message-info.scss'],
})

export abstract class MessageInfo {

    public event: DirectMessageEvent | GroupMessageEvent = null;
    public onRouteChange: Subject<void> = new Subject<void>();

    constructor(
        public directMessaging: DirectMessagingService,
        public groupMessaging: GroupMessagingService,
        public route: ActivatedRoute,
        private router: Router,
    ) {
        route.params.subscribe((params: { conversation: string, id: string }) => {
            const isGroup = this.router.url.includes('my-messages/group');
            if (!isGroup) {
                this.directMessaging.getEvent(params.conversation, params.id).pipe(
                    takeUntil(this.onRouteChange)
                ).subscribe(event => this.setEvent(event));
                this.directMessaging.fetchEvent(params.conversation, params.id);
            } else {
                this.groupMessaging.getEvent(params.conversation, params.id).pipe(
                    takeUntil(this.onRouteChange),
                    filter(event => event instanceof GroupMessageEvent)
                ).subscribe(event => this.setEvent(event as GroupMessageEvent));
                this.groupMessaging.fetchEvent(params.conversation, params.id);
            }
        });
    }

    public ionViewWillLeave() {
        this.onRouteChange.next();
    }

    public setEvent(event: DirectMessageEvent | GroupMessageEvent) {
        this.event = event;
    }

    public isGroupMessage(event: DirectMessageEvent | GroupMessageEvent) {
        return event instanceof GroupMessageEvent;
    }

    public isDirectMessage(event: DirectMessageEvent | GroupMessageEvent) {
        return event instanceof DirectMessageEvent;
    }
}
