import { EphemeralStore } from './ephemeral.store';
import { User } from '../models/user';

export class UserStore extends EphemeralStore<User> {

    constructor(items?: User[]) {
        super(items);
    }

    public findById(id: string): User {
        return this.find(user => user.id === id);
    }

}
