import { Component } from '@angular/core';
import { NewGroupMessageName} from './new-group-message-name';

@Component({
    selector: 'app-phone-new-group-message-name-page',
    templateUrl: 'phone-new-group-message-name.html',
    styleUrls: ['new-group-message-name.scss', 'phone-new-group-message-name.scss'],
})
export class PhoneNewGroupMessageNamePage extends NewGroupMessageName{



}
