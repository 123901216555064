import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import { NavController, Platform } from '@ionic/angular';
import { MessageService } from './message.service';
import { Conversation } from '../my-messages/my-messages';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { NetworkService } from '../../services/network.service';

@Component({
    selector: 'app-message-page',
    template: '',
    styleUrls: ['message.scss'],
})

export abstract class Message implements OnInit, OnDestroy {

    public conversation: Conversation = null;
    public isDestroyed: Subject<void> = new Subject<void>();
    public allowSending: boolean = null;

    constructor(
        private settings: SettingsService,
        private directMessaging: DirectMessagingService,
        protected navCtrl: NavController,
        private network: NetworkService,
        private route: ActivatedRoute,
        private router: Router,
        protected platform: Platform,
        private messageService: MessageService,
    ) {
        messageService.onConversationChanged().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(conversation => {
            this.conversation = conversation;
        });

        route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((params: { id: string }) => {
            const isGroup = this.router.url.includes('my-messages/group');
            this.messageService.setConversationId(params.id, isGroup);
        });
    }

    public ngOnInit(): void {
        this.platform.ready().then(() => {
            this.allowSending = this.network.isOnline;
            this.network.onConnect().pipe(takeUntil(this.isDestroyed)).subscribe(() => this.allowSending = true);
            this.network.onDisconnect().pipe(takeUntil(this.isDestroyed)).subscribe(() => this.allowSending = false);
        });
    }

    public ngOnDestroy(): void {
        this.messageService.clearConversation();
        this.isDestroyed.next();
    }

    public goToConversationInfo() {
        if (this.conversation instanceof DirectMessageConversation) {
            this.router.navigate(['/my-messages/' + this.conversation.id + '/info']);
        }
        if (this.conversation instanceof GroupMessageConversation) {
            this.router.navigate(['/my-messages/group/' + this.conversation.id + '/info']);
        }
    }

    public goToNewMessage() {
        this.router.navigate(['/new-message']);
    }

    public navigateBack() {
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        this.router.navigateByUrl('/my-messages');
    }

    public get isMobile(): boolean {
        return this.platform.is('mobile');
    }

}
