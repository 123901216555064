import { NgModule } from '@angular/core';
import { PhoneOnlyDirective } from './phone-only.directive';

@NgModule({
    exports: [
        PhoneOnlyDirective
    ],
    declarations: [
        PhoneOnlyDirective,
    ]
})
export class DirectivesModule {
}
