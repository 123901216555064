import { BeforeInsert, BeforeUpdate, Column, Entity, JoinColumn, ManyToOne } from 'typeorm';
import { UserRole, UserState } from '@scaffold/mediccoms-api-client/types';
import { User as ApiUser } from '@scaffold/mediccoms-api-client/models';
import { AbstractSyncedModel } from './abstract-synced-model';
import { Organisation } from './organisation';
import { ConversationParticipant } from './rtm-events.model';

@Entity('users')
export class User extends AbstractSyncedModel {

    @Column({
        name: 'can_assign_cases',
        type: 'boolean',
        nullable: true
    })
    public canAssignCases: boolean = null;

    @Column({
        name: 'email',
        type: 'varchar',
        nullable: true
    })
    public email: string = null;

    @Column({
        name: 'first_name',
        type: 'varchar',
        nullable: true
    })
    public firstName: string = null;

    @Column({
        name: 'has_full_case_access',
        type: 'boolean',
        nullable: true
    })
    public hasFullCaseAccess: boolean = null;

    @Column({
        name: 'image',
        type: 'varchar',
        nullable: true
    })
    public image: string = null;

    @Column({
        name: 'job_title',
        type: 'varchar',
        nullable: true
    })
    public jobTitle: string = null;

    @Column({
        name: 'last_name',
        type: 'varchar',
        nullable: true
    })
    public lastName: string = null;

    @ManyToOne(() => Organisation, null, {nullable: true, eager: false})
    @JoinColumn({name: 'organisation_id'})
    public organisation: Organisation;

    @Column({
        name: 'phone_number',
        type: 'varchar',
        nullable: true
    })
    public phoneNumber: string = null;

    @Column({
        name: 'role',
        type: 'varchar',
        nullable: true
    })
    public role: UserRole = null;

    @Column({
        name: 'state',
        type: 'varchar',
        nullable: true
    })
    public state: UserState = null;

    @Column({
        name: 'title',
        type: 'varchar',
        nullable: true
    })
    public title: string = null;
    public messaging: boolean = null;
    public cases: boolean = null;

    public static mapFromApi(data: Partial<ApiUser>): Partial<User> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            canAssignCases: data.can_assign_cases,
            email: data.email,
            firstName: data.first_name,
            hasFullCaseAccess: data.has_full_case_access,
            image: data.image,
            jobTitle: data.job_title,
            lastName: data.last_name,
            organisation: data.organisation_id ? {
                id: data.organisation_id
            } as Required<Organisation> : undefined,
            phoneNumber: data.phone_number,
            role: data.role,
            state: data.state,
            title: data.title,
            messaging: data.messaging,
            cases: data.cases,
        };
    }

    public static mapFromConversationParticipant(data: Partial<ConversationParticipant>): Partial<User> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            title: data.title,
            firstName: data.first_name,
            lastName: data.last_name,
            image: data.image,
            organisation: data.organisation_id ? {
                id: data.organisation_id
            } as Required<Organisation> : undefined,
            messaging: data.messaging,
            cases: data.cases,
        };
    }

    public static createFromConversationParticipant(data: ConversationParticipant) {
        const user = User.mapFromConversationParticipant(data);
        return User.createOne(user);
    }

    @BeforeInsert()
    @BeforeUpdate()
    public async beforeSave() {
        if (this.organisation) { await this.organisation.save(); }
    }

    public is(otherModel: Partial<this>): boolean {
        return this.id === otherModel.id;
    }

    public fill(data: Partial<User>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.canAssignCases !== undefined) {
            this.canAssignCases = data.canAssignCases;
        }
        if (data.email !== undefined) {
            this.email = data.email;
        }
        if (data.firstName !== undefined) {
            this.firstName = data.firstName;
        }
        if (data.hasFullCaseAccess !== undefined) {
            this.hasFullCaseAccess = data.hasFullCaseAccess;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.jobTitle !== undefined) {
            this.jobTitle = data.jobTitle;
        }
        if (data.lastName !== undefined) {
            this.lastName = data.lastName;
        }
        if (data.organisation !== undefined) {
            this.organisation = Organisation.createOne(data.organisation);
        }
        if (data.phoneNumber !== undefined) {
            this.phoneNumber = data.phoneNumber;
        }
        if (data.role !== undefined) {
            this.role = data.role;
        }
        if (data.state !== undefined) {
            this.state = data.state;
        }
        if (data.title !== undefined) {
            this.title = data.title;
        }
        if (data.messaging !== undefined) {
            this.messaging = data.messaging;
        }
        if (data.cases !== undefined) {
            this.cases = data.cases;
        }
    }

    public get isDoctor(): boolean {
        return this.title && this.title.toLowerCase() === 'dr';
    }

    public get friendlyName(): string {
        const parts = [];
        if (this.isDoctor) {
            parts.push(this.title, this.lastName);
        } else {
            parts.push(this.firstName, this.lastName);
        }
        return parts.length ? parts.filter(part => part).join(' ') : null;
    }

    public get fullName(): string | null {
        const parts = [
            this.firstName,
            this.lastName,
        ].filter(part => part);
        return parts.length ? parts.join(' ') : null;
    }

    public get fullNameWithTitle(): string | null {
        const parts = [
            this.title,
            this.firstName,
            this.lastName,
        ].filter(part => part);
        return parts.length ? parts.join(' ') : null;
    }

    public get initials(): string | null {
        if (this.firstName && this.lastName) {
            return this.firstName.substr(0, 1) + this.lastName.substr(0, 1);
        }
        return null;
    }

    public toJSON() {

        // console.log('[CONVERT USER TO JSON]', this);

        return {
            id: this.id,
            canAssignCases: this.canAssignCases,
            email: this.email,
            firstName: this.firstName,
            hasFullCaseAccess: this.hasFullCaseAccess,
            image: this.image,
            jobTitle: this.jobTitle,
            lastName: this.lastName,
            organisation: this.organisation ? this.organisation.id : null,
            phoneNumber: this.phoneNumber,
            role: this.role,
            state: this.state,
            title: this.title,
            messaging: this.messaging,
            cases: this.cases,
        };
    }
}
