import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    public transform<T>(collection: T[], property: keyof T, order: 'asc' | 'desc' = 'asc'): T[] {
        return collection.sort((a, b) => {
            if (a[property] < b[property]) { return order === 'asc' ? -1 : 1; }
            if (a[property] > b[property]) { return order === 'asc' ? 1 : -1; }
            return 0;
        });
    }

}
