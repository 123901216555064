import { EphemeralStore } from './ephemeral.store';
import { GroupMessageConversation } from '../models/group-messaging/group-message-conversation';

export class GroupMessageConversationStore extends EphemeralStore<GroupMessageConversation> {

    public findById(id: string): GroupMessageConversation {
        return this.find(conversation => conversation.id === id);
    }

}
