import { Component } from '@angular/core';
import { MessageInfo } from './message-info';

@Component({
    selector: 'app-tablet-message-info-page',
    templateUrl: 'tablet-message-info.html',
    styleUrls: ['message-info.scss', 'tablet-message-info.scss'],
})
export class TabletMessageInfoPage extends MessageInfo {

}
