import { Component } from '@angular/core';
import { User } from '../../../services/models/user';
import { NewMessageService } from '../new-message.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-new-external-message-page',
    templateUrl: 'external.html',
    styleUrls: ['external.scss'],
})
export class AppNewExternalMessagePage {

    public get users(): User[] {
        return this.service.getExternalUsers();
    }

    constructor(
        private service: NewMessageService,
    ) {

    }

    public onSelect(user: User) {
        this.service.selectUser(user);
    }
}
