import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { takeUntil } from 'rxjs/operators';
import { NetworkService } from '../../services/network.service';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-offline-notification',
    templateUrl: 'offline-notification.html',
    styleUrls: ['offline-notification.scss'],
})
export class OfflineNotificationComponent implements OnDestroy, OnInit {

    public isDestroyed: Subject<void> = new Subject<void>();

    @HostBinding('class.visible') public visible = false;

    constructor(
        private network: NetworkService,
        private platform: Platform,
    ) {

    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.platform.ready().then(() => {
            this.network.onConnect().pipe(takeUntil(this.isDestroyed)).subscribe(() => this.visible = false);
            this.network.onDisconnect().pipe(takeUntil(this.isDestroyed)).subscribe(() => this.visible = true);
        });
    }

}
