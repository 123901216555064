import { NgModule } from '@angular/core';
import { ApiClientModule, ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { DirectMessagingServiceFactory } from './factory';
import { DirectMessagingService } from './abstract.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';

@NgModule({
    imports: [
        ApiClientModule,
    ],
    providers: [
        DatabaseService,
        RealTimeMessagingService,
        {
            provide: DirectMessagingService,
            useFactory: DirectMessagingServiceFactory,
            deps: [
                ApiClientService,
                DatabaseService,
                RealTimeMessagingService,
                Platform,
                PrivateSettingsService,
                UserService,
            ],
        }
    ],
})
export class DirectMessagingServiceModule {}
