import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CaseMessageEvent } from '../../../services/models/case-conversation-events/case-message-event';
import { CaseMessagingService } from '../../../services/case-messaging-service';

@Component({
    selector: 'app-case-message-info-page',
    templateUrl: 'message-info.html',
    styleUrls: ['message-info.scss'],
})

export class MessageInfoPage implements OnInit, OnDestroy {

    public message: CaseMessageEvent = null;
    public isDestroyed: Subject<void> = new Subject<void>();
    public onRouteChange: Subject<void> = new Subject<void>();

    constructor(
        public caseMessaging: CaseMessagingService,
        public route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.params.pipe(
            takeUntil(this.isDestroyed),
        ).subscribe((params: { conversation: string, id: string }) => {
            this.onRouteChange.next();

            this.caseMessaging.getEvents(params.conversation).pipe(
                takeUntil(this.onRouteChange),
                takeUntil(this.isDestroyed),
            ).subscribe(events => {
                const event = events.find(item => item.id === params.id);
                this.message = event as CaseMessageEvent;
            });

            this.caseMessaging.fetchCase(params.conversation);
            this.caseMessaging.fetchEvents(params.conversation, {
                before: new Date().toISOString(),
            });
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

}
