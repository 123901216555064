import { Component, ViewChild, ElementRef } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-changed-password-page',
    template: '',
    styleUrls: ['changed-password.scss'],
})

export abstract class ChangedPasswordPage {

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private navCtrl: NavController,
        private router: Router,
    ) {
    }

    public goToDashboard() {
        this.router.navigate(['/dashboard']);
    }

    public ionViewWillEnter() {
        if (!this.cognitoAuth.canShowChangedPasswordSuccess()) {
            this.router.navigate(['/dashboard']);
        }
    }

}
