import { Component, ViewChild, ElementRef } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-build-page',
    template: '',
    styleUrls: ['build.scss'],
})

export abstract class BuildPage {

    protected title = 'Build Screen';

    constructor(public alertController: AlertController) {}

    async presentAlert() {
        const alert = await this.alertController.create({
          cssClass: 'custom-alert',
          message: 'Are you sure this case if resolved?',
          buttons: [
            {
                text: '',
                role: 'cancel',
                cssClass: 'close',
                handler: (blah) => {
                  console.log('Confirm Cancel: blah');
                }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: 'Yes',
              cssClass: 'primary',
              handler: () => {
                console.log('Confirm Okay');
              }
            }
          ]
        });

        await alert.present();
    }

    searchResults = [
        {
            "title": "Absolute Community Pharmacy",
            "subTitle": "The Health Centre, 2 King's Avenue",
            "image":"/assets/icon/pharmaBest.svg"
        },
        {
            "title": "Absolute Community Pharmacy",
            "subTitle": "The Health Centre, 2 King's Avenue",
            "image":"/assets/icon/pharmaBest.svg"
        },
        {
            "title": "Absolute Community Pharmacy",
            "subTitle": "The Health Centre, 2 King's Avenue",
            "image":"/assets/icon/pharmaBest.svg"
        },
        {
            "title": "Absolute Community Pharmacy",
            "subTitle": "The Promenade, 119 Beach Road",
            "image":"/assets/icon/absoluteCommunityPharmacy.svg"
        },
        {
            "title": "Absolute Community Pharmacy",
            "subTitle": "Springside, 8 Glenview Road",
            "image":"/assets/icon/absoluteCommunityPharmacy.svg"
        }
    ]

}
