import { NgModule } from '@angular/core';
import { OrganisationService } from './abstract.service';
import { OrganisationServiceFactory } from './factory';
import { ApiClientModule, ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { PrivateSettingsService } from '../private-settings.service';

@NgModule({
    imports: [
        ApiClientModule,
    ],
    providers: [
        DatabaseService,
        {
            provide: OrganisationService,
            useFactory: OrganisationServiceFactory,
            deps: [
                ApiClientService,
                DatabaseService,
                Platform,
                PrivateSettingsService,
            ],
        }
    ],
})
export class OrganisationServiceModule {
}
