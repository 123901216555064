import { DirectMessagingService } from '../../services/direct-messaging.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DirectMessageEvent } from '../../services/models/direct-message-event';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Conversation } from '../my-messages/my-messages';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';

@Injectable()
export class MessageService {

    private conversationId: string = null;
    private conversationIdChanged: Subject<string> = new Subject<string>();
    private conversation: Conversation = null;
    private conversationChanged: Subject<Conversation> = new Subject<Conversation>();
    private currentUser: User = null;
    private isGroup = false;

    private readRequests: { [k: string]: Promise<any> } = {};

    constructor(
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private router: Router,
        private userService: UserService,
    ) {
        userService.getMe().subscribe(me => this.currentUser = me);

        this.onConversationIdChanged().subscribe(conversationId => {
            if (this.isGroup) {
                this.groupMessaging.getGroup(this.conversationId).pipe(
                    takeUntil(this.conversationIdChanged),
                ).subscribe(conversation => {
                    this.setConversation(conversation);
                });
                this.groupMessaging.getEvents(conversationId).pipe(
                    filter(() => router.isActive('/my-messages/group/' + this.conversationId, true)),
                    takeUntil(this.conversationIdChanged),
                ).subscribe(events => {
                    const unreadEvents = (events as GroupMessageEvent[]).filter(event => {
                        if (event.type !== 'message') {
                            return;
                        }
                        if (!this.currentUser) {
                            return;
                        }
                        if (event.sentBy === undefined) {
                            return;
                        }
                        if (event.readBy(this.currentUser.id)) {
                            return;
                        }
                        if (event.sentBy.id === this.currentUser?.id) {
                            return;
                        }
                        return event;
                    });
                    unreadEvents.forEach(event => this.readEvent(event));
                });
            } else {
                this.directMessaging.getConversation(this.conversationId).pipe(
                    takeUntil(this.conversationIdChanged),
                ).subscribe(conversation => {
                    this.setConversation(conversation);
                });
                this.directMessaging.getAllEvents(conversationId).pipe(
                    filter(() => router.isActive('/my-messages/' + this.conversationId, true)),
                    takeUntil(this.conversationIdChanged),
                ).subscribe(events => {
                    const unreadEvents = events.filter(event => !event.readAt && event.sentBy !== this.currentUser?.id);
                    unreadEvents.forEach(event => this.readEvent(event));
                });
            }
        });
    }

    public getConversationId(): string {
        return this.conversationId;
    }

    public getConversation(): Conversation {
        return this.conversation;
    }

    public onConversationIdChanged(): Observable<string> {
        return this.conversationIdChanged.asObservable();
    }

    public onConversationChanged(): Observable<Conversation> {
        return this.conversationChanged.asObservable();
    }

    public setConversationId(id: string, isGroup: boolean = false) {
        if (this.conversationId === id) {
            return;
        }
        this.isGroup = isGroup;
        this.conversationId = id;
        this.conversationIdChanged.next(id);
    }

    public clearConversation() {
        this.conversationId = null;
        this.conversation = null;
    }

    public setConversation(conversation: Conversation) {
        this.conversation = conversation;
        this.conversationChanged.next(conversation);
    }

    public readEvent(event: DirectMessageEvent|GroupMessageEvent) {
        if (this.readRequests[event.id]) {
            return;
        }
        console.log('[READ]', event.id, event.messageBody);
        if (event instanceof DirectMessageEvent) {
            this.readRequests[event.id] = this.directMessaging.readEvent(event).then(() => delete this.readRequests[event.id]);
        }
        if (event instanceof GroupMessageEvent) {
            // tslint:disable-next-line:max-line-length
            this.readRequests[event.id] = this.groupMessaging.readMessage(event.conversation, event.id).then(() => delete this.readRequests[event.id]);
        }
    }

}
