import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DirectMessagingRoutingModule } from './routing.module';
import { ComponentsModule } from '../../components/components.module';
import { DirectivesModule } from '../../directives/directives.module';
import { AppDirectMessagingListPage } from './list/list';
import { AppDirectMessagingSinglePage } from './single/single';
import { DirectMessagingService } from '../../services/direct-messaging.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DirectMessagingRoutingModule,
        ComponentsModule,
        DirectivesModule,
    ],
    declarations: [
        AppDirectMessagingListPage,
        AppDirectMessagingSinglePage,
    ],
    providers: [
        // DirectMessagingService,
    ]
})
export class DirectMessagingPagesModule {
}
