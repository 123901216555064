import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Environment, Environments, ENVIRONMENTS } from '../../app.constants';
import { EnvironmentService } from '../../services/environment.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router } from '@angular/router';
import { SettingsService } from '../../services/settings.service';

@Component({
    selector: 'app-developer-environment',
    templateUrl: './developer-environment.page.html',
    styleUrls: ['./developer-environment.page.scss'],
})
export class DeveloperEnvironmentPage implements OnInit {

    public currentEnvironment: Environment = null;

    constructor(
        private alertCtrl: AlertController,
        private environment: EnvironmentService,
        private modalCtrl: ModalController,
        private router: Router,
        private storage: SettingsService,
        private splashScreen: SplashScreen,
    ) {

    }

    public ngOnInit(): void {
        this.currentEnvironment = this.environment.getEnvironment();
    }

    public cancel() {
        this.modalCtrl.dismiss();
    }

    public get environments(): Environment[] {
        const environments: Environment[] = [];

        for (const key in ENVIRONMENTS) {
            environments.push(ENVIRONMENTS[key]);
        }

        return environments;
    }

    public async setEnvironment(item: Environment) {
        this.alertCtrl.create({
            cssClass: 'my-custom-class',
            header: 'Change Environment',
            message: `Are you sure you want to change to the ${item.name} Environment?`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Confirm',
                    handler: async () => {
                        await this.splashScreen.show();
                        await this.storage.remove('canActivate:firstLoad');
                        await this.storage.set('environment', item.name);
                        this.router.navigate(['/']).then(() => document.location.reload());
                    }
                }
            ]
        }).then(alert => alert.present());
    }

}
