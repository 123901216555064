import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reverse'
})
export class ReversePipe implements PipeTransform {

    public transform<T>(collection: T[]): T[] {
        return collection.reverse();
    }

}
