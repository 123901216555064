import { Component } from '@angular/core';
import { MessageGroup } from './message-group';

@Component({
    selector: 'app-phone-message-group-page',
    templateUrl: 'phone-message-group.html',
    styleUrls: ['message-group.scss', 'phone-message-group.scss'],
})
export class PhoneMessageGroupPage extends MessageGroup {



}
