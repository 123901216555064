import { AbstractSyncedModel } from '../abstract-synced-model';
import { GroupMessageMember as ApiGroupMessageMember } from '@scaffold/mediccoms-api-client/models/group-message-member';
import { User } from '../user';

export class GroupMessageParticipant extends AbstractSyncedModel {
    public id: string;
    public admin: boolean;
    public user: User;

    public static mapFromApi(data: Partial<ApiGroupMessageMember>): Partial<GroupMessageParticipant> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            admin: data.admin,
            user: User.mapFromApi(data as any) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<any>): Partial<GroupMessageParticipant> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            admin: false,
            user: User.mapFromConversationParticipant(data) as Required<User>,
        };
    }

    public fill(data: Partial<GroupMessageParticipant>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.admin !== undefined) {
            this.admin = data.admin;
        }
        if (data.user !== undefined) {
            this.user = User.createOne(data.user);
        }
    }

    public toJSON() {
        return {
            id: this.id,
            admin: this.admin,
        };
    }
}
