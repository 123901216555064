import { Pipe, PipeTransform } from '@angular/core';
import { differenceInSeconds, formatDistanceToNow, formatDistanceToNowStrict, parseISO } from 'date-fns';

@Pipe({
    name: 'distanceToNow',
    pure: false,
})
export class DistanceToNowPipe implements PipeTransform {

    public transform(value: Date | number | string, strict: true, options?: {
        addSuffix?: boolean
        unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
        roundingMethod?: 'floor' | 'ceil' | 'round'
        locale?: Locale
    }): string;
    public transform(value: Date | number | string, strict: false | null, options?: {
        includeSeconds?: boolean
        addSuffix?: boolean
        locale?: Locale
    }): string;
    public transform(value: Date | number | string, strict: boolean, options?): string;
    public transform(value: Date | number | string, strict: boolean, options?): string {
        if (typeof value === 'string') {
            return this.transform(parseISO(value), strict, options);
        }

        if (differenceInSeconds(new Date(), value) <= 50) {
            return 'a few seconds';
        } else if (differenceInSeconds(new Date(), value) <= 90) {
            return 'about a minute';
        }

        const formatter = strict ? formatDistanceToNowStrict : formatDistanceToNow;
        return formatter(value, options);
    }

}
