import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthResponse, CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AlertController, GestureController, Platform } from '@ionic/angular';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
    selector: 'app-login-page',
    template: '',
    styleUrls: ['login.scss'],
})

export abstract class Login implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('header') header: ElementRef;
    private longPressTimer: Subscription;

    private currentUser: User = null;
    public username = '';
    public password = '';
    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;
    private isDestroyed: Subject<void> = new Subject<void>();
    private loggedIn = false;

    constructor(
        private alertCtrl: AlertController,
        private analytics: AnalyticsService,
        private cognitoAuth: CognitoAuthenticationService,
        private platform: Platform,
        private gestureCtrl: GestureController,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(async currentUser => {
            this.currentUser = currentUser;
            if (!this.loggedIn || this.currentUser === null) {
                return;
            }
            if (['developer', 'mediccoms_admin', 'mediccoms_user'].includes(this.currentUser?.role)) {
                this.cognitoAuth.logout();
                this.addError('MedicComs user account cannot access app.');
            } else if (this.router.url === '/login') {
                await this.router.navigate(['dashboard']);
            }
        });

        this.cognitoAuth.getLogout().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => this.loggedIn = false);
    }

    public ngAfterViewInit(): void {

        const gesture = this.gestureCtrl.create({
            gestureName: 'long-press',
            el: this.header.nativeElement,
            onStart: _ => this.startLongPressTimer(),
            onEnd: _ => this.stopLongPressTimer(),
            threshold: 0,
        });

        gesture.enable();
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    private addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    private clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    private validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public signIn() {
        this.clearErrors();
        this.isLoading = true;

        if (!this.username) {
            this.addError('Email is required.');
            this.isLoading = false;
            return;
        }

        if (!this.password) {
            this.addError('Password is required.');
            this.isLoading = false;
            return;
        }

        if (!this.validateEmail(this.username)) {
            this.addError('Invalid Email');
            this.isLoading = false;
            return;
        }

        this.cognitoAuth.initiateAuth(this.username, this.password)
            .then(async (authResponse: AuthResponse) => {
                if (authResponse.loggedIn) {
                    this.loggedIn = authResponse.loggedIn;
                    this.analytics.logEvent('login');
                    return;
                    // return await this.router.navigate(['dashboard']);
                } else if (authResponse.challengeType) {
                    switch (authResponse.challengeType) {
                        case 'new-password':
                            return this.router.navigate(['/change-temporary-password']);
                        case 'mfa':
                            return this.router.navigate(['/mfa']);
                        default:
                            this.addError('Invalid Authorisation Response');
                            this.isLoading = false;
                            return;
                    }
                } else {
                    this.addError('An error occurred');
                    this.password = '';
                }
            })
            .catch(error => {
                this.addError(error);
                this.isLoading = false;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public ionViewWillEnter() {
        this.isLoading = false;
        this.clearErrors();
        this.username = '';
        this.password = '';
    }

    public clearLongPressTimer() {
        if (this.longPressTimer) {
            this.longPressTimer.unsubscribe();
            this.longPressTimer = null;
        }
    }

    public startLongPressTimer() {
        this.clearLongPressTimer();
        if (this.platform.is('cordova')) {
            this.longPressTimer = timer(3000).subscribe(async () => {
                await this.presentDevToolsAlert();
            });
        }
    }

    public stopLongPressTimer() {
        this.clearLongPressTimer();
    }

    private async presentDevToolsAlert() {
        const alert = await this.alertCtrl.create({
            header: 'Developer Tools',
            message: 'Please enter password to continue',
            inputs: [
                {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Password'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        alert.dismiss();
                    }
                }, {
                    text: 'Ok',
                    handler: data => {
                        if (data.password === '8155') {
                            this.router.navigate(['developer']);
                        }
                    }
                }
            ]
        });

        await alert.present();
    }
}
