import { NgModule } from '@angular/core';
import { ApiClientModule, ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { CaseMessagingServiceFactory } from './factory';
import { CaseMessagingService } from './abstract.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { Router } from '@angular/router';
import { File as CordovaFile } from '@ionic-native/file/ngx';

@NgModule({
    imports: [
        ApiClientModule,
    ],
    providers: [
        DatabaseService,
        RealTimeMessagingService,
        {
            provide: CaseMessagingService,
            useFactory: CaseMessagingServiceFactory,
            deps: [
                ApiClientService,
                CordovaFile,
                DatabaseService,
                OrganisationService,
                Router,
                RealTimeMessagingService,
                Platform,
                PrivateSettingsService,
                UserService,
            ],
        }
    ],
})
export class CaseMessagingServiceModule {}
