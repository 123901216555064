import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NetworkService {

    private didConnect: Subject<void> = new Subject<void>();
    private didDisconnect: Subject<void> = new Subject<void>();
    private didChange: Subject<string> = new Subject<string>();

    constructor(
        private platform: Platform,
        private network: Network,
    ) {
        if (this.platform.ready() && this.platform.is('cordova')) {
            this.network.onChange().subscribe(() => {
                if (this.isOnline) {
                    this.didConnect.next();
                } else {
                    this.didDisconnect.next();
                }
            });
        }
    }

    public onChange(): Observable<string> {
        return this.didChange.asObservable();
    }

    public onConnect(): Observable<void> {
        return this.didConnect.asObservable();
    }

    public onDisconnect(): Observable<void> {
        return this.didDisconnect.asObservable();
    }

    public get isOnline(): boolean {
        return this.platform.is('cordova') ? this.isDeviceOnline() : this.isBrowserOnline();
    }

    private isBrowserOnline(): boolean {
        return true;
    }

    private isDeviceOnline(): boolean {
        const network = this.network;
        const connection = network.Connection;
        switch (network.type) {
            case connection.CELL:
            case connection.CELL_2G:
            case connection.CELL_3G:
            case connection.CELL_4G:
            case connection.ETHERNET:
            case connection.WIFI:
                return true;
            case connection.NONE:
            case connection.UNKNOWN:
            default:
                return false;
        }
    }

    public get isOffline(): boolean {
        return !this.isOnline;
    }

}
