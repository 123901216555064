import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    public transform(value: string, length: number = 20): string {
        if (!value) {
            return;
        }

        if (value.length <= length) {
            return value;
        }

        return value.substr(0, length - 3) + '...';
    }

}
