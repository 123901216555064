import { Component } from '@angular/core';
import { Message } from './message';

@Component({
    selector: 'app-tablet-message-page',
    templateUrl: 'tablet-message.html',
    styleUrls: ['message.scss', 'tablet-message.scss'],
})
export class TabletMessagePage extends Message {

}
