import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Observable } from 'rxjs';
import { Organisation } from '../models/organisation';
import { Branch } from '../models/branch';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationStore } from '../stores/organisation.store';
import { BranchStore } from '../stores/branch.store';
import { User } from '../models/user';

@Injectable()
export abstract class OrganisationService {

    public abstract organisations: OrganisationStore;
    public abstract branches: BranchStore;

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public storage: PrivateSettingsService,
    ) {
    }

    public abstract fetchBranches(organisationId: string): Promise<Branch[]>;

    public abstract fetchOrganisations(): Promise<Organisation[]>;

    public abstract fetchOrganisation(organisationId: string): Promise<Organisation>;

    public abstract getAllBranches(): Observable<Branch[]>;

    public abstract getBranch(branchId: string): Observable<Branch>;

    public abstract getBranches(organisationId?: string): Observable<Branch[]>;

    public abstract getOrganisation(organisationId: string): Observable<Organisation>;

    public abstract getOrganisations(): Observable<Organisation[]>;

    public abstract clear(): Promise<void>;

    public abstract loadFromMemory(): Promise<void>;

    public abstract getBranchUsers(organisationId: string, branchId: string): Observable<User[]>;

    public abstract fetchBranchUsers(organisationId: string, branchId: string): Promise<User[]>;

    public abstract ready(): Promise<boolean>;
}
