import { Component } from '@angular/core';
import { InstructionsPage } from './instructions';

@Component({
    selector: 'app-phone-case-instructions-page',
    templateUrl: 'instructions.html',
    styleUrls: ['instructions.scss', 'phone-instructions.scss'],
})
export class PhoneInstructionsPage extends InstructionsPage {

}
