import { Platform } from '@ionic/angular';
import { MobileUserService } from './mobile.service';
import { BrowserUserService } from './browser.service';
import { UserService } from './abstract.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';

export const UserServiceFactory = (
    api: ApiClientService,
    database: DatabaseService,
    organisationService: OrganisationService,
    platform: Platform,
    storage: PrivateSettingsService,
): UserService => {
    const service = platform.is('cordova') ? MobileUserService : BrowserUserService;
    return new service(api, database, organisationService, storage);
};
