import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { CaseConversation } from '../models/case-conversation';
import { Observable } from 'rxjs';
import { CaseConversationEventType } from '@scaffold/mediccoms-api-client/types';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { CaseEvent } from '../models/case-conversation-events/case-event';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';
import { SearchCaseEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { Router } from '@angular/router';
import { File as CordovaFile } from '@ionic-native/file/ngx';

@Injectable()
export abstract class CaseMessagingService {
    constructor(
        public api: ApiClientService,
        public cordovaFile: CordovaFile,
        public database: DatabaseService,
        public router: Router,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        (window as any).api = api;
    }

    public abstract acceptCase(caseId: string): Promise<CaseConversation>;

    public abstract assignCase(caseId: string, assigneeId: string): Promise<CaseConversation>;

    public abstract cancelCase(caseId: string): Promise<boolean>;

    public abstract createCase(recipientId: string, patientId: string): Promise<CaseConversation>;

    public abstract fetchAllCases(): Promise<CaseConversation[]>;

    public abstract fetchCase(caseId: string): Promise<CaseConversation>;

    public abstract fetchEvents(caseId: string, request: SearchCaseEventsRequest): Promise<CaseConversationEventType[]>;

    public abstract getAllCases(): Observable<CaseConversation[]>;

    public abstract getMyCases(): Observable<CaseConversation[]>;

    public abstract getEvents(caseId: string): Observable<CaseEvent[]>;

    public abstract getPendingEvents(offlineCaseId: string): Observable<CaseEvent[]>;

    public abstract getCase(caseId: string): Observable<CaseConversation>;

    public abstract openCase(caseId: string): Promise<CaseConversation>;

    public abstract readMessage(caseId: string, messageId: string): Promise<CaseConversation>;

    public abstract resolveCase(caseId: string): Promise<CaseConversation>;

    public abstract searchAllCases(status: string | null, patientId: string | null, page?: number): Promise<CaseConversation[]>;

    public abstract sendMessage(caseId: string, message: string, attachments: File[]): Promise<boolean>;

    public abstract clear(): Promise<void>;

    public abstract loadFromMemory(): Promise<void>;

    public abstract ready(): Promise<boolean>;

    public abstract getOfflineCases(): Promise<CaseConversation[]>;

    public abstract getOfflineCase(caseId: string): Promise<CaseConversation>;

    public abstract getCasesPromise(): Promise<CaseConversation[]>;

    public abstract getCasePromise(caseId: string): Promise<CaseConversation>;

    public abstract getOfflineCaseEvents(): Promise<CaseEvent[]>;

    public abstract getOfflineEventsForCase(caseId: string): Promise<CaseEvent[]>;

    public abstract completeOfflineActions(): Promise<boolean>;

    public abstract cancelOfflineActions(): Promise<boolean>;
}
