import { Component } from '@angular/core';
import { CaseMessengerPage } from './messenger';

@Component({
    selector: 'app-large-case-messenger-page',
    templateUrl: 'messenger.html',
    styleUrls: ['messenger.scss', 'large.messenger.scss'],
})
export class LargeCaseMessengerPage extends CaseMessengerPage {

}
