/* tslint:disable:variable-name */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { NavController, Platform } from '@ionic/angular';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';

export type Conversation = DirectMessageConversation | GroupMessageConversation;

@Component({
    selector: 'app-my-messages-page',
    template: '',
    styleUrls: ['my-messages.scss'],
})

export abstract class MyMessages implements OnDestroy, OnInit {

    private _searchTerm = '';

    public groupConversations: GroupMessageConversation[] = null;
    public directConversations: DirectMessageConversation[] = null;
    public filteredConversations: Conversation[] = [];
    public onDestroy: Subject<void> = new Subject<void>();

    public get conversations(): Conversation[] {
        return [].concat(this.directConversations, this.groupConversations);
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        this._searchTerm = value;
        this.applyFilters();
    }

    constructor(
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private navCtrl: NavController,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    public filterDirectMessageConversation(conversation: DirectMessageConversation) {
        const searchTerm = this.searchTerm?.toLowerCase();
        if (!searchTerm) { return true; }
        if (conversation.recipient?.fullNameWithTitle?.toLowerCase().includes(searchTerm)) { return true; }
        return false;
    }

    public filterGroupMessageConversation(conversation: GroupMessageConversation) {
        const searchTerm = this.searchTerm?.toLowerCase();
        if (!searchTerm) { return true; }
        if (conversation.name?.toLowerCase().includes(searchTerm)) { return true; }
        return false;
    }

    public applyFilters() {
        this.filteredConversations = this.conversations.filter(conversation => {
            if (conversation instanceof DirectMessageConversation) {
                return this.filterDirectMessageConversation(conversation);
            }
            if (conversation instanceof GroupMessageConversation) {
                return this.filterGroupMessageConversation(conversation);
            }
        });
    }

    public ionViewWillEnter() {
        // this.directMessaging.fetchAllConversations();
        // this.groupMessaging.fetchGroups();
    }

    public ngOnInit() {
        this.directMessaging.getAllConversations().pipe(
            debounceTime(20),
            takeUntil(this.onDestroy),
        ).subscribe(conversations => {
            this.directConversations = conversations.filter(({ events }) => events && events.length > 0)
                .filter(conversation => conversation?.recipient?.messaging);
            this.applyFilters();
        }).add(() => {
            this.directConversations = null;
        });
        this.groupMessaging.getGroups().pipe(
            debounceTime(20),
            takeUntil(this.onDestroy),
        ).subscribe(groups => {
            this.groupConversations = groups;
            this.applyFilters();
        }).add(() => {
            this.groupConversations = null;
        });
    }

    public ngOnDestroy() {
        this.onDestroy.next();
    }

    public selectConversation(conversation: Conversation) {
        this.navCtrl.setDirection('forward', this.platform.is('mobile'));
        if (conversation instanceof DirectMessageConversation) {
            this.router.navigate(['my-messages', conversation.id]);
        }
        if (conversation instanceof GroupMessageConversation) {
            // console.log('[selectConversation]', conversation);
            this.router.navigate(['my-messages', 'group', conversation.id]);
        }
    }

    public goToNewMessage() {
        this.router.navigate(['/new-message']);
    }

    public goToUserProfile() {
        this.router.navigate(['/message-user-detail']);
    }
}
