import {
    ConversationCreatedEvent,
    MessageDeliveredEvent, MessageReadEvent,
    NewMessageEvent, RtmsEvent,
    RtmsTargetType
} from '../models/rtm-events.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RtmsBaseEventsService {

    constructor(
        private rtms: RealTimeMessagingService
    ) {
    }

    public onEvent(): Observable<RtmsEvent> {
        return this.rtms.onEvent();
    }

    public onConversationCreated(targetType?: RtmsTargetType): Observable<ConversationCreatedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (targetType && event.target_type !== targetType) {
                    return false;
                }
                return event.event === 'conversation_created';
            }),
            map(event => ConversationCreatedEvent.createOne(event)),
        );
    }

    public onMessage(targetType?: RtmsTargetType, conversationId?: string): Observable<NewMessageEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (targetType && event.target_type !== targetType) {
                    return false;
                }
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'new_message';
            }),
            map(event => NewMessageEvent.createOne(event)),
        );
    }

    public onMessageDelivered(targetType?: RtmsTargetType): Observable<MessageDeliveredEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (targetType && event.target_type !== targetType) {
                    return false;
                }
                return event.event === 'message_delivered';
            }),
            map(event => MessageDeliveredEvent.createOne(event)),
        );
    }

    public onMessageRead(targetType?: RtmsTargetType): Observable<MessageReadEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (targetType && event.target_type !== targetType) {
                    return false;
                }
                return event.event === 'message_read';
            }),
            map(event => MessageReadEvent.createOne(event)),
        );
    }
}
