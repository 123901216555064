import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CaseConversation } from '../../services/models/case-conversation';

@Component({
    selector: 'app-cases-widget',
    templateUrl: 'cases-widget.html',
    styleUrls: ['cases-widget.scss'],
})
export class CasesWidgetComponent {

    private _cases: CaseConversation[] = [];

    public open: number = null;
    public resolved: number = null;

    public get cases(): CaseConversation[] {
        return this._cases;
    }

    @Input()
    public set cases(cases: CaseConversation[]) {
        this._cases = cases;
        this.open = cases.filter(item => item.isOpen).length;
        this.resolved = cases.filter(item => item.isResolved).length;
    }

    @Output('click') public clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent) {
        this.clickEvent.emit(event);
    }

}
