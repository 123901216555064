import { Component } from '@angular/core';
import { MessageUserDetail } from './message-user-detail';

@Component({
    selector: 'app-phone-message-user-detail-page',
    templateUrl: 'phone-message-user-detail.html',
    styleUrls: ['message-user-detail.scss', 'phone-message-user-detail.scss'],
})
export class PhoneMessageUserDetailPage extends MessageUserDetail {



}
