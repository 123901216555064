import { Component } from '@angular/core';
import { DashboardPage } from './dashboard';

@Component({
    selector: 'app-tablet-dashboard-page',
    templateUrl: 'tablet-dashboard.html',
    styleUrls: ['dashboard.scss', 'tablet-dashboard.scss'],
})
export class TabletDashboardPage extends DashboardPage {

}
