import { Component } from '@angular/core';
import { OnBoarding } from './on-boarding';

@Component({
    selector: 'app-phone-on-boarding-page',
    templateUrl: 'phone-on-boarding.html',
    styleUrls: ['on-boarding.scss', 'phone-on-boarding.scss'],
})
export class PhoneOnBordingPage extends OnBoarding {

}
