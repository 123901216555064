import { GroupEvent } from './group-event';
import { User } from '../user';
import { GroupMessageEvent as ApiGroupMessageEvent } from '@scaffold/mediccoms-api-client/models/group-message-event';
import { GroupMessageReceipt } from './group-message-receipt';
import { NewMessageEvent, RtmsEvent } from '../rtm-events.model';

export class GroupMessageEvent extends GroupEvent {

    public type: string;
    public attachments: string[];
    public deliveredAt: GroupMessageReceipt[];
    public messageBody: string;
    public readAt: GroupMessageReceipt[];
    public sentBy: User;

    public static mapFromApi(data: Partial<ApiGroupMessageEvent>): Partial<GroupMessageEvent> {
        if (!data) {
            return null;
        }

        const deliveredAt: GroupMessageReceipt[] = [];
        const readAt: GroupMessageReceipt[] = [];

        for (const receipt of data.delivered_at) {
            deliveredAt.push(GroupMessageReceipt.mapFromApi(receipt) as GroupMessageReceipt);
        }

        for (const receipt of data.read_at) {
            readAt.push(GroupMessageReceipt.mapFromApi(receipt) as GroupMessageReceipt);
        }

        return {
            id: data.id,
            type: 'message',
            attachments: data.attachments,
            deliveredAt,
            messageBody: data.message_body,
            readAt,
            sentAt: data.sent_at,
            sentBy: User.mapFromApi({
                id: data.sent_by
            }) as Required<User>,
        };
    }

    public static mapFromRtms(data: Partial<NewMessageEvent>): Partial<GroupMessageEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.data.message_id,
            type: 'message',
            attachments: data.data.attachments,
            messageBody: data.data.message_body,
            sentAt: data.timestamp,
            sentBy: User.mapFromApi({
                id: data.sender_id
            }) as Required<User>,
            conversation: data.target_id,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            attachments: this.attachments,
            deliveredAt: this.deliveredAt,
            messageBody: this.messageBody,
            readAt: this.readAt,
            sentAt: this.sentAt,
            sentBy: this.sentBy ? this.sentBy.id : null,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<GroupMessageEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.attachments !== undefined) {
            this.attachments = data.attachments;
        }
        if (data.deliveredAt !== undefined) {
            this.deliveredAt = GroupMessageReceipt.createMany(data.deliveredAt);
        }
        if (data.messageBody !== undefined) {
            this.messageBody = data.messageBody;
        }
        if (data.readAt !== undefined) {
            this.readAt = GroupMessageReceipt.createMany(data.readAt);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.sentBy !== undefined) {
            this.sentBy = User.createOne(data.sentBy);
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public readBy(id: string) {
        if (!this.readAt) {
            return false;
        }
        return !!this.readAt.find(receipt => receipt.user.id === id);
    }
}
