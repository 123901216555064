import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy, Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { WebFile } from '../../helpers/web-file';
import { CaseEvent } from '../../services/models/case-conversation-events/case-event';
import { CaseConversation } from '../../services/models/case-conversation';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { CaseMessageEvent } from '../../services/models/case-conversation-events/case-message-event';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { AbstractSyncedModel } from '../../services/models/abstract-synced-model';
import { parseISO, isSameDay, getDay, isToday, isYesterday, isThisWeek } from 'date-fns';
import { BiometricService } from '../../services/biometric.service';

@Component({
    selector: 'app-case-messenger',
    templateUrl: 'case-messenger.html',
    styleUrls: ['case-messenger.scss'],
})
export class CaseMessengerComponent implements AfterViewInit, OnChanges, OnDestroy {

    @Input() public conversation: CaseConversation = null;

    @Output() messageString = new EventEmitter<string>();

    private attachment: WebFile = null;
    private readRequests: { [k: string]: Subject<void> } = {};

    public actionSheet: HTMLIonActionSheetElement = null;
    public events: CaseEvent[] = null;
    public _newMessage = '';
    public isDestroyed: Subject<void> = new Subject<void>();
    public conversationChanged: Subject<CaseConversation> = new Subject<CaseConversation>();
    // public pendingMessages: PendingDirectMessage[] = null;
    public imageInput: HTMLInputElement;
    public currentUser: User;

    public loadPreviousEventsRequest: Promise<any> = null;
    public noPreviousEvents = false;
    public latestMessage: CaseEvent = null;
    public earliestMessage: CaseEvent = null;
    private previousHeight: number = null;

    @ViewChild('messageInput') public messageInputRef: ElementRef<HTMLTextAreaElement>;
    @ViewChild('imageInput', { static: false }) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('scrollable') public scrollableRef: ElementRef;
    @ViewChild('wrapper') public wrapperRef: ElementRef;
    @HostListener('scroll', ['$event'])

    public onScroll(event: any) {
        if (event.target.scrollTop === 0) {
            this.loadPreviousEvents();
        }
    }

    public set newMessage(message: string) {
        this._newMessage = message;
        if (message === '') {
            message = null;
        }
        this.messageString.emit(message);
    }

    public get newMessage() {
        return this._newMessage;
    }

    public loadPreviousEvents() {
        if (this.noPreviousEvents || this.loadPreviousEventsRequest) {
            return this.loadPreviousEventsRequest;
        }
        this.previousHeight = this.scrollableRef.nativeElement.clientHeight;

        if (this.events.length) {
            this.loadPreviousEventsRequest = this.caseMessaging.fetchEvents(this.conversation.id, {
                before: this.earliestMessage?.sentAt
            }).then(result => {
                if (!result.length) {
                    this.noPreviousEvents = true;
                }
                this.loadPreviousEventsRequest = null;
                return result;
            });
        }
    }

    public get sendingIsAllowed(): boolean {
        if (!this.conversation) {
            return false;
        }
        if (this.conversation.isResolved) {
            return false;
        }
        return this.conversation.createdBy?.id === this.currentUser?.id || this.conversation.assignee?.id === this.currentUser?.id;
    }

    constructor(
        private actionSheetController: ActionSheetController,
        private biometric: BiometricService,
        private camera: Camera,
        private caseMessaging: CaseMessagingService,
        private file: CordovaFile,
        private keyboard: Keyboard,
        private platform: Platform,
        private router: Router,
        private userService: UserService,
    ) {
        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(me => this.currentUser = me);

        this.conversationChanged.asObservable().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(conversation => {
            if (!conversation) {
                this.events = null;
            } else {
                this.caseMessaging.getEvents(conversation.id).pipe(
                    // debounceTime(0, animationFrame),
                    debounceTime(20),
                    takeUntil(this.conversationChanged),
                    takeUntil(this.isDestroyed),
                ).subscribe(events => {
                    this.events = events.reverse();

                    if (!this.events.length) {
                        return;
                    }

                    const latestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) < new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    const earliestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) > new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    if (!(this.latestMessage?.sentAt) || new Date(latestMessage.sentAt) > new Date(this.latestMessage.sentAt)) {
                        this.latestMessage = latestMessage;
                        this.scrollToBottom();
                    }
                    if (!(this.earliestMessage?.sentAt)) {
                        this.earliestMessage = earliestMessage;
                    } else if (new Date(earliestMessage.sentAt) < new Date(this.earliestMessage.sentAt)) {
                        this.earliestMessage = earliestMessage;
                        this.scrollToPrevious();
                    }

                    const messages: CaseMessageEvent[] = events.filter(event => event instanceof CaseMessageEvent) as CaseMessageEvent[];
                    const unreadMessages = messages.filter(message => !message.readAt && message.sentBy?.id !== this.currentUser?.id);
                    unreadMessages.forEach(message => this.readMessage(message.id));
                });
            }
        });

        this.keyboard.onKeyboardDidShow().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => {
            this.scrollToBottom();
        });
    }

    public readMessage(id: string) {
        let subject = this.readRequests[id];
        if (!subject && this.conversation.assignee && this.conversation.assignee.id === this.currentUser.id) {
            this.readRequests[id] = subject = new Subject<void>();
            this.caseMessaging.readMessage(this.conversation.id, id).then(() => subject.complete());
        }
        if (!subject && this.conversation?.createdBy?.id === this.currentUser.id) {
            this.readRequests[id] = subject = new Subject<void>();
            this.caseMessaging.readMessage(this.conversation.id, id).then(() => subject.complete());
        }
    }

    private async androidTakePhoto() {
        this.biometric.cameraTriggered();

        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            targetWidth: 1280,
            targetHeight: 1280,
        };

        this.camera.getPicture(options).then(async (tempFile: string) => {
            const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
            const tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
            const directory = await this.file.resolveDirectoryUrl(tempBaseFilesystemPath);
            const file = await this.file.getFile(directory, tempFilename, {});

            const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = event => resolve(event.target.result as ArrayBuffer);
                fileReader.readAsArrayBuffer(input);
            });

            file.file(async (resFile: IFile) => {
                const buffer = await getArrayBufferForFile(resFile);

                this.attachment = new WebFile([buffer], resFile.name, { lastModified: Date.now() });

                this.biometric.cameraCompleted();

                this.sendMessage();

                if (this.actionSheet) {
                    this.actionSheet.dismiss();
                }
            });
        });
    }

    private selectPhoto() {
        if (this.platform.is('android')) {
            this.biometric.cameraTriggered();
        }
        this.imageInput.click();
    }

    private presentAddImageAction() {
        this.actionSheetController.create({
            buttons: [{
                text: 'Photo Library',
                icon: 'image-outline',
                handler: () => this.selectPhoto()
            }, {
                text: 'Take Photo',
                icon: 'camera-outline',
                handler: () => this.androidTakePhoto()
            }]
        }).then(async (actionSheet) => {
            this.actionSheet = actionSheet;
            await actionSheet.present();
        });
    }

    public async clickImageUpload() {
        if (this.platform.is('android')) {
            await this.presentAddImageAction();
        } else {
            this.selectPhoto();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('conversation' in changes) {
            // this.scrollToBottom();
            this.conversationChanged.next(changes.conversation.currentValue);
        }
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];
        if (this.platform.is('android')) {
            this.biometric.cameraCompleted();
        }
        this.sendMessage();
    }

    public goToUserProfile() {
        this.router.navigate(['/message-user-detail']);
    }

    public scrollToBottom() {
        if (!this.wrapperRef || !this.scrollableRef) { return; }

        setTimeout(() => {
            const wrapper: HTMLElement = this.wrapperRef.nativeElement;
            const scrollable: HTMLElement = this.scrollableRef.nativeElement;
            wrapper.scrollTop = scrollable.clientHeight;
        }, 1);
    }

    public scrollToPrevious() {
        if (!this.wrapperRef || !this.scrollableRef) { return; }

        setTimeout(() => {
            const wrapper: HTMLElement = this.wrapperRef.nativeElement;
            const scrollable: HTMLElement = this.scrollableRef.nativeElement;
            wrapper.scrollTop = (scrollable.clientHeight - this.previousHeight);
        }, 1);
    }

    public sendMessage() {
        if (!this.attachment && !this.newMessage?.trim()) {
            return;
        }
        const message = this.newMessage.trim();
        this.newMessage = '';

        let attachments = null;
        if (this.attachment) {
            attachments = [this.attachment];
            this.attachment = null;
        }

        if (this.messageInputRef) {
            this.messageInputRef.nativeElement.focus();
        }

        this.caseMessaging.sendMessage(this.conversation.id, message, attachments || []);
    }

    public onKeyPress(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            if (!event.shiftKey && !this.platform.is('cordova')) {
                event.preventDefault();
                this.sendMessage();
            }
        }
    }

    public trackById<T extends AbstractSyncedModel>(index, item: T): any {
        return item.id;
    }

    public messageIsNewDay(first: string, second: string) {
        const firstDate = parseISO(first);
        const secondDate = parseISO(second);

        return !isSameDay(firstDate, secondDate);
    }

    public isToday(date: string) {
        return isToday(parseISO(date));
    }

    public isYesterday(date: string) {
        return isYesterday(parseISO(date));
    }

    public isThisWeek(date: string) {
        return !this.isToday(date) &&
            !this.isYesterday(date) &&
            isThisWeek(parseISO(date), {weekStartsOn: getDay(new Date().getDate() + 1)});
    }

    public isOlder(date: string) {
        return !this.isToday(date) && !this.isYesterday(date) && !this.isThisWeek(date);
    }
}
