import { AbstractModel } from './abstract-model';
import { User } from './user';

export class BranchUser extends AbstractModel {

    public branch_id: string = null;
    public users: User[] = null;

    public fill(data: Partial<BranchUser>) {
        if (data.branch_id !== undefined) {
            this.branch_id = data.branch_id;
        }
        if (data.users !== undefined) {
            this.users = User.createMany(data.users);
        }
    }

}
