import { Component } from '@angular/core';
import { MessageUserDetail } from './message-user-detail';

@Component({
    selector: 'app-tablet-message-user-detail-page',
    templateUrl: 'tablet-message-user-detail.html',
    styleUrls: ['message-user-detail.scss', 'tablet-message-user-detail.scss'],
})
export class TabletMessageUserDetailPage extends MessageUserDetail {

}
