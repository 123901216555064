import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GroupParticipantsService } from './group-participants.service';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { GroupMessagingService } from '../../services/group-messaging.service';

@Component({
    selector: 'app-new-group-message-participants-page',
    template: '',
    styleUrls: ['new-group-message-participants.scss'],
})

export abstract class NewGroupMessageParticipants implements OnInit, OnDestroy {

    public currentUser: User;
    public conversation: GroupMessageConversation = null;
    public conversationId: string = null;
    public onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();

    public newGroup: User[] = [];

    constructor(
        private groupParticipantsService: GroupParticipantsService,
        private groupMessaging: GroupMessagingService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ){
        route.params.subscribe((params: { conversation: string }) => {
            if (params.conversation) {
                if (params.conversation !== this.conversationId) {
                    this.groupParticipantsService.setExistingGroupType();
                    this.conversationId = params.conversation;
                    this.setConversation(params.conversation);
                }
            }
        });
    }

    public get searchTerm(): string {
        return this.groupParticipantsService.getSearchTerm();
    }

    public set searchTerm(value: string) {
        this.groupParticipantsService.setSearchTerm(value);
    }

    public get users(): User[] {
        return this.groupParticipantsService.getUsers();
    }

    public get internalUsers(): User[] {
        return this.groupParticipantsService.getInternalUsers();
    }

    public get externalUsers(): User[] {
        return this.groupParticipantsService.getExternalUsers();
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.newGroup = [];
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
        });
        this.userService.getUsers().subscribe(users => this.setUsers(users));
    }

    public setUsers(users: User[]) {
        this.groupParticipantsService.setUsers(users);
    }

    public addToGroup(user){
        const userIndex = this.newGroup.findIndex(selectedUser => selectedUser.id === user.id);
        if (userIndex < 0) {
            this.newGroup.push(user);
        } else {
            this.newGroup.splice(userIndex, 1);
        }
    }

    public setConversation(conversationId: string) {
        this.groupMessaging.getGroup(conversationId).subscribe((conversation) => {
            this.conversation = conversation;
            this.groupParticipantsService.setConversation(conversation);
        });
        this.groupMessaging.fetchGroup(conversationId);
    }

    public isSelected(user: User) {
        return this.newGroup.find(selectedUser => selectedUser.id === user.id);
    }

    public async goToCreateGroup(){
        if (!this.newGroup.length) {
            return;
        }
        if (this.conversationId) {
            for (const user of this.newGroup) {
                await this.groupMessaging.addParticipant(this.conversation.id, user.id);
            }
            this.newGroup = [];
            await this.router.navigate([`/my-messages/group/${this.conversationId}/info`]);
        } else {
            this.groupParticipantsService.setParticipants(this.newGroup);
            this.newGroup = [];
            await this.router.navigate([`/new-message/group/create`]);
        }
    }

}
