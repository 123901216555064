import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { AWSError, CognitoIdentityServiceProvider } from 'aws-sdk';

@Component({
    selector: 'app-forgot-password-page',
    template: '',
    styleUrls: ['forgot-password.scss'],
})

export abstract class ForgotPassword {

    public username = '';
    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;

    constructor(
        private router: Router,
        private cognitoAuth: CognitoAuthenticationService,
    ) {}

    private addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    private clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    public forgotPassword() {

        this.clearErrors();

        if (this.username === '') {
            this.addError('Username is required.');
            return;
        }

        this.isLoading = true;
        this.cognitoAuth.forgotPassword(this.username).then(forgotPasswordResponse => {
            if (forgotPasswordResponse) {
                this.router.navigate(['/forgot-password-sent']);
            } else {
                this.addError('An Unexpected Error Occurred');
                this.isLoading = false;
            }
        }).catch(error => {
            this.addError(error);
            this.isLoading = false;
        });
    }

    public ionViewWillEnter() {
        this.isLoading = false;
        this.username = '';
        this.cognitoAuth.clearForgotPassword();
    }

}
