import { Component } from '@angular/core';
import { ForgotPasswordChangePage } from './forgot-password-change';

@Component({
    selector: 'app-change-temporary-password-page',
    templateUrl: 'phone-forgot-password-change.html',
    styleUrls: ['forgot-password-change.scss', 'phone-forgot-password-change.scss'],
})
export class PhoneForgotPasswordChangePage extends ForgotPasswordChangePage {

}
