import { Component, ViewChild, ElementRef } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password-changed-page',
    template: '',
    styleUrls: ['forgot-password-changed.scss'],
})

export abstract class ForgotPasswordChanged {

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private navCtrl: NavController,
        private router: Router,
    ) {
    }

    public goToLogin() {
        this.router.navigate(['/login']);
    }

    public ionViewWillEnter() {
        if (!this.cognitoAuth.canShowForgotPasswordSuccess()) {
            this.router.navigate(['/forgot-password']);
        }
    }

}
