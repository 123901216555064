import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-message-group-page',
    template: '',
    styleUrls: ['message-group.scss'],
})

export abstract class MessageGroup {

    users = [
        {
            "title": "Dr Cate Williams",
            "subTitle": "GP",
            "image":"/assets/icon/cate-williams.svg"
        },
        {
            "title": "Dr Chris McCloy",
            "subTitle": "GP ",
            "image":"/assets/icon/drTaylorProfileImage.svg"
        },
        {
            "title": "Dr Darren Johnston",
            "subTitle": "GP",
            "image":"/assets/icon/darrenJohnston.svg"
        },
    ];

    currentMessenger = [
        {
            "user": {
                "id": 1,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "OK thanks for letting me know. I will change the prescription to azithromycin. Will you mark it as unfilled and I'll issue a new one?",
                "attachment": "",
                "timeDate": "11:27",
                "status": "opened-message",
            }
        },
        {
            "user": {
                "id": 2,
                "image": "/assets/icon/drTaylorProfileImage.svg",
                "name": "Dr Taylor",
            },
            "messageObject": {
                "content": "",
                "attachment":"/assets/icon/messenger-image-example.jpg",
                "timeDate": "11:38",
                "status": "sent-message",
            }
        },
        {
            "user": {
                "id": 1,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "OK thanks for letting me know. I will change the prescription to azithromycin. Will you mark it as unfilled and I'll issue a new one?",
                "attachment": "",
                "timeDate": "11:27",
                "status": "sent-message",
            }
        },
        {
            "user": {
                "id": 1,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "OK thanks for letting me know. I will change the prescription to azithromycin. Will you mark it as unfilled and I'll issue a new one?",
                "attachment": "",
                "timeDate": "11:27",
                "status": "opened-message",
            }
        },{
            "user": {
                "id": 1,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "OK thanks for letting me know. I will change the prescription to azithromycin. Will you mark it as unfilled and I'll issue a new one?",
                "attachment": "",
                "timeDate": "11:27",
                "status": "opened-message",
            }
        },
        {
            "user": {
                "id": 1,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "Yes of course",
                "attachment": "",
                "timeDate": "11:30",
                "status": "new-message",
            }
        },
        {
            "user": {
                "id": 2,
                "image": "/assets/icon/absoluteCommunityPharmacy.svg",
                "name": "Absolute Community Pharmacy"
            },
            "messageObject": {
                "content": "Yes of course",
                "attachment": "",
                "timeDate": "11:30",
                "status": "new-message",
            }
        }
    ];

    // currentMessenger = [];

    constructor(private router: Router){}

    public goToGroupDetail(){
        this.router.navigate(['/message-group-detail']);
    }

    public goToGroupMemeberDetail(){
        this.router.navigate(['/message-group-participant']);
    }

}
