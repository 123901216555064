import { Component } from '@angular/core';
import { MessageGroupDetail } from './message-group-detail';

@Component({
    selector: 'app-tablet-message-group-detail-page',
    templateUrl: 'tablet-message-group-detail.html',
    styleUrls: ['message-group-detail.scss', 'tablet-message-group-detail.scss'],
})
export class TabletMessageGroupDetailPage extends MessageGroupDetail {

}
