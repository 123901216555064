import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { User } from '../../services/models/user';
import { UserService } from '../../services/user.service';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-message-group-participant-page',
    template: '',
    styleUrls: ['message-group-participant.scss'],
})

export abstract class MessageGroupParticipant implements OnDestroy, OnInit {

    public conversation: GroupMessageConversation = null;
    private conversationId: string = null;
    public user: User = null;
    private userId: string = null;
    public adminParticipant = false;
    private currentUser: User = null;
    public currentUserAdmin = false;
    public onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();

    constructor(
        public alertController: AlertController,
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private navCtrl: NavController,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ){
        route.params.subscribe((params: { conversation: string, user: string }) => {
            if (params.conversation !== this.conversationId) {
                this.conversationId = params.conversation;
                this.setConversation(params.conversation);
            }
            if (params.user !== this.userId) {
                this.userId = params.user;
                this.setUser(params.user);
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
            if (me && this.conversation) {
                const currentUserParticipant = this.conversation.members.find(participant => participant.id === this.currentUser.id);
                this.currentUserAdmin = currentUserParticipant?.admin;
            }
        });
    }

    public setConversation(conversationId: string) {
        this.groupMessaging.getGroup(conversationId).subscribe((conversation) => {
            this.conversation = conversation;
            if (conversation instanceof GroupMessageConversation) {
                const currentParticipant = conversation.members.find(participant => participant.id === this.userId);
                this.adminParticipant = currentParticipant?.admin;

                if (this.currentUser) {
                    const currentUserParticipant = conversation.members.find(participant => participant.id === this.currentUser.id);
                    this.currentUserAdmin = currentUserParticipant?.admin;
                }
            }
        });
        this.groupMessaging.fetchGroup(conversationId).then(() => {
            this.groupMessaging.fetchEvents(conversationId, {
                before: new Date().toISOString()
            });
        });
    }

    public setUser(userId: string) {
        this.userService.getUser(userId).subscribe((user) => {
            this.user = user;
        });
        this.userService.fetchUser(userId);
    }

    public async messageParticipant() {
        if (this.user) {
            const directMessage = await this.directMessaging.newConversation(this.user.id);
            await this.router.navigate(['/my-messages/' + directMessage.id]);
        }
    }

    public async makeParticipantAdmin() {
        if (this.user && this.conversation) {
            await this.groupMessaging.makeAdmin(this.conversation.id, this.user.id);
        }
    }

    public async removeParticipantAdmin() {
        if (this.user && this.conversation) {
            await this.groupMessaging.removeAdmin(this.conversation.id, this.user.id);
        }
    }

    public async removeParticipant() {
        if (this.user && this.conversation) {
            this.navCtrl.setDirection('back');
            await this.groupMessaging.removeParticipant(this.conversation.id, this.user.id);
            await this.router.navigate(['/my-messages/group/' + this.conversation.id + '/info']);
        }
    }

    public get isCurrentUser() {
        return this.currentUser?.id === this.user?.id;
    }

    public async navigateBack() {
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        await this.router.navigate([`/my-messages/group/${this.conversationId}/info`]);
    }

}
