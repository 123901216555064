import { Component } from '@angular/core';
import { MyMessages } from './my-messages';
import { DirectMessagingService } from '../../services/direct-messaging.service';

@Component({
    selector: 'app-tablet-my-messages-page',
    templateUrl: 'tablet-my-messages.html',
    styleUrls: ['my-messages.scss', 'tablet-my-messages.scss'],
})
export class TabletMyMessagesPage {

    constructor(
        private directMessaging: DirectMessagingService
    ) {
    }

    public ionViewWillEnter() {
        this.directMessaging.fetchAllConversations();
        console.log('Fetching conversations');
    }

}
