import { Component } from '@angular/core';
import { ForgotPasswordChangePage } from './forgot-password-change';

@Component({
    selector: 'app-change-temporary-password-page',
    templateUrl: 'tablet-forgot-password-change.html',
    styleUrls: ['forgot-password-change.scss', 'tablet-forgot-password-change.scss'],
})
export class TabletForgotPasswordChangePage extends ForgotPasswordChangePage {

}
