import { BrowserCaseMessagingService } from './browser.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { PrivateSettingsService } from '../private-settings.service';
import { UserService } from '../user.service';
import { merge } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { CaseConversation } from '../models/case-conversation';
import { OrganisationService } from '../organisation.service';
import { CreateCaseRequest } from '@scaffold/mediccoms-api-client/requests';
import { CaseOpenedEvent } from '../models/case-conversation-events/case-opened-event';
import { CaseResolvedEvent } from '../models/case-conversation-events/case-resolved-event';
import { HttpEventType } from '@angular/common/http';
import { CaseMessageEvent } from '../models/case-conversation-events/case-message-event';
import { v4 as uuid } from 'uuid';
import { CaseEvent } from '../models/case-conversation-events/case-event';
import { Router } from '@angular/router';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { WebFile } from '../../helpers/web-file';


export class MobileCaseMessagingService extends BrowserCaseMessagingService {

    private offlineProcessing: boolean = null;

    constructor(
        public api: ApiClientService,
        public cordovaFile: CordovaFile,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public router: Router,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, cordovaFile, database, organisationService, router, rtms, storage, user);
    }

    public loadFromMemory(): Promise<any> {
        return new Promise(async (resolve) => {
            const eventsRaw = await this.storage.get('case.events');
            if (eventsRaw) {
                const events = JSON.parse(eventsRaw);
                for (const eventData of events) {
                    switch (eventData.type) {
                        case 'opened':
                            eventData.openedBy = eventData.openedBy ? this.user.users.findById(eventData.openedBy) : null;
                            break;
                        case 'message':
                            eventData.sentBy = eventData.sentBy ? this.user.users.findById(eventData.sentBy) : null;
                            break;
                        case 'accepted':
                            eventData.acceptedBy = eventData.acceptedBy ? this.user.users.findById(eventData.acceptedBy) : null;
                            break;
                        case 'assigned':
                            eventData.assignedBy = eventData.assignedBy ? this.user.users.findById(eventData.assignedBy) : null;
                            eventData.newAssignee = eventData.newAssignee ? this.user.users.findById(eventData.newAssignee) : null;
                            // tslint:disable-next-line:max-line-length
                            eventData.previousAssignee = eventData.previousAssignee ? this.user.users.findById(eventData.previousAssignee) : null;
                            break;
                        case 'resolved':
                            eventData.resolvedBy = eventData.resolvedBy ? this.user.users.findById(eventData.resolvedBy) : null;
                            break;
                    }
                    const event = CaseConversation.createEvent(eventData);
                    this.events.store(event);
                }
            }

            const conversationsRaw = await this.storage.get('case.conversations');
            if (conversationsRaw) {
                const conversations = JSON.parse(conversationsRaw);
                for (const conversationData of conversations) {
                    const conversation = CaseConversation.createOne(conversationData);
                    conversation.events = this.events.filterByCaseId(conversation.id);
                    // tslint:disable-next-line:max-line-length
                    conversation.recipientBranch = conversationData.recipientBranch ? this.organisationService.branches.findById(conversationData.recipientBranch) : null;
                    // tslint:disable-next-line:max-line-length
                    conversation.senderBranch = conversationData.senderBranch ? this.organisationService.branches.findById(conversationData.senderBranch) : null;
                    conversation.createdBy = this.user.users.findById(conversationData.createdBy);
                    conversation.assignee = conversationData.assignee ? this.user.users.findById(conversationData.assignee) : null;
                    if (!conversation.resolvedWithinDays(30)) {
                        this.conversations.store(conversation);
                    }
                }
            }

            this.setupSubscriptions();
            resolve(undefined);
            this.isReady = true;
            this.readySubject.next(true);
        });
    }

    private persistConversationsMemory() {
        const conversations = this.conversations.all();
        const time = Date.now();
        this.storage.set('case.conversations', JSON.stringify(conversations))
            .then(() => console.log('[CASE CONVERSATIONS STORED]', conversations.length, Date.now() - time));
    }

    private persistEventsMemory() {
        const events = this.events.all();
        const time = Date.now();
        this.storage.set('case.events', JSON.stringify(events))
            .then(() => console.log('[CASE EVENTS STORED]', events.length, Date.now() - time));
    }

    private setupSubscriptions(): void {
        merge(
            this.conversations.onInsert(),
            this.conversations.onUpdate(),
            this.conversations.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistConversationsMemory();
        });

        merge(
            this.events.onInsert(),
            this.events.onUpdate(),
            this.events.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistEventsMemory();
        });
    }

    public async cancelCase(caseId: string): Promise<boolean> {
        const conversation = this.conversations.findById(caseId);

        let cancelled;
        if (conversation.isOffline) {
            cancelled = true;
        } else {
            const response = await this.api.cases.cancelCase(caseId).toPromise();
            cancelled = response.cancelled;
        }

        if (cancelled && conversation) {
            this.conversations.remove(conversation);
        }

        return Promise.resolve(cancelled);
    }

    public async createCase(recipientId: string, patientId: string): Promise<CaseConversation> {
        const createCaseRequest: CreateCaseRequest = {
            recipient_id: recipientId,
            patient_id: patientId,
        };

        try {
            const response = await this.api.cases.createCase(createCaseRequest).toPromise();

            if (response.case) {
                const conversation = this.conversations.findById(response.case.id) || CaseConversation.createOne({id: response.case.id});
                conversation.fillFromApi(response.case);
                this.conversations.store(conversation);
                return Promise.resolve(conversation);
            }
        } catch (e) {
            console.log('failed - check if offline and then save locally');
            return new Promise(resolve => {
                this.organisationService.getBranch(recipientId).pipe(
                    first(),
                ).subscribe(recipientBranch => {
                    const id = `offline_${uuid()}`;
                    const conversation = CaseConversation.createOne({
                        id,
                        patientId,
                        recipientBranch,
                        isOffline: true,
                        offlineId: id,
                        createdBy: this.currentUser,
                        createdAt: new Date().toISOString(),
                    });

                    this.conversations.store(conversation);

                    resolve(conversation);
                });
            });
        }

        return Promise.resolve(undefined);
    }

    public async openCase(caseId: string): Promise<CaseConversation> {
        try {
            const {event = null} = await this.api.cases.openCase(caseId).toPromise();

            if (event) {
                const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
                const storeEvent = this.events.findById(event.id) || CaseOpenedEvent.createOne({
                    id: event.id,
                    type: event.type,
                    conversation: conversation.id,
                });
                storeEvent.fillFromApi(event);
                storeEvent.conversation = conversation.id;
                this.events.store(storeEvent);
                conversation.addEvent(storeEvent);
                conversation.openedAt = event.sent_at;
                this.conversations.store(conversation);

                return Promise.resolve(conversation);
            }
        } catch (e) {
            const conversation = this.conversations.findById(caseId);
            conversation.openedAt = new Date().toISOString();
            const id = `offline_${uuid()}`;
            const storeEvent = CaseOpenedEvent.createOne({
                id,
                type: 'opened',
                conversation: conversation.id,
                openedBy: this.currentUser,
                sentAt: conversation.openedAt,
                isOffline: true,
                offlineId: id,
            });

            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);
            this.events.store(storeEvent);
            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async resolveCase(caseId: string): Promise<CaseConversation> {
        try {
            const {event = null} = await this.api.cases.resolveCase(caseId).toPromise();

            if (event) {
                const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
                const storeEvent = this.events.findById(event.id) || CaseResolvedEvent.createOne({
                    id: event.id,
                    type: event.type,
                    conversation: conversation.id,
                });
                storeEvent.fillFromApi(event);
                storeEvent.conversation = conversation.id;
                this.events.store(storeEvent);
                conversation.addEvent(storeEvent);
                conversation.resolvedAt = event.resolved_at;
                this.conversations.store(conversation);

                return Promise.resolve(conversation);
            }
        } catch (e) {
            const conversation = this.conversations.findById(caseId);
            conversation.resolvedAt = new Date().toISOString();
            const id = `offline_${uuid()}`;
            const storeEvent = CaseResolvedEvent.createOne({
                id, // 'offline_event'
                type: 'resolved',
                conversation: conversation.id,
                resolvedBy: this.currentUser,
                sentAt: conversation.resolvedAt,
                isOffline: true,
                offlineId: id,
            });

            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);
            this.events.store(storeEvent);
            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async sendMessage(caseId: string, message: string, attachments: File[]): Promise<boolean> {
        try {
            const attachmentIds: string[] = [];

            if (attachments) {
                for (const attachment of attachments) {
                    const response = await new Promise<string>((resolve, reject) => {
                        this.api.attachments.uploadImage(attachment).subscribe(result => {
                            if (result.type === HttpEventType.Response) {
                                resolve(result.body.image_id);
                            } else {
                                reject('error');
                            }
                        });
                    });
                    attachmentIds.push(response);
                }
            }

            const {event = null} = await this.api.cases.sendMessage(caseId, {
                message,
                attachments: attachmentIds
            }).toPromise();

            if (event) {
                const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
                const storeEvent = this.events.findById(event.id) || CaseMessageEvent.createOne({
                    id: event.id,
                    type: event.type,
                    conversation: conversation.id,
                });
                storeEvent.fillFromApi(event);
                conversation.addEvent(storeEvent);
                this.conversations.store(conversation);

                storeEvent.conversation = conversation.id;
                return Promise.resolve(!!this.events.store(storeEvent));
            }
        } catch (e) {
            const conversation = this.conversations.findById(caseId);
            const id = `offline_${uuid()}`;

            const localAttachments = [];

            for (const attachment of attachments) {
                const de = await this.cordovaFile.createDir(this.cordovaFile.dataDirectory, 'offline', true);
                const fileName = uuid() + '_' + attachment.name;
                const file = await this.cordovaFile.writeFile(de.toURL(), fileName, attachment);
                localAttachments.push(file.name);
            }

            const storeEvent = CaseMessageEvent.createOne({
                id,
                type: 'message',
                conversation: conversation.id,
                attachments: localAttachments,
                messageBody: message,
                sentBy: this.currentUser,
                sentAt: new Date().toISOString(),
                isOffline: true,
                offlineId: id,
            });

            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);
            return Promise.resolve(!!this.events.store(storeEvent));
        }

        return Promise.resolve(false);
    }

    private async submitOfflineConversation(conversation: CaseConversation): Promise<CaseConversation> {
        const offlineId = conversation.offlineId;
        return new Promise(async (resolve, reject) => {
            const createCaseRequest: CreateCaseRequest = {
                recipient_id: conversation.recipientBranch.id,
                patient_id: conversation.patientId,
            };

            try {
                const response = await this.api.cases.createCase(createCaseRequest).toPromise();
                const onlineCase = this.conversations.findById(response.case.id);
                if (onlineCase) {
                    onlineCase.fillFromApi(response.case);
                    onlineCase.isOffline = false;
                    onlineCase.offlineId = offlineId;

                    for (const event of conversation.events) {
                        event.conversation = onlineCase.id;
                        this.events.store(event);
                        onlineCase.addEvent(event);
                    }

                    this.conversations.store(onlineCase);
                    this.conversations.remove(conversation);

                    if (this.router.url === '/cases/' + offlineId) {
                        await this.router.navigate(['cases', onlineCase.id]);
                    }

                    resolve(onlineCase);
                } else {
                    conversation.fillFromApi(response.case);
                    conversation.isOffline = false;
                    conversation.offlineId = offlineId;

                    for (const event of conversation.events) {
                        event.conversation = conversation.id;
                        this.events.store(event);
                    }

                    this.conversations.store(conversation);

                    if (this.router.url === '/cases/' + offlineId) {
                        await this.router.navigate(['cases', conversation.id]);
                    }

                    resolve(conversation);
                }
            } catch (e) {
                console.error(e);
                reject(false);
            }
        });
    }

    private async submitOfflineEvent(event: CaseEvent): Promise<CaseEvent> {
        const offlineId = event.offlineId;
        let conversation = await this.getOfflineCase(event.conversation);
        if (!conversation) {
            conversation = await this.getCasePromise(event.conversation);
            if (!conversation) {
                return;
            }
        }

        return new Promise(async (resolve, reject) => {
            try {
                let response = null;
                if (event instanceof CaseMessageEvent) {
                    const attachmentIds: string[] = [];

                    if (event.attachments) {
                        const dir = await this.cordovaFile.resolveDirectoryUrl(this.cordovaFile.dataDirectory);
                        const dirEntity = await this.cordovaFile.getDirectory(dir, 'offline', {});

                        for (const attachment of event.attachments) {
                            const fe = await this.cordovaFile.getFile(dirEntity, attachment, {});
                            const attachmentResponse = await new Promise<string>((resolveUpload) => {

                                // tslint:disable-next-line:max-line-length
                                const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolveArrayBuffer) => {
                                    const fileReader = new FileReader();
                                    // tslint:disable-next-line:max-line-length
                                    fileReader.onload = fileReaderEvent => resolveArrayBuffer(fileReaderEvent.target.result as ArrayBuffer);
                                    fileReader.readAsArrayBuffer(input);
                                });

                                fe.file(async (iFile: IFile) => {
                                    const buffer = await getArrayBufferForFile(iFile);
                                    const file = new WebFile([buffer], iFile.name, { lastModified: Date.now() });
                                    this.api.attachments.uploadImage(file).subscribe(result => {
                                        if (result.type === HttpEventType.Response) {
                                            resolveUpload(result.body.image_id);
                                        }
                                    });
                                });
                            });
                            attachmentIds.push(attachmentResponse);
                        }
                        console.log('UPLOADED: ', attachmentIds);
                    }
                    response = await this.api.cases.sendMessage(conversation.id, {
                        message: event.messageBody,
                        attachments: attachmentIds
                    }).toPromise();
                } else if (event instanceof CaseOpenedEvent) {
                    response = await this.api.cases.openCase(conversation.id).toPromise();
                } else if (event instanceof CaseResolvedEvent) {
                    response = await this.api.cases.resolveCase(conversation.id).toPromise();
                }

                if (response) {
                    const onlineEvent = this.events.findById(response.event.id);
                    if (onlineEvent) {
                        onlineEvent.fillFromApi(response.event);
                        onlineEvent.isOffline = false;
                        onlineEvent.offlineId = offlineId;
                        this.events.store(onlineEvent);
                        this.events.remove(event);
                        resolve(event);
                    } else {
                        event.fillFromApi(response.event);
                        event.isOffline = false;
                        event.offlineId = offlineId;
                        this.events.store(event);
                        resolve(event);
                    }
                    // if Message delete FileEntry
                }

            } catch (e) {
                console.error(e);
                reject(false);
            }
        });
    }

    public async completeOfflineActions(): Promise<boolean> {
        if (this.offlineProcessing) {
            return;
        }

        this.offlineProcessing = true;

        const conversations = await this.getOfflineCases();
        const events = await this.getOfflineCaseEvents();

        for (const conversation of conversations) {
            await this.submitOfflineConversation(conversation);
        }

        for (const event of events) {
            await this.submitOfflineEvent(event);
        }

        this.offlineProcessing = false;

        return Promise.resolve(true);
    }

    public cancelOfflineActions(): Promise<boolean> {
        this.offlineProcessing = false;
        return Promise.resolve(true);
    }

}
