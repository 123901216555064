import { Component } from '@angular/core';
import { ChangedPasswordPage } from './changed-password';

@Component({
    selector: 'app-tablet-changed-password-page',
    templateUrl: 'tablet-changed-password.html',
    styleUrls: ['changed-password.scss', 'tablet-changed-password.scss'],
})
export class TabletChangedPasswordPage extends ChangedPasswordPage {

}
