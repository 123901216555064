import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab';

@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.html',
    styleUrls: ['tabs.scss'],
})
export class TabsComponent implements AfterContentInit {

    @ContentChildren(TabComponent) public tabs: QueryList<TabComponent>;

    @Output('tabChanged') public tabChanged: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();

    public ngAfterContentInit(): void {
        this.activateTab(this.tabs.first);
    }

    public activateTab(tab: TabComponent) {
        this.tabs.forEach(item => item.active = false);
        tab.active = true;
        this.tabChanged.emit(tab);
    }

    public selectTab(tab: TabComponent) {
        this.activateTab(tab);
    }

}
