import { Component } from '@angular/core';
import { ChangePasswordPage } from './change-password';

@Component({
    selector: 'app-change-password-page',
    templateUrl: 'tablet-change-password.html',
    styleUrls: ['change-password.scss', 'tablet-change-password.scss'],
})
export class TabletChangePasswordPage extends ChangePasswordPage {

}
