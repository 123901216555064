import { Component } from '@angular/core';
import { ForgotPasswordChanged } from './forgot-password-changed';

@Component({
    selector: 'app-phone-forgot-password-changed-page',
    templateUrl: 'phone-forgot-password-changed.html',
    styleUrls: ['forgot-password-changed.scss', 'phone-forgot-password-changed.scss'],
})
export class PhoneForgotPasswordChangedPage extends ForgotPasswordChanged {

}
