import { NgModule } from '@angular/core';
import { AppNewInternalMessagePage } from './internal/internal';
import { AppNewExternalMessagePage } from './external/external';
import { NewMessageService } from './new-message.service';
import { ComponentsModule } from '../../components/components.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        IonicModule,
    ],
    providers: [
        NewMessageService,
    ],
    declarations: [
        AppNewExternalMessagePage,
        AppNewInternalMessagePage,
    ],
})
export class NewMessageModule {

}
