import { ActivatedRoute, Router } from '@angular/router';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-case-instructions-page',
    template: '',
    styleUrls: ['instructions.scss'],
})
export abstract class InstructionsPage {

    constructor(
        private navCtrl: NavController,
        private router: Router,
        private routerOutlet: IonRouterOutlet,
    ) {
    }

    public close() {
        if (this.routerOutlet.canGoBack()) {
            this.navCtrl.pop();
        } else {
            this.navCtrl.setDirection('back');
            this.router.navigate(['cases']);
        }
    }

    public continue() {
        this.navCtrl.setDirection('forward');
        this.router.navigate(['cases', 'open']);
    }

}
