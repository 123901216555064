import { Component } from '@angular/core';
import { MyCasesPage } from './my-cases';

@Component({
    selector: 'app-phone-my-cases-page',
    templateUrl: 'phone-my-cases.html',
    styleUrls: ['my-cases.scss', 'phone-my-cases.scss'],
})
export class PhoneMyCasesPage extends MyCasesPage {

}
