import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupParticipantsService } from '../new-group-message-participants/group-participants.service';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '../../services/models/user';
import { WebFile } from '../../helpers/web-file';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { BiometricService } from '../../services/biometric.service';

@Component({
    selector: 'app-new-group-message-name-page',
    template: '',
    styleUrls: ['new-group-message-name.scss'],
})

export abstract class NewGroupMessageName implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('imageInput', { static: false }) public imageUploadRef: ElementRef<HTMLInputElement>;
    public imageInput: HTMLInputElement;

    public conversationName = '';
    public currentUser: User;
    public onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();
    public actionSheet: HTMLIonActionSheetElement = null;
    public imageId: string = null;

    constructor(
        private actionSheetController: ActionSheetController,
        private biometric: BiometricService,
        private camera: Camera,
        private file: CordovaFile,
        private groupParticipantsService: GroupParticipantsService,
        private groupMessaging: GroupMessagingService,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ){}

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

    public ngOnDestroy(): void {
        this.imageId = null;
        this.conversationName = '';
        this.groupParticipantsService.setIcon(null);
        this.groupParticipantsService.setNewConversationName(this.conversationName);
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        if (!this.groupParticipantsService.getParticipants().length) {
            this.router.navigate(['new-message', 'group']);
        }
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
        });
    }

    public get users(): User[] {
        return this.groupParticipantsService.getParticipants();
    }

    public async createNewGroup() {
        if (!this.conversationName) {
            return;
        }
        this.groupParticipantsService.setNewConversationName(this.conversationName);
        const group = await this.groupParticipantsService.createNewConversation();
        await this.router.navigate([`/my-messages/group/${group.id}`]);
    }

    public async selectIcon() {
        if (this.platform.is('android')) {
            await this.presentAddImageAction();
        } else {
            this.selectPhoto();
        }
    }

    private selectPhoto() {
        if (this.platform.is('android')) {
            this.biometric.cameraTriggered();
        }
        this.imageInput.click();
    }

    public async onIconAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        if (this.platform.is('android')) {
            this.biometric.cameraCompleted();
        }
        this.imageId = await this.groupParticipantsService.setIcon(this.imageInput.files[0]);
    }

    private presentAddImageAction() {
        this.actionSheetController.create({
            buttons: [{
                text: 'Photo Library',
                icon: 'image-outline',
                handler: () => this.selectPhoto()
            }, {
                text: 'Take Photo',
                icon: 'camera-outline',
                handler: () => this.androidTakePhoto()
            }]
        }).then(async (actionSheet) => {
            this.actionSheet = actionSheet;
            await actionSheet.present();
        });
    }

    private async androidTakePhoto() {
        this.biometric.cameraTriggered();

        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            targetWidth: 1280,
            targetHeight: 1280,
        };

        this.camera.getPicture(options).then(async (tempFile: string) => {
            const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
            const tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
            const directory = await this.file.resolveDirectoryUrl(tempBaseFilesystemPath);
            const file = await this.file.getFile(directory, tempFilename, {});

            const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = event => resolve(event.target.result as ArrayBuffer);
                fileReader.readAsArrayBuffer(input);
            });

            file.file(async (resFile: IFile) => {
                const buffer = await getArrayBufferForFile(resFile);

                const icon = new WebFile([buffer], resFile.name, { lastModified: Date.now() });

                this.biometric.cameraCompleted();

                this.imageId = await this.groupParticipantsService.setIcon(icon);

                if (this.actionSheet) {
                    this.actionSheet.dismiss();
                }
            });
        });
    }

}
