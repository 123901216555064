import { DirectMessagingService } from './abstract.service';
import { DirectMessageConversation } from '../models/direct-message-conversation';
import { SearchDirectMessageEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { DirectMessageEvent } from '../models/direct-message-event';
import { merge, Observable, Subject } from 'rxjs';
import { PendingDirectMessage } from '../models/pending-direct-message';
import { DirectMessageStore } from '../stores/direct-message.store';
import { DirectMessageEventStore } from '../stores/direct-message-event.store';
import { debounceTime, filter, first } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { User } from '../models/user';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';

export class BrowserDirectMessagingService extends DirectMessagingService {

    protected conversations = new DirectMessageStore();
    protected events = new DirectMessageEventStore();
    protected isReady = false;
    protected readySubject: Subject<boolean> = new Subject<boolean>();

    protected currentUser: User = null;

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, database, rtms, storage, user);

        this.setupService();
    }

    protected setupService() {
        this.user.getMe().subscribe(me => this.currentUser = me);

        this.rtms.directMessages.onConversationCreated().subscribe(rtmsEvent => {
            const recipientData = rtmsEvent.data.participants.find(rtmsUser => rtmsUser.id !== this.currentUser.id);
            const recipient = User.createFromConversationParticipant(recipientData);

            const conversation = this.conversations.findById(rtmsEvent.target_id) || DirectMessageConversation.createOne({
                id: rtmsEvent.target_id,
                recipient,
            });
            conversation.fillFromRtms(rtmsEvent);

            this.conversations.store(conversation);
        });

        this.rtms.directMessages.onMessage().subscribe(rtmsEvent => {
            const conversation = this.conversations.findById(rtmsEvent.target_id) || DirectMessageConversation.createOne({
                id: rtmsEvent.target_id
            });
            const event = this.events.findById(rtmsEvent.data.message_id) || DirectMessageEvent.createOne({
                id: rtmsEvent.data.message_id,
                conversation: conversation.id,
            });

            event.fillFromRtms(rtmsEvent);
            this.events.store(event);
            conversation.addEvent(event);
            this.conversations.store(conversation);
        });

        this.rtms.directMessages.onMessageDelivered().subscribe(rtmsEvent => {
            const conversation = this.conversations.findById(rtmsEvent.target_id) || DirectMessageConversation.createOne({
                id: rtmsEvent.target_id
            });
            const event = this.events.findById(rtmsEvent.data.message_id) || DirectMessageEvent.createOne({
                id: rtmsEvent.data.message_id,
                conversation: conversation.id,
            });
            event.deliveredAt = rtmsEvent.timestamp;
            this.events.store(event);
            conversation.addEvent(event);
            this.conversations.store(conversation);
        });

        this.rtms.directMessages.onMessageRead().subscribe(rtmsEvent => {
            const conversation = this.conversations.findById(rtmsEvent.target_id) || DirectMessageConversation.createOne({
                id: rtmsEvent.target_id
            });
            const event = this.events.findById(rtmsEvent.data.message_id) || DirectMessageEvent.createOne({
                id: rtmsEvent.data.message_id,
                conversation: conversation.id,
            });
            event.readAt = rtmsEvent.timestamp;
            this.events.store(event);
            conversation.addEvent(event);
            this.conversations.store(conversation);
        });
    }

    public async newConversation(userId: string): Promise<DirectMessageConversation> {
        let conversation = this.conversations.findByUserId(userId);
        if (!conversation) {
            const response = await this.api.directMessages.createConversation({
                recipient_id: userId
            }).toPromise();
            conversation = DirectMessageConversation.createOne({id: response.direct_message.id});
            conversation.fillFromApi(response.direct_message);
        }

        return Promise.resolve(conversation);
    }

    public async fetchAllConversations(): Promise<DirectMessageConversation[]> {
        let currentPage = 1;
        let perPage = 0;
        let totalItems = 0;

        do {
            const response = await this.api.directMessages.allConversations({
                page: currentPage,
            }).toPromise();
            if (!response) {
                return;
            }
            for (const directMessage of response.direct_messages) {
                currentPage = response.pagination.current_page;
                perPage = response.pagination.per_page;
                totalItems = response.pagination.total_items;
                const conversation = this.conversations.findById(directMessage.id) || DirectMessageConversation.createOne({
                    id: directMessage.id
                });
                conversation.fillFromApi(directMessage);
                this.conversations.store(conversation);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        return this.conversations.all();
    }

    public async fetchEvent(conversationId: string, eventId: string): Promise<DirectMessageEvent> {
        const {event = null} = await this.api.directMessages.getEvent(conversationId, eventId).toPromise();
        const conversation = this.conversations.findById(conversationId) || DirectMessageConversation.createOne({
            id: conversationId
        });
        const storedEvent = this.events.findById(event.id) || DirectMessageEvent.createOne({
            id: event.id,
            conversation: conversation.id,
        });
        storedEvent.fillFromApi(event);
        this.events.store(storedEvent);
        conversation.addEvent(storedEvent);
        this.conversations.store(conversation);

        return Promise.resolve(storedEvent);
    }

    public async fetchAllEvents(conversationId: string, request: SearchDirectMessageEventsRequest): Promise<DirectMessageEvent[]> {
        const {events = []} = await this.api.directMessages.searchEvents(conversationId, request).toPromise();

        const conversation = this.conversations.findById(conversationId) || DirectMessageConversation.createOne({
            id: conversationId
        });
        const storedEvents: DirectMessageEvent[] = [];

        for (const apiEvent of events) {
            const event = this.events.findById(apiEvent.id) || DirectMessageEvent.createOne({
                id: apiEvent.id,
                conversation: conversation.id,
            });

            event.fillFromApi(apiEvent);
            storedEvents.push(event);
            this.events.store(event);
        }
        conversation.addEvents(storedEvents);
        this.conversations.store(conversation);

        return Promise.resolve(storedEvents);
    }

    public async fetchConversation(conversationId: string): Promise<DirectMessageConversation> {
        const response = await this.api.directMessages.getConversation(conversationId).toPromise();
        const conversation = this.conversations.findById(conversationId) || DirectMessageConversation.createOne({id: conversationId});
        conversation.fillFromApi(response.direct_message);
        return this.conversations.store(conversation);
    }

    public getAllConversations(): Observable<DirectMessageConversation[]> {
        return new Observable<DirectMessageConversation[]>(observer => {
            const conversations = this.conversations;
            observer.next(conversations.all());

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(this.conversations.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    public getAllEvents(conversationId: string): Observable<DirectMessageEvent[]> {
        return new Observable<DirectMessageEvent[]>(observer => {
            const events = this.events;
            const filteredEvents = events.filterByDirectMessageId(conversationId);

            observer.next(filteredEvents.sort((a, b) => {
                return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
            }));

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => {
                    if (event.conversation !== undefined) {
                        return event.conversation === conversationId;
                    }
                    return false;
                }),
                debounceTime(10),
            ).subscribe(() => {
                observer.next(this.events.filterByDirectMessageId(conversationId).sort((a, b) => {
                    return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
                }));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getAllMessages(): Observable<DirectMessageEvent[]> {
        return new Observable<DirectMessageEvent[]>(observer => {
            const events = this.events;
            observer.next(events.all());

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                debounceTime(10),
            ).subscribe(() => {
                observer.next(this.events.all());
            });

            return () => subscription.unsubscribe();
        });
    }

    getAllPendingMessages(conversationId: string): Observable<PendingDirectMessage[]> {
        return new Observable<PendingDirectMessage[]>();
    }

    public getConversation(conversationId: string): Observable<DirectMessageConversation> {
        return new Observable<DirectMessageConversation>(observer => {
            const conversations = this.conversations;
            observer.next(conversations.findById(conversationId));

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                filter(conversation => conversation.id === conversationId),
            ).subscribe(() => {
                observer.next(this.conversations.findById(conversationId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getEvent(conversationId: string, eventId: string): Observable<DirectMessageEvent> {
        return new Observable<DirectMessageEvent>(observer => {
            this.getConversation(conversationId).subscribe(() => {
                const event = this.events;
                observer.next(event.findById(eventId));

                const subscription = merge(
                    event.onInsert(),
                    event.onUpdate(),
                    event.onRemove(),
                ).pipe(
                    filter(messageEvent => messageEvent.id === eventId),
                ).subscribe(() => {
                    observer.next(this.events.findById(eventId));
                });

                return () => subscription.unsubscribe();
            });
            this.fetchConversation(conversationId);
        });
    }

    public async readEvent(directMessageEvent: DirectMessageEvent): Promise<any> {
        const conversationId = directMessageEvent.conversation;

        const {events = []} = await this.api.directMessages.readEvent(conversationId, directMessageEvent.id).toPromise();

        if (events) {
            const event = events[0];
            if (event) {
                const conversation = this.conversations.findById(conversationId) || DirectMessageConversation.createOne({
                    id: conversationId
                });
                const storeEvent = this.events.findById(event.id) || DirectMessageEvent.createOne({
                    id: event.id,
                    type: event.type,
                    conversation: conversation.id,
                });
                storeEvent.fillFromApi(event);
                conversation.addEvent(storeEvent);
                this.events.store(storeEvent);

                return Promise.resolve(this.conversations.store(conversation));
            }
        }

        return Promise.resolve(undefined);
    }

    public async sendMessage(conversationId: string, body: string | null, attachments: File[] | null = []): Promise<boolean> {
        const attachmentIds: string[] = [];

        if (attachments) {
            for (const attachment of attachments) {
                const response = await new Promise<string>((resolve, reject) => {
                    this.api.attachments.uploadImage(attachment).subscribe(result => {
                        if (result.type === HttpEventType.Response) {
                            resolve(result.body.image_id);
                        }
                    });
                });
                attachmentIds.push(response);
            }
        }

        const {event = null} = await this.api.directMessages.sendMessage(conversationId, {
            message: body,
            attachments: attachmentIds
        }).toPromise();

        if (event) {
            const conversation = this.conversations.findById(conversationId) || DirectMessageConversation.createOne({id: conversationId});
            const storeEvent = this.events.findById(event.id) || DirectMessageEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id
            });
            storeEvent.fillFromApi(event);
            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);

            return Promise.resolve(!!this.events.store(storeEvent));
        }

        return Promise.resolve(false);
    }

    public clear(): Promise<void> {
        this.conversations.clear();
        this.events.clear();
        return Promise.resolve(undefined);
    }

    public loadFromMemory(): Promise<void> {
        this.isReady = true;
        this.readySubject.next(true);
        return Promise.resolve(undefined);
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.isReady) {
                return resolve(true);
            }

            this.readySubject.asObservable().pipe(
                first()
            ).subscribe((ready) => resolve(ready));
        });
    }
}
