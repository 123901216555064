import { Component } from '@angular/core';

@Component({
    selector: 'app-tablet-split-view',
    templateUrl: 'tablet-split-view.html',
    styleUrls: ['tablet-split-view.scss'],
})
export class TabletSplitViewPage {

}
