import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/routing.module').then(m => m.PageRoutingModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        }),
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule {
}
