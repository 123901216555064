import { BrowserDirectMessagingService } from './browser.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DirectMessageConversation } from '../models/direct-message-conversation';
import { PrivateSettingsService } from '../private-settings.service';
import { DirectMessageEvent } from '../models/direct-message-event';

export class MobileDirectMessagingService extends BrowserDirectMessagingService {

    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, database, rtms, storage, user);
    }

    public loadFromMemory(): Promise<any> {
        return new Promise(async (resolve) => {
            let conversations = null;

            const conversationsRaw = await this.storage.get('direct_message.conversations');
            if (conversationsRaw) {
                conversations = JSON.parse(conversationsRaw);
                for (const conversationData of conversations) {
                    // tslint:disable-next-line:max-line-length
                    const conversation = this.conversations.findById(conversationData.id) || DirectMessageConversation.createOne(conversationData);
                    conversation.recipient = this.user.users.findById(conversationData.recipient);
                    this.conversations.store(conversation);
                }
            }

            const eventsRaw = await this.storage.get('direct_message.events');
            if (eventsRaw) {
                const events = JSON.parse(eventsRaw);
                for (const eventData of events) {
                    const event = this.events.findById(eventData.id) || DirectMessageEvent.createOne(eventData);
                    event.conversation = eventData.conversation;
                    const conversation = this.conversations.findById(eventData.conversation);
                    conversation.addEvent(event);
                    this.events.store(event);
                    this.conversations.store(conversation);
                }
            }

            this.setupSubscriptions();
            resolve(undefined);
            this.isReady = true;
            this.readySubject.next(true);
        });
    }

    private persistConversationsMemory() {
        const conversations = this.conversations.all();
        const time = Date.now();
        this.storage.set('direct_message.conversations', JSON.stringify(conversations))
            .then(() => console.log('[DM CONVERSATIONS STORED]', conversations.length, Date.now() - time));
    }

    private persistEventsMemory() {
        const events = this.events.all();
        const time = Date.now();
        this.storage.set('direct_message.events', JSON.stringify(events))
            .then(() => console.log('[DM EVENTS STORED]', events.length, Date.now() - time));
    }

    private setupSubscriptions(): void {
        merge(
            this.conversations.onInsert(),
            this.conversations.onUpdate(),
            this.conversations.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistConversationsMemory();
        });

        merge(
            this.events.onInsert(),
            this.events.onUpdate(),
            this.events.onRemove(),
        ).pipe(
            debounceTime(1000)
        ).subscribe(async () => {
            await this.persistEventsMemory();
        });
    }
}
