import { Component } from '@angular/core';
import { ForgotPasswordSent } from './forgot-password-sent';

@Component({
    selector: 'app-phone-forgot-password-sent-page',
    templateUrl: 'phone-forgot-password-sent.html',
    styleUrls: ['forgot-password-sent.scss', 'phone-forgot-password-sent.scss'],
})
export class PhoneForgotPasswordSentPage extends ForgotPasswordSent {

}
