import { Type } from '@angular/core';
import * as DeviceDetector from 'device-detector-js';

export interface PlatformFactoryOsVariants {
    android?: Type<any>;
    browser?: Type<any>;
    ios?: Type<any>;
}

export interface PlatformFactoryVariants {
    desktop?: Type<any> | PlatformFactoryOsVariants;
    phone?: Type<any> | PlatformFactoryOsVariants;
    tablet?: Type<any> | PlatformFactoryOsVariants;
}

export const PlatformFactory = (declarable: Type<any>, variants: PlatformFactoryVariants): Type<any> => {
    const detector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = detector.parse(userAgent);

    let platform = 'desktop';
    if (device.device.type === 'tablet') { platform = 'tablet'; }
    if (device.device.type === 'phablet') { platform = 'phone'; }
    if (device.device.type === 'smartphone' || navigator.platform === 'iPhone') { platform = 'phone'; }

    let os = 'browser';
    if (device.os.name === 'Android') { os = 'android'; }
    if (device.os.name === 'iOS') { os = 'ios'; }

    if (platform in variants) {
        if (os in variants[platform]) { return variants[platform][os]; }
        else { return variants[platform]; }
    }

    return declarable;
};
