import { Component } from '@angular/core';
import { MyCasesPage } from './my-cases';

@Component({
    selector: 'app-tablet-my-cases-page',
    templateUrl: 'tablet-my-cases.html',
    styleUrls: ['my-cases.scss', 'tablet-my-cases.scss'],
})
export class TabletMyCasesPage extends MyCasesPage {

}
