import { EphemeralStore } from './ephemeral.store';
import { CaseConversation } from '../models/case-conversation';

export class CaseConversationStore extends EphemeralStore<CaseConversation> {

    public hasBranches(): CaseConversation[] {
        return this.items.filter(item => {
            return item.senderBranch?.id && item.recipientBranch?.id;
        });
    }

    public findById(id: string): CaseConversation {
        return this.hasBranches().find(conversation => conversation.id === id);
    }

    public findByOfflineId(offlineId: string): CaseConversation {
        return this.find(conversation => conversation.offlineId === offlineId);
    }

    public belongsToUser(id: string): CaseConversation[] {
        return this.hasBranches().filter(item => {
            return item.createdBy?.id === id || (item.hasBeenAssigned && item.assignee.id === id);
        });
    }

    public recentCases(id?: string): CaseConversation[] {
        return this.hasBranches().filter(item => {
            if (id) {
                return (item.createdBy?.id === id || (item.hasBeenAssigned && item.assignee.id === id)) && !item.resolvedWithinDays(30);
            } else {
                return !item.resolvedWithinDays(30);
            }
        });
    }

    public offlineOnly(): CaseConversation[] {
        return this.hasBranches().filter(item => item.isOffline);
    }
}
