import { AbstractSyncedModel } from '../abstract-synced-model';
import { BeforeInsert, BeforeUpdate, Column, Entity, JoinColumn, ManyToOne } from 'typeorm';
import { CaseConversation } from '../case-conversation';
import { RtmsEvent } from '../rtm-events.model';
import { AbstractModel } from '../abstract-model';

@Entity('case_events')
export class CaseEvent extends AbstractSyncedModel {

    public isOffline: boolean = null;
    public offlineId: string = null;

    @Column({
        name: 'type',
        type: 'varchar',
    })
    public type: string;

    @Column({
        name: 'sent_at',
        type: 'varchar',
        nullable: true
    })
    public sentAt: string;

    public conversation: string;


    public get sentAtDate(): Date {
        return this.sentAt ? new Date(this.sentAt) : null;
    }

    public static mapFromApi(data: Partial<any>): Partial<CaseEvent> {
        if (!data) { return null; }

        return {
            id: data.id,
            type: data.type,
            sentAt: data.sent_at,
            conversation: data.conversation,
        };
    }

    public static mapFromRtms(data: Partial<RtmsEvent>): Partial<CaseEvent> {
        if (!data) { return null; }

        const eventType = (data.event === 'new_message') ? 'message' : data.event;

        return {
            id: data.data.message_id,
            type: eventType,
            sentAt: data.timestamp,
            conversation: data.target_id,
        };
    }

    public fill(data: Partial<CaseEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
        if (data.isOffline !== undefined) {
            this.isOffline = data.isOffline;
        }
        if (data.offlineId !== undefined) {
            this.offlineId = data.offlineId;
        }
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            sentAt: this.sentAt,
            conversation: this.conversation,
            isOffline: this.isOffline,
            offlineId: this.offlineId,
        };
    }

    public is(otherModel: Partial<this>): boolean {
        return this.id === otherModel.id;
    }
}
