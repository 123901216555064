import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PageRoutingModule } from './routing.module';
// import { PhoneBuildPage } from './build/phone-build';
// import { TabletBuildPage } from './build/tablet-build';
import { PhoneOnBordingPage } from './on-boarding/phone-on-boarding';
import { TabletOnBoardingPage } from './on-boarding/tablet-on-boarding';

import { PhoneDashboardPage } from './dashboard/phone-dashboard';
import { TabletDashboardPage } from './dashboard/tablet-dashboard';

import { PhoneMyCasesPage } from './my-cases/phone-my-cases';
import { TabletMyCasesPage } from './my-cases/tablet-my-cases';

import { PhoneMyMessagesPage } from './my-messages/phone-my-messages';
import { TabletMyMessagesPage } from './my-messages/tablet-my-messages';

import { PhoneMessageInfoPage } from './message-info/phone-message-info';
import { TabletMessageInfoPage } from './message-info/tablet-message-info';

import { PhoneMessengerProfilePage } from './messenger-profile/phone-messenger-profile';
import { TabletMessengerProfilePage  } from './messenger-profile/tablet-messenger-profile';

import { PhoneNewMessagePage } from './new-message/phone-new-message';
import { TabletNewMessagePage  } from './new-message/tablet-new-message';

import { PhoneNewGroupMessageParticipantsPage } from './new-group-message-participants/phone-new-group-message-participants';
import { TabletNewGroupMessageParticipantsPage  } from './new-group-message-participants/tablet-new-group-message-participants';

import { PhoneNewGroupMessageNamePage } from './new-group-message-name/phone-new-group-message-name';
import { TabletNewGroupMessageNamePage  } from './new-group-message-name/tablet-new-group-message-name';

import { PhoneMessagePage } from './message/phone-message';
import { TabletMessagePage  } from './message/tablet-message';

import { PhoneMessageGroupPage } from './message-group/phone-message-group';
import { TabletMessageGroupPage  } from './message-group/tablet-message-group';

import { PhoneMessageGroupDetailPage } from './message-group-detail/phone-message-group-detail';
import { TabletMessageGroupDetailPage  } from './message-group-detail/tablet-message-group-detail';

import { PhoneMessageGroupRenamePage } from './message-group-rename/phone-message-group-rename';
import { TabletMessageGroupRenamePage } from './message-group-rename/tablet-message-group-rename';

import { PhoneMessageGroupSharedImagesPage } from './message-group-shared-images/phone-message-group-shared-images';
import { TabletMessageGroupSharedImagesPage } from './message-group-shared-images/tablet-message-group-shared-images';

import { PhoneMessageUserDetailPage } from './message-user-detail/phone-message-user-detail';
import { TabletMessageUserDetailPage  } from './message-user-detail/tablet-message-user-detail';

import { PhoneLoginPage } from './login/phone-login';
import { TabletLoginPage  } from './login/tablet-login';

import { PhoneForgotPasswordPage} from './forgot-password/phone-forgot-password';
import { TabletForgotPasswordPage  } from './forgot-password/tablet-forgot-password';

import { PhoneForgotPasswordSentPage} from './forgot-password-sent/phone-forgot-password-sent';
import { TabletForgotPasswordSentPage  } from './forgot-password-sent/tablet-forgot-password-sent';

import { MyProfilePage } from './my-profile/my-profile';

import { PhoneMessageGroupParticipantPage } from './message-group-participant/phone-message-group-participant';
import { TabletMessageGroupParticipantPage } from './message-group-participant/tablet-message-group-participant';

import { PhoneChangeTemporaryPassword } from './change-temporary-password/phone-change-temporary-password';
import { TabletChangeTemporaryPassword } from './change-temporary-password/tablet-change-temporary-password';

import { PhoneLoginMfaPage } from './login-mfa/phone-login-mfa';
import { TabletLoginMfaPage } from './login-mfa/tablet-login-mfa';

import { PhoneForgotPasswordChangedPage } from './forgot-password-changed/phone-forgot-password-changed';
import { TabletForgotPasswordChangedPage } from './forgot-password-changed/tablet-forgot-password-changed';

import { PhoneForgotPasswordChangePage } from './forgot-password-change/phone-forgot-password-change';
import { TabletForgotPasswordChangePage } from './forgot-password-change/tablet-forgot-password-change';

import { PhoneChangePasswordPage } from './change-password/phone-change-password';
import { TabletChangePasswordPage } from './change-password/tablet-change-password';

import { PhoneChangedPasswordPage } from './changed-password/phone-changed-password';
import { TabletChangedPasswordPage } from './changed-password/tablet-changed-password';

import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { IonBottomDrawerModule } from 'ion-bottom-drawer';
import { NewMessageModule } from './new-message/new-message.module';
import { TabletSplitViewPage } from './tablet-split-view/tablet-split-view';
import { PipesModule } from '../pipes/pipes.module';
import { CasesModule } from './cases/cases.module';
import { BlankPage } from './blank/blank';
import { DeveloperToolsPage } from './developer-tools/developer-tools.page';
import { DeveloperEnvironmentPage } from './developer-environment/developer-environment.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PageRoutingModule,
        ComponentsModule,
        DirectivesModule,
        IonBottomDrawerModule,
        NewMessageModule,
        CasesModule,
        PipesModule,
    ],
    declarations: [
        // PhoneBuildPage,
        // TabletBuildPage,
        PhoneOnBordingPage,
        TabletOnBoardingPage,
        PhoneDashboardPage,
        TabletDashboardPage,
        PhoneMyCasesPage,
        TabletMyCasesPage,
        PhoneMyMessagesPage,
        TabletMyMessagesPage,
        PhoneMessengerProfilePage,
        TabletMessengerProfilePage,
        PhoneNewMessagePage,
        TabletNewMessagePage,
        PhoneMessagePage,
        TabletMessagePage,
        PhoneMessageUserDetailPage,
        TabletMessageUserDetailPage,
        PhoneLoginPage,
        TabletLoginPage,
        PhoneForgotPasswordPage,
        TabletForgotPasswordPage,
        PhoneForgotPasswordSentPage,
        TabletForgotPasswordSentPage,
        PhoneNewGroupMessageParticipantsPage,
        TabletNewGroupMessageParticipantsPage,
        PhoneNewGroupMessageNamePage,
        TabletNewGroupMessageNamePage,
        PhoneMessageGroupPage,
        TabletMessageGroupPage,
        PhoneMessageGroupDetailPage,
        TabletMessageGroupDetailPage,
        PhoneMessageGroupRenamePage,
        TabletMessageGroupRenamePage,
        PhoneMessageGroupSharedImagesPage,
        TabletMessageGroupSharedImagesPage,
        MyProfilePage,
        PhoneMessageInfoPage,
        TabletMessageInfoPage,
        PhoneMessageGroupParticipantPage,
        TabletMessageGroupParticipantPage,
        PhoneChangeTemporaryPassword,
        TabletChangeTemporaryPassword,
        PhoneLoginMfaPage,
        TabletLoginMfaPage,
        PhoneForgotPasswordChangedPage,
        TabletForgotPasswordChangedPage,
        PhoneForgotPasswordChangePage,
        TabletForgotPasswordChangePage,
        PhoneChangePasswordPage,
        TabletChangePasswordPage,
        PhoneChangedPasswordPage,
        TabletChangedPasswordPage,
        TabletSplitViewPage,
        BlankPage,
        DeveloperToolsPage,
        DeveloperEnvironmentPage,
    ]
})
export class PagesModule {
}
