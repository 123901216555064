import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CognitoAuthenticationService } from '../services/cognito-authentication.service';

@Injectable({
    providedIn: 'root'
})
export class LogoutInterceptor implements HttpInterceptor {
    constructor(
        private authService: CognitoAuthenticationService,
    ) {
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).pipe(takeUntil(this.authService.getLogout()));
    }
}
