import { EphemeralStore } from './ephemeral.store';
import { Branch } from '../models/branch';
import { Organisation } from '../models/organisation';

export class BranchStore extends EphemeralStore<Branch> {

    public findById(id: string): Branch {
        return this.find(branch => branch.id === id);
    }

    public findByOrganisation(organisation: Organisation): Branch[] {
        return this.findByOrganisationId(organisation.id);
    }

    public findByOrganisationId(organisationId: string): Branch[] {
        return this.all().filter(branch => branch.organisation?.id === organisationId);
    }

}
