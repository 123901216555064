import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { Platform } from '@ionic/angular';
import { GroupMessagingService } from './abstract.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { MobileGroupMessagingService } from './mobile.service';
import { BrowserGroupMessagingService } from './browser.service';

export const GroupMessagingServiceFactory = (
    api: ApiClientService,
    database: DatabaseService,
    organisationService: OrganisationService,
    rtms: RealTimeMessagingService,
    platform: Platform,
    storage: PrivateSettingsService,
    user: UserService,
): GroupMessagingService => {
    const service = platform.is('cordova') ? MobileGroupMessagingService : BrowserGroupMessagingService;
    return new service(api, database, organisationService, rtms, storage, user);
};
