import { Observable } from 'rxjs';
import {
    ConversationCreatedEvent,
    ConversationDeletedEvent,
    ConversationParticipantAdded,
    ConversationParticipantLeft,
    ConversationParticipantRemoved,
    ConversationParticipantUpdated,
    MessageDeliveredEvent,
    MessageReadEvent,
    NewMessageEvent,
    RtmsEvent,
    RtmsTargetType
} from '../models/rtm-events.model';
import { filter, map } from 'rxjs/operators';
import { RtmsBaseEventsService } from './base-events.service';

export class RtmsGroupMessageService extends RtmsBaseEventsService {

    public targetType: RtmsTargetType = 'group_message';

    public onEvent(): Observable<RtmsEvent> {
        return super.onEvent().pipe(
            filter(event => event.target_type === this.targetType),
        );
    }

    public onConversationCreated(): Observable<ConversationCreatedEvent> {
        return super.onConversationCreated(this.targetType);
    }

    public onMessage(conversationId?: string): Observable<NewMessageEvent> {
        return super.onMessage(this.targetType, conversationId);
    }

    public onMessageDelivered(conversationId?: string): Observable<MessageDeliveredEvent> {
        return super.onMessageDelivered(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }

    public onMessageRead(conversationId?: string): Observable<MessageReadEvent> {
        return super.onMessageRead(this.targetType).pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return true;
            }),
        );
    }

    public onConversationDeleted(conversationId?: string): Observable<ConversationDeletedEvent> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'conversation_deleted';
            }),
            map(event => ConversationDeletedEvent.createOne(event)),
        );
    }

    public onGroupParticipantAdded(conversationId?: string): Observable<ConversationParticipantAdded> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'participant_added';
            }),
            map(event => ConversationParticipantAdded.createOne(event)),
        );
    }

    public onGroupParticipantRemoved(conversationId?: string): Observable<ConversationParticipantRemoved> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'participant_removed';
            }),
            map(event => ConversationParticipantRemoved.createOne(event)),
        );
    }

    public onGroupParticipantLeft(conversationId?: string): Observable<ConversationParticipantLeft> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'participant_left';
            }),
            map(event => ConversationParticipantLeft.createOne(event)),
        );
    }

    public onGroupParticipantUpdated(conversationId?: string): Observable<ConversationParticipantUpdated> {
        return this.onEvent().pipe(
            filter(event => {
                if (conversationId && event.target_id !== conversationId) {
                    return false;
                }
                return event.event === 'participant_updated';
            }),
            map(event => ConversationParticipantUpdated.createOne(event)),
        );
    }
}
