import { Component } from '@angular/core';
import { NewMessage } from './new-message';

@Component({
    selector: 'app-phone-new-message-page',
    templateUrl: 'phone-new-message.html',
    styleUrls: ['new-message.scss', 'phone-new-message.scss'],
})
export class PhoneNewMessagePage extends NewMessage {

}
