import { Component } from '@angular/core';
import { MessageGroupRename } from './message-group-rename';

@Component({
    selector: 'app-phone-message-group-rename-page',
    templateUrl: 'phone-message-group-rename.html',
    styleUrls: ['message-group-rename.scss', 'phone-message-group-rename.scss'],
})
export class PhoneMessageGroupRenamePage extends MessageGroupRename {



}
