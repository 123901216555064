import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { PrivateSettingsService } from '../private-settings.service';
import { UserService } from '../user.service';
import { OrganisationService } from '../organisation.service';
import { GroupMessageConversation } from '../models/group-messaging/group-message-conversation';
import { Observable } from 'rxjs';
import { GroupMessageEvent } from '../models/group-messaging/group-message-event';
import { SearchGroupMessageEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { GroupEvent } from '../models/group-messaging/group-event';

@Injectable()
export abstract class GroupMessagingService {
    constructor(
        public api: ApiClientService,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        (window as any).api = api;
    }

    public abstract createGroup(name: string, participants: string[], image: string): Promise<GroupMessageConversation>;

    public abstract fetchGroups(): Promise<GroupMessageConversation[]>;

    public abstract fetchGroup(groupId: string): Promise<GroupMessageConversation>;

    public abstract fetchEvents(groupId: string, request: SearchGroupMessageEventsRequest): Promise<GroupEvent[]>;

    public abstract fetchEvent(groupId: string, eventId: string): Promise<GroupEvent>;

    public abstract getGroups(): Observable<GroupMessageConversation[]>;

    public abstract getGroup(groupId: string): Observable<GroupMessageConversation>;

    public abstract getEvents(groupId: string): Observable<GroupEvent[]>;

    public abstract getAllMessages(): Observable<GroupEvent[]>;

    public abstract getEvent(groupId: string, eventId: string): Observable<GroupEvent>;

    public abstract addParticipant(groupId: string, userId: string): Promise<boolean>;

    public abstract removeParticipant(groupId: string, userId: string): Promise<boolean>;

    public abstract leaveGroup(groupId: string): Promise<boolean>;

    public abstract deleteGroup(groupId: string): Promise<boolean>;

    public abstract renameGroup(groupId: string, groupName: string): Promise<GroupMessageConversation>;

    public abstract changeGroupIcon(groupId: string, groupIcon: string): Promise<GroupMessageConversation>;

    public abstract makeAdmin(groupId: string, userId: string): Promise<boolean>;

    public abstract removeAdmin(groupId: string, userId: string): Promise<boolean>;

    public abstract sendMessage(groupId: string, message: string, attachments: File[]): Promise<boolean>;

    public abstract readMessage(groupId: string, messageId: string): Promise<GroupMessageConversation>;

    public abstract clear(): Promise<void>;

    public abstract loadFromMemory(): Promise<void>;

    public abstract ready(): Promise<boolean>;
}
