import { Component } from '@angular/core';
import { LoginMfaPage } from './login-mfa';

@Component({
    selector: 'app-login-mfa-page',
    templateUrl: 'tablet-login-mfa.html',
    styleUrls: ['login-mfa.scss', 'tablet-login-mfa.scss'],
})
export class TabletLoginMfaPage extends LoginMfaPage {

}
