import { AbstractModel } from './abstract-model';

export class PrimaryContact extends AbstractModel {

    public name: string = null;
    public phone_number: string = null;
    public email: string = null;

    public fill(data: Partial<PrimaryContact>) {
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.phone_number !== undefined) {
            this.phone_number = data.phone_number;
        }
        if (data.email !== undefined) {
            this.email = data.email;
        }
    }

}
