import { Injectable } from '@angular/core';
import { ArrayChunk } from '../helpers';
import { CognitoAuthenticationService } from './cognito-authentication.service';
import { CaseMessagingService } from './case-messaging-service';
import { DirectMessagingService } from './direct-messaging.service';
import { GroupMessagingService } from './group-messaging.service';
import { OrganisationService } from './organisation.service';
import { UserService } from './user.service';

@Injectable()
export class RemoteDataService {

    private fetchCasesAttempt: Promise<any> = null;
    private fetchGroupsAttempt: Promise<any> = null;
    private fetchMessagesAttempt: Promise<any> = null;
    private fetchOrganisationsAttempt: Promise<any> = null;
    private searchCasesAttempt: Promise<any> = null;

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private caseMessaging: CaseMessagingService,
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private organisations: OrganisationService,
        private userService: UserService,
    ) {
    }

    public fetch() {
        if (this.cognitoAuth.isLoggedIn()) {
            return Promise.all([
                this.userService.fetchMe().then(() => this.userService.fetchUsers()),
                this.fetchCases(),
                this.fetchMessages(),
                this.fetchGroups(),
                this.fetchOrganisations(),
                this.searchCases(),
            ]);
        }
    }

    public fetchCases(): Promise<any> {
        if (this.fetchCasesAttempt) {
            return this.fetchCasesAttempt;
        }

        this.fetchCasesAttempt = this.caseMessaging.fetchAllCases().then(async cases => {
            if (!cases) {
                return;
            }
            const chunks = ArrayChunk(cases.reverse(), 3);
            for (const chunk of chunks) {
                const requests = [];
                for (const conversation of chunk) {
                    requests.push(this.caseMessaging.fetchEvents(conversation.id, {
                        before: new Date().toISOString(),
                    }));
                }
                try {
                    await Promise.all(requests);
                } catch (e) {}
            }
        }).finally(() => {
            this.fetchCasesAttempt = null;
        });

        return this.fetchCasesAttempt;
    }

    public fetchGroups(): Promise<any> {
        if (this.fetchGroupsAttempt) {
            return this.fetchGroupsAttempt;
        }

        this.fetchGroupsAttempt = this.groupMessaging.fetchGroups().then(async groups => {
            if (!groups) {
                return;
            }
            const chunks = ArrayChunk(groups.reverse(), 3);
            for (const chunk of chunks) {
                const requests = [];
                for (const group of chunk) {
                    requests.push(this.groupMessaging.fetchEvents(group.id, {
                        before: new Date().toISOString(),
                    }));
                }
                try {
                    await Promise.all(requests);
                } catch (e) {}
            }
        }).finally(() => {
            this.fetchGroupsAttempt = null;
        });

        return this.fetchGroupsAttempt;
    }

    public fetchMessages(): Promise<any> {
        if (this.fetchMessagesAttempt) {
            return this.fetchMessagesAttempt;
        }

        this.fetchMessagesAttempt = this.directMessaging.fetchAllConversations().then(async conversations => {
            if (!conversations) {
                return;
            }
            const chunks = ArrayChunk(conversations.reverse(), 3);
            for (const chunk of chunks) {
                const requests = [];
                for (const conversation of chunk) {
                    requests.push(this.directMessaging.fetchAllEvents(conversation.id, {
                        before: new Date().toISOString(),
                    }));
                }
                try {
                    await Promise.all(requests);
                } catch (e) {}
            }
        }).finally(() => {
            this.fetchMessagesAttempt = null;
        });

        return this.fetchMessagesAttempt;
    }

    public fetchOrganisations(): Promise<any> {
        if (this.fetchOrganisationsAttempt) {
            return this.fetchOrganisationsAttempt;
        }
        this.fetchOrganisationsAttempt = this.organisations.fetchOrganisations().then(async organisations => {
            if (!organisations) {
                return;
            }
            const chunks = ArrayChunk(organisations.reverse(), 3);
            for (const chunk of chunks) {
                const requests = [];
                for (const organisation of chunk) {
                    requests.push(this.organisations.fetchBranches(organisation.id));
                }
                try {
                    await Promise.all(requests);
                } catch (e) {}
            }
        }).finally(() => {
            this.fetchOrganisationsAttempt = null;
        });

        return this.fetchOrganisationsAttempt;
    }

    public searchCases(): Promise<any> {
        if (this.searchCasesAttempt) {
            return this.searchCasesAttempt;
        }

        this.searchCasesAttempt = this.caseMessaging.searchAllCases(null, null).then(async cases => {
            if (!cases) {
                return;
            }
            const chunks = ArrayChunk(cases.reverse(), 3);
            for (const chunk of chunks) {
                const requests = [];
                for (const conversation of chunk) {
                    requests.push(this.caseMessaging.fetchEvents(conversation.id, {
                        before: new Date().toISOString(),
                    }));
                }
                try {
                    await Promise.all(requests);
                } catch (e) {}
            }
        }).finally(() => {
            this.searchCasesAttempt = null;
        });

        return this.searchCasesAttempt;
    }
}
