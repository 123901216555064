import { AbstractModel } from './abstract-model';

export type RtmsEventType =
    'new_message'
    | 'message_delivered'
    | 'message_read'
    | 'conversation_created'
    | 'conversation_deleted'
    | 'participant_added'
    | 'participant_updated'
    | 'participant_removed'
    | 'participant_left'
    | 'opened'
    | 'accepted'
    | 'assigned'
    | 'resolved';
export type RtmsTargetType = 'direct_message' | 'group_message' | 'case';

export class RtmsEvent extends AbstractModel {
    event: RtmsEventType;
    target_type: RtmsTargetType;
    target_id: string;
    sender_id: string;
    timestamp: string;
    data: any;

    public fill(data: Partial<RtmsEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = data.data; }
    }
}

export class ConversationParticipant extends AbstractModel {
    id: string;
    title: string;
    first_name: string;
    last_name: string;
    image: string;
    organisation_id: string;
    messaging: boolean;
    cases: boolean;

    public fill(data: Partial<ConversationParticipant>) {
        if (data.id !== undefined) { this.id = data.id; }
        if (data.title !== undefined) { this.title = data.title; }
        if (data.first_name !== undefined) { this.first_name = data.first_name; }
        if (data.last_name !== undefined) { this.last_name = data.last_name; }
        if (data.image !== undefined) { this.image = data.image; }
        if (data.organisation_id !== undefined) { this.organisation_id = data.organisation_id; }
        if (data.messaging !== undefined) { this.messaging = data.messaging; }
        if (data.cases !== undefined) { this.cases = data.cases; }
    }
}

export class ConversationParticipantData extends AbstractModel {
    participant: ConversationParticipant;
    event_id: string;

    public fill(data: Partial<ConversationParticipantData>) {
        if (data.participant !== undefined) { this.participant = ConversationParticipant.createOne(data.participant); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
    }
}

export class ConversationParticipantRemovedData extends AbstractModel {
    participant: ConversationParticipant;
    event_id: string;
    remove_connections: string[];

    public fill(data: Partial<ConversationParticipantRemovedData>) {
        if (data.participant !== undefined) { this.participant = ConversationParticipant.createOne(data.participant); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
        if (data.remove_connections !== undefined) { this.remove_connections = data.remove_connections; }
    }
}

export class ConversationParticipantUpdatedData extends AbstractModel {
    participant: ConversationParticipant;
    event_id: string;
    is_admin: boolean;

    public fill(data: Partial<ConversationParticipantUpdatedData>) {
        if (data.participant !== undefined) { this.participant = ConversationParticipant.createOne(data.participant); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
        if (data.is_admin  !== undefined) { this.is_admin = data.is_admin; }
    }
}

export class ConversationCreatedEventData extends AbstractModel {
    connections: string[];
    participants: ConversationParticipant[];
    name?: string;
    image?: string;

    public fill(data: Partial<ConversationCreatedEventData>) {
        if (data.connections !== undefined) { this.connections = data.connections; }
        if (data.participants !== undefined) { this.participants = ConversationParticipant.createMany(data.participants); }
        if (data.name !== undefined) { this.name = data.name; }
        if (data.image !== undefined) { this.image = data.image; }
    }
}

export class ConversationDeletedEventData extends AbstractModel {
    connections: string[];

    public fill(data: Partial<ConversationDeletedEventData>) {
        if (data.connections !== undefined) { this.connections = data.connections; }
    }
}

export class NewMessageEventData extends AbstractModel {
    attachments: string[];
    message_body: string;
    message_id: string;

    public fill(data: Partial<NewMessageEventData>) {
        if (data.attachments !== undefined) { this.attachments = data.attachments; }
        if (data.message_body !== undefined) { this.message_body = data.message_body; }
        if (data.message_id !== undefined) { this.message_id = data.message_id; }
    }
}

export class MessageDeliveredEventData extends AbstractModel {
    message_id: string;

    public fill(data: Partial<MessageDeliveredEventData>) {
        if (data.message_id !== undefined) { this.message_id = data.message_id; }
    }
}

export class ConversationParticipantAdded extends RtmsEvent {
    data: ConversationParticipantData;

    public fill(data: Partial<ConversationParticipantAdded>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationParticipantData.createOne(data.data); }
    }
}

export class ConversationParticipantRemoved extends RtmsEvent {
    data: ConversationParticipantRemovedData;

    public fill(data: Partial<ConversationParticipantRemoved>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationParticipantRemovedData.createOne(data.data); }
    }
}

export class ConversationParticipantLeft extends RtmsEvent {
    data: ConversationParticipantRemovedData;

    public fill(data: Partial<ConversationParticipantLeft>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationParticipantRemovedData.createOne(data.data); }
    }
}

export class ConversationParticipantUpdated extends RtmsEvent {
    data: ConversationParticipantUpdatedData;

    public fill(data: Partial<ConversationParticipantUpdated>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationParticipantUpdatedData.createOne(data.data); }
    }
}

export class ConversationCreatedEvent extends RtmsEvent {
    data: ConversationCreatedEventData;

    public fill(data: Partial<ConversationCreatedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationCreatedEventData.createOne(data.data); }
    }
}

export class ConversationDeletedEvent extends RtmsEvent {
    data: ConversationDeletedEventData;

    public fill(data: Partial<ConversationDeletedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = ConversationDeletedEventData.createOne(data.data); }
    }
}

export class NewMessageEvent extends RtmsEvent {
    data: NewMessageEventData;

    public fill(data: Partial<NewMessageEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = NewMessageEventData.createOne(data.data); }
    }
}

export class MessageDeliveredEvent extends RtmsEvent {
    data: MessageDeliveredEventData;

    public fill(data: Partial<MessageDeliveredEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = MessageDeliveredEventData.createOne(data.data); }
    }
}

export class MessageReadEvent extends RtmsEvent {
    data: MessageDeliveredEventData;

    public fill(data: Partial<MessageReadEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = MessageDeliveredEventData.createOne(data.data); }
    }
}

export class CaseOpenedEventData extends AbstractModel {
    'opened_by': ConversationParticipant;
    'connections': string[];
    'event_id': string;

    public fill(data: Partial<CaseOpenedEventData>) {
        if (data.connections !== undefined) { this.connections = data.connections; }
        if (data.opened_by !== undefined) { this.opened_by = ConversationParticipant.createOne(data.opened_by); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
    }
}

export class CaseAcceptedEventData extends AbstractModel {
    'accepted_by': ConversationParticipant;
    'connections': string[];
    'event_id': string;

    public fill(data: Partial<CaseAcceptedEventData>) {
        if (data.connections !== undefined) { this.connections = data.connections; }
        if (data.accepted_by !== undefined) { this.accepted_by = ConversationParticipant.createOne(data.accepted_by); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
    }
}

export class CaseAssignedEventData extends AbstractModel {
    'assigned_by': ConversationParticipant;
    'assigned_to': ConversationParticipant;
    'previous_assignee'?: ConversationParticipant;
    'connections': string[];
    'remove_connections': string[];
    'event_id': string;

    public fill(data: Partial<CaseAssignedEventData>) {
        if (data.connections !== undefined) { this.connections = data.connections; }
        if (data.remove_connections !== undefined) { this.remove_connections = data.remove_connections; }
        if (data.assigned_by !== undefined) { this.assigned_by = ConversationParticipant.createOne(data.assigned_by); }
        if (data.assigned_to !== undefined) { this.assigned_to = ConversationParticipant.createOne(data.assigned_to); }
        if (data.previous_assignee !== undefined) { this.previous_assignee = ConversationParticipant.createOne(data.previous_assignee); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
    }
}

export class CaseResolvedEventData extends AbstractModel {
    'resolved_by': ConversationParticipant;
    'remove_connections': string[];
    'event_id': string;

    public fill(data: Partial<CaseResolvedEventData>) {
        if (data.remove_connections !== undefined) { this.remove_connections = data.remove_connections; }
        if (data.resolved_by !== undefined) { this.resolved_by = ConversationParticipant.createOne(data.resolved_by); }
        if (data.event_id !== undefined) { this.event_id = data.event_id; }
    }
}

export class CaseOpenedEvent extends RtmsEvent {
    data: CaseOpenedEventData;

    public fill(data: Partial<CaseOpenedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = CaseOpenedEventData.createOne(data.data); }
    }
}

export class CaseAcceptedEvent extends RtmsEvent {
    data: CaseAcceptedEventData;

    public fill(data: Partial<CaseAcceptedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = CaseAcceptedEventData.createOne(data.data); }
    }
}

export class CaseAssignedEvent extends RtmsEvent {
    data: CaseAssignedEventData;

    public fill(data: Partial<CaseAssignedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = CaseAssignedEventData.createOne(data.data); }
    }
}

export class CaseResolvedEvent extends RtmsEvent {
    data: CaseResolvedEventData;

    public fill(data: Partial<CaseResolvedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = CaseResolvedEventData.createOne(data.data); }
    }
}

export class Address extends AbstractModel {
    line_1: string;
    line_2: string;
    town: string;
    postcode: string;

    public fill(data: Partial<Address>) {
        if (data.line_1 !== undefined) { this.line_1 = data.line_1; }
        if (data.line_2 !== undefined) { this.line_2 = data.line_2; }
        if (data.town !== undefined) { this.town = data.town; }
        if (data.postcode !== undefined) { this.postcode = data.postcode; }
    }
}

export class Contact extends AbstractModel {
    name: string;
    phone_number: string;
    email: string;

    public fill(data: Partial<Contact>) {
        if (data.name !== undefined) { this.name = data.name; }
        if (data.phone_number !== undefined) { this.phone_number = data.phone_number; }
        if (data.email !== undefined) { this.email = data.email; }
    }
}

export class Branch extends AbstractModel {
    id: string;
    organisation_id: string;
    name: string;
    image: string;
    address: Address;
    primary_contact: Contact;

    public fill(data: Partial<Branch>) {
        if (data.id !== undefined) { this.id = data.id; }
        if (data.organisation_id !== undefined) { this.organisation_id = data.organisation_id; }
        if (data.name !== undefined) { this.name = data.name; }
        if (data.image !== undefined) { this.image = data.image; }
        if (data.address !== undefined) { this.address = Address.createOne(data.address); }
        if (data.primary_contact !== undefined) { this.primary_contact = Contact.createOne(data.primary_contact); }
    }
}

export class PatientCase extends AbstractModel {
    id: string;
    image: string;
    created_at: string;
    opened_at: string;
    accepted_at: string;
    resolved_at: string;
    patient_id: string;
    case_number: string;
    created_by: string;
    sender_branch: Branch;
    recipient_branch: Branch;
    assignee_id: string;

    public fill(data: Partial<PatientCase>) {
        if (data.id !== undefined) { this.id = data.id; }
        if (data.image !== undefined) { this.image = data.image; }
        if (data.created_at !== undefined) { this.created_at = data.created_at; }
        if (data.opened_at !== undefined) { this.opened_at = data.opened_at; }
        if (data.accepted_at !== undefined) { this.accepted_at = data.accepted_at; }
        if (data.resolved_at !== undefined) { this.resolved_at = data.resolved_at; }
        if (data.patient_id !== undefined) { this.patient_id = data.patient_id; }
        if (data.case_number !== undefined) { this.case_number = data.case_number; }
        if (data.created_by !== undefined) { this.created_by = data.created_by; }
        if (data.sender_branch !== undefined) { this.sender_branch = Branch.createOne(data.sender_branch); }
        if (data.recipient_branch !== undefined) { this.recipient_branch = Branch.createOne(data.recipient_branch); }
        if (data.assignee_id !== undefined) { this.assignee_id = data.assignee_id; }
    }
}

export class CaseCreatedEventData extends AbstractModel {
    case: PatientCase;

    public fill(data: Partial<CaseCreatedEventData>) {
        if (data.case !== undefined) { this.case = PatientCase.createOne(data.case); }
    }
}

export class CaseCreatedEvent extends RtmsEvent {
    data: CaseCreatedEventData;

    public fill(data: Partial<CaseCreatedEvent>) {
        if (data.event !== undefined) { this.event = data.event; }
        if (data.target_type !== undefined) { this.target_type = data.target_type; }
        if (data.target_id !== undefined) { this.target_id = data.target_id; }
        if (data.sender_id !== undefined) { this.sender_id = data.sender_id; }
        if (data.timestamp !== undefined) { this.timestamp = data.timestamp; }
        if (data.data !== undefined) { this.data = CaseCreatedEventData.createOne(data.data); }
    }
}
