import { EphemeralStore } from './ephemeral.store';
import { Organisation } from '../models/organisation';

export class OrganisationStore extends EphemeralStore<Organisation> {

    public findById(id: string): Organisation {
        return this.find(organisation => organisation.id === id);
    }

}
