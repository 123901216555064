import { Component } from '@angular/core';
import { MessengerProfile } from './messenger-profile';

@Component({
    selector: 'app-tablet-messenger-profile-page',
    templateUrl: 'tablet-messenger-profile.html',
    styleUrls: ['messenger-profile.scss', 'tablet-messenger-profile.scss'],
})
export class TabletMessengerProfilePage extends MessengerProfile {

}
