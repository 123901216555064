import { Component, ViewChild, ElementRef } from '@angular/core';
import { AWSError, CognitoIdentityServiceProvider } from 'aws-sdk';
import { Router } from '@angular/router';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-change-password-page',
    template: '',
    styleUrls: ['change-password.scss'],
})

export abstract class ChangePasswordPage {

    public passwordCurrent = '';
    public passwordNew = '';
    public passwordNewConfirm = '';

    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private router: Router,
        private navCtrl: NavController,
    ) {
        this.cognitoAuth.getError().subscribe((error: AWSError) => {
            this.addError(error.message);
            this.isLoading = false;
        });
    }

    public addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    public clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    public goBackToPreviousPage() {
        if (!this.isLoading) {
            this.router.navigate(['/my-profile'] );
        }
    }

    public changePassword() {

        this.clearErrors();

        if (this.passwordCurrent === '' || this.passwordNew === '' || this.passwordNewConfirm === '') {
            this.addError('All Fields Required.');
            return;
        }

        if (this.passwordNew !== this.passwordNewConfirm) {
            this.addError('New Passwords Do Not Match.');
            return;
        }

        this.clearErrors();
        this.isLoading = true;
        this.cognitoAuth.changePassword(this.passwordCurrent, this.passwordNew)
            .then(response => {
                if (response) {
                    this.router.navigate(['/my-profile/password-changed']);
                } else {
                    this.addError('An Unexpected Error Occurred');
                    this.isLoading = false;
                }
            })
            .catch(error => {
                this.addError(error);
                this.isLoading = false;
            });
    }

    public ionViewWillEnter()
    {
        this.passwordCurrent = '';
        this.passwordNew = '';
        this.passwordNewConfirm = '';
    }
}
