import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { DirectMessageEvent } from '../../services/models/direct-message-event';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PendingDirectMessage } from '../../services/models/pending-direct-message';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { WebFile } from '../../helpers/web-file';
import { AbstractSyncedModel } from '../../services/models/abstract-synced-model';
import { GroupEvent } from '../../services/models/group-messaging/group-event';
import { Conversation } from '../../pages/my-messages/my-messages';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { GroupMessageParticipantAddedEvent } from '../../services/models/group-messaging/group-message-participant-added-event';
import { GroupMessageParticipantRemovedEvent } from '../../services/models/group-messaging/group-message-participant-removed-event';
import { GroupMessageParticipantLeftEvent } from '../../services/models/group-messaging/group-message-participant-left-event';
import { parseISO, isSameDay, getDay, isToday, isYesterday, isThisWeek } from 'date-fns';
import { BiometricService } from '../../services/biometric.service';

export type ConversationEvent = DirectMessageEvent | GroupEvent;

@Component({
    selector: 'app-messenger',
    templateUrl: 'messenger.html',
    styleUrls: ['messenger.scss'],
})
export class MessengerComponent implements AfterViewInit, OnChanges, OnDestroy {

    @ViewChild('imageInput', { static: false }) public imageUploadRef: ElementRef<HTMLInputElement>;
    public imageInput: HTMLInputElement;

    private attachment: WebFile = null;

    public events: ConversationEvent[] = null;
    public newMessage = '';
    public onDestroy: Subject<void> = new Subject<void>();
    public onConversationChanged: Subject<Conversation> = new Subject<Conversation>();
    public pendingMessages: PendingDirectMessage[] = null;
    public actionSheet: HTMLIonActionSheetElement = null;

    public loadPreviousEventsRequest: Promise<any> = null;
    public noPreviousEvents = false;
    public latestMessage: ConversationEvent = null;
    public earliestMessage: ConversationEvent = null;
    private previousHeight: number = null;

    @Input() currentMessenger: any;
    @ViewChild('messageInput') public messageInputRef: ElementRef<HTMLTextAreaElement>;
    @ViewChild('scrollable') scrollableRef: ElementRef;
    @ViewChild('wrapper') wrapperRef: ElementRef;

    @Input() public allowSending = true;
    @Input() public conversation: Conversation = null;

    constructor(
        public actionSheetController: ActionSheetController,
        private biometric: BiometricService,
        private camera: Camera,
        private directMessaging: DirectMessagingService,
        private file: CordovaFile,
        private groupMessaging: GroupMessagingService,
        private keyboard: Keyboard,
        private platform: Platform,
    ) {
        this.onConversationChanged.asObservable().pipe(
            debounceTime(20),
            takeUntil(this.onDestroy)
        ).subscribe(conversation => {
            if (!conversation) { this.events = null; }
            else if (conversation instanceof DirectMessageConversation) {
                this.directMessaging.getAllEvents(conversation.id).pipe(
                    debounceTime(20),
                    takeUntil(this.onConversationChanged),
                    takeUntil(this.onDestroy),
                ).subscribe(events => {
                    this.events = events.reverse();

                    if (!this.events.length) {
                        return;
                    }

                    const latestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) < new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    const earliestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) > new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    if (!(this.latestMessage?.sentAt) || new Date(latestMessage.sentAt) > new Date(this.latestMessage.sentAt)) {
                        this.latestMessage = latestMessage;
                        this.scrollToBottom();
                    }
                    if (!(this.earliestMessage?.sentAt)) {
                        this.earliestMessage = earliestMessage;
                    } else if (new Date(earliestMessage.sentAt) < new Date(this.earliestMessage.sentAt)) {
                        this.earliestMessage = earliestMessage;
                        this.scrollToPrevious();
                    }

                });
            } else if (conversation instanceof GroupMessageConversation) {
                this.groupMessaging.getEvents(conversation.id).pipe(
                    debounceTime(20),
                    takeUntil(this.onConversationChanged),
                    takeUntil(this.onDestroy),
                ).subscribe(events => {
                    this.events = events.reverse();

                    if (!this.events.length) {
                        return;
                    }

                    const latestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) < new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    const earliestMessage = this.events.reduce((previousValue, currentValue) => {
                        return new Date(previousValue.sentAt) > new Date(currentValue.sentAt) ? currentValue : previousValue;
                    });

                    if (!(this.latestMessage?.sentAt) || new Date(latestMessage.sentAt) > new Date(this.latestMessage.sentAt)) {
                        this.latestMessage = latestMessage;
                        this.scrollToBottom();
                    }
                    if (!(this.earliestMessage?.sentAt)) {
                        this.earliestMessage = earliestMessage;
                    } else if (new Date(earliestMessage.sentAt) < new Date(this.earliestMessage.sentAt)) {
                        this.earliestMessage = earliestMessage;
                        this.scrollToPrevious();
                    }
                });
            }
        });

        this.keyboard.onKeyboardDidShow().subscribe(() => {
            this.scrollToBottom();
        });
    }

    public onScroll(event: any) {
        if (event.target.scrollTop === 0) {
            this.loadPreviousEvents();
        }
    }

    public loadPreviousEvents() {
        if (this.noPreviousEvents || this.loadPreviousEventsRequest) {
            return this.loadPreviousEventsRequest;
        }
        this.previousHeight = this.scrollableRef.nativeElement.clientHeight;

        if (this.events.length) {
            if (this.conversation instanceof DirectMessageConversation) {
                this.loadPreviousEventsRequest = this.directMessaging.fetchAllEvents(this.conversation.id, {
                    before: this.earliestMessage.sentAt
                }).then(result => {
                    if (!result.length) {
                        this.noPreviousEvents = true;
                    }
                    this.loadPreviousEventsRequest = null;
                    return result;
                });
            }
            if (this.conversation instanceof GroupMessageConversation) {
                this.loadPreviousEventsRequest = this.groupMessaging.fetchEvents(this.conversation.id, {
                    before: this.earliestMessage.sentAt
                }).then(result => {
                    if (!result.length) {
                        this.noPreviousEvents = true;
                    }
                    this.loadPreviousEventsRequest = null;
                    return result;
                });
            }
        }
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('conversation' in changes) {
            this.onConversationChanged.next(changes.conversation.currentValue);
            // this.scrollToBottom();
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public async clickImageUpload() {
        if (this.platform.is('android')) {
            await this.presentAddImageAction();
        } else {
            this.selectPhoto();
        }
    }

    public onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];
        if (this.platform.is('android')) {
            this.biometric.cameraCompleted();
        }
        this.sendMessage();
    }

    public scrollToBottom() {
        if (!this.wrapperRef || !this.scrollableRef) { return; }

        setTimeout(() => {
            const wrapper: HTMLElement = this.wrapperRef.nativeElement;
            const scrollable: HTMLElement = this.scrollableRef.nativeElement;
            wrapper.scrollTop = scrollable.clientHeight;
        }, 1);
    }

    public scrollToPrevious() {
        if (!this.wrapperRef || !this.scrollableRef) { return; }

        setTimeout(() => {
            const wrapper: HTMLElement = this.wrapperRef.nativeElement;
            const scrollable: HTMLElement = this.scrollableRef.nativeElement;
            wrapper.scrollTop = (scrollable.clientHeight - this.previousHeight);
        }, 1);
    }

    public sendMessage() {
        if (!this.attachment && !this.newMessage?.trim()) {
            return;
        }
        const message = this.newMessage.trim();
        this.newMessage = '';

        let attachments = null;
        if (this.attachment) {
            attachments = [ this.attachment ];
            this.attachment = null;
        }

        if (this.messageInputRef) {
            this.messageInputRef.nativeElement.focus();
        }

        if (this.conversation instanceof DirectMessageConversation) {
            this.directMessaging.sendMessage(this.conversation.id, message, attachments);
        }
        if (this.conversation instanceof GroupMessageConversation) {
            this.groupMessaging.sendMessage(this.conversation.id, message, attachments);
        }
    }


    private async androidTakePhoto() {
        this.biometric.cameraTriggered();

        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            targetWidth: 1280,
            targetHeight: 1280,
        };

        this.camera.getPicture(options).then(async (tempFile: string) => {
            const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
            const tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
            const directory = await this.file.resolveDirectoryUrl(tempBaseFilesystemPath);
            const file = await this.file.getFile(directory, tempFilename, {});

            const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = event => resolve(event.target.result as ArrayBuffer);
                fileReader.readAsArrayBuffer(input);
            });

            file.file(async (resFile: IFile) => {
                const buffer = await getArrayBufferForFile(resFile);

                this.attachment = new WebFile([buffer], resFile.name, { lastModified: Date.now() });

                this.biometric.cameraCompleted();

                this.sendMessage();

                if (this.actionSheet) {
                    this.actionSheet.dismiss();
                }
            });
        });
    }

    private selectPhoto() {
        if (this.platform.is('android')) {
            this.biometric.cameraTriggered();
        }
        this.imageInput.click();
    }

    private presentAddImageAction() {
        this.actionSheetController.create({
            buttons: [{
                text: 'Photo Library',
                icon: 'image-outline',
                handler: () => this.selectPhoto()
            }, {
                text: 'Take Photo',
                icon: 'camera-outline',
                handler: () => this.androidTakePhoto()
            }]
        }).then(async (actionSheet) => {
            this.actionSheet = actionSheet;
            await actionSheet.present();
        });
    }

    public onKeyPress(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            if (!event.shiftKey && !this.platform.is('cordova')) {
                event.preventDefault();
                this.sendMessage();
            }
        }
    }

    public trackById<T extends AbstractSyncedModel>(index, item: T): any {
        return item.id;
    }

    public isParticipantAddedEvent(event: GroupEvent) {
        return event instanceof GroupMessageParticipantAddedEvent;
    }

    public isParticipantRemovedEvent(event: GroupEvent) {
        return event instanceof GroupMessageParticipantRemovedEvent;
    }

    public isParticipantLeftEvent(event: GroupEvent) {
        return event instanceof GroupMessageParticipantLeftEvent;
    }

    public messageIsNewDay(first: string, second: string) {
        const firstDate = parseISO(first);
        const secondDate = parseISO(second);

        return !isSameDay(firstDate, secondDate);
    }

    public isToday(date: string) {
        return isToday(parseISO(date));
    }

    public isYesterday(date: string) {
        return isYesterday(parseISO(date));
    }

    public isThisWeek(date: string) {
        return !this.isToday(date) &&
            !this.isYesterday(date) &&
            isThisWeek(parseISO(date), {weekStartsOn: getDay(new Date().getDate() + 1)});
    }

    public isOlder(date: string) {
        return !this.isToday(date) && !this.isYesterday(date) && !this.isThisWeek(date);
    }
}
