import { EphemeralStore } from './ephemeral.store';
import { DirectMessageConversation } from '../models/direct-message-conversation';

export class DirectMessageStore extends EphemeralStore<DirectMessageConversation> {

    public findById(id: string): DirectMessageConversation {
        return this.find(conversation => conversation.id === id);
    }

    public findByUserId(id: string): DirectMessageConversation {
        return this.find(conversation => conversation.recipient.id === id);
    }

}
