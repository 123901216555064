import { Component, ViewChild, ElementRef } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password-sent-page',
    template: '',
    styleUrls: ['forgot-password-sent.scss'],
})

export abstract class ForgotPasswordSent {

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private navCtrl: NavController,
        private router: Router,
    ) {
    }

    public goBackToPreviousPage() {
        this.router.navigate(['/forgot-password']);
    }

    public resendLink() {
        this.cognitoAuth.forgotPasswordResend().then(forgotPasswordResponse => {}).catch(error => {});
    }

    public ionViewWillEnter() {
        if (!this.cognitoAuth.hasForgotPasswordResponse()) {
            this.router.navigate(['/forgot-password']);
        }
    }

}
