import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CasesService } from '../../pages/cases/cases.service';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.html',
    styleUrls: ['menu.scss'],
})
export class MenuComponent implements AfterViewInit, OnDestroy {

    private isDestroyed: Subject<void> = new Subject<void>();

    public menu: HTMLIonMenuElement = null;
    public user: User = null;
    public versionCode: string|number = null;
    public versionNumber: string = null;

    constructor(
        private appVersion: AppVersion,
        private caseService: CasesService,
        private menuCtrl: MenuController,
        private router: Router,
        private platform: Platform,
        private userService: UserService,
    ) {
        this.userService.getMe().pipe(takeUntil(this.isDestroyed)).subscribe(user => this.user = user);
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                this.getAppVersion();
            }
        });
    }

    public ngAfterViewInit(): void {
        this.menuCtrl.get('first').then(menu => {
            this.menu = menu;
            this.menu.addEventListener('ionWillOpen', () => this.userService.fetchMe());
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public closeMenu() {
        this.menu.close();
    }

    public goToDashboard() {
        this.router.navigate(['/dashboard']);
        this.menu.close();
    }

    public goToMyMessages() {
        this.router.navigate(['/my-messages']);
        this.menu.close();
    }

    public goToIncomingCases() {
        this.router.navigate(['cases', 'incoming']);
        this.menu.close();
    }

    public goToMyCases() {
        this.router.navigate(['cases']);
        this.menu.close();
    }

    public goToMyProfile() {
        this.router.navigate(['/my-profile']);
        this.menu.close();
    }

    public get unassignedCaseCount() {
        return this.caseService.unassignedCases.length;
    }

    private async getAppVersion() {
        this.versionCode = await this.appVersion.getVersionCode();
        this.versionNumber = await this.appVersion.getVersionNumber();
    }

}
