import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { UserService } from '../../services/user.service';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { User } from '../../services/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-message-group-rename-page',
    template: '',
    styleUrls: ['message-group-rename.scss'],
})

export abstract class MessageGroupRename implements OnDestroy, OnInit {

    public conversation: GroupMessageConversation = null;
    private conversationId: string = null;
    public user: User = null;
    private userId: string = null;
    public adminParticipant = false;
    private currentUser: User = null;
    public currentUserAdmin = false;
    public onDestroy: Subject<void> = new Subject<void>();
    public userReady: Subject<void> = new Subject<void>();
    public conversationName: string = null;

    constructor(
        public alertController: AlertController,
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private navCtrl: NavController,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ){
        route.params.subscribe((params: { conversation: string, user: string }) => {
            if (params.conversation !== this.conversationId) {
                this.conversationId = params.conversation;
                this.setConversation(params.conversation);
            }
            if (params.user !== this.userId) {
                this.userId = params.user;
                this.setUser(params.user);
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => {
            this.currentUser = me;
            if (me) {
                this.userReady.next();
            }
            if (me && this.conversation) {
                const currentUserParticipant = this.conversation.members.find(participant => participant.id === this.currentUser.id);
                if (!currentUserParticipant?.admin) {
                    this.navigateBack();
                }
            }
        });
    }

    public async saveGroupName(){
        if (this.conversation) {
            const renamed = await this.groupMessaging.renameGroup(this.conversation.id, this.conversationName);
            if (renamed) {
                await this.navigateBack();
            }
        }
    }

    public setConversation(conversationId: string) {
        this.groupMessaging.getGroup(conversationId).subscribe((conversation) => {
            this.conversation = conversation;
            if (conversation instanceof GroupMessageConversation) {
                this.conversationName = conversation.name;

                if (this.currentUser) {
                    const currentUserParticipant = conversation.members.find(participant => participant.id === this.currentUser.id);
                    this.currentUserAdmin = currentUserParticipant?.admin;
                }
            }
        });
        this.groupMessaging.fetchGroup(conversationId).then(() => {
            this.groupMessaging.fetchEvents(conversationId, {
                before: new Date().toISOString()
            });
        });
    }

    public setUser(userId: string) {
        this.userService.getUser(userId).subscribe((user) => {
            this.user = user;
        });
        this.userService.fetchUser(userId);
    }

    public async navigateBack() {
        this.navCtrl.setDirection('back', this.platform.is('mobile'));
        await this.router.navigate([`/my-messages/group/${this.conversationId}/info`]);
    }

}
