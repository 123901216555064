import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header';
import { ButtonComponent } from './button/button';
import { DirectivesModule } from '../directives/directives.module';
import { SidebarComponent } from './sidebar/sidebar';
import { DrawerComponent } from './drawer/drawer';
import { IonBottomDrawerModule } from 'ion-bottom-drawer';
import { CasesListComponent } from './cases-list/cases-list';
import { MessageListComponent } from './message-list/message-list';
import { NotificationBarComponent } from './notification-bar/notification-bar';
import { NewMessagesWidgetComponent } from './new-messages-widget/new-messages-widget';
import { CasesWidgetComponent } from './cases-widget/cases-widget';
import { MessengerComponent } from './messenger/messenger';
import { SearchComponent } from './search/search';
import { UserListItemComponent } from './user-list-item/user-list-item';
import { UserDetailComponent } from './user-detail/user-detail';
import { MenuComponent } from './menu/menu';
import { MediaGridComponent } from './media-grid/media-grid';
import { MessageComponent } from './message/message';
import { UnsafeUrlPipe } from '../helpers/pipes/unsafeUrlPipe';
import { AppPendingMessageComponent } from './message/pending-message/pending-message';
import { PipesModule } from '../pipes/pipes.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner';
import { TabsComponent } from './tabs/tabs';
import { TabComponent } from './tab/tab';
import { CaseMessengerComponent } from './case-messenger/case-messenger';
import { AppCaseMessageComponent } from './message/case-message/case-message';
import { OfflineNotificationComponent } from './offline-notification/offline-notification';
import { AutosizeModule } from 'ngx-autosize';
import { AppVersion } from '@ionic-native/app-version/ngx';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DirectivesModule,
        IonBottomDrawerModule,
        PipesModule,
        AutosizeModule,
    ],
    exports: [
        ButtonComponent,
        SidebarComponent,
        CasesListComponent,
        DrawerComponent,
        MessageListComponent,
        NotificationBarComponent,
        NewMessagesWidgetComponent,
        CasesWidgetComponent,
        MessengerComponent,
        SearchComponent,
        UserListItemComponent,
        HeaderComponent,
        UserDetailComponent,
        MenuComponent,
        MediaGridComponent,
        MessageComponent,
        LoadingSpinnerComponent,
        TabsComponent,
        TabComponent,
        CaseMessengerComponent,
        AppCaseMessageComponent,
        OfflineNotificationComponent,
    ],
    declarations: [
        ButtonComponent,
        SidebarComponent,
        CasesListComponent,
        DrawerComponent,
        MessageListComponent,
        NotificationBarComponent,
        NewMessagesWidgetComponent,
        CasesWidgetComponent,
        MessengerComponent,
        SearchComponent,
        UserListItemComponent,
        HeaderComponent,
        UserDetailComponent,
        MenuComponent,
        MediaGridComponent,
        MessageComponent,
        AppPendingMessageComponent,
        MessageComponent,
        LoadingSpinnerComponent,
        TabsComponent,
        TabComponent,
        CaseMessengerComponent,
        AppCaseMessageComponent,
        OfflineNotificationComponent,
        UnsafeUrlPipe
    ],
    providers: [
        AppVersion,
    ]
})
export class ComponentsModule {
}
