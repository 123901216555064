import { Platform } from '@ionic/angular';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { OrganisationService } from './abstract.service';
import { MobileOrganisationService } from './mobile.service';
import { BrowserOrganisationService } from './browser.service';
import { PrivateSettingsService } from '../private-settings.service';

export const OrganisationServiceFactory = (
    api: ApiClientService,
    database: DatabaseService,
    platform: Platform,
    storage: PrivateSettingsService,
): OrganisationService => {
    const service = platform.is('cordova') ? MobileOrganisationService : BrowserOrganisationService;
    return new service(api, database, storage);
};
