import { Component } from '@angular/core';
import { MessageGroupSharedImages } from './message-group-shared-images';

@Component({
    selector: 'app-tablet-message-group-shared-images-page',
    templateUrl: 'tablet-message-group-shared-images.html',
    styleUrls: ['message-group-shared-images.scss', 'tablet-message-group-shared-images.scss'],
})
export class TabletMessageGroupSharedImagesPage extends MessageGroupSharedImages{

}
