import { EphemeralStore } from './ephemeral.store';
import { BranchUser } from '../models/branch-user';
import { User } from '../models/user';

export class BranchUserStore extends EphemeralStore<BranchUser> {

    public findById(id: string): BranchUser {
        return this.find(branchUser => branchUser.branch_id === id);
    }

    public getUsers(id: string): User[] {
        return this.findById(id)?.users;
    }

}
