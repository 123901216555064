import { Pipe, PipeTransform } from '@angular/core';
import { format as formatDate, parseISO } from 'date-fns';

@Pipe({
    name: 'dateFns'
})
export class DateFnsPipe implements PipeTransform {

    public transform(value: Date | number | string, format: string): any {
        if (typeof value === 'string') {
            return this.transform(parseISO(value), format);
        }
        return formatDate(value, format);
    }

}
