import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { CaseConversation } from '../../services/models/case-conversation';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { format as formatDate, isSameDay, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';
import { AbstractSyncedModel } from '../../services/models/abstract-synced-model';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';

@Component({
    selector: 'app-cases-list',
    templateUrl: 'cases-list.html',
    styleUrls: ['cases-list.scss'],
})

export class CasesListComponent implements OnChanges, OnDestroy, OnInit {

    @Input('cases') public cases: CaseConversation[] = [];
    @Input('mode') public mode: ('default' | 'assignment') = 'default';

    @Output('select') public selectEvent: EventEmitter<CaseConversation> = new EventEmitter<CaseConversation>();

    private casesChanged: Subject<CaseConversation[]> = new Subject<CaseConversation[]>();
    private currentCase: string = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    public currentUser: User = null;

    public get openCases(): CaseConversation[] {
        return this.cases;
    }

    constructor(
        public api: ApiClientService,
        private caseMessaging: CaseMessagingService,
        private route: ActivatedRoute,
        private userService: UserService,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ('cases' in changes) {
            this.casesChanged.next(changes.cases.currentValue);
        }
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.isDestroyed)).subscribe(me => this.currentUser = me);
        this.casesChanged.asObservable().pipe(
            debounceTime(0, animationFrame),
            takeUntil(this.isDestroyed),
        ).subscribe(cases => {
            for (const conversation of cases) {
                this.caseMessaging.getEvents(conversation.id).pipe(
                    debounceTime(0, animationFrame),
                    takeUntil(this.casesChanged),
                    takeUntil(this.isDestroyed),
                ).subscribe(events => conversation.events = events);
            }
        });

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('id')) {
                if (this.currentCase !== params.id) {
                    this.currentCase = params.id;
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public getCaseClasses(conversation: CaseConversation): string[] {
        const classes = [];

        if (conversation.isOpen) {
            classes.push('open');
        }
        if (conversation.isResolved) {
            classes.push('resolved');
        }

        return classes;
    }

    public getMessageClasses(conversation: CaseConversation) {
        const classes: string[] = [];

        if (conversation.id === this.currentCase) {
            classes.push('current-message');
        }

        const latestMessage = conversation.latestMessage;

        if (latestMessage) {
            const sentByMe = latestMessage.sentBy?.id === this.currentUser?.id;

            switch (latestMessage.status) {
                case 'sent':
                case 'delivered':
                    classes.push(sentByMe ? 'sent-message' : 'new-message');
                    break;
                case 'read':
                    classes.push(sentByMe ? 'seen-message' : 'opened-message');
                    break;
                default:
                    classes.push('opened-message');
                    break;
            }
        } else {
            classes.push('opened-message');
        }

        return classes;
    }

    public shouldDisplayDateSeparator(conversation: CaseConversation, cases: CaseConversation[]): boolean {
        const currentIndex = cases.findIndex(item => item.id === conversation.id);
        const previousIndex = currentIndex - 1;

        if (previousIndex < 0) { return true; }

        const previousConversation = cases[previousIndex];
        const current = conversation.updatedAt;
        const previous = previousConversation.updatedAt;

        return !isSameDay(current, previous);
    }


    public getDateSeparator(conversation: CaseConversation): string {
        const then = conversation.updatedAt;

        if (isToday(then)) { return 'Today'; }
        if (isYesterday(then)) { return 'Yesterday'; }
        if (isThisWeek(then)) { return formatDate(then, 'EEEE'); }

        const format = isThisYear(then) ? 'do MMMM' : 'do MMMM Y';

        return formatDate(then, format);
    }

    public selectCase(conversation: CaseConversation) {
        this.selectEvent.emit(conversation);
    }

    public trackById<T extends AbstractSyncedModel>(index, item: T): any {
        return item.id;
    }

}
