import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentService } from '../../../services/attachment.service';
import { AlertController, ModalController } from '@ionic/angular';
import { User } from '../../../services/models/user';
import { UserService } from '../../../services/user.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { CaseConversation } from '../../../services/models/case-conversation';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'app-cases-select-assignee-page',
    templateUrl: 'select-assignee.html',
    styleUrls: ['select-assignee.scss'],
})
export class SelectAssigneePage implements OnDestroy, OnInit {

    public currentUser: User = null;
    public searchTerm = '';
    public users: User[] = [];

    @Input() case: CaseConversation;

    private onDestroy: Subject<void> = new Subject<void>();

    public get filteredUsers(): User[] {
        return this.users.filter(user => {
            return user.organisation?.id === this.currentUser?.organisation?.id;
        }).filter(user => {
            if (!this.searchTerm) { return true; }
            return user.fullName.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
    }

    constructor(
        private alertCtrl: AlertController,
        private api: ApiClientService,
        private attachments: AttachmentService,
        private modalCtrl: ModalController,
        private organisations: OrganisationService,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(
            takeUntil(this.onDestroy)
        ).subscribe(me => this.currentUser = me);

        // this.api.branches.allUsers().pipe(
        //     takeUntil(this.onDestroy)
        // ).subscribe(users => console.log('branchUsers: ', users));

        this.organisations.getBranchUsers(this.case.recipientBranch?.organisation?.id, this.case.recipientBranch?.id).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(users => {
            this.users = users;
            // console.log('branchUsers:', users);
        });

        this.organisations.fetchBranchUsers(this.case.recipientBranch?.organisation?.id, this.case.recipientBranch?.id);

        // this.userService.getUsers().pipe(
        //     takeUntil(this.onDestroy)
        // ).subscribe(users => this.users = users);
    }

    public async select(user: User) {
        const alert = await this.alertCtrl.create({
            cssClass: 'custom-alert',
            message: `Are you sure you want to assign '${user.friendlyName}' to this case?`,
            buttons: [
                {
                    text: '',
                    role: 'cancel',
                    cssClass: 'close',
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Assign',
                    cssClass: 'primary',
                    handler: () => this.dismiss(user)
                }
            ]
        });

        await alert.present();
    }

    public dismiss(result?: User) {
        return this.modalCtrl.dismiss(result);
    }

}
