import { Component, Input } from '@angular/core';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Conversation } from '../../pages/my-messages/my-messages';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';

@Component({
    selector: 'app-media-grid',
    templateUrl: 'media-grid.html',
    styleUrls: ['media-grid.scss'],
})
export class MediaGridComponent {

    public attachments: string[] = [];

    @Input() public set conversation(conversation: Conversation) {
        if (conversation?.events) {
            this.attachments = [];
            if (conversation instanceof DirectMessageConversation) {
                conversation.events.forEach(event => {
                    if (event.type === 'message') {
                        if (event.attachments) {
                            event.attachments.forEach(attachment => {
                                if (!this.attachments.includes(attachment)) {
                                    this.attachments.push(attachment);
                                }
                            });
                        }
                    }
                });
            }
            if (conversation instanceof GroupMessageConversation) {
                conversation.events.forEach(event => {
                    if (event instanceof GroupMessageEvent) {
                        event.attachments.forEach(attachment => {
                            if (!this.attachments.includes(attachment)) {
                                this.attachments.push(attachment);
                            }
                        });
                    }
                });
            }
        }
    }

    constructor(
        public api: ApiClientService,
    ) {
    }
}
