import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DirectMessageConversation } from '../../services/models/direct-message-conversation';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { DirectMessagingService } from '../../services/direct-messaging.service';
import { AbstractSyncedModel } from '../../services/models/abstract-synced-model';
import { Platform } from '@ionic/angular';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { Conversation } from '../../pages/my-messages/my-messages';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { getDay, isToday, isYesterday, isThisWeek } from 'date-fns';
import { GroupMessageReceipt } from '../../services/models/group-messaging/group-message-receipt';
import { GroupMessageEvent } from '../../services/models/group-messaging/group-message-event';

@Component({
    selector: 'app-message-list',
    templateUrl: 'message-list.html',
    styleUrls: ['message-list.scss'],
})
export class MessageListComponent implements OnChanges, OnDestroy, OnInit {

    @Input() public conversations: Conversation[] = null;
    @Output('select') public selectEventEmitter = new EventEmitter<Conversation>();

    private conversationsUpdated: Subject<Conversation[]> = new Subject<Conversation[]>();
    private currentMessage: string = null;
    private currentUser: User = null;
    private eventsUpdated: Subject<Conversation> = new Subject<Conversation>();
    private isDestroyed: Subject<void> = new Subject<void>();

    public messageClasses: { [id: string]: string[] } = {};

    constructor(
        public api: ApiClientService,
        private directMessaging: DirectMessagingService,
        private groupMessaging: GroupMessagingService,
        private route: ActivatedRoute,
        private router: Router,
        private platform: Platform,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(takeUntil(this.isDestroyed)).subscribe(me => this.currentUser = me);

        this.conversationsUpdated.asObservable().pipe(
            debounceTime(20),
            takeUntil(this.isDestroyed),
        ).subscribe(conversations => {
            for (const conversation of conversations) {
                if (conversation instanceof DirectMessageConversation) {
                    this.eventsUpdated.next(conversation);
                    this.directMessaging.getAllEvents(conversation.id).pipe(
                        debounceTime(20),
                        takeUntil(this.conversationsUpdated),
                        takeUntil(this.isDestroyed),
                    ).subscribe(events => {
                        conversation.events = events;
                        this.eventsUpdated.next(conversation);
                    });
                }
                if (conversation instanceof GroupMessageConversation) {
                    this.eventsUpdated.next(conversation);
                    this.groupMessaging.getEvents(conversation.id).pipe(
                        debounceTime(20),
                        takeUntil(this.conversationsUpdated),
                        takeUntil(this.isDestroyed),
                    ).subscribe(events => {
                        conversation.events = events;
                        this.eventsUpdated.next(conversation);
                    });
                }
            }
        });

        this.eventsUpdated.asObservable().pipe(
            debounceTime(20),
            takeUntil(this.isDestroyed),
        ).subscribe(() => {
            for (const conversation of this.conversations) {
                this.messageClasses[conversation.id] = this.getMessageClasses(conversation);
            }
        });

        this.router.events.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(event => {
            if (event instanceof ActivationEnd) {
                const params = event.snapshot.params;
                if (params.id && this.currentMessage !== params.id) {
                    this.currentMessage = params.id;

                    for (const conversation of this.conversations) {
                        this.messageClasses[conversation.id] = this.getMessageClasses(conversation);
                    }
                }
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('conversations' in changes) {
            this.conversationsUpdated.next(changes.conversations.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public getMessageClasses(conversation: Conversation) {
        const classes: string[] = [];

        if (conversation.id === this.currentMessage) {
            classes.push('current-message');
        }

        const latestMessage = conversation.latestMessage;
        const me = this.currentUser?.id;

        if (!latestMessage || !me) {
            if (conversation instanceof GroupMessageConversation && !latestMessage) {
                classes.push('opened-message');
            }
            return classes;
        }

        if (conversation instanceof DirectMessageConversation) {
            if (latestMessage.sentBy === me) {
                if (!latestMessage.sentAt) { classes.push('none'); }
                else if (!latestMessage.deliveredAt) { classes.push('sent-message'); }
                else if (!latestMessage.readAt) { classes.push('sent-message'); }
                else { classes.push('seen-message'); }
            } else {
                if (!latestMessage.readAt) { classes.push('new-message'); }
                else { classes.push('opened-message'); }
            }
        }

        if (conversation instanceof GroupMessageConversation) {
            if (latestMessage instanceof GroupMessageEvent) {
                if (latestMessage.sentBy.id === me) {
                    if (!latestMessage.sentAt) { classes.push('none'); }
                    else if (!latestMessage.deliveredAt.length) { classes.push('sent-message'); }
                    else if (!latestMessage.readAt.length) { classes.push('sent-message'); }
                    else { classes.push('seen-message'); }
                } else {
                    if (latestMessage.readBy(me)) {
                        classes.push('opened-message');
                    } else {
                        classes.push('new-message');
                    }
                }

            } else {
                classes.push('none');
            }

        }

        return classes;
    }

    public selectConversation(conversation: Conversation) {
        this.selectEventEmitter.emit(conversation);
    }

    public trackById<T extends AbstractSyncedModel>(index, item: T): any {
        return item.id;
    }

    public isDirectMessageConversation(conversation: Conversation) {
        return conversation instanceof DirectMessageConversation;
    }

    public isGroupMessageConversation(conversation: Conversation) {
        return conversation instanceof GroupMessageConversation;
    }

    public isToday(date: Date) {
        return isToday(date);
    }

    public isYesterday(date: Date) {
        return isYesterday(date);
    }

    public isThisWeek(date: Date) {
        return !this.isToday(date) && !this.isYesterday(date) && isThisWeek(date, {weekStartsOn: getDay(new Date().getDate() + 1)});
    }

    public isOlder(date: Date) {
        return !this.isToday(date) && !this.isYesterday(date) && !this.isThisWeek(date);
    }

}
