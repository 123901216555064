import { Component } from '@angular/core';
import { ForgotPasswordSent } from './forgot-password-sent';

@Component({
    selector: 'app-tablet-forgot-password-sent-page',
    templateUrl: 'tablet-forgot-password-sent.html',
    styleUrls: ['forgot-password-sent.scss', 'tablet-forgot-password-sent.scss'],
})
export class TabletForgotPasswordSentPage extends ForgotPasswordSent {

}
