import { GroupEvent } from './group-event';
import { GroupMessageParticipantAddedEvent as ApiGroupMessageParticipantAddedEvent } from '@scaffold/mediccoms-api-client/models/group-message-participant-added-event';
import { GroupMessageParticipant } from './group-message-participant';
import { ConversationParticipantAdded, RtmsEvent } from '../rtm-events.model';

export class GroupMessageParticipantAddedEvent extends GroupEvent {

    public type: string;
    public addedBy: string;
    public participant: GroupMessageParticipant;

    public static mapFromApi(data: Partial<ApiGroupMessageParticipantAddedEvent>): Partial<GroupMessageParticipantAddedEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            type: 'participant_added',
            addedBy: data.added_by,
            participant: GroupMessageParticipant.mapFromApi(data.participant) as Required<GroupMessageParticipant>,
            sentAt: data.sent_at,
        };
    }

    public static mapFromRtms(data: Partial<ConversationParticipantAdded>): Partial<GroupMessageParticipantAddedEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.data.event_id,
            type: 'participant_added',
            addedBy: data.sender_id,
            participant: GroupMessageParticipant.mapFromRtms(data.data.participant) as Required<GroupMessageParticipant>,
            sentAt: data.timestamp,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            conversation: this.conversation,
            participant: this.participant,
            sentAt: this.sentAt,
            addedBt: this.addedBy,
        };
    }

    public fill(data: Partial<GroupMessageParticipantAddedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.addedBy !== undefined) {
            this.addedBy = data.addedBy;
        }
        if (data.participant !== undefined) {
            this.participant = GroupMessageParticipant.createOne(data.participant);
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }
}
