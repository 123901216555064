import { Component } from '@angular/core';
import { CaseSentPage } from './sent';

@Component({
    selector: 'app-phone-case-sent-page',
    templateUrl: 'sent.html',
    styleUrls: ['sent.scss', 'phone-sent.scss'],
})
export class PhoneCaseSentPage extends CaseSentPage {

}
