/* tslint:disable:unified-signatures */
import { SettingsService } from './abstract.service';
import { DatabaseService } from '../database.service';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Settings } from '../models/settings';

export class MobileSettingsService extends SettingsService {
    private settings: { [id: string]: string } = {};

    private fetch(id: string): Observable<string> {
        return from(this.database.settings.findOne(id)).pipe(
            map(setting => {
                if (setting) {
                    return setting.value;
                }
                return null;
            }),
            tap(setting => this.settings[id] = setting)
        );
    }

    public get(id: string): Promise<string> {
        return new Promise<string>((resolve => {
            if (this.settings[id]) {
                resolve(this.settings[id]) ;
            } else {
                this.fetch(id).subscribe(resolve);
            }
        }));
    }

    public set(id: string, value: string) {
        if (this.settings[id] !== value) {
            this.settings[id] = value;
            return this.database.settings.save(Settings.createOne({id, value}));
        }
        return new Promise<any>(resolve => resolve(this.settings[id]));
    }

    public remove(id: string) {
        delete this.settings[id];
        return this.set(id, '');
    }

    public ready(): Promise<void> {
        return this.database.sharedReady();
    }
}
