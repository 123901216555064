import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Platform } from '@ionic/angular';
import { File as CordovaFile, FileEntry } from '@ionic-native/file/ngx';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AttachmentService {

    private downloadRequests: { [k: string]: Subject<any> } = {};
    private urlStore: { [k: string]: { [s: string]: string }[] } = {};

    constructor(
        private apiClient: ApiClientService,
        private file: CordovaFile,
        private http: HttpClient,
        private platform: Platform,
    ) {
    }

    private fetchImageUrl(imageId: string, size: string) {
        const url = this.apiClient.attachments.getImageUrl(imageId, size);
        if (!this.urlStore.hasOwnProperty(imageId)) {
            this.urlStore[imageId] = [];
        }
        this.urlStore[imageId][size] = url;

        return url;
    }

    public getImageUrl(imageId: string, size: string = 'original'): Promise<string | null> {
        if (this.platform.is('cordova')) {
            return new Promise<string | null>((resolve) => {
                if (this.urlStore.hasOwnProperty(imageId) && this.urlStore[imageId].hasOwnProperty(size)) {
                    return resolve(this.urlStore[imageId][size]);
                }
                this.file.checkFile(this.file.dataDirectory, 'attachments/' + imageId + '_' + size)
                    .then(async file => {
                        if (file) {
                            const dir = await this.file.resolveDirectoryUrl(this.file.dataDirectory);
                            const dirEntity = await this.file.getDirectory(dir, 'attachments', {});
                            this.file.getFile(dirEntity, imageId + '_' + size, {}).then((fe) => {
                                const safeUrl = (window as any).Ionic.WebView.convertFileSrc(fe.toURL());
                                if (!this.urlStore.hasOwnProperty(imageId)) {
                                    this.urlStore[imageId] = [];
                                }
                                this.urlStore[imageId][size] = safeUrl;
                                return resolve(safeUrl);
                            });
                        } else {
                            this.download(imageId, size).subscribe((response) => {
                                this.processDownload(response, imageId, size)
                                    .then(localUrl => resolve(localUrl))
                                    .catch(() => resolve(this.fetchImageUrl(imageId, size)));
                            });
                        }
                    })
                    .catch(() => {
                        this.download(imageId, size).subscribe((response) => {
                            this.processDownload(response, imageId, size)
                                .then(localUrl => resolve(localUrl))
                                .catch(() => resolve(this.fetchImageUrl(imageId, size)));
                        });
                    });
            });
        } else {
            return new Promise<string>(resolve => {
                if (this.urlStore.hasOwnProperty(imageId) && this.urlStore[imageId].hasOwnProperty(size)) {
                    return resolve(this.urlStore[imageId][size]);
                }
                resolve(this.fetchImageUrl(imageId, size));
            });
        }
    }

    private download(imageId: string, size: string): Observable<string> {
        const remoteUrl = this.fetchImageUrl(imageId, size);

        let subject = this.downloadRequests[imageId];
        if (!subject || subject.isStopped) {
            this.downloadRequests[imageId] = subject = new Subject<any>();

            this.http.get(remoteUrl, {observe: 'response', responseType: 'blob'})
                .subscribe(response => {
                    subject.next(response);
                    subject.complete();
                });
        }

        return subject.asObservable();
    }

    private processDownload(response, imageId, size): Promise<string> {
        return new Promise((resolve, reject) => {
            this.file.createDir(this.file.dataDirectory, 'attachments', true)
                .then(de => {
                    this.file.writeFile(de.toURL(), imageId + '_' + size, response.body, {replace: true})
                        .then(async (fe: FileEntry) => {
                            const safeUrl = (window as any).Ionic.WebView.convertFileSrc(fe.toURL());
                            return resolve(safeUrl);
                        })
                        .catch(err => reject(err));
                })
                .catch(err => reject(err));
        });
    }
}
