import { BaseEntity, FindConditions } from 'typeorm';
import { FindOneOptions } from 'typeorm/find-options/FindOneOptions';

export abstract class AbstractModel extends BaseEntity {

    public static async findOrCreateOne<T extends AbstractModel>(
        this: new (...args: any[]) => T,
        conditions?: FindConditions<T>,
        properties?: Partial<T>,
        options?: FindOneOptions<T>): Promise<T> {

        const constructor = this.prototype.constructor as typeof AbstractModel;

        // @ts-ignore
        let result = await constructor.findOne<T>(conditions, options);
        if (!result) {
            // @ts-ignore
            result = constructor.createOne<T>(conditions);
        }
        result.fill(properties || {});
        return result;
    }

    public static createOne<T extends AbstractModel>(this: new (...args: any[]) => T, data?: Partial<T>): T {
        const instance = new this();
        if (data) {
            instance.fill(data);
        }
        return instance;
    }

    public static createMany<T extends AbstractModel>(this: new (...args: any[]) => T, data: Partial<T>[] = []): T[] {
        const instances: T[] = [];
        for (const item of data) {
            const context = this.prototype.constructor;
            const instance: T = context.createOne(item);
            instances.push(instance);
        }

        return instances;
    }

    public clone<T extends AbstractModel>(this: T): T {
        const cloneClass = this.constructor as any;
        const clone: T = new cloneClass();
        clone.fill(this);
        return clone;
    }

    public abstract fill<T>(data: Partial<T>);

    public is(otherModel: Partial<this>): boolean {
        return this === otherModel;
    }

}
