import { Injectable } from '@angular/core';
import { Environment, ENVIRONMENTS } from '../app.constants';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { CognitoAuthenticationService } from './cognito-authentication.service';
import { RealTimeMessagingService } from './real-time-messaging.service';
import { Platform } from '@ionic/angular';
import { SettingsService } from './settings.service';


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    private environment: Environment = null;

    constructor(
        private api: ApiClientService,
        private cognitoAuth: CognitoAuthenticationService,
        private platform: Platform,
        private rtms: RealTimeMessagingService,
        private storage: SettingsService,
    ) {
    }

    public setEnvironmentByHost(host: string): Promise<string> {
        return new Promise<string>(resolve => {
            this.platform.ready().then(async () => {
                if (!this.platform.is('cordova')) {
                    for (const id in ENVIRONMENTS) {
                        const environment = ENVIRONMENTS[id];
                        if (environment.host === host || environment.host === 'localhost' && host.includes('localhost')) {
                            await this.setEnvironment(environment);
                            return environment.name;
                        }
                    }
                }

                await this.setEnvironment(this.defaultEnvironment);
                resolve(this.defaultEnvironment.name);
            });
        });
    }

    public setEnvironment(environment: Environment): Promise<void> {
        console.log('setEnvironment', environment);
        return new Promise<void>(async resolve => {
            await this.storage.set('environment', environment.name);
            this.environment = environment;
            this.cognitoAuth.setClientId(environment.app_client_id);
            this.api.setEndpoint(environment.url);
            this.api.setAuthRefreshHandler(() => this.cognitoAuth.refreshTokenAuthApi());
            this.rtms.setEndpoint(environment.rtm);

            resolve();
        });
    }

    public getEnvironment(): Environment {
        return this.environment || this.defaultEnvironment;
    }

    public setEnvironmentByName(name: string): Promise<void> {
        for (const id in ENVIRONMENTS) {
            const environment = ENVIRONMENTS[id];
            if (environment.name === name) {
                return this.setEnvironment(environment);
            }
        }
        return this.setEnvironment(this.defaultEnvironment);
    }

    public get defaultEnvironment(): Environment {
        let defaultEnvironment: Environment = null;
        for (const key in ENVIRONMENTS) {
            const environment = ENVIRONMENTS[key];
            if (environment.default) {
                defaultEnvironment = environment;
                break;
            }
        }
        return defaultEnvironment;
    }

    public setDefaultEnvironment(): Promise<void> {
        return this.setEnvironment(this.defaultEnvironment);
    }
}
