import { Component } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CaseMessagingService } from '../../../services/case-messaging-service';
import { SelectRecipientPage } from '../select-recipient/select-recipient';
import { OrganisationService } from '../../../services/organisation.service';
import { Branch } from '../../../services/models/branch';

@Component({
    selector: 'app-open-case-page',
    templateUrl: 'open.html',
    styleUrls: ['open.scss'],
})
export class OpenCasePage {

    public hasClickedNext = false;
    public patientId = '';
    public recipient: Branch = null;

    public patientIdPatterns: {
        [character: string]: {
            pattern: RegExp;
            optional?: boolean;
            symbol?: string;
        }
    } = {
        0: {
            pattern: /^\d$/
        },
        X: {
            pattern: /^[\dX]$/
        },
    };

    constructor(
        private caseMessaging: CaseMessagingService,
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController,
        private organisationService: OrganisationService,
        private router: Router,
    ) {

    }

    public canGoNext(): boolean {
        return this.hasRecipient() && this.isPatientIdValid();
    }

    public hasRecipientValidationError(): boolean {
        if (!this.hasClickedNext) { return false; }
        return !this.hasRecipient();
    }

    public hasRecipient(): boolean {
        return this.recipient !== null;
    }

    public hasPatientIdValidationError(): boolean {
        if (!this.hasClickedNext) {
            return false;
        }
        return !this.isPatientIdValid();
    }

    public ionViewDidEnter() {
        this.organisationService.fetchOrganisations().then(organisations => {
            for (const organisation of organisations) {
                this.organisationService.fetchBranches(organisation.id);
            }
        });
    }

    public isPatientIdValid(): boolean {
        if (this.patientId.length < 10) {
            return false;
        }
        return this.isValidMod11(this.patientId);
    }

    public isValidMod11(input: any): boolean {
        if (!input) {
            return false;
        }
        if (typeof input !== 'string') {
            return this.isValidMod11(input.toString());
        }

        if (input.length < 2) { return false; }

        const digits = input.toUpperCase().replace(/[^\dX]/g, '').split('').map(char => {
            if (char === 'X') { return 10; }
            if (char === '0') { return 11; }
            return parseInt(char, 10);
        });

        const checkDigit = digits.pop();
        const mod11 = [0, ...digits, checkDigit].reduce((sum, value, index) => sum + value * (digits.length - index + 2)) % 11;

        return mod11 === 0;
    }

    public async next() {
        this.hasClickedNext = true;

        if (!this.canGoNext()) { return; }

        const loader = await this.loadingCtrl.create();
        await loader.present();

        try {
            const conversation = await this.caseMessaging.createCase(this.recipient.id, this.patientId);
            await this.router.navigate(['cases', conversation.id]);
        } catch (error) {
            console.error(error);
        } finally {
            await loader.dismiss();
        }
    }

    public async selectOrganisation() {
        const modal = await this.modalCtrl.create({
            component: SelectRecipientPage,
        });

        await modal.present();

        const branch = await modal.onWillDismiss().then(result => result.data || null);

        if (branch) { this.recipient = branch; }
    }

}
