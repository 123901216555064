import { User } from '../../services/models/user';
import { UserService } from '../../services/user.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { NetworkService } from '../../services/network.service';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../../services/database.service';
import { GroupMessageConversation } from '../../services/models/group-messaging/group-message-conversation';
import { GroupMessagingService } from '../../services/group-messaging.service';
import { WebFile } from '../../helpers/web-file';
import { HttpEventType } from '@angular/common/http';

@Injectable()
export class GroupParticipantsService {

    private conversation: GroupMessageConversation = null;
    private currentUser: User = null;
    private externalUsers: User[] = [];
    private internalUsers: User[] = [];
    private searchTerm: string = null;
    private users: User[] = [];
    private isNewGroup = true;
    private selectedParticipants: User[] = [];
    private newConversationName: string = null;
    private groupAttachment: WebFile = null;
    private iconId: string = null;

    constructor(
        private alertCtrl: AlertController,
        private api: ApiClientService,
        private database: DatabaseService,
        private groupMessaging: GroupMessagingService,
        private network: NetworkService,
        private platform: Platform,
        private router: Router,
        private userService: UserService,
    ) {
        this.userService.getMe().subscribe(me => this.currentUser = me);
    }

    public async setIcon(file: WebFile): Promise<string> {
        this.groupAttachment = file;
        if (file) {
            const response = await new Promise<string>((resolve, reject) => {
                this.api.attachments.uploadImage(this.groupAttachment).subscribe(result => {
                    if (result.type === HttpEventType.Response) {
                        resolve(result.body.image_id);
                    }
                });
            });

            this.iconId = response;
        } else {
            this.iconId = null;
        }
        return this.iconId;
    }

    public getIcon(): string {
        return this.iconId;
    }

    public async createNewConversation(): Promise<GroupMessageConversation> {
        const participants = [];
        for (const participant of this.selectedParticipants) {
            participants.push(participant.id);
        }
        const response = await this.groupMessaging.createGroup(this.newConversationName, participants, this.iconId);

        this.newConversationName = null;
        this.selectedParticipants = [];
        this.iconId = null;

        return Promise.resolve(response);
    }

    public setNewConversationName(name: string) {
        this.newConversationName = name;
    }

    public setParticipants(participants: User[]) {
        this.selectedParticipants = participants;
    }

    public getParticipants(): User[] {
        return this.selectedParticipants;
    }

    public setExistingGroupType() {
        this.isNewGroup = false;
    }

    public setNewGroupType() {
        this.isNewGroup = true;
    }

    public getInternalUsers(): User[] {
        return this.internalUsers.filter(user => this.filterUser(user));
    }

    public filterUser(user: User) {
        if (this.searchTerm) {
            if (!user.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                return false;
            }
        }
        if (!this.isNewGroup) {
            return !this.conversation.members.find(member => member.id === user.id);
        }
        return true;
    }

    public getExternalUsers(): User[] {
        return this.externalUsers.filter(user => this.filterUser(user));
    }

    public getUsers(): User[] {
        return this.users.filter(user => this.filterUser(user));
    }

    public setUsers(users: User[]) {
        this.users = users.filter(user => user.id !== this.currentUser.id).sort((a, b) => {
            if (a.friendlyName < b.friendlyName) {
                return -1;
            }
            if (a.friendlyName > b.friendlyName) {
                return 1;
            }
            return 0;
        });

        this.internalUsers = this.users.filter(user => user.organisation?.id === this.currentUser.organisation?.id);
        this.externalUsers = this.users.filter(user => user.organisation?.id !== this.currentUser.organisation?.id)
            .filter(user => user.messaging);
    }

    public setConversation(conversation: GroupMessageConversation) {
        this.conversation = conversation;
    }

    public async selectUser(user: User) {
        // TODO: ...
    }

    public getSearchTerm(): string {
        return this.searchTerm || '';
    }

    public setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

}
