/* tslint:disable:variable-name */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { CaseConversation } from '../../services/models/case-conversation';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-my-cases-page',
    template: '',
    styleUrls: ['my-cases.scss'],
})

export class MyCasesPage implements OnDestroy, OnInit {

    private isDestroyed: Subject<void> = new Subject<void>();
    private _searchTerm = '';

    public cases: CaseConversation[] = [];
    public filteredCases: CaseConversation[] = [];

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        this._searchTerm = value;
        this.filterCases();
    }

    constructor(
        private caseMessaging: CaseMessagingService,
        private router: Router,
    ) {
    }

    public createNewCase() {
        this.router.navigate(['open-case-instructions']);
    }

    public filterCases() {
        const searchTerm = this.searchTerm;

        if (!searchTerm) {
            this.filteredCases = this.cases;
            return;
        }

        this.filteredCases = this.cases.filter(item => {
            if (item.patientId.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
            }
            if (item.caseNumber.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
            }
            return false;
        });
    }

    public ngOnInit(): void {
        this.caseMessaging.getMyCases().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(cases => {
            this.setCases(cases.filter(item => item.createdAt));
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public selectCase(conversation: CaseConversation) {
        this.router.navigate(['cases', conversation.id]);
    }

    public setCases(cases: CaseConversation[]) {
        this.cases = cases;
        this.filterCases();
    }

}
