import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from '../../services/database.service';
import { Subject } from 'rxjs';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { NetworkService } from '../../services/network.service';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { CasesService } from './cases.service';
import { CaseConversation } from '../../services/models/case-conversation';
import { CaseMessagingService } from '../../services/case-messaging-service';
import { takeUntil } from 'rxjs/operators';
import { TabsComponent } from '../../components/tabs/tabs';

@Component({
    selector: 'app-cases',
    templateUrl: 'cases.html',
    styleUrls: ['cases.scss'],
})
export class CasesPage implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild('tabs') public tabs: TabsComponent;

    public onDestroy: Subject<void> = new Subject<void>();

    public get searchTerm(): string {
        return this.service.getSearchTerm();
    }

    public set searchTerm(value: string) {
        this.service.setSearchTerm(value);
    }

    public get cases(): CaseConversation[] {
        return this.service.getCases();
    }

    constructor(
        private alertCtrl: AlertController,
        private caseMessaging: CaseMessagingService,
        private api: ApiClientService,
        private database: DatabaseService,
        private network: NetworkService,
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        private navCtrl: NavController,
        private service: CasesService,
    ) {
    }

    public ngOnDestroy(): void {
        this.searchTerm = '';
        this.onDestroy.next();
    }

    public async ngOnInit() {
    }

    public async ngAfterViewInit() {
        this.tabs.tabChanged.pipe(takeUntil(this.onDestroy)).subscribe(tab => this.service.setActiveTab(tab.label));

        this.service.fetchCases();
        this.service.searchCases();

        this.route.queryParams.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(params => {
            const initialTab: string = params.status || this.service.getActiveTab();
            if (initialTab) {
                const activeTab = this.tabs.tabs.find(tab => tab.label.toLowerCase() === initialTab.toLowerCase());
                if (activeTab) {
                    this.tabs.activateTab(activeTab);
                }
            }
        });
    }

    public createNewCase() {
        this.router.navigate(['cases', 'open', 'instructions']);
    }

    public selectCase(conversation: CaseConversation) {
        this.navCtrl.setDirection('forward', this.platform.is('mobile'));
        this.router.navigate(['cases', conversation.id]);
    }

    public get allCases(): CaseConversation[] {
        return this.service.myCases;
    }

    public get openCases(): CaseConversation[] {
        return this.service.openCases;
    }

    public get resolvedCases(): CaseConversation[] {
        return this.service.resolvedCases;
    }
}
