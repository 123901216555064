import { GroupEvent } from './group-event';
import { GroupMessageParticipantRemovedEvent as ApiGroupMessageParticipantRemovedEvent } from '@scaffold/mediccoms-api-client/models/group-message-participant-removed-event';
import { ConversationParticipantRemoved } from '../rtm-events.model';
import { GroupMessageParticipant } from './group-message-participant';

export class GroupMessageParticipantRemovedEvent extends GroupEvent {

    public type: string;
    public removedBy: string;
    public participant: GroupMessageParticipant;

    public static mapFromApi(data: Partial<ApiGroupMessageParticipantRemovedEvent>): Partial<GroupMessageParticipantRemovedEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            type: 'participant_removed',
            removedBy: data.removed_by,
            participant: GroupMessageParticipant.mapFromApi(data.participant) as Required<GroupMessageParticipant>,
            sentAt: data.sent_at,
        };
    }

    public static mapFromRtms(data: Partial<ConversationParticipantRemoved>): Partial<GroupMessageParticipantRemovedEvent> {
        if (!data) {
            return null;
        }

        return {
            id: data.data.event_id,
            type: 'participant_removed',
            removedBy: data.sender_id,
            participant: GroupMessageParticipant.mapFromRtms(data.data.participant) as Required<GroupMessageParticipant>,
            sentAt: data.timestamp,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            conversation: this.conversation,
            sentAt: this.sentAt,
            removedBy: this.removedBy,
            participant: this.participant,
        };
    }

    public fill(data: Partial<GroupMessageParticipantRemovedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.removedBy !== undefined) {
            this.removedBy = data.removedBy;
        }
        if (data.participant !== undefined) {
            this.participant = GroupMessageParticipant.createOne(data.participant);
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }
}
