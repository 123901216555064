import { CaseMessagingService } from './abstract.service';
import { CaseConversation } from '../models/case-conversation';
import { merge, Observable, Subject } from 'rxjs';
import { CaseConversationStore } from '../stores/case-conversation.store';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { AssignCaseRequest, CreateCaseRequest, SearchCaseEventsRequest } from '@scaffold/mediccoms-api-client/requests';
import { CaseConversationEventType } from '@scaffold/mediccoms-api-client/types';
import { CaseEventStore } from '../stores/case-event.store';
import { CaseEvent } from '../models/case-conversation-events/case-event';
import { CaseOpenedEvent } from '../models/case-conversation-events/case-opened-event';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { DatabaseService } from '../database.service';
import { CaseResolvedEvent } from '../models/case-conversation-events/case-resolved-event';
import { CaseMessageEvent } from '../models/case-conversation-events/case-message-event';
import { CaseAcceptedEvent } from '../models/case-conversation-events/case-accepted-event';
import { CaseAssignedEvent } from '../models/case-conversation-events/case-assigned-event';
import { CaseAssignedEventData, RtmsEvent } from '../models/rtm-events.model';
import { RealTimeMessagingService } from '../real-time-messaging.service';
import { HttpEventType } from '@angular/common/http';
import { User } from '../models/user';
import { UserService } from '../user.service';
import { PrivateSettingsService } from '../private-settings.service';
import { OrganisationService } from '../organisation.service';
import { Router } from '@angular/router';
import { File as CordovaFile } from '@ionic-native/file/ngx';

export class BrowserCaseMessagingService extends CaseMessagingService {

    protected conversations = new CaseConversationStore();
    protected events = new CaseEventStore();
    protected isReady = false;
    protected readySubject: Subject<boolean> = new Subject<boolean>();

    protected currentUser: User = null;

    constructor(
        public api: ApiClientService,
        public cordovaFile: CordovaFile,
        public database: DatabaseService,
        public organisationService: OrganisationService,
        public router: Router,
        public rtms: RealTimeMessagingService,
        public storage: PrivateSettingsService,
        public user: UserService,
    ) {
        super(api, cordovaFile, database, router, rtms, storage, user);

        this.user.getMe().subscribe(me => this.currentUser = me);

        rtms.cases.onCaseCreated().subscribe(conversation => this.rtmsConversationCallback(conversation));
        rtms.cases.onMessage().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onCaseOpened().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onCaseAccepted().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onCaseAssigned().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onCaseResolved().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onMessageRead().subscribe(event => this.rtmsCallback(event));
        rtms.cases.onMessageDelivered().subscribe(event => this.rtmsCallback(event));
    }

    private rtmsConversationCallback(conversationEvent: RtmsEvent) {
        const conversation = this.conversations.findById(conversationEvent.target_id) || CaseConversation.createOne({
            id: conversationEvent.target_id
        });
        conversation.fillFromRtms(conversationEvent);
        this.conversations.store(conversation);
    }

    private rtmsCallback(event: RtmsEvent) {
        const conversation = this.conversations.findById(event.target_id) || CaseConversation.createOne({id: event.target_id});
        const storeEvent = this.events.findById(event.data.message_id) || CaseConversation.createEvent({
            id: event.data.message_id,
            conversation: conversation.id,
            type: event.event,
        });
        storeEvent.fillFromRtms(event);
        storeEvent.conversation = conversation.id;
        this.events.store(storeEvent);
        conversation.addEvent(storeEvent);

        switch (event.event) {
            case 'assigned':
                const data = event.data as CaseAssignedEventData;
                const assignedTo = data.assigned_to;
                const assignee = this.user.users.findById(assignedTo.id);
                assignee.fill({
                    firstName: assignedTo.first_name,
                    lastName: assignedTo.last_name,
                    image: assignedTo.image,
                    title: assignedTo.title,
                });
                this.user.users.store(assignee);
                conversation.assignee = assignee;
                break;
            case 'opened':
                conversation.openedAt = event.timestamp;
                break;
            case 'accepted':
                conversation.acceptedAt = event.timestamp;
                break;
            case 'resolved':
                conversation.resolvedAt = event.timestamp;
                break;
        }

        this.conversations.store(conversation);
    }

    public async acceptCase(caseId: string): Promise<CaseConversation> {
        const {event = null} = await this.api.cases.acceptCase(caseId).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseAcceptedEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);
            conversation.acceptedAt = event.accepted_at;
            this.conversations.store(conversation);

            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async assignCase(caseId: string, assigneeId: string): Promise<CaseConversation> {
        const assignCaseRequest: AssignCaseRequest = {
            assignee_id: assigneeId,
        };

        const {event = null} = await this.api.cases.assignCase(caseId, assignCaseRequest).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseAssignedEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);

            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async cancelCase(caseId: string): Promise<boolean> {
        const {cancelled = null} = await this.api.cases.cancelCase(caseId).toPromise();
        const conversation = this.conversations.findById(caseId);

        if (cancelled && conversation) {
            this.conversations.remove(conversation);
        }

        return Promise.resolve(cancelled);
    }

    public async createCase(recipientId: string, patientId: string): Promise<CaseConversation> {
        const createCaseRequest: CreateCaseRequest = {
            recipient_id: recipientId,
            patient_id: patientId,
        };

        const response = await this.api.cases.createCase(createCaseRequest).toPromise();

        if (response.case) {
            const conversation = this.conversations.findById(response.case.id) || CaseConversation.createOne({id: response.case.id});
            conversation.fillFromApi(response.case);
            this.conversations.store(conversation);
            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async fetchAllCases(): Promise<CaseConversation[]> {
        let currentPage = 1;
        let perPage = 0;
        let totalItems = 0;

        do {
            const response = await this.api.cases.allCases({
                page: currentPage,
            }).toPromise();
            if (!response) {
                return;
            }
            for (const apiCase of response.cases) {
                currentPage = response.pagination.current_page;
                perPage = response.pagination.per_page;
                totalItems = response.pagination.total_items;
                const conversation = this.conversations.findById(apiCase.id) || CaseConversation.createOne({
                    id: apiCase.id
                });
                conversation.fillFromApi(apiCase);
                this.conversations.store(conversation);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        return this.conversations.recentCases();
    }

    public async fetchCase(caseId: string): Promise<CaseConversation> {
        const response = await this.api.cases.getCase(caseId).toPromise();
        const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: response.case.id});
        conversation.fillFromApi(response.case);
        return this.conversations.store(conversation);
    }

    public async fetchEvents(caseId: string, request: SearchCaseEventsRequest): Promise<CaseConversationEventType[]> {
        const response = await this.api.cases.searchEvents(caseId, request).toPromise();

        if (!response) {
            return;
        }

        const {events = []} = response;

        const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
        const storedEvents: CaseEvent[] = [];

        for (const apiEvent of events) {
            const event = this.events.findById(apiEvent.id) || CaseConversation.createEvent({
                id: apiEvent.id,
                type: apiEvent.type,
                conversation: conversation.id,
            });
            event.fillFromApi(apiEvent);
            event.conversation = conversation.id;
            storedEvents.push(event);
            this.events.store(event);

            switch (apiEvent.type) {
                case 'opened':
                    conversation.openedAt = apiEvent.sent_at;
                    break;
                case 'accepted':
                    conversation.acceptedAt = apiEvent.accepted_at;
                    break;
                case 'resolved':
                    conversation.resolvedAt = apiEvent.resolved_at;
                    break;
            }
        }
        conversation.addEvents(storedEvents);
        this.conversations.store(conversation);

        return events;
    }

    public getAllCases(): Observable<CaseConversation[]> {
        return new Observable<CaseConversation[]>(observer => {
            const conversations = this.conversations;

            observer.next(conversations.recentCases());

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                debounceTime(10)
            ).subscribe(() => {
                observer.next(this.conversations.recentCases());
            });

            return () => subscription.unsubscribe();
        });
    }

    public getMyCases(): Observable<CaseConversation[]> {
        return new Observable<CaseConversation[]>(observer => {
            const conversations = this.conversations;

            if (this.currentUser) {
                observer.next(conversations.recentCases(this.currentUser.id));
            }

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                debounceTime(10),
            ).subscribe(() => {
                if (this.currentUser) {
                    observer.next(this.conversations.recentCases(this.currentUser?.id));
                }
            });

            return () => subscription.unsubscribe();
        });
    }

    public getCase(caseId: string): Observable<CaseConversation> {
        return new Observable<CaseConversation>(observer => {
            const conversations = this.conversations;

            observer.next(conversations.findById(caseId));

            const subscription = merge(
                conversations.onInsert(),
                conversations.onUpdate(),
                conversations.onRemove(),
            ).pipe(
                filter(conversation => conversation.id === caseId),
            ).subscribe(() => {
                observer.next(this.conversations.findById(caseId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getEvents(caseId: string): Observable<CaseEvent[]> {
        return new Observable<any[]>(observer => {
            const events = this.events;

            observer.next(events.filterByCaseId(caseId));

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => {
                    if (event.conversation !== undefined) {
                        return event.conversation === caseId;
                    }
                    return false;
                }),
                debounceTime(10),
            ).subscribe(() => {
                observer.next(this.events.filterByCaseId(caseId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public getPendingEvents(offlineCaseId: string): Observable<CaseEvent[]> {
        return new Observable<any[]>(observer => {
            const events = this.events;

            observer.next(events.offlineConversation(offlineCaseId));

            const subscription = merge(
                events.onInsert(),
                events.onUpdate(),
                events.onRemove(),
            ).pipe(
                filter(event => {
                    if (event.conversation !== undefined) {
                        return event.conversation === offlineCaseId;
                    }
                    return false;
                }),
                debounceTime(10),
            ).subscribe(() => {
                observer.next(this.events.offlineConversation(offlineCaseId));
            });

            return () => subscription.unsubscribe();
        });
    }

    public async openCase(caseId: string): Promise<CaseConversation> {
        const {event = null} = await this.api.cases.openCase(caseId).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseOpenedEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);
            conversation.openedAt = event.sent_at;
            this.conversations.store(conversation);

            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async readMessage(caseId: string, messageId: string): Promise<CaseConversation> {
        const {event = null} = await this.api.cases.readEvent(caseId, messageId).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseMessageEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            conversation.addEvent(storeEvent);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);

            return Promise.resolve(this.conversations.store(conversation));
        }

        return Promise.resolve(undefined);
    }

    public async resolveCase(caseId: string): Promise<CaseConversation> {
        const {event = null} = await this.api.cases.resolveCase(caseId).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseResolvedEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            storeEvent.conversation = conversation.id;
            this.events.store(storeEvent);
            conversation.addEvent(storeEvent);
            conversation.resolvedAt = event.resolved_at;
            this.conversations.store(conversation);

            return Promise.resolve(conversation);
        }

        return Promise.resolve(undefined);
    }

    public async searchAllCases(status: string | null, patientId: string | null, page?: number): Promise<CaseConversation[]> {
        const conversations = [];
        let currentPage = page ? page : 1;
        let perPage = 0;
        let totalItems = 0;

        do {
            const response = await this.api.cases.searchCases({
                status,
                patient_id: patientId,
                page: currentPage,
                resolved_within: 30,
            }).toPromise();
            if (!response) {
                return;
            }
            for (const apiCase of response.cases) {
                currentPage = response.pagination.current_page;
                perPage = response.pagination.per_page;
                totalItems = response.pagination.total_items;
                const conversation = this.conversations.findById(apiCase.id) || CaseConversation.createOne({
                    id: apiCase.id
                });
                conversation.fillFromApi(apiCase);
                this.conversations.store(conversation);
                conversations.push(conversation);
            }
            currentPage++;
        } while ((currentPage - 1) * perPage < totalItems);

        return conversations;
    }

    public async sendMessage(caseId: string, message: string, attachments: File[]): Promise<boolean> {
        const attachmentIds: string[] = [];

        if (attachments) {
            for (const attachment of attachments) {
                const response = await new Promise<string>((resolve, reject) => {
                    this.api.attachments.uploadImage(attachment).subscribe(result => {
                        if (result.type === HttpEventType.Response) {
                            resolve(result.body.image_id);
                        }
                    });
                });
                attachmentIds.push(response);
            }
        }

        const {event = null} = await this.api.cases.sendMessage(caseId, {
            message,
            attachments: attachmentIds
        }).toPromise();

        if (event) {
            const conversation = this.conversations.findById(caseId) || CaseConversation.createOne({id: caseId});
            const storeEvent = this.events.findById(event.id) || CaseMessageEvent.createOne({
                id: event.id,
                type: event.type,
                conversation: conversation.id,
            });
            storeEvent.fillFromApi(event);
            conversation.addEvent(storeEvent);
            this.conversations.store(conversation);

            storeEvent.conversation = conversation.id;
            return Promise.resolve(!!this.events.store(storeEvent));
        }

        return Promise.resolve(false);
    }

    public clear(): Promise<void> {
        this.conversations.clear();
        this.events.clear();
        return Promise.resolve(undefined);
    }

    public loadFromMemory(): Promise<void> {
        this.isReady = true;
        this.readySubject.next(true);
        return Promise.resolve(undefined);
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.isReady) {
                return resolve(true);
            }

            this.readySubject.asObservable().pipe(
                first()
            ).subscribe((ready) => resolve(ready));
        });
    }

    public getOfflineCases(): Promise<CaseConversation[]> {
        return new Promise<CaseConversation[]>(resolve => {
            const conversations = this.conversations;
            resolve(conversations.offlineOnly());
        });
    }

    public getOfflineCaseEvents(): Promise<CaseEvent[]> {
        return new Promise<CaseEvent[]>(resolve => {
            const events = this.events;
            resolve(events.offlineOnly());
        });
    }

    public getOfflineEventsForCase(caseId: string): Promise<CaseEvent[]> {
        return new Promise<CaseEvent[]>(resolve => {
            const events = this.events;
            resolve(events.offlineOnly().filter(event => event.offlineId === caseId));
        });
    }

    public getOfflineCase(caseId: string): Promise<CaseConversation> {
        return new Promise<CaseConversation>(resolve => {
            const conversations = this.conversations;
            resolve(conversations.findByOfflineId(caseId));
        });
    }

    public getCasesPromise(): Promise<CaseConversation[]> {
        return new Promise<CaseConversation[]>(resolve => {
            const conversations = this.conversations;
            resolve(conversations.hasBranches());
        });
    }

    public getCasePromise(caseId: string): Promise<CaseConversation> {
        return new Promise<CaseConversation>(resolve => {
            const conversations = this.conversations;
            resolve(conversations.findById(caseId));
        });
    }

    public completeOfflineActions(): Promise<boolean> {
        return Promise.resolve(true);
    }

    public cancelOfflineActions(): Promise<boolean> {
        return Promise.resolve(true);
    }
}
