import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from '../../services/database.service';
import { Subject } from 'rxjs';
import { User } from '../../services/models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { NetworkService } from '../../services/network.service';
import { AlertController } from '@ionic/angular';
import { NewMessageService } from './new-message.service';

@Component({
    selector: 'app-new-message-page',
    template: '',
    styleUrls: ['new-message.scss'],
})

export abstract class NewMessage implements OnDestroy, OnInit {

    currentMessenger = [];

    public currentUser: User;
    public onDestroy: Subject<void> = new Subject<void>();

    public get searchTerm(): string {
        return this.service.getSearchTerm();
    }

    public set searchTerm(value: string) {
        this.service.setSearchTerm(value);
    }

    public get users(): User[] {
        return this.service.getUsers();
    }

    constructor(
        private alertCtrl: AlertController,
        private api: ApiClientService,
        private database: DatabaseService,
        private network: NetworkService,
        private router: Router,
        private service: NewMessageService,
        private userService: UserService,
    ) {

    }

    public ngOnDestroy(): void {
        this.searchTerm = '';
        this.onDestroy.next();
    }

    public async ngOnInit() {
        this.userService.getMe().pipe(takeUntil(this.onDestroy)).subscribe(me => this.currentUser = me);
        this.userService.getUsers().pipe(takeUntil(this.onDestroy)).subscribe(users => this.setUsers(users));
    }

    public ionViewWillEnter() {
        this.userService.fetchUsers();
    }

    public setUsers(users: User[]) {
        this.service.setUsers(users);
    }

    public createGroupMessage() {
        this.router.navigate(['new-message', 'group']);
    }

}
