import { Component } from '@angular/core';
import { MessageGroupDetail } from './message-group-detail';

@Component({
    selector: 'app-phone-message-group-detail-page',
    templateUrl: 'phone-message-group-detail.html',
    styleUrls: ['message-group-detail.scss', 'phone-message-group-detail.scss'],
})
export class PhoneMessageGroupDetailPage extends MessageGroupDetail {



}
