import { Component } from '@angular/core';
import { Login } from './login';

@Component({
    selector: 'app-tablet-login-page',
    templateUrl: 'tablet-login.html',
    styleUrls: ['login.scss', 'tablet-login.scss'],
})
export class TabletLoginPage extends Login {

}
